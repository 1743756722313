import { Box, makeStyles, styled } from "@material-ui/core"
import { rem } from "polished"
import { palette, spacing } from "components/theme"

export const Container = styled(Box)(() => ({
  display: "grid",
  gridTemplateRows: "auto 1fr auto",
  gridTemplateColumns: "1fr",
  color: palette.grey[800],
}))

export const ContainerMainTitle = styled(Box)(() => ({
  display: "flex",
  alignSelf: "flex-start",
  "& h2": {
    margin: spacing(1, 0),
  },
}))

export const ContainerContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: spacing(6),
  "& h2": {
    marginBottom: spacing(2),
  },
  "& p": {
    marginBottom: spacing(4),
  },
}))

export const InputContainer = styled(Box)(() => ({
  width: rem("332px"),
}))

export const settingsStyle = makeStyles(() => ({
  separator: {
    width: "90%",
    margin: spacing(5, 3),
  },
}))

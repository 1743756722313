import * as React from "react"

import { Table } from "components"
import { ENV } from "constants/env"
import { stepGroupColumns } from "../constants/rulesPricing"
import { StepGroupListProvider, useStepGroupList } from "../components/StepGroupListContext"

const StepGroupListChildren = () => {
  const { data, isStepGroupsLoading } = useStepGroupList()

  return (
    <Table
      rows={data}
      columns={stepGroupColumns}
      loading={isStepGroupsLoading}
      pagination
      hideFooterSelectedRowCount
      autoPageSize={false}
      pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
      filterMode="client"
    />
  )
}

const StepGroupList = () => (
  <StepGroupListProvider>
    <StepGroupListChildren />
  </StepGroupListProvider>
)

export { StepGroupList }

import { Box, styled } from "@material-ui/core"
import { rem } from "polished"
import { theme } from "../theme"

export const LogoContainer = styled(Box)(() => ({
  padding: theme.spacing(3),
  width: rem("148px"),
  height: rem("16px"),
  "& img": {
    maxWidth: "100%",
    height: "auto",
  },
}))

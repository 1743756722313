import { IAPISetting, IAPISettingRequest } from "modules/Settings/models/settings"
import { api } from "./api"

export const getSettings = async (): Promise<IAPISetting[]> => {
  const { data } = await api.settings.get()
  return data
}

export const updateSettingById = async (settingId: string, settingData: IAPISettingRequest): Promise<IAPISetting> => {
  const { data } = await api.settings.update(settingId, settingData)
  return data
}

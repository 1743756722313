import React from "react"
import { GridOverlay } from "@mui/x-data-grid"

const NoRowsOverlay = (): JSX.Element => {
  return (
    <GridOverlay>
      <div />
    </GridOverlay>
  )
}

export { NoRowsOverlay }

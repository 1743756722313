import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles({
  root: {
    fontWeight: "bold",
    height: "100%",
    // eslint-disable-next-line no-useless-computed-key
    ["& fieldset"]: {
      border: 0,
    },
  },
  formControl: {
    height: "100%",
  },
  input: {
    height: "100%",
  },
})

import { GridColDef } from "@mui/x-data-grid"
import { IStepGroupType } from "modules/Rules-Pricing/models/calculator"
import { leftNonEditableColumn, leftNonEditableTooltipColumn, priceFormatterParser } from "modules/TableGlobalStyles"
import { dateValueFormatter } from "utils/table"

const publishedColumns: GridColDef[] = [
  {
    field: "productVsId",
    headerName: "VS ID",
    type: "string",
    minWidth: 130,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "calculatorName",
    headerName: "Calculator",
    type: "string",
    minWidth: 220,
    ...leftNonEditableTooltipColumn,
    filterable: false,
    sortable: false,
  },
  {
    field: "userName",
    headerName: "User",
    type: "string",
    minWidth: 130,
    ...leftNonEditableTooltipColumn,
    filterable: false,
    sortable: false,
  },
  {
    field: "publicationDate",
    headerName: "Published Date",
    type: "date",
    minWidth: 160,
    ...leftNonEditableColumn,
    valueFormatter: dateValueFormatter,
    filterable: false,
    sortable: false,
  },
  {
    field: "created",
    headerName: "Target Date",
    type: "date",
    minWidth: 160,
    ...leftNonEditableColumn,
    valueFormatter: dateValueFormatter,
    filterable: false,
    sortable: false,
  },
  {
    field: "ecomname",
    headerName: "eCom Name",
    type: "string",
    minWidth: 180,
    ...leftNonEditableTooltipColumn,
    filterable: false,
    sortable: false,
  },
  {
    field: "customerGroup",
    headerName: "Customer Group",
    type: "string",
    minWidth: 180,
    ...leftNonEditableTooltipColumn,
  },
]

const getDynamicColumns = (stepGroupTypes: IStepGroupType[]) => {
  return stepGroupTypes.map(({ name, displayName }) => ({
    field: name,
    headerName: displayName,
    type: "number",
    minWidth: displayName.length > 12 ? 180 : 150,
    ...leftNonEditableColumn,
    ...priceFormatterParser,
    filterable: false,
    sortable: false,
  }))
}

export { publishedColumns, getDynamicColumns }

import { Box, styled } from "@material-ui/core"
import { rem } from "polished"
import { palette, spacing } from "../theme"

export const HeaderContainer = styled("header")(() => ({
  padding: spacing(1, 0),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  "& button": {
    marginRight: spacing(2),
  },
}))

export const EditContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  "& h1": {
    fontSize: rem("26px"),
  },
  "& button": {
    marginLeft: spacing(3),
    "& svg": {
      fill: palette.grey[400],
    },
  },
}))

export const Actions = styled(Box)(() => ({
  display: "flex",
  gap: spacing(2),
  "& button": {
    margin: 0,
    flex: "1 0 auto",
  },
  "& div[class*='MuiFormControl-root']": {
    minWidth: rem("200px"),
  },
}))

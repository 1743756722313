import React from "react"
import { AppBar, Toolbar, ThemeProvider, IconButton, Box } from "@material-ui/core"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import { Logo } from "../Logo/Logo"
import { NavBarTheme } from "./styles"

const NavBar = (): JSX.Element => {
  return (
    <ThemeProvider theme={NavBarTheme}>
      <AppBar position="relative" color="default" elevation={1}>
        <Toolbar>
          <Box>
            <Logo />
          </Box>
          <Box display="flex">
            <IconButton className="user-button">
              <AccountCircleIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export { NavBar }

import React, { useEffect, useState } from "react"
import { Box, InputAdornment, Typography } from "@material-ui/core"
import HistoryIcon from "@material-ui/icons/History"
import { useFormik } from "formik"
import {
  Button,
  Tooltip,
  StepFooter,
  Spacer,
  Separator,
  Input,
  CalculatorIcon,
  SubtitleIcon,
  FunctionIcon,
} from "components"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"
import { getSettings, updateSettingById } from "services"
import numeral from "numeral"
import { useHasReadWriteRole } from "utils/user"
import { Container, ContainerContent, ContainerMainTitle, InputContainer, settingsStyle } from "../styles"
import {
  IAPISetting,
  IAPISettingRequest,
  ISettingsForm,
  settingNames,
  settingsFormInitialValues,
} from "../models/settings"

const SettingsForm = (): JSX.Element => {
  const userHasReadWriteRole = useHasReadWriteRole()

  const dispatch = useDispatch()

  const iterationTooltip =
    "Limit of iterations within circular reference. Calculation stops when this limit is reached."

  const thresholdTooltip = "Iterations will finish if the price variation is less or equals to threshold."

  const calculatorHistoryTooltip = "The amount of runs stored as a calculation history."

  const atypicalThresholdTooltip =
    "The price will be marked as atypical if the percentage of variation is greater or less than this threshold compared with the last price published of a product."

  const queryClient = useQueryClient()

  const { isLoading, data: settings } = useQuery("getSettings", getSettings)

  const { mutate } = useMutation(
    ({ settingId, settingData }: { settingId: string; settingData: IAPISettingRequest }) =>
      updateSettingById(settingId, settingData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getSettings")
        dispatch(
          uiActions.showNotification({
            children: translations.general.changesSaved,
            severity: "success",
          })
        )
      },
      onError: () => {
        dispatch(
          uiActions.showNotification({
            children: translations.general.somethingWrong,
            severity: "error",
          })
        )
      },
    }
  )

  const [mappedSettings, setMappedSettings] = useState(settingsFormInitialValues)

  useEffect(() => {
    if (!isLoading && settings?.length) {
      const currentSettings = settings?.reduce(
        (obj, item) => ({ ...obj, [item.name]: item.value }),
        {}
      ) as ISettingsForm
      const atypicalThreshold = numeral(currentSettings.ATYPICAL_PRICE_THRESHOLD || 0).multiply(100)

      currentSettings.ATYPICAL_PRICE_THRESHOLD = (atypicalThreshold.value() || 0).toString()
      setMappedSettings(currentSettings)
    }
  }, [settings, isLoading])

  const getCurrentSettingByName = (settingName: settingNames) =>
    settings?.find((setting: IAPISetting) => setting.name === settingName)

  const buildSettingMutationData = (currentSetting: IAPISetting, value: string) => ({
    settingId: currentSetting.id,
    settingData: {
      name: currentSetting.name,
      value: value || currentSetting.value,
    },
  })

  const formik = useFormik({
    initialValues: mappedSettings,
    onSubmit: values => {
      if (values.MAX_ITERATIONS !== mappedSettings.MAX_ITERATIONS) {
        const currentSetting = getCurrentSettingByName(settingNames.MAX_ITERATIONS)
        if (currentSetting && values?.MAX_ITERATIONS) {
          mutate(buildSettingMutationData(currentSetting, values.MAX_ITERATIONS))
        }
      }
      if (values.THRESHOLD !== mappedSettings.THRESHOLD) {
        const currentSetting = getCurrentSettingByName(settingNames.THRESHOLD)
        if (currentSetting && values?.THRESHOLD) {
          mutate(buildSettingMutationData(currentSetting, values.THRESHOLD))
        }
      }
      if (values.MAX_RUN_HISTORY !== mappedSettings.MAX_RUN_HISTORY) {
        const currentSetting = getCurrentSettingByName(settingNames.MAX_RUN_HISTORY)
        if (currentSetting && values?.MAX_RUN_HISTORY) {
          mutate(buildSettingMutationData(currentSetting, values.MAX_RUN_HISTORY))
        }
      }
      if (values.ATYPICAL_PRICE_THRESHOLD !== mappedSettings.ATYPICAL_PRICE_THRESHOLD) {
        const currentSetting = getCurrentSettingByName(settingNames.ATYPICAL_PRICE_THRESHOLD)
        if (currentSetting && values?.ATYPICAL_PRICE_THRESHOLD) {
          const numeric = numeral(values.ATYPICAL_PRICE_THRESHOLD)

          const percent = numeric.divide(100)
          mutate(buildSettingMutationData(currentSetting, percent?.value()?.toString() || currentSetting.value))
        }
      }
    },
    isInitialValid: false,
    enableReinitialize: true,
  })

  const classes = settingsStyle()

  return (
    <Container>
      <ContainerMainTitle>
        <Typography variant="h2">Application Settings</Typography>
      </ContainerMainTitle>

      <ContainerContent>
        <Spacer size={4} />
        <SubtitleIcon title="Circular Reference" icon={<CalculatorIcon />} />
        <Typography variant="h2">Set the General Circular References Settings</Typography>
        <Spacer size={2} />
        <InputContainer>
          <Tooltip placement="left" title={iterationTooltip}>
            <Box>
              <Input
                label="Max number of iterations"
                placeholder="Type max number of iterations"
                name="MAX_ITERATIONS"
                value={formik.values.MAX_ITERATIONS}
                onChange={formik.handleChange}
                hasError={Boolean(formik.errors.MAX_ITERATIONS && formik.touched.MAX_ITERATIONS)}
                onBlur={formik.handleBlur}
              />
            </Box>
          </Tooltip>
          <Spacer size={3} />
          <Tooltip placement="left" title={thresholdTooltip}>
            <Box>
              <Input
                label="Threshold"
                name="THRESHOLD"
                placeholder="Type threshold"
                value={formik.values.THRESHOLD}
                onChange={formik.handleChange}
                hasError={Boolean(formik.errors.THRESHOLD && formik.touched.THRESHOLD)}
                onBlur={formik.handleBlur}
              />
            </Box>
          </Tooltip>
          <Spacer size={2} />
        </InputContainer>

        <Separator className={classes.separator} />

        <SubtitleIcon title="Calculator History" icon={<HistoryIcon />} />

        <Typography variant="h2">Set the General Calculator History</Typography>
        <Spacer size={2} />
        <Tooltip placement="left" title={calculatorHistoryTooltip}>
          <InputContainer>
            <Input
              label="Calculator History"
              name="MAX_RUN_HISTORY"
              placeholder="Type calculator history"
              value={formik.values.MAX_RUN_HISTORY}
              onChange={formik.handleChange}
              hasError={Boolean(formik.errors.MAX_RUN_HISTORY && formik.touched.MAX_RUN_HISTORY)}
              onBlur={formik.handleBlur}
            />
          </InputContainer>
        </Tooltip>

        <Separator className={classes.separator} />

        <SubtitleIcon title="Calculation Results" icon={<FunctionIcon />} />

        <Typography variant="h2">Atypical Price Threshold</Typography>
        <Spacer size={2} />
        <Tooltip placement="left" title={atypicalThresholdTooltip}>
          <InputContainer>
            <Input
              label="Threshold"
              name="ATYPICAL_PRICE_THRESHOLD"
              placeholder="Type atypical price threshold"
              inputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={formik.values.ATYPICAL_PRICE_THRESHOLD}
              onChange={formik.handleChange}
              hasError={Boolean(formik.errors.ATYPICAL_PRICE_THRESHOLD && formik.touched.ATYPICAL_PRICE_THRESHOLD)}
              onBlur={formik.handleBlur}
            />
          </InputContainer>
        </Tooltip>
      </ContainerContent>

      <StepFooter>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          onClick={() => formik.resetForm()}
          isDisabled={!formik.dirty || !userHasReadWriteRole}
        >
          Reset
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          isDisabled={!formik.dirty || !userHasReadWriteRole}
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
      </StepFooter>
    </Container>
  )
}
export { SettingsForm }

import * as React from "react"
import { useMutation, useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { UseAutocompleteProps } from "@material-ui/lab"
import _ from "lodash"

import { createCustomer, getSubGroups } from "services"
import { uiActions } from "store/ui-slice"
import { Autocomplete, Dialog, Input, ISelectOption, Spacer } from "components"
import { ICreateCustomerRequest } from "../models/customer"
import { ICustomerGroup } from "../models/customerGroup"

interface IProps {
  customerGroups: ICustomerGroup[]
  handleClose: () => void
  handleRefetch: () => void
}

type CustomerGroupsAutocomplete = UseAutocompleteProps<ISelectOption, false, false, true>

const CreateCustomerDialog = ({ customerGroups, handleClose, handleRefetch }: IProps): JSX.Element => {
  const dispatch = useDispatch()

  const [state, setState] = React.useState({
    fields: {
      name: "",
      resellerId: "",
      customerGroupName: "",
      customerGroupSubgroup: "",
    },
    errors: {
      name: "",
      resellerId: "",
      customerGroupName: "",
      customerGroupSubgroup: "",
    },
  })

  const { fields, errors } = state

  const { data: subGroups } = useQuery(["getSubGroups"], () => getSubGroups())

  const updater = (newState: Partial<typeof state>) => {
    setState(prevState => ({
      ...prevState,
      fields: { ...prevState.fields, ...newState.fields },
      errors: { ...prevState.errors, ...newState.errors },
    }))
  }

  const { isLoading: isCreatingCustomer, mutate } = useMutation(
    (payload: ICreateCustomerRequest) => createCustomer(payload),
    {
      onSuccess: handleRefetch,
      onError: (error: string) => {
        dispatch(
          uiActions.showNotification({
            children: error.toString(),
            severity: "error",
          })
        )
      },
    }
  )

  const handleChange = React.useCallback(
    (field: keyof typeof state["fields"]) => (event: React.ChangeEvent) => {
      const { value } = event.currentTarget as HTMLInputElement

      const trimmedValue = _.trim(value)

      updater({
        fields: { [field]: value },
        errors: { [field]: trimmedValue ? "" : "Field is required" }, // @todo description field shouldn't be mandatory
      } as typeof state)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleAutocompleteCustomerGroupValue: CustomerGroupsAutocomplete["onChange"] = (event, value) => {
    const trimmedLabel = _.trim(((value || {}) as ISelectOption).label)

    updater({
      fields: { customerGroupName: trimmedLabel, customerGroupSubgroup: "" },
      errors: {
        customerGroupName: trimmedLabel ? "" : "Field is required",
        customerGroupSubgroup: "",
      },
    } as typeof state)
  }

  const handleAutocompleteCustomerGroupInput: CustomerGroupsAutocomplete["onInputChange"] = (event, value, reason) => {
    if (fields.customerGroupName && reason !== "reset") {
      updater({
        fields: { customerGroupName: "" },
        errors: { customerGroupName: "Field is required" },
      } as typeof state)
    }
  }

  const handleAutocompleteTierValue: CustomerGroupsAutocomplete["onChange"] = (event, value) => {
    const trimmedLabel = _.trim(((value || {}) as ISelectOption).label)

    updater({
      fields: { customerGroupSubgroup: trimmedLabel },
      errors: {
        customerGroupSubgroup: trimmedLabel ? "" : "Field is required",
      },
    } as typeof state)
  }

  const handleAutocompleteTierInput: CustomerGroupsAutocomplete["onInputChange"] = (event, value, reason) => {
    if (fields.customerGroupName && reason !== "reset") {
      updater({
        fields: { customerGroupSubgroup: "" },
        errors: { customerGroupSubgroup: "Field is required" },
      } as typeof state)
    }
  }

  const handleAccept = () => {
    if (isCreatingCustomer) {
      return
    }

    const isInvalid = _.some(fields, _.isEmpty)

    if (isInvalid) {
      const pickedFields = _.pickBy(fields, _.isEmpty)

      const messages = _.mapValues(pickedFields, () => "Field is required")

      updater({ errors: messages } as typeof state)

      return
    }

    mutate(fields)
  }

  const options = React.useMemo(() => {
    const result = _.map(customerGroups, item => ({
      value: item.id.toString(),
      label: item.name,
    }))

    return result
  }, [customerGroups])

  const tierOptions = React.useMemo(() => subGroups?.map(subGroup => ({ value: subGroup, label: subGroup })) || [], [
    subGroups,
  ])

  return (
    <Dialog
      open
      title="Create Customer"
      onClose={handleClose}
      primaryActionHandler={isCreatingCustomer ? ((undefined as unknown) as () => void) : handleAccept}
      secondaryActionLabel="Cancel"
      primaryActionLabel={isCreatingCustomer ? "Creating" : "Create"}
    >
      <Input
        autoFocus
        hasError={Boolean(errors.name)}
        label="Name"
        type="text"
        value={fields.name}
        onChange={handleChange("name")}
      />
      <Spacer size={3} />
      <Input
        hasError={Boolean(errors.resellerId)}
        label="Site Id"
        type="text"
        value={fields.resellerId}
        onChange={handleChange("resellerId")}
      />
      <Spacer size={3} />
      <Autocomplete
        hasError={Boolean(errors.customerGroupName)}
        value={fields.customerGroupName}
        name="customerGroupName"
        options={options}
        label="Customer Group Name"
        onChange={handleAutocompleteCustomerGroupValue}
        onInputChange={handleAutocompleteCustomerGroupInput}
      />
      <Spacer size={3} />
      <Autocomplete
        hasError={Boolean(errors.customerGroupSubgroup)}
        value={fields.customerGroupSubgroup}
        name="customerGroupSubgroup"
        options={tierOptions}
        label="Tier"
        onChange={handleAutocompleteTierValue}
        onInputChange={handleAutocompleteTierInput}
      />
    </Dialog>
  )
}

export { CreateCustomerDialog }

import { Box, styled } from "@material-ui/core"
import { rem } from "polished"
import { palette, spacing } from "../theme"

export const MainContainer = styled(Box)(() => ({
  display: "flex",
  height: "100vh",
}))

export const Content = styled(Box)(() => ({
  flexGrow: 1,
  padding: spacing(7, 4),
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "auto auto 1fr auto",
  "&.main-content": {
    paddingLeft: rem("268px"),
    paddingBottom: 0,
  },
}))

type SpacerProps = {
  size?: number
}
export const Spacer = styled(Box)(({ size = 1 }: SpacerProps) => ({
  height: spacing(size),
}))

export const Separator = styled(Box)(() => ({
  height: rem("1px"),
  width: "100%",
  backgroundColor: palette.grey[300],
}))

export const ContentCenter = styled(Box)(() => ({
  alignItems: "center",
}))

export const StepFooter = styled("footer")(() => ({
  borderTop: `1px solid ${palette.grey[300]}`,
  display: "flex",
  justifyContent: "center",
  padding: spacing(3),
  gridGap: spacing(2),
}))

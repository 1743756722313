import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useMutation } from "react-query"

import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"
import { runCalculation } from "services/calculator"
import { ROUTES } from "constants/routes"
import { ICalculateEventRequest } from "modules/Rules-Pricing/models/calculator"
import { useCalculatorQueryKey } from "../useCalculator"

export const useRunCalculator = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const [, id] = useCalculatorQueryKey()

  const calculateEventRequest: ICalculateEventRequest = { calculators: [id] }

  const { isLoading, mutate: runCalc } = useMutation(() => runCalculation(calculateEventRequest), {
    onSuccess: params => {
      dispatch(
        uiActions.showNotification({
          children: translations.alert.calculator.success,
          severity: "success",
        })
      )

      if (params.executed.length) {
        const [{ id: eventId }] = params.executed

        history.push(`${ROUTES.RULES_PRICING.path}/${id}/price-history`, {
          calculationUuid: eventId,
        })
      } else {
        dispatch(
          uiActions.showNotification({
            children: translations.general.calculatorRunning,
            severity: "warning",
          })
        )
      }
    },
    onError: (error: InstanceType<typeof Error>) => {
      dispatch(
        uiActions.showNotification({
          children: error.message || JSON.stringify(error),
          severity: "error",
        })
      )
    },
  })

  return { isLoading, runCalc }
}

import axios, { AxiosError } from "axios"
import { ENV } from "constants/env"
import { getAuthCookie } from "utils/user"

const API_DOMAIN = `${ENV.API.URL}/pricingtool/api`

const API_APPSERVER_DOMAIN = `${ENV.API_APPSERVER.URL}/appserver`

const apiClient = axios.create({
  baseURL: API_DOMAIN,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Username: getAuthCookie()?.data?.userName,
  },
})

const apiClientAppserver = axios.create({
  baseURL: API_APPSERVER_DOMAIN,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
})

// @todo complete this interceptor
apiClient.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    if (!error.response) {
      return Promise.reject(error)
    }
    switch (error.response.status) {
      case 401:
        throw Error("Unauthorized user")
      case 409:
        if (error.request.responseURL.includes("bulk")) {
          throw Error("There is a bulk upload in progress, please try again later")
        }
        throw Error("Conflict")
      case 500:
        if (error.request.responseURL.includes("publications") && error.response.data.message.includes("active")) {
          throw Error(error.response.data.message)
        }
        throw Error("Internal server error")

      default:
        throw Error(error.response.data.message.toString())
    }
  }
)

export { apiClient, apiClientAppserver, API_DOMAIN, API_APPSERVER_DOMAIN }

import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Typography } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import _ from "lodash"

import type { CalculatorRulesLocationState, IRuleState } from "typings/modules"
import { RootState } from "store"
import { useQueryCache } from "hooks"
import { Button, HeaderContainer, Spacer } from "components"
import { ROUTES } from "constants/routes"
import CalculatorStepRule from "./CalculatorStepRule"
import CalculatorRulesProvider from "../context/CalculatorRulesContext"
import useCalculatorRules from "../hooks/use-calculator-rules"

/**
 * @todo Split `progress` component
 * @author Daniel Infante <dinfante@makingsense.com>
 */
const CalculatorRulesUpsertContent = () => {
  const { ruleStates, handleAddRule } = useCalculatorRules()

  const history = useHistory<CalculatorRulesLocationState>()

  const { mode, calculator, stepGroup, step } = history.location.state

  const stepQueryKey = `calculator-${calculator.id}-step-${step.id}`

  const cachedStepQuery = useQueryCache(stepQueryKey)

  const handleCancel = () => {
    history.push(`${ROUTES.RULES_PRICING.path}/${calculator.id}`)
  }

  const actionStatus = useSelector((selectorState: RootState) => selectorState.ui)

  /**
   * @description read-only mode
   */
  const isWatchMode = mode === "watch"

  const title = `${calculator.name} - ${stepGroup.name} - ${step.name}`

  const [defaultRule, childrenRules] = React.useMemo(() => {
    if (mode === "watch") {
      return [null, ruleStates]
    }

    const [newFirstRule, ...rules] = ruleStates

    const isEmptyRestRules = _.size(rules) === 0

    let children: IRuleState[] = rules

    if (!isEmptyRestRules && newFirstRule.id) {
      children = ruleStates
    }

    let firstRule = newFirstRule

    if (!mode || mode === "edit") {
      if (isEmptyRestRules) {
        children.push(newFirstRule)
      }

      firstRule = null
    }

    return [firstRule, children]
  }, [ruleStates, mode])

  return (
    <>
      <HeaderContainer>
        <div>
          <Button variant="text" onClick={handleCancel} icon={<ArrowBack />} size="small">
            Back
          </Button>
          <Spacer size={1} />
          <Typography variant="h5">{title}</Typography>
        </div>
        {!isWatchMode && (
          <Button onClick={handleCancel} variant="outlined" isDisabled={actionStatus.isLoading}>
            Cancel
          </Button>
        )}
      </HeaderContainer>
      <div>
        {defaultRule && (
          <CalculatorStepRule key={defaultRule.internalId} rule={defaultRule} isFakeRule canEditCondition={false} />
        )}
        {!defaultRule && !isWatchMode && (
          <Button onClick={handleAddRule} variant="outlined" isDisabled={cachedStepQuery.state.isFetching}>
            + Add New Rule
          </Button>
        )}
        <Spacer size={2} />
        {childrenRules.map(rule => (
          <CalculatorStepRule key={rule.internalId} rule={rule} isFakeRule={false} canEditCondition />
        ))}
      </div>
    </>
  )
}

const RulesEditor = () => {
  return (
    <CalculatorRulesProvider>
      <CalculatorRulesUpsertContent />
    </CalculatorRulesProvider>
  )
}

export { RulesEditor }

import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const InputListTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiContainer: {
      root: {
        width: "100%",
        display: "flex",
        padding: `${spacing(0, 0, 2, 0)} !important`,
        margin: `${spacing(0, 0, 0, 1)} !important`,
        alignItems: "center",
      },
    },
    MuiIconButton: {
      root: {
        fill: palette.grey[500],
        padding: spacing(1),
        marginLeft: rem("4px"),
      },
      label: {
        width: rem("20px"),
        height: rem("20px"),
      },
    },
    MuiTypography: {
      body2: {
        fontWeight: typography.fontWeightMedium as number,
        marginLeft: spacing(1),
        marginBottom: spacing(1),
      },
    },
  },
})

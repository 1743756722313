import React from "react"
import { TextField, MenuItem, ThemeProvider, IconButton, SelectProps } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"

import { FormControlTheme } from "../styles"

export interface ISelectOptionChild {
  value: string
  label: string
}

export interface ISelectOption<Value = string> {
  value: Value
  label: string
  isDisabled?: boolean
  children?: ISelectOptionChild[]
}

export interface ISelectProps {
  name?: string
  options: ReadonlyArray<ISelectOption>
  error?: boolean
  disabled?: boolean
  label?: string
  value?: string
  helperText?: string
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleClear?: (value: string) => void
  placeholder?: string
}

const Select = ({
  name,
  options,
  error,
  disabled,
  label,
  helperText,
  value,
  placeholder,
  handleChange,
  handleClear,
}: ISelectProps): JSX.Element => {
  const newValue = options.find(option => option.value === value && option.label)

  const getRenderValue = () => (value === "" ? <em> {placeholder}</em> : <>{newValue?.label}</>)

  const customSelectProps: SelectProps = {
    IconComponent: ({ className }) => {
      return <KeyboardArrowDownIcon className={className} />
    },
    displayEmpty: true,
    renderValue: getRenderValue,
    MenuProps: {
      variant: "menu",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    },
  }

  if (handleClear) {
    customSelectProps.endAdornment = (
      <IconButton
        disableFocusRipple
        disableTouchRipple
        focusRipple={false}
        centerRipple
        style={{ transform: "translateX(-22.5px)", padding: 0 }}
        edge={false}
        onClick={() => handleClear(value as string)}
      >
        <CancelIcon fontSize="small" />
      </IconButton>
    )
  }

  return (
    <ThemeProvider theme={FormControlTheme}>
      <TextField
        name={name}
        data-testid="select"
        label={label}
        variant="outlined"
        select
        value={value}
        disabled={disabled}
        error={error}
        helperText={helperText}
        onChange={handleChange}
        SelectProps={customSelectProps}
        InputLabelProps={{
          shrink: true,
        }}
      >
        {options.map((option: ISelectOption) => (
          <MenuItem key={option.value} value={option.value} disabled={option.isDisabled}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </ThemeProvider>
  )
}

export { Select }

import * as React from "react"
import { InputLabelProps, TextField, ThemeProvider } from "@material-ui/core"
import { Autocomplete as MuiAutocomplete, UseAutocompleteProps } from "@material-ui/lab"
import { autocompleteStyles } from "./style"
import { FormControlTheme } from "../styles"
import { ISelectOption } from "../Select/Select"

export interface IAutocompleteProps
  extends Partial<
    Pick<UseAutocompleteProps<ISelectOption, false, false, true>, "onChange" | "onInputChange" | "value">
  > {
  options: ISelectOption[]
  disabled?: boolean
  label?: string
  name?: string
  helperText?: string
  hasError?: boolean
  freeSolo?: boolean
}

const Autocomplete = ({
  name,
  options,
  value = "",
  disabled,
  label,
  helperText,
  hasError,
  onChange,
  onInputChange,
  freeSolo,
}: IAutocompleteProps): JSX.Element => {
  const classes = autocompleteStyles()
  return (
    <ThemeProvider theme={FormControlTheme}>
      <MuiAutocomplete
        data-testid="autocomplete"
        className={classes.autocomplete}
        size="small"
        value={value}
        disabled={disabled}
        freeSolo={freeSolo}
        options={options}
        getOptionLabel={(option: ISelectOption) => (typeof option === "string" ? option : option.label)}
        getOptionSelected={(option, currentValue) => Boolean(currentValue)}
        onChange={onChange}
        onInputChange={onInputChange}
        renderInput={({
          id,
          disabled: fieldDisabled,
          fullWidth,
          size,
          inputProps,
          InputProps,
          InputLabelProps: labelProps,
        }) => {
          const updatedInputProps = {
            ...inputProps,
            style: { paddingLeft: "calc(0.75rem - 6px)" },
          }

          return (
            <TextField
              id={id}
              name={name}
              disabled={fieldDisabled}
              fullWidth={fullWidth}
              error={hasError}
              label={label}
              type="text"
              variant="outlined"
              size={size}
              InputLabelProps={{
                shrink: true,
                id: (labelProps as InputLabelProps).id as string,
                htmlFor: (labelProps as InputLabelProps).htmlFor as string,
              }}
              inputProps={updatedInputProps}
              helperText={helperText}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={InputProps}
            />
          )
        }}
      />
    </ThemeProvider>
  )
}

export { Autocomplete }

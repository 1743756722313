import { Container, styled, makeStyles } from "@material-ui/core"
import { Content } from "../Layout"
import { palette, spacing, theme } from "../theme"

export const getModalStyle = (): any => {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export const ModalHeader = styled("header")(() => ({
  display: "grid",
  gridTemplateColumns: "5fr auto",
  gridTemplateRows: "1fr",
  alignItems: "center",
  padding: spacing(3),
  "& h5": {
    justifySelf: "center",
  },
  "& button": {
    justifySelf: "end",
  },
  borderBottom: `1px solid ${palette.grey[300]}`,
  boxShadow: "0px 1px rgba(0, 0, 0, 0.05)",
}))

export const ModalContainer = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  padding: 0,
  backgroundColor: palette.background.default,
  boxShadow: "0px 6px 13px rgba(21, 33, 56, 0.533345)",
  borderRadius: "10px",
  margin: 0,
  "&:focus": {
    outline: "none",
  },
}))

export const ModalContent = styled(Content)(() => ({
  padding: theme.spacing(3),
}))

export const ModalFooter = styled("footer")(() => ({
  padding: theme.spacing(3),
  display: "flex",
  justifyContent: "center",
  borderTop: `1px solid ${palette.grey[300]}`,
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
  "& button": {
    padding: spacing(1, 5),
  },
}))

export const modalStyles = makeStyles(() => ({
  small: {
    width: "30%",
  },
  medium: {
    width: "60%",
  },
  large: {
    width: "80%",
  },
}))

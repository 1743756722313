import { makeStyles } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import { palette, spacing, typography } from "../theme"

export const TableCollapsibleTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: palette.background.default,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: palette.grey[100],
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        "& svg": {
          fill: palette.grey[500],
        },
      },
      head: {
        fontSize: typography.body2.fontSize,
        fontWeight: typography.body2.fontWeight,
        lineHeight: 0,
        height: "26px",
        padding: spacing(1, 2),
        borderRight: "1px solid white",
      },
      body: {
        fontWeight: typography.fontWeightMedium as undefined,
        fontSize: typography.fontSize,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
  },
})

export const TableCoStyles = makeStyles(() => ({
  icon: {
    marginRight: spacing(2),
  },
  iconCollapsibleTableOpen: {
    fill: palette.primary.main,
  },
  iconCollapsibleTableClose: {
    fill: palette.grey[500],
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}))

export const anotherTableStyles = makeStyles(() => ({
  body: {
    fontsize: "rem(13px)",
    fontWeight: typography.fontWeightRegular as number,
    "&:nth-child(1)": {
      textDecoration: "underline",
      paddingLeft: "58px",
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: spacing(2),
      },
    },
  },
}))

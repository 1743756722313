import React from "react"
import { IconButton, Typography, ThemeProvider, Box } from "@material-ui/core"
import { Variant } from "@material-ui/core/styles/createTypography"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import CheckAndClearButtons from "components/CheckAndClearButtons"
import { Input } from "../Form"
import { EditInlineTheme } from "./styles"

export interface IEditInlineProps {
  name?: string
  isEditable: boolean
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  text?: string
  onEdit?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onCheckClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  placeholder: string
  variant?: Variant | "inherit"
  isDisabled?: boolean
  isCheckDisabled?: boolean
  maxLength?: string
}

export const EditInline = ({
  name,
  isEditable,
  onInputChange,
  text,
  onEdit,
  onCheckClick,
  onClose,
  placeholder,
  variant,
  isDisabled = false,
  isCheckDisabled = false,
  maxLength,
}: IEditInlineProps): JSX.Element => {
  return (
    <ThemeProvider theme={EditInlineTheme}>
      <Box display="flex" alignItems="center">
        {isEditable ? (
          <>
            <Input
              name={name}
              placeholder={placeholder}
              onChange={onInputChange}
              value={text}
              onClick={event => event.stopPropagation()}
              maxLength={maxLength}
            />
            <CheckAndClearButtons onCheckClick={onCheckClick} onCloseClick={onClose} disabled={isCheckDisabled} />
          </>
        ) : (
          <>
            <Typography variant={variant} color="textSecondary">
              {text || placeholder}
            </Typography>
            {onEdit && (
              <IconButton onClick={onEdit} disabled={isDisabled}>
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </ThemeProvider>
  )
}

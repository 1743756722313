import { useQuery, useQueryClient, useMutation } from "react-query"
import { useParams } from "react-router-dom"
import { getStepGroupById, upsertStepGroup } from "services/stepGroups"
import type { IStepGroupResponse } from "typings/modules"
import { useDispatch } from "react-redux"
import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"

interface IStepGroupParams {
  stepGroupId: number
  options?: any
}

export const useStepGroup = ({ stepGroupId, options }: IStepGroupParams) => {
  const queryClient = useQueryClient()

  const queryKey = `stepGroup-${stepGroupId}`

  return useQuery(queryKey, () => getStepGroupById(stepGroupId), {
    ...options,
    initialData: () => queryClient.getQueryCache().get(queryKey),
  })
}

export const useUpdateStepGroup = () => {
  const dispatch = useDispatch()

  const queryClient = useQueryClient()
  const { calculatorId } = useParams<{ calculatorId: string }>()

  const { isLoading, mutate: updateStepGroup } = useMutation(
    (stepGroup: IStepGroupResponse) => upsertStepGroup(stepGroup, +calculatorId || 0),
    {
      onMutate: newStepGroup => {
        const queryKey = `stepGroup-${newStepGroup.id}`

        queryClient.cancelQueries(queryKey)

        const previousCalculator = queryClient.getQueryData(queryKey)

        queryClient.setQueryData(queryKey, (old: any) => ({ ...old, newStepGroup }))

        return { previousCalculator }
      },
      onSuccess: () => {
        dispatch(
          uiActions.showNotification({
            children: translations.alert.calculator.success,
            severity: "success",
          })
        )
      },
      onError: (error: InstanceType<typeof Error>) => {
        dispatch(uiActions.hideSpinner())

        dispatch(
          uiActions.showNotification({
            children: error.message || JSON.stringify(error),
            severity: "error",
          })
        )
      },
      onSettled: (data, error, variables) => {
        const queryKey = `stepGroup-${variables.id}`
        queryClient.invalidateQueries(queryKey)
        queryClient.invalidateQueries(`StepsImport-${variables.id}`)
      },
    }
  )

  return { updateStepGroup, isLoading }
}

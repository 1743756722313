import { createStyles, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      html: {
        height: "100%",
      },
      body: {
        height: "100%",
      },
      "#root": {
        height: "100%",
      },
    },
  })
)

const GlobalStyles = (): null => {
  useStyles()

  return null
}

export { GlobalStyles }

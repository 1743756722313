import * as React from "react"
import _ from "lodash"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Box, Tab, ListItemText } from "@material-ui/core"
import { useDispatch } from "react-redux"
import {
  Table,
  Tabs,
  Header,
  Button,
  ButtonsGroup,
  TableToolbar,
  TableLoadingOverlay,
  DropzoneDialog,
  IObjectTab,
  TabPanel,
  Dialog,
  List,
} from "components"
import { useHistory, useLocation } from "react-router"
import { GridFilterItem, GridFilterModel, GridRowModel, GridSortModel, getGridStringOperators } from "@mui/x-data-grid"
import { getProducts, getAllProducts, auditProducts } from "services"
import { getUploadStatus } from "services/bulk"
import { getPublishedPrices } from "services/calculator"
import { updateProducts } from "services/product"
import { ROUTES } from "constants/routes"
import { BULK_STATUS, TABLE } from "constants/bulk"
import { ALLOWED_FILTER_OPERATORS, csvToJson, operatorIn, setAlertContent } from "utils/general"
import { uiActions } from "store/ui-slice"
import { IInputCSV, IBulkResponse } from "models/bulk"
import FileSaver from "file-saver"
import { CloudDownload } from "@material-ui/icons"
import { useStepGroupTypes } from "services/hooks/useStepGroupTypes"
import { getCsvContent } from "utils/csvHelper"
import { PRODUCT_AUDIT_TABLE_HEADERS, PRODUCT_TABLE_HEADERS } from "constants/tables"
import {
  IPublicationCustomerGroup,
  IPublicationResponse,
  IStepGroupPublication,
  IStepGroupType,
} from "modules/Rules-Pricing/models/calculator"
import { ENV } from "constants/env"
import { TooltipWrapperButton } from "components/Table/styles"
import { useAuditLog } from "hooks/use-audit"
import { ProductsPublishedProductsState } from "typings/modules"
import Loader from "components/Loader"
import { useHasReadWriteRole } from "utils/user"
import { getColumns } from "../constants/producTable"
import { publishedColumns, getDynamicColumns } from "../constants/publishedProducTable"
import { CurrentSwitch } from "../components/CurrentPriceSwitch"
import { CurrentSwitchWrapper } from "../styles"

const INITIAL_FILTER_STATE_PUBLISHED = {
  page: 0,
  size: ENV.PAGINATION.DEFAULT_PAGE_SIZE,
  sort: "publication_date",
  dir: "DESC",
} as const

export const ProductsList = () => {
  const userHasReadWriteRole = useHasReadWriteRole()

  const history = useHistory()

  const { search } = useLocation()

  const queryClient = useQueryClient()

  const dispatch = useDispatch()

  const [dropzoneIsOpen, setDropzoneIsOpen] = React.useState(false)

  const [isProductByCustomerGroupImport, setProductByCustomerGroupImport] = React.useState(false)

  const [productFiles, setProductFiles] = React.useState<File[]>([])

  const [tabValue, setTabValue] = React.useState(0)

  const [csvConverted, setCsvConverted] = React.useState<IInputCSV[]>([])

  const [productsPage, setProductsPage] = React.useState(0)

  const [totalProducts, setTotalProducts] = React.useState<number>()

  const [productsSortModel, setProductsSortModel] = React.useState<GridSortModel>([] as GridSortModel)

  const [productFilterValue, setProductFilterValue] = React.useState<GridFilterItem>()

  const [publishedProductsParams, setPublishedProductsParams] = React.useState<ProductsPublishedProductsState>(
    INITIAL_FILTER_STATE_PUBLISHED
  )

  const [stepGroupTypesState, setStepGroupTypesState] = React.useState<IStepGroupType[]>([])

  const [bulkImportModalOpen, setBulkImportModalOpen] = React.useState<boolean>(false)

  const updaterPublishedProducts = (newState: Partial<ProductsPublishedProductsState>) => {
    setPublishedProductsParams(prevState => ({
      ...prevState,
      ...newState,
    }))
  }

  const { getAuditLog } = useAuditLog({
    getAuditLogService: auditProducts,
    tableHeaders: PRODUCT_AUDIT_TABLE_HEADERS,
    fileName: "Products Audit Log",
  })

  const onPublishedFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    if (filterModel.items.length) {
      const [item] = filterModel.items

      if (item && item.value) {
        const { columnField, value } = item

        updaterPublishedProducts({ [columnField]: value })
      } else {
        setPublishedProductsParams(prevState => ({
          ...INITIAL_FILTER_STATE_PUBLISHED,
          current: prevState.current,
        }))
      }
    }
  }, [])

  const onProductFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    if (filterModel.items.length) {
      const [item] = filterModel.items
      if (item) {
        setProductFilterValue(item)
      }
    }
  }, [])

  const onSortChange = (sortModel: GridSortModel) => {
    if (sortModel.length) {
      const [item] = sortModel

      if (item) {
        updaterPublishedProducts({
          sort: item.field,
          dir: item.sort.toUpperCase() as "ASC" | "DESC",
        })
      }
    } else {
      setPublishedProductsParams(INITIAL_FILTER_STATE_PUBLISHED)
    }
  }

  React.useEffect(() => {
    const mapCsvToJson = async () => {
      const textProductFile = await productFiles[0].text()
      const json = csvToJson(
        isProductByCustomerGroupImport ? TABLE.PRODUCTS_BY_CUSTOMERS_GROUP : TABLE.PRODUCTS,
        textProductFile
      )
      setCsvConverted(json)
    }
    if (productFiles.length) {
      mapCsvToJson()
    }
  }, [isProductByCustomerGroupImport, productFiles])
  const { isLoading, data: products } = useQuery(
    ["getProducts", productsPage, productsSortModel, productFilterValue],
    () => getProducts(productsPage, ENV.PAGINATION.DEFAULT_PAGE_SIZE, productsSortModel, productFilterValue)
  )

  const { isLoading: getPublishedPricesIsLoading, data: published } = useQuery(
    ["getPublishedPrices", _.toPairs(publishedProductsParams)],
    () => getPublishedPrices(publishedProductsParams),
    {
      enabled: tabValue === 1,
    }
  )

  const { data: stepGroupTypes } = useStepGroupTypes({ options: { enabled: !stepGroupTypesState?.length } })

  React.useEffect(() => {
    if (stepGroupTypes?.length && !stepGroupTypesState?.length) {
      setStepGroupTypesState(stepGroupTypes)
    }
  }, [stepGroupTypes, stepGroupTypesState])

  const { mutate, data: responseData, isLoading: isMutating } = useMutation(
    (inputCSV: IInputCSV[]) => updateProducts(inputCSV),
    {
      onMutate: () => {
        const { children, severity } = setAlertContent(BULK_STATUS.PROCESSING)

        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )
      },
      onError: () => {
        const { children, severity } = setAlertContent(BULK_STATUS.FAILED)

        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )
      },
    }
  )

  const exportTable = async () => {
    dispatch(uiActions.showSpinner())

    const items = await getAllProducts()

    const csv = items?.length > 0 ? getCsvContent(PRODUCT_TABLE_HEADERS, items) : ""

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })

    const fileName = "List of Products"

    dispatch(uiActions.hideSpinner())

    FileSaver.saveAs(blob, `${fileName}.csv`)
  }

  const openProductsImport = () => {
    setProductByCustomerGroupImport(false)
    setDropzoneIsOpen(true)
  }

  const options = [
    { label: "Import Products", action: () => openProductsImport(), disabled: !userHasReadWriteRole },
    { label: "Export products", action: () => exportTable(), disabled: !userHasReadWriteRole },
  ]
  const cacheUploadStatusData = queryClient.getQueryData<IBulkResponse>(["getUploadStatus", responseData?.id])

  const uniqueUploadErrorMessages = Array.from(new Set(cacheUploadStatusData?.errorMessages))

  const { isFetching: isUploading, data: uploadStatusData } = useQuery(
    ["getUploadStatus", responseData?.id],
    () => getUploadStatus(responseData?.id),
    {
      enabled:
        !!responseData?.id &&
        cacheUploadStatusData?.status !== BULK_STATUS.COMPLETED &&
        cacheUploadStatusData?.status !== BULK_STATUS.PROCESSED &&
        cacheUploadStatusData?.status !== BULK_STATUS.FAILED &&
        cacheUploadStatusData?.status !== BULK_STATUS.ERROR,
      refetchInterval: 5000,
      refetchIntervalInBackground: true,
      onSuccess: response => {
        if (response?.status === BULK_STATUS.PROCESSED || response?.status === BULK_STATUS.COMPLETED) {
          const { children, severity } = setAlertContent(response?.status)

          dispatch(
            uiActions.showNotification({
              children,
              severity,
            })
          )

          queryClient.invalidateQueries("getProducts")
        } else if (response?.status === BULK_STATUS.FAILED || response?.status === BULK_STATUS.ERROR) {
          setBulkImportModalOpen(true)
        }
      },
      onError: () => {
        const { children, severity } = setAlertContent(BULK_STATUS.FAILED)

        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )
      },
    }
  )

  React.useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)

      const showPrices = urlSearchParams.get("showPrices")

      if (showPrices) {
        setTabValue(1)
      }
    }
  }, [search])

  React.useEffect(() => {
    if (csvConverted.length) {
      mutate(csvConverted)
    }
  }, [csvConverted, mutate])

  React.useEffect(() => {
    if (products?.content.length) {
      setTotalProducts(products.totalElements)
    }
  }, [products?.content.length, products?.totalElements])

  const getStepPublicationData = React.useCallback((stepGroupResults: IStepGroupPublication[]) => {
    const [firstStepGroupResult] = stepGroupResults

    return {
      calculatorName: firstStepGroupResult.calculatorName,
      userName: firstStepGroupResult.userName,
      created: firstStepGroupResult.created,
      publicationStatus: firstStepGroupResult.publicationStatus,
      current: firstStepGroupResult.current,
      ...stepGroupResults.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.type]: curr.price,
        }
      }, {}),
    }
  }, [])

  const publishedRows = React.useMemo(() => {
    const list = published?.content || []

    const result = list
      .map((publication: IPublicationResponse) =>
        publication.customerGroups.map((customerGroup: IPublicationCustomerGroup) => ({
          id:
            publication.product.id +
            customerGroup.id +
            publication.publicationDate +
            customerGroup.stepGroupResults[0].calculatorName,
          productName: publication.product.name,
          productVsId: publication.product.vsId,
          customerGroup: customerGroup.name,
          ecomname: publication.product.ecomname,
          publicationDate: publication.publicationDate,
          ...getStepPublicationData(customerGroup.stepGroupResults),
        }))
      )
      .reduce((acc, curVal) => acc.concat(curVal), [])

    return result
  }, [published, getStepPublicationData])

  const productColumns = React.useMemo(() => {
    const columns = getColumns(history, userHasReadWriteRole)
    const gridStringOperatorsArray = getGridStringOperators()
    const gridStringOperatorsProduct = [...gridStringOperatorsArray, operatorIn]
    return [...columns].map(column => ({
      ...column,
      filterOperators: gridStringOperatorsProduct.filter(({ value }) => {
        return ALLOWED_FILTER_OPERATORS.includes(value)
      }),
    }))
  }, [history, userHasReadWriteRole])

  const columns = React.useMemo(() => {
    const dynamicColumns = stepGroupTypesState?.length ? getDynamicColumns(stepGroupTypesState) : []
    const gridStringOperatorsArray = getGridStringOperators()
    const gridStringOperatorsProduct = [...gridStringOperatorsArray, operatorIn]
    return [...publishedColumns, ...dynamicColumns].map(column => ({
      ...column,
      filterOperators: gridStringOperatorsProduct.filter(({ value }) => {
        return ALLOWED_FILTER_OPERATORS.includes(value)
      }),
    }))
  }, [stepGroupTypesState])

  const productTabs: IObjectTab[] = [
    {
      id: 0,
      label: "Products",
      children: (
        <>
          <Table
            columns={productColumns}
            rows={products?.content?.length ? (products.content as GridRowModel[]) : []}
            loading={isLoading}
            components={{
              Toolbar: () => (
                <Box display="flex">
                  <TableToolbar showColumnsButton />
                  <TooltipWrapperButton>
                    <Button variant="text" onClick={getAuditLog} size="small" icon={<CloudDownload />}>
                      Download products audit log
                    </Button>
                  </TooltipWrapperButton>
                </Box>
              ),
              LoadingOverlay: TableLoadingOverlay,
            }}
            pagination
            hideFooterPagination={!products?.content?.length}
            disableSelectionOnClick
            paginationMode="server"
            onPageChange={(newPage: number) => {
              setProductsPage(newPage)
            }}
            autoPageSize={false}
            pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
            sortingMode="server"
            sortModel={productsSortModel}
            onSortModelChange={(newModel: GridSortModel) => setProductsSortModel(newModel)}
            rowCount={totalProducts}
            filterMode="server"
            onFilterModelChange={onProductFilterChange}
          />
        </>
      ),
    },
    {
      id: 1,
      label: "Published Prices",
      children: (
        <>
          <CurrentSwitchWrapper>
            <CurrentSwitch
              isChecked={publishedProductsParams.current}
              onSwitchChange={() => updaterPublishedProducts({ current: !publishedProductsParams.current })}
              label="Current Price"
            />
          </CurrentSwitchWrapper>
          <Table
            rows={publishedRows?.length ? (publishedRows as GridRowModel[]) : []}
            columns={columns}
            loading={getPublishedPricesIsLoading}
            components={{
              Toolbar: () => <TableToolbar showColumnsButton />,
              LoadingOverlay: TableLoadingOverlay,
            }}
            pagination
            onPageChange={(newPage: number) => {
              updaterPublishedProducts({ page: newPage })
            }}
            paginationMode="server"
            autoPageSize={false}
            pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
            sortingMode="server"
            onSortModelChange={onSortChange}
            rowCount={published?.totalElements || 0}
            filterMode="server"
            onFilterModelChange={onPublishedFilterChange}
          />
        </>
      ),
    },
  ]

  const isProcessing = isMutating || isUploading || uploadStatusData?.status === BULK_STATUS.PROCESSING

  return (
    <>
      {isProcessing && <Loader />}
      {bulkImportModalOpen && (
        <Dialog
          open={bulkImportModalOpen}
          title="Errors while importing products"
          onClose={() => setBulkImportModalOpen(false)}
          primaryActionHandler={() => setBulkImportModalOpen(false)}
          primaryActionLabel="Dismiss"
          maxWidth="lg"
          fullWidth={false}
          dialogContentText={
            <List>
              {uniqueUploadErrorMessages.map(errorMessage => (
                <ListItemText primaryTypographyProps={{ style: { color: "maroon" } }} key={errorMessage}>
                  {errorMessage}
                </ListItemText>
              ))}
            </List>
          }
        />
      )}
      <Box>
        <Header
          title="List of Products"
          actions={
            tabValue === 0 && (
              <>
                <ButtonsGroup options={options} />
                <Button onClick={() => history.push(ROUTES.NEW_PRODUCT.path)} isDisabled={!userHasReadWriteRole}>
                  Create Product
                </Button>
              </>
            )
          }
        />
        <DropzoneDialog
          open={dropzoneIsOpen}
          onSave={files => {
            setProductFiles(files)
            setDropzoneIsOpen(false)
          }}
          onClose={() => setDropzoneIsOpen(false)}
          acceptedFiles={["text/csv", ".csv"]}
          initialFiles={productFiles}
          showPreviews
          showFileNamesInPreview
          dialogTitle="Import Product"
          submitButtonText="Import"
        />
        <Tabs
          value={tabValue}
          onChange={(event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
            if (newValue === 1) {
              updaterPublishedProducts({ current: true })
            }

            setTabValue(newValue)
          }}
        >
          {productTabs.map((tab: IObjectTab) => [<Tab key={tab.id} icon={tab.icon} label={tab.label} disableRipple />])}
        </Tabs>
      </Box>
      {productTabs.map((tab: IObjectTab) => [
        <TabPanel key={`panel-${tab.id}`} value={tabValue} index={tab.id}>
          {tab.children}
        </TabPanel>,
      ])}
    </>
  )
}

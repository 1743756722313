import * as React from "react"
import { TextField, TextFieldProps as Props } from "@material-ui/core"

const Field = ({ type = "text", ...props }: Props & { inputRef: React.Ref<HTMLInputElement> }): JSX.Element => {
  const { InputLabelProps } = props

  return (
    <TextField
      type={type}
      variant="outlined"
      classes={props.classes}
      id={props.id}
      name={props.name}
      InputProps={props.InputProps}
      inputMode={props.inputMode}
      autoFocus={props.autoFocus}
      value={props.value}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      inputRef={props.inputRef}
    />
  )
}

export { Field }

import React, { useEffect, useRef, useState } from "react"
import { ThemeProvider, Tooltip as MuiTooltip } from "@material-ui/core"
import { TooltipTheme } from "./styles"

interface ITooltip {
  title: NonNullable<React.ReactNode>
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
  children: JSX.Element
}

const Tooltip = ({ placement, children, title }: ITooltip): JSX.Element => {
  const textElementRef = useRef<any>(null)

  const [hoverStatus, setHover] = useState(false)

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    setHover(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
  }, [])

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize)
    },
    []
  )

  return (
    <ThemeProvider theme={TooltipTheme}>
      <MuiTooltip title={title} arrow placement={placement} disableHoverListener={!hoverStatus}>
        <div
          ref={textElementRef}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {children}
        </div>
      </MuiTooltip>
    </ThemeProvider>
  )
}

export { Tooltip }

Tooltip.defaultProps = {
  placement: "bottom",
}

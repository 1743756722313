import React from "react"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import { Box } from "@material-ui/core"
import { IconButton } from "components"
import { useHistory } from "react-router"
import { ROUTES } from "constants/routes"
import HistoryIcon from "@material-ui/icons/History"

interface ICalculatorListActions {
  id: number
}

const CalculatorListActions = ({ id }: ICalculatorListActions): JSX.Element => {
  const history = useHistory()

  return (
    <Box>
      <IconButton
        size="small"
        color="inherit"
        icon={<HistoryIcon />}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault()

          history.push(`${ROUTES.RULES_PRICING.path}/${id}/price-history`)
        }}
      />
      <IconButton
        size="small"
        color="inherit"
        icon={<EditOutlinedIcon />}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault()
          history.push(`${ROUTES.RULES_PRICING.path}/${id}`)
        }}
      />
    </Box>
  )
}

export { CalculatorListActions }

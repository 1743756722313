import { makeStyles } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const CardTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiCard: {
      root: {
        borderRadius: "6px",
        backgroundColor: palette.background.default,
        border: `1px solid ${palette.grey[300]}`,
        padding: spacing(3, 0),
        boxShadow: "none",
      },
    },
    MuiPaper: {
      root: {
        elevation: "0",
        boxShadow: "none",
      },
    },
    MuiCardHeader: {
      root: {
        padding: spacing(0),
        justifyContent: "center",
        borderBottom: `1px solid ${palette.grey[300]}`,
      },
      content: {
        flex: "none",
        paddingBottom: spacing(3),
      },
      title: {
        fontWeight: typography.fontWeightBold as number,
        fontSize: rem("19px"),
        color: palette.primary.main,
        textTransform: "uppercase",
      },
      subheader: {
        fontSize: typography.fontSize,
        fontWeight: typography.fontWeightRegular as number,
        color: palette.secondary.light,
        padding: spacing(0),
      },
    },
    MuiCardContent: {
      root: {
        display: "flex",
        flexDirection: "column",
        minHeight: rem("200px"),
        marginTop: spacing(4),
        padding: spacing(0, 1, 0, 0),
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiCardActions: {
      root: {
        marginTop: spacing(0),
        padding: spacing(0),
        display: "flex",
        justifyContent: "flex-end",
        "& div[class*='MuiFormControl-root']": {
          margin: spacing(0, 2),
          width: "220px",
        },
        "& button": {
          width: rem("180px"),
          margin: spacing(0, 2),
        },
      },
    },

    MuiContainer: {
      root: {
        maxWidth: rem("350px"),
        minHeight: rem("70px"),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        paddingLeft: `${spacing(0)} !important`,
        paddingRight: `${spacing(0)} !important`,
        "& svg": {
          width: rem("99px"),
          height: rem("99px"),
        },
        "& p": {
          paddingTop: spacing(3),
        },
      },
    },
  },
})

export const sizeStyles = makeStyles(() => ({
  small: {
    flex: 1,
  },
  medium: {
    flex: 3,
  },
  large: {
    width: "100%",
  },
}))

import * as React from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import _ from "lodash"

import { createSupplier } from "services"
import { uiActions } from "store/ui-slice"
import { Dialog } from "components"
import { ICreateSupplierRequest } from "../models/supplier"

interface IProps {
  isOpen: boolean
  handleClose: () => void
  handleRefetch: () => void
}

const CreateSupplierDialog = ({ isOpen, handleClose, handleRefetch }: IProps): JSX.Element => {
  const dispatch = useDispatch()

  const [state, setState] = React.useState({
    name: "",
    error: "",
  })

  const updater = (newState: Partial<typeof state>) => {
    setState(prevState => ({
      ...prevState,
      ...newState,
    }))
  }

  const { isLoading: isCreatingSupplier, mutate } = useMutation(
    (payload: ICreateSupplierRequest) => createSupplier(payload),
    {
      onSuccess: handleRefetch,
      onError: (error: string) => {
        dispatch(
          uiActions.showNotification({
            children: error.toString(),
            severity: "error",
          })
        )
      },
    }
  )

  const handleAccept = () => {
    if (isCreatingSupplier) {
      return
    }

    const name = _.trim(state.name)

    if (!name) {
      updater({ error: "Supplier name is required" })

      return
    }

    mutate({ name })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    updater({ name: value, error: "" })
  }

  return (
    <Dialog
      title="Create Supplier"
      onClose={handleClose}
      open={isOpen}
      primaryActionHandler={isCreatingSupplier ? ((undefined as unknown) as () => void) : handleAccept}
      secondaryActionLabel="Cancel"
      primaryActionLabel={isCreatingSupplier ? "Creating" : "Create"}
      dialogContentForm={{
        id: "supplierName",
        label: "Supplier Name",
        type: "string",
        value: state.name,
        error: Boolean(state.error),
        onChange: handleChange,
      }}
    />
  )
}

export { CreateSupplierDialog }

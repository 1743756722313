import * as React from "react"
import { useQuery } from "react-query"
import { Typography, Box, IconButton } from "@material-ui/core"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"

import { getMasterStepGroupTypes } from "services"
import { Input, Select, Spacer } from "components"
import CheckAndClearButtons from "components/CheckAndClearButtons"
import Loader from "components/Loader"
import { DashboardHeader } from "../styles"
import { useStepGroup } from "./StepGroupContext"

const StepGroupForm = ({ isCreating }: { isCreating?: boolean }) => {
  const { stepGroupRef, isBlocking, handleSubmit } = useStepGroup()

  const { isLoading: isMasterTypesLoading, data: masterTypes } = useQuery("getMasterStepGroupTypes", () =>
    getMasterStepGroupTypes()
  )

  const masterTypeOptions = React.useMemo(
    () =>
      (masterTypes || []).map(masterType => ({
        value: masterType.name,
        label: masterType.name,
      })),
    [masterTypes]
  )

  const stepGroup = stepGroupRef.current

  const initialState = {
    name: stepGroup.name,
    type: stepGroup.type,
    description: stepGroup.description,
  }

  const [state, setState] = React.useState(initialState)

  const [isEditing, setIsEditing] = React.useState(isCreating)

  const updater = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    const keyName = name as keyof Pick<typeof stepGroupRef.current, "name" | "description">

    updater({ [keyName]: value })
  }

  const handleSaveEdition = () => {
    setIsEditing(false)
    stepGroupRef.current.name = state.name
    stepGroupRef.current.description = state.description
    stepGroupRef.current.type = state.type
    handleSubmit()
  }

  const handleCloseEdition = () => {
    setState(initialState)
    setIsEditing(false)
  }

  const hasMasterTypeOptions = masterTypeOptions.length > 0

  const isViewDisabled = isMasterTypesLoading || isBlocking || !state.type || !state.name

  return (
    <>
      {isBlocking && <Loader />}
      <Typography variant="h2" color="textSecondary">
        {state.name || "Untitled Rule Set"}
      </Typography>
      <Spacer size={1} />
      <DashboardHeader>
        <Input
          name="name"
          label="RuleSet Name"
          value={state.name}
          onChange={handleChange}
          placeholder="Type RuleSet Name"
          maxLength="100"
          isDisabled={!isEditing}
        />
        <Select
          name="type"
          options={masterTypeOptions}
          handleChange={handleChange}
          value={hasMasterTypeOptions ? state.type : ""}
          label="RuleSet Type"
          placeholder="Choose a RuleSet Type"
          disabled={!isEditing}
        />
        <Input
          name="description"
          multiline
          rowsMax={5}
          collapsableMultiline
          label="RuleSet Description"
          value={state.description}
          onChange={handleChange}
          placeholder="Type RuleSet Description"
          maxLength="200"
          isDisabled={!isEditing}
        />
        {isEditing ? (
          <CheckAndClearButtons
            onCheckClick={handleSaveEdition}
            onCloseClick={handleCloseEdition}
            disabled={isViewDisabled}
          />
        ) : (
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </DashboardHeader>
    </>
  )
}

export { StepGroupForm }

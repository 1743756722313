import { useQuery, useQueryClient, useMutation } from "react-query"
import { apiClient } from "services/ApiServiceAxios"
import type { IStep } from "typings/modules"
import { useDispatch } from "react-redux"
import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"

interface IStepParams {
  stepId?: number
  calculatorId?: number
  stepGroupId?: number
  options?: any
}

const fetchStep = async (stepId: number): Promise<IStep> => {
  const { data }: { data: IStep } = await apiClient.get(`steps/${stepId}`)

  return data
}

export const useStep = ({ stepId, options }: IStepParams) => {
  const queryClient = useQueryClient()

  return useQuery(`Step-${stepId}`, () => fetchStep(stepId), {
    ...options,
    initialData: () => queryClient.getQueryCache().get("Step"),
  })
}

const patchStep = async (calculatorId: number, stepGroupId: number, step: IStep): Promise<IStep> => {
  const url = calculatorId
    ? `steps/${step.id}?calculatorId=${calculatorId}&stepGroupId=${stepGroupId}`
    : `steps/${step.id}?stepGroupId=${stepGroupId}`
  const { data }: { data: IStep } = await apiClient.patch(url, step)

  return data
}

export const useUpdateStep = ({ calculatorId, stepGroupId }: IStepParams) => {
  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const { isLoading, mutate: updateStep } = useMutation((step: IStep) => patchStep(calculatorId, stepGroupId, step), {
    onMutate: newStep => {
      queryClient.cancelQueries(`Step-${newStep.id}`)

      const previousStep = queryClient.getQueryData(`Step-${newStep.id}`)

      queryClient.setQueryData(`Step-${newStep.id}`, (old: any) => ({ ...old, newStep }))

      return { previousStep }
    },
    onSuccess: () => {
      dispatch(
        uiActions.showNotification({
          children: translations.alert.calculator.success,
          severity: "success",
        })
      )
    },
    onError: (error: InstanceType<typeof Error>) => {
      dispatch(
        uiActions.showNotification({
          children: error.message || JSON.stringify(error),
          severity: "error",
        })
      )
    },
    onSettled: param => queryClient.invalidateQueries(`Step-${param.id}`),
  })

  return { updateStep, isLoading }
}

import type { TextFieldProps } from "@material-ui/core"
import * as React from "react"
import { useFormik } from "formik"

import { IMaskAccept, MaskedInput } from "components"

export interface IPercentInputProps {
  field: TextFieldProps
  handleChange: ReturnType<typeof useFormik>["handleChange"]
}

const PercentInput = (props: IPercentInputProps): JSX.Element => {
  const { field, handleChange } = props

  const handleAccept: IMaskAccept = (value, inputMask, changeEvent): void => {
    handleChange(changeEvent)
  }

  return (
    <MaskedInput
      field={{
        name: "percent",
        type: "text",
        InputProps: {
          inputProps: {
            maxLength: 5,
            inputMode: "numeric",
          },
        },
        inputMode: "numeric",
        ...field,
      }}
      mask={{
        mask: Number,
        scale: 0,
        signed: false,
        padFractionalZeros: false,
        normalizeZeros: true,
        min: -100,
        max: 100,
      }}
      handleAccept={handleAccept}
    />
  )
}

export { PercentInput }

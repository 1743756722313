import { useDispatch } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useMutation } from "react-query"

import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"
import { duplicateCalculator } from "services/calculator"
import type { RuleRouteState } from "typings/modules"
import { useCalculatorQueryKey } from "../useCalculator"

export const useDuplicate = () => {
  const dispatch = useDispatch()

  const history = useHistory<RuleRouteState>()
  const { url } = useRouteMatch()

  const [, id] = useCalculatorQueryKey()

  const { isLoading, mutate: duplicateCalc } = useMutation((name: string) => duplicateCalculator(id, name), {
    onSuccess: param => {
      dispatch(
        uiActions.showNotification({
          children: translations.alert.calculator.success,
          severity: "success",
        })
      )
      history.push(`${url}`.replace(`${id}`, `${param.id}`))
    },
    onError: (error: InstanceType<typeof Error>) => {
      dispatch(
        uiActions.showNotification({
          children: error.message || JSON.stringify(error),
          severity: "error",
        })
      )
      throw new Error(`Error duplicating calculator with id ${id}`)
    },
  })

  return { isLoading, duplicateCalc }
}

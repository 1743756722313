import * as React from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { Typography } from "@material-ui/core"

import { deleteSupplier } from "services"
import { uiActions } from "store/ui-slice"
import { Dialog, Spacer } from "components"
import { ISupplier } from "../models/supplier"

interface IProps {
  data: ISupplier
  isOpen: boolean
  handleClose: () => void
  handleRefetch: () => void
}

const DeleteSupplierDialog = ({ isOpen, data, handleClose, handleRefetch }: IProps): JSX.Element => {
  const dispatch = useDispatch()

  const { isLoading: isDeletingSupplier, mutate } = useMutation((id: number) => deleteSupplier(id), {
    onSuccess: handleRefetch,
    onError: (error: string) => {
      dispatch(
        uiActions.showNotification({
          children: error.toString(),
          severity: "error",
        })
      )
    },
  })

  const handleAccept = () => mutate(data.id)

  return (
    <Dialog
      title={`Delete Supplier ${data.id}`}
      onClose={handleClose}
      open={isOpen}
      primaryActionHandler={isDeletingSupplier ? ((undefined as unknown) as () => void) : handleAccept}
      secondaryActionLabel="Cancel"
      primaryActionLabel={isDeletingSupplier ? "Deleting" : "Delete"}
    >
      <Spacer size={3} />
      <Typography variant="h4">
        Are you sure you want to delete <strong>{data.name}</strong>?
      </Typography>
      <Spacer size={3} />
    </Dialog>
  )
}

export { DeleteSupplierDialog }

import { makeStyles } from "@material-ui/core"
import { palette, spacing } from "components/theme"
import { rem } from "polished"

export const sidebarStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    position: "fixed",
    bottom: 0,
    top: rem("64px"),
    zIndex: 100,
    "&.collapsed + .main-content": {
      paddingLeft: spacing(7),
    },
  },
  navTabs: {
    display: "block",
    width: rem("227px"),
    transitionDelay: "100ms",
    transition: "all 500ms ease",
  },
  collapse: {
    width: rem("60px"),
    "&:hover": {
      width: rem("227px"),
      "& .MuiTabs-indicator": {
        borderRadius: 8,
        left: 6,
      },
    },
    "& .MuiTabs-indicator": {
      borderRadius: 0,
      left: 0,
    },
  },
  iconCollapse: {
    display: "block",
    margin: spacing(4, 2),
    padding: 0,
    width: rem("55px"),
    height: rem("55px"),
    transition: "all 500ms ease",
    position: "absolute",
    right: "-15px",
    bottom: 0,
    "& span": {
      "& svg": {
        fill: `${palette.secondary.main} !important`,
      },
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  iconOpacity: {
    opacity: 0,
    visibility: "hidden",
  },
}))

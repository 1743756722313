import React from "react"
import {
  ThemeProvider,
  Dialog as MuiDialog,
  DialogContent,
  DialogActions,
  Typography,
  DialogProps,
  DialogContentText,
} from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import { Button, Input } from "components"
import { DialogTheme } from "./styles"

export interface IDialogProps extends DialogProps {
  onClose: (event: React.MouseEvent) => void
  primaryActionHandler: (event: React.MouseEvent) => void
  secondaryActionHandler?: (event: React.MouseEvent) => void
  secondaryActionLabel?: string
  primaryActionLabel?: string
  open: boolean
  title?: string
  maxWidth?: "xl" | "lg" | "md" | "sm" | "xs" | false
  fullWidth?: boolean
  dialogContentText?: JSX.Element
  dialogContentForm?: {
    id: string
    label: string
    type: string
    value: string
    error?: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
  children?: JSX.Element | JSX.Element[]
}

export interface IDialogTitleProps {
  id: string
  children: React.ReactNode
}

const DialogTitle = ({ children }: IDialogTitleProps) => {
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h3">{children}</Typography>
    </MuiDialogTitle>
  )
}

export const Dialog = ({
  open,
  onClose,
  primaryActionHandler,
  secondaryActionLabel,
  primaryActionLabel,
  title,
  maxWidth,
  fullWidth = true,
  secondaryActionHandler,
  dialogContentText,
  dialogContentForm,
  children,
}: IDialogProps): JSX.Element => {
  return (
    <ThemeProvider theme={DialogTheme}>
      <MuiDialog
        onClose={onClose}
        open={open}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        aria-labelledby="customized-dialog-title"
        data-testid="dialog"
      >
        <DialogTitle id="customized-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {dialogContentText && <DialogContentText>{dialogContentText}</DialogContentText>}
          {dialogContentForm?.id && (
            <Input
              autoFocus
              hasError={dialogContentForm.error}
              label={dialogContentForm.label}
              type={dialogContentForm.type}
              value={dialogContentForm.value}
              onChange={dialogContentForm?.onChange}
            />
          )}
          {children}
        </DialogContent>
        <DialogActions>
          {secondaryActionLabel && (
            <Button
              isDisabled={!(secondaryActionHandler || onClose)}
              color="primary"
              variant="text"
              onClick={secondaryActionHandler || onClose}
            >
              {secondaryActionLabel}
            </Button>
          )}
          {primaryActionLabel && (
            <Button
              isDisabled={!primaryActionHandler}
              color="primary"
              variant="contained"
              onClick={primaryActionHandler}
            >
              {primaryActionLabel}
            </Button>
          )}
        </DialogActions>
      </MuiDialog>
    </ThemeProvider>
  )
}

import * as React from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import _ from "lodash"

import { updateCustomerGroup } from "services"
import { uiActions } from "store/ui-slice"
import { Dialog, Input, Spacer } from "components"
import { ICustomerGroup, IUpdateCustomerGroupRequest } from "../models/customerGroup"
import { getUpdateCustomerGroupInitialState } from "../constants/customerInitializer"

interface IProps {
  data: ICustomerGroup
  handleClose: () => void
  handleRefetch: () => void
}

const UpdateCustomerGroupDialog = ({ data, handleClose, handleRefetch }: IProps): JSX.Element => {
  const dispatch = useDispatch()

  const [state, setState] = React.useState(() => getUpdateCustomerGroupInitialState(data))

  const { fields, errors } = state

  const updater = (newState: Partial<typeof state>) => {
    setState(prevState => ({
      ...prevState,
      fields: { ...prevState.fields, ...newState.fields },
      errors: { ...prevState.errors, ...newState.errors },
    }))
  }

  const { isLoading: isUpdatingCustomer, mutate } = useMutation(
    (payload: IUpdateCustomerGroupRequest) => updateCustomerGroup(data.id, payload),
    {
      onSuccess: handleRefetch,
      onError: (error: string) => {
        dispatch(
          uiActions.showNotification({
            children: error.toString(),
            severity: "error",
          })
        )
      },
    }
  )

  const handleChange = React.useCallback(
    (field: keyof typeof state["fields"]) => (event: React.ChangeEvent) => {
      const { value } = event.currentTarget as HTMLInputElement

      const trimmedValue = _.trim(value)

      updater({
        fields: { [field]: value },
        errors: { [field]: trimmedValue ? "" : "Field is required" },
      } as typeof state)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleAccept = () => {
    if (isUpdatingCustomer) {
      return
    }

    const isInvalid = _.some(fields, _.isEmpty)

    if (isInvalid) {
      const pickedFields = _.pickBy(fields, _.isEmpty)

      const messages = _.mapValues(pickedFields, () => "Field is required")

      updater({ errors: messages } as typeof state)

      return
    }

    mutate({ ...fields, vsGroupId: data.vsGroupId, isPartner: data.isPartner })
  }

  return (
    <Dialog
      open
      title={`Update Customer ${data.id}`}
      onClose={handleClose}
      primaryActionHandler={isUpdatingCustomer ? ((undefined as unknown) as () => void) : handleAccept}
      secondaryActionLabel="Cancel"
      primaryActionLabel={isUpdatingCustomer ? "Updating" : "Update"}
    >
      <Input
        autoFocus
        hasError={Boolean(errors.name)}
        label="Name"
        type="text"
        value={fields.name}
        onChange={handleChange("name")}
      />
      <Spacer size={3} />
      <Input
        hasError={Boolean(errors.fullName)}
        label="Full Name"
        type="text"
        value={fields.fullName}
        onChange={handleChange("fullName")}
      />
    </Dialog>
  )
}

export { UpdateCustomerGroupDialog }

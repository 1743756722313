import React from "react"
import { IconWrapper } from "./styles"

interface IIcon {
  icon: React.ReactNode
  className?: string
}

export const Icon = ({ icon, className }: IIcon): JSX.Element => {
  return (
    <IconWrapper className={className} data-testid="icon">
      {icon}
    </IconWrapper>
  )
}

Icon.defaultProps = {
  className: "",
}

import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMutation, useQuery } from "react-query"
import { useHistory, useParams } from "react-router"
import { v4 as uuidV4 } from "uuid"
import _ from "lodash"

import type { IStepGroup, IStepGroupResponse, StepGroupRef } from "typings/modules"
import type { RootState } from "store"
import { getStepGroupById, upsertStepGroup } from "services"
import { uiActions } from "store/ui-slice"
import { ROUTES } from "constants/routes"
import { stepGroupInitialValues } from "constants/calculator"
import { translations } from "utils/translations"
import { formatUpsertStepGroup } from "../constants/utils"

type StepGroupContextValues = {
  isBlocking: boolean
  stepGroup: IStepGroup
  handleDeleteStep: (stepInternalId: string) => void
  handleAddStep: () => void
  handleDeleteRule: (stepId: number, ruleId: number, comparatorId: number) => void
  handleSubmit: () => Promise<void>
  stepGroupRef: React.MutableRefObject<StepGroupRef>
}

const StepGroupContext = React.createContext<StepGroupContextValues>({} as StepGroupContextValues)

const StepGroupProvider = (props: Required<React.PropsWithChildren<unknown>>) => {
  const { children } = props

  const dispatch = useDispatch()

  const history = useHistory()

  const actionStatus = useSelector((state: RootState) => state.ui)

  const { stepGroupId } = useParams<{ stepGroupId: string }>()

  const castedStepGroupId = Number(stepGroupId)

  const stepGroupQueryResult = useQuery(["getStepGroupById", stepGroupId], () => getStepGroupById(castedStepGroupId), {
    enabled: castedStepGroupId > 0,
  })

  const {
    isLoading: isStepGroupLoading,
    isFetching: isStepGroupFetching,
    data: stepGroup,
    refetch,
  } = stepGroupQueryResult

  const { mutate, isLoading: isMutating } = useMutation(upsertStepGroup as any, {
    onSuccess: result => {
      const { data }: any = result

      dispatch(
        uiActions.showNotification({
          children: translations.general.success,
          severity: "success",
        })
      )

      if (Number.isNaN(Number(stepGroupId))) {
        history.replace(`${ROUTES.STEP_GROUPS.path}/${data.id}`)
      } else {
        refetch()
      }
    },
    onError: (error: InstanceType<typeof Error>) => {
      const message = error.message || JSON.stringify(error)

      dispatch(
        uiActions.showNotification({
          children: message,
          severity: "error",
        })
      )
    },
  })

  const [state, setState] = React.useState<IStepGroup>(() => {
    const initialState = { ...stepGroupInitialValues }

    if (Number.isNaN(castedStepGroupId)) {
      initialState.id = 0
    }

    return initialState
  })

  const updater = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }))
  }

  const stepGroupRef = React.useRef<StepGroupRef>({
    ...state,
    steps: _.keyBy(stepGroupInitialValues.steps, "internalId"),
  })

  const handleSubmit = async () => {
    const values = {
      ...stepGroupRef.current,
      steps: Object.values(stepGroupRef.current.steps),
    }

    const newValues = formatUpsertStepGroup(values)

    // TODO: HANDLE TYPESCRIPT
    mutate(newValues as any)
  }

  const handleAddStep = () => {
    const steps = Object.values(stepGroupRef.current.steps)

    const stepOrders = steps.map(step => step.order)

    const keptOriginalOrders = _.map(stepGroup?.steps, step => step.order)

    stepOrders.push(0)

    const nextStepOrder = Math.max(...stepOrders, ...keptOriginalOrders) + 1

    const newStep: IStepGroup["steps"][number] = {
      internalId: uuidV4(),
      name: "",
      cycleReference: false,
      description: "",
      rules: [],
      order: nextStepOrder,
    }

    const newSteps = [newStep, ...steps]

    stepGroupRef.current = {
      ...state,
      steps: _.keyBy(newSteps, "internalId"),
    }

    updater({ steps: newSteps })
  }

  const handleDeleteStep = (stepInternalId: string) => {
    const steps = Object.values(stepGroupRef.current.steps)

    const newSteps = steps.filter(step => step.internalId !== stepInternalId)

    if (newSteps.length === 0) {
      const [initialStep] = stepGroupInitialValues.steps

      const newStep = { ...initialStep, internalId: uuidV4(), order: 1 }

      newSteps.push(newStep)
    }

    stepGroupRef.current = {
      ...state,
      steps: _.keyBy(newSteps, "internalId"),
    }

    updater({ steps: newSteps })
  }

  const handleDeleteRule = (stepId: number, ruleId: number, comparatorId: number) => {
    const steps = Object.values(stepGroupRef.current.steps)

    const stepIndex = steps.findIndex(step => step.id === stepId)

    const currentRules = steps[stepIndex].rules

    let newRules = currentRules.filter(rule => rule.id !== ruleId)

    if (comparatorId) {
      newRules = newRules.filter(rule => rule.id !== comparatorId)
    }

    if (stepIndex > -1) {
      const newSteps = [...steps]

      newSteps[stepIndex].rules = newRules

      stepGroupRef.current = {
        ...state,
        steps: _.keyBy(newSteps, "internalId"),
      }

      updater({ steps: newSteps })
    }
  }

  const formatSteps = (values: IStepGroupResponse) => {
    const formattedSteps = values.steps?.map(step => {
      const formattedStep = {
        internalId: uuidV4(),
        ...step,
        rules: (step.rules || []).map(rule => ({
          ...rule,
          internalId: uuidV4(),
        })),
      }

      return formattedStep
    })

    if (formattedSteps?.length === 0) {
      const [initialStep] = stepGroupInitialValues.steps

      const tempStep = {
        ...initialStep,
        internalId: uuidV4(),
        order: 1,
      } as typeof formattedSteps[number]

      formattedSteps.push(tempStep)
    }

    return formattedSteps
  }

  React.useEffect(() => {
    if (stepGroup) {
      const mappedSteps = formatSteps(stepGroup)

      const newStepGroup = {
        ...stepGroup,
        description: stepGroup.description ?? "",
        steps: mappedSteps,
      }

      stepGroupRef.current = {
        ...newStepGroup,
        steps: _.keyBy(mappedSteps, "internalId"),
      }

      stepGroupRef.current = {
        ...newStepGroup,
        steps: _.keyBy(newStepGroup.steps, "internalId"),
      }

      updater(newStepGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepGroup])

  React.useEffect(() => {
    if (isStepGroupLoading) {
      dispatch(uiActions.showSpinner())
    }

    if (!isStepGroupLoading && actionStatus.isLoading) {
      dispatch(uiActions.hideSpinner())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStepGroupLoading, actionStatus])

  const isBlocking = isStepGroupLoading || isStepGroupFetching || actionStatus.isLoading || isMutating

  return (
    <StepGroupContext.Provider
      value={{
        isBlocking,
        stepGroup: state,
        handleAddStep,
        handleDeleteStep,
        handleDeleteRule,
        handleSubmit,
        stepGroupRef,
      }}
    >
      {isStepGroupLoading || state.id === undefined ? null : children}
    </StepGroupContext.Provider>
  )
}

const useStepGroup = () => {
  const context = React.useContext(StepGroupContext)

  if (context === undefined) {
    throw new Error("useStepGroup must be used within a StepGroupProvider")
  }

  return context
}

export { StepGroupProvider, useStepGroup }

import { Box, makeStyles, styled } from "@material-ui/core"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const tableStyles = makeStyles(() => ({
  container: {
    height: "100%",
    maxWidth: "100%",
    padding: spacing(2, 0, 0),
    "&.modal-table": {
      maxHeight: "440px",
    },
  },
  root: {
    "& .MuiDataGrid-cell--editable": {
      color: palette.primary.dark,
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      overflow: "unset",
    },
    "& .MuiDataGrid-columnHeader--alignCenter": {
      "& .MuiDataGrid-columnHeaderTitle": {
        paddingLeft: "12px",
      },
    },
    overflowX: "auto",
    border: "none",
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
    "& .MuiDataGrid-columnHeaderDraggableContainer": {
      "&:focus": {
        border: "none",
      },
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: palette.grey[200],
      },

      "& .MuiDataGrid-cell": {
        "& button": {
          "& svg": {
            fill: palette.primary.light,
          },
          "&:hover": {
            "& svg": {
              fill: palette.primary.main,
            },
          },
        },

        "& .MuiDataGrid-checkboxInput.Mui-checked": {
          "& span": {
            "& svg": {
              fill: palette.primary.main,
            },
          },
        },
        "& .MuiDataGrid-checkboxInput:not(.Mui-checked)": {
          "& span": {
            "& svg": {
              fill: palette.text.secondary,
            },
          },
        },
      },
      "&.Mui-odd": {
        backgroundColor: palette.grey[100],
        "&:hover": {
          backgroundColor: palette.grey[200],
        },
        "&.Mui-selected": {
          backgroundColor: `hsla(197, 79%, 49%, 0.08) !important`,
        },
      },
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
      outlineOffset: "-3px",
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: palette.grey[100],
      "& .MuiDataGrid-columnHeaderCheckbox": {
        padding: spacing(0, 1),
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: palette.grey[900],
        fontWeight: typography.fontWeightBold,
      },
      "& .MuiDataGrid-columnSeparator": {
        width: rem("2px"),
        minWidth: rem("2px"),
        position: "relative",
        backgroundColor: palette.common.white,
      },
    },
    "& .MuiDataGrid-colCellTitle": {
      fontWeight: typography.fontWeightMedium,
    },
    "& .MuiDataGrid-viewport": {
      fontWeight: typography.body1.fontWeight,
      fontSize: typography.body1.fontSize,
      textAlign: "start",
    },
    "& .MuiDataGrid-cell--editing": {
      padding: "0 !important",
    },
    "& .MuiDataGrid-iconSeparator": {
      width: 0,
      heigth: 0,
    },

    "& .MuiDataGrid-cellEditable": {
      borderRadius: "5px",
      boxShadow: "none !important",
      border: `1px solid ${palette.grey[500]}`,
      backgroundColor: `${palette.background.default} !important`,
    },
    "& .MuiDataGrid-cell:focus , .MuiDataGrid-cell:focus-within": {
      outline: "unset",
    },
    "& .MuiDataGrid-cellWithRenderer": {
      justifyContent: "center !important",
    },
    "& .atypical-true": {
      backgroundColor: `${palette.error.light} !important`,
      "&:hover": {
        backgroundColor: `${palette.error.contrastText} !important`,
      },
      "&.Mui-selected": {
        backgroundColor: `${palette.error.contrastText} !important`,
      },
      "& .MuiDataGrid-cell": {
        "& .MuiDataGrid-checkboxInput.Mui-checked": {
          "& span": {
            "& svg": {
              fill: `${palette.error.main} !important`,
            },
          },
        },
      },
    },
  },
}))

export const TooltipWrapperButton = styled(Box)(() => ({
  top: "5px",
  position: "relative",
  "& :hover": {
    backgroundColor: palette.info.light,
  },
  "& button": {
    fontSize: "13px",
    textTransform: "uppercase",
    padding: `spacing(2, 0) !important`,
    "&:not(disabled) svg": {
      fill: "currentColor",
    },
    "& span": {
      padding: 4,
    },
    "& svg": {
      marginRight: spacing(1),
    },
  },
}))

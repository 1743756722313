import React, { useState } from "react"
import { MuiThemeProvider, Typography } from "@material-ui/core"
import { Button, Input, Spacer, theme } from "components"
import { useMutation } from "react-query"
import { signIn } from "services/user"
import { IUserData } from "modules/SignIn/models/user"
import { setAuthCookie } from "utils/user"
import { useHistory } from "react-router-dom"
import Logo from "assets/images/logo.svg"
import SignInImage from "assets/images/signInImage.png"
import { translations } from "utils/translations"
import {
  SignInContainer,
  SignInStyledLayout,
  SignInImageContainer,
  SignInTitle,
  SignInInput,
} from "modules/SignIn/styles"
import { ROUTES } from "constants/routes"

const SignIn = (): JSX.Element => {
  const [inputData, setInputData] = useState({ name: "", password: "" })

  const history = useHistory()

  const { mutate } = useMutation((userData: IUserData) => signIn(userData), {
    onSuccess: result => {
      setAuthCookie(result)
      history.replace(ROUTES.RULES_PRICING.path)
    },
    onError: () => {
      // @todo add proper notification for wrong credentials case
      // eslint-disable-next-line no-alert
      alert(translations.alert.signIn.error)
    },
  })

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      name: event.target.value,
    })
  }
  const handlePassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      password: event.target.value,
    })
  }

  return (
    <MuiThemeProvider theme={theme}>
      <SignInStyledLayout>
        <SignInContainer>
          <Spacer size={6} />
          <img width={193} src={Logo} alt="vetsource" />
          <Spacer size={7} />
          <SignInTitle>
            <Typography variant="h1">Sign In</Typography>
            <Typography variant="body2">Pricing Tool for Vetsource</Typography>
          </SignInTitle>
          <Spacer size={8} />
          <SignInInput>
            <Input
              placeholder="Type your email address"
              label="Email"
              name="name"
              value={inputData.name}
              onChange={handleNameChange}
            />
            <Input
              placeholder="Type your password"
              label="Password"
              name="password"
              type="password"
              value={inputData.password}
              onChange={handlePassChange}
            />
          </SignInInput>
          <Spacer size={7} />
          <Button
            isDisabled={!inputData.password || !inputData.name}
            onClick={() =>
              mutate({
                account: inputData.name,
                password: inputData.password,
                realm: "pmt",
                subRealm: "pmt",
              })
            }
          >
            Sign In
          </Button>
        </SignInContainer>
        <SignInImageContainer>
          <img src={SignInImage} alt="SignIn images" />
        </SignInImageContainer>
      </SignInStyledLayout>
    </MuiThemeProvider>
  )
}

export { SignIn }

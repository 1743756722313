import { Box, styled } from "@material-ui/core"
import { palette, spacing } from "components/theme"
import { rem } from "polished"

export const SignInContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "61%",
  "& h1": {
    color: palette.grey[600],
  },
}))

export const SignInImageContainer = styled(Box)(() => ({
  height: "100%",
  "& img": {
    height: "100vh",
  },
}))

export const SignInStyledLayout = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  height: "100vh",
}))

export const SignInTitle = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "& h1": {
    marginBottom: spacing(1),
  },
}))

export const SignInInput = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: rem("350px"),
  gap: spacing(4),
}))

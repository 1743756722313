import Masonry from "react-masonry-css"
import { Box, styled, Typography } from "@material-ui/core"
import { createTheme, makeStyles } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, typography, spacing, theme } from "components/theme"

export const WizardTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiStepper: {
      root: {
        backgroundColor: "transparent",
        maxWidth: rem("674px"),
        width: "100%",
        padding: spacing(1, 3),
        margin: "0 auto",
      },
    },

    MuiStepLabel: {
      root: {
        // alignItems: "flex-start",
      },

      label: {
        "&[class*='MuiStepLabel-alternativeLabel']": {
          marginTop: spacing(1),
          fontSize: typography.fontSize,
          fontWeight: typography.fontWeightMedium,
          color: palette.grey[600],
        },
        "&[class*='MuiStepLabel-active']": {
          color: palette.primary.main,
          fontWeight: typography.fontWeightBold,
        },
      },
      iconContainer: {
        height: rem("22px"),
        width: rem("22px"),
        "& svg": {
          fill: palette.primary.main,
          height: rem("22px"),
          width: rem("22px"),
        },
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: "9px",
      },
      line: {
        border: `3px solid ${palette.grey[300]}`,
      },
      active: {
        "& $line": {
          borderColor: palette.primary.main,
        },
      },
      completed: {
        "& $line": {
          borderColor: palette.primary.main,
        },
      },
    },
  },
})

export const WizardContainer = styled("fieldset")(() => ({
  border: 0,
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "auto 1fr auto",
  height: "100%",
  margin: "0 auto",
  padding: 0,
  width: "100%",
}))

export const WizardTitle = styled(Typography)(() => ({
  width: "100%",
  textAlign: "center",
  color: theme.palette.grey[800],
  margin: theme.spacing(4, 0),
}))

export type IStepContent = {
  fullWidth?: boolean
}

export const CollapseContainer = styled(Masonry)(() => ({
  display: "flex",
  margin: "0 auto",
  width: "unset",
  maxWidth: rem("886px"),
  "& .collapse-column": {
    paddingLeft: 0,
  },
}))

export const StepContent = styled(Box)(({ fullWidth }: IStepContent) => ({
  padding: spacing(1, 5, 5, 5),
  width: "100%",
  maxWidth: fullWidth ? "100%" : rem("410px"),
  display: "block",
  margin: "0 auto",
  "& > button": {
    margin: "16px auto 0",
    display: "flex",
  },
}))

export const TableStepContent = styled(StepContent)(() => ({
  width: "auto",
  display: "flex",
  flexDirection: "column",
  maxWidth: "none",
  margin: 0,
  minHeight: rem("400px"),
  padding: 0,
}))

export const StyledWizardHeader = styled(Box)(() => ({
  padding: spacing(5, 0),
  textAlign: "center",
  whiteSpace: "nowrap",
  "& h2": {
    marginBottom: spacing(2),
    color: palette.text.primary,
  },
  "& p": {
    color: palette.grey[800],
  },
}))

export const TableWizardHeader = styled(StyledWizardHeader)(() => ({
  padding: theme.spacing(1, 0),
}))

export const CustomIcon = styled("div")(() => ({
  height: spacing(2),
  width: spacing(2),
  padding: "2px",
  backgroundClip: "content-box",
  "& svg": {
    fill: palette.grey[400],
  },
  "&.active": {
    "& svg": {
      fill: palette.primary.main,
    },
  },
}))

export const CurrentSwitchWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  position: "absolute",
  top: "6px",
  right: 0,
  zIndex: 10,
  "& button": {
    margin: spacing(0, 2),
  },
}))

export const StyledPublishDate = styled(Box)(() => ({
  display: "flex",
  justifyContent: "row",
  "& div": {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
}))

export const ContainerInputs = styled(Box)(() => ({
  display: "flex",
  justifyContent: "row",
  alignItems: "center",
  gridGap: spacing(3),
}))

export const ContainerInputIconButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-end",
  position: "relative",
}))

export const ContainerIconButton = styled(Box)(() => ({
  position: "absolute",
  right: "-72px",
}))

export const useCustomerAndPricingStyles = makeStyles({
  container: {
    margin: spacing(2, 0),
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: `${spacing(2)}px !important`,
  },
  title: {
    color: palette.grey[800],
  },
  subtitle: {
    color: palette.grey[500],
    fontWeight: "bold",
  },
  middle: {
    margin: spacing(0, 1),
  },
  button: {
    color: palette.primary.light,
    "&:hover": {
      color: palette.primary.main,
    },
  },
})

import { ISelectOption } from "components"
import { Operators } from "constants/calculator"
import { OperationTypeToLabelMap } from "../models/calculator"

export const CONDITIONAL_OPTIONS: ReadonlyArray<ISelectOption> = [
  {
    value: "1",
    label: "-",
  },
  {
    value: "2",
    label: "If",
  },
]

export const CONDITIONAL_OPERATORS = [
  { value: Operators.GREATER_THAN, label: Operators.GREATER_THAN } as ISelectOption<Operators.GREATER_THAN>,
  {
    value: Operators.EQUALS_GREATER_THAN,
    label: Operators.EQUALS_GREATER_THAN,
  } as ISelectOption<Operators.EQUALS_GREATER_THAN>,
  { value: Operators.LESS_THAN, label: Operators.LESS_THAN } as ISelectOption<Operators.LESS_THAN>,
  { value: Operators.EQUALS_LESS_THAN, label: Operators.EQUALS_LESS_THAN } as ISelectOption<Operators.EQUALS_LESS_THAN>,
  { value: Operators.EQUALS, label: Operators.EQUALS } as ISelectOption<Operators.EQUALS>,
  { value: Operators.EXISTS, label: Operators.EXISTS } as ISelectOption<Operators.EXISTS>,
] as const

export const NUMERICAL_OPERATORS: ReadonlyArray<ISelectOption> = [
  { value: Operators.SUM, label: Operators.SUM },
  { value: Operators.SUBTRACTION, label: Operators.SUBTRACTION },
  { value: Operators.DIVISION, label: Operators.DIVISION },
  { value: Operators.MULTIPLICATION, label: Operators.MULTIPLICATION },
  { value: Operators.ROUND_UP, label: Operators.ROUND_UP },
  { value: Operators.ROUND_DOWN, label: Operators.ROUND_DOWN },
  { value: Operators.MAXIMUM, label: Operators.MAXIMUM },
  { value: Operators.MINIMUM, label: Operators.MINIMUM },
  { value: Operators.AVERAGE, label: Operators.AVERAGE },
] as const

export const CONDITIONAL_OPERATORS_LIST: ReadonlyArray<typeof CONDITIONAL_OPERATORS[number]["value"]> = [
  Operators.GREATER_THAN,
  Operators.EQUALS_GREATER_THAN,
  Operators.LESS_THAN,
  Operators.EQUALS_LESS_THAN,
  Operators.EQUALS,
  Operators.EXISTS,
]

export const CONDITIONAL_AVAILABLE_ARGUMENTS = [OperationTypeToLabelMap.RULE, OperationTypeToLabelMap.STEP] as const

/**
 * @description Show the next options as long as the selected option is included here.
 */
export const BLOCKED_CONDITIONAL_ARGUMENTS = [Operators.EXISTS] as const

import React from "react"
import { Tabs as MuiTabs } from "@material-ui/core"
import { ITabGroup } from "./props"
import { tabsMenuStyles } from "./styles"

export const TabGroup = ({
  orientation,
  variant,
  value,
  onChange,
  className,
  indicatorColor,
  children,
}: ITabGroup): JSX.Element => {
  const classes = tabsMenuStyles()
  return (
    <MuiTabs
      data-testid="tabsGroups"
      orientation={orientation}
      variant={variant}
      value={value}
      onChange={onChange}
      className={`${className} ${classes.tabs}`}
      indicatorColor={indicatorColor}
    >
      {children}
    </MuiTabs>
  )
}

TabGroup.defaultProps = {
  orientation: "vertical",
  variant: "fullWidth",
  indicatorColor: "primary",
}

import { useDispatch } from "react-redux"
import { useParams, useHistory, useRouteMatch } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"

import { apiClient } from "services/ApiServiceAxios"
import { putCalculator } from "services/calculator"
import { IAPICalculatorResponse } from "modules/Rules-Pricing/models/calculator"
import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"
import { useQueryCache } from "hooks"
import type { RuleRouteState } from "typings/modules"

const fetchCalculators = async (calculatorId: number): Promise<IAPICalculatorResponse> => {
  const { data } = await apiClient.get(`/calculators/${calculatorId}`)

  return data
}

export const useCalculatorQueryKey = () => {
  const { calculatorId } = useParams<{ calculatorId: string }>()

  const id = Number(calculatorId)

  const queryKey = `/calculators/${calculatorId}`

  return [queryKey, id] as const
}

export const useCalculator = () => {
  const [queryKey, calculatorId] = useCalculatorQueryKey()

  return useQuery(queryKey, () => fetchCalculators(calculatorId))
}

export const useCalculatorConditional = (calculatorId: number) => {
  const queryKey = `/calculators/${calculatorId}`

  const cachedCalculator = useQueryCache(queryKey)

  const queryResult = useQuery(queryKey, () => fetchCalculators(calculatorId), {
    enabled: !cachedCalculator,
  })

  return {
    calculator: cachedCalculator?.state.data || queryResult.data,
    status: queryResult.status,
  }
}

export const useUpdateCalculator = () => {
  const dispatch = useDispatch()

  const history = useHistory<RuleRouteState>()
  const match = useRouteMatch()

  const queryClient = useQueryClient()

  const [queryKey, id] = useCalculatorQueryKey()

  const { isLoading: isCalculatorSaving, mutate: updateCalculator, isError } = useMutation(
    (calculatorAsParam: Partial<IAPICalculatorResponse>) => putCalculator(calculatorAsParam, id),
    {
      onMutate: newCalculator => {
        queryClient.cancelQueries(queryKey)

        const previousCalculator = queryClient.getQueryData(queryKey)

        queryClient.setQueryData(queryKey, (old: any) => ({ ...old, newCalculator }))

        return { previousCalculator }
      },
      onSuccess: param => {
        dispatch(
          uiActions.showNotification({
            children: translations.alert.calculator.success,
            severity: "success",
          })
        )
        if (!id) {
          history.push(`${match.url}`.replace("new", `${param.id}`))
        }
      },
      onError: (error: InstanceType<typeof Error>) => {
        dispatch(
          uiActions.showNotification({
            children: error.message || JSON.stringify(error),
            severity: "error",
          })
        )
        queryClient.invalidateQueries(queryKey)
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  )

  return { updateCalculator, isCalculatorSaving, isError }
}

import * as React from "react"
import { useQuery } from "react-query"

import { auditSuppliers, getAllSuppliers } from "services"
import { Button, Header, Table, TableLoadingOverlay, TableToolbar } from "components"
import { Box } from "@material-ui/core"
import { TooltipWrapperButton } from "components/Table/styles"
import { CloudDownload } from "@material-ui/icons"
import { AUDIT_TABLE_HEADERS } from "constants/tables"
import { useAuditLog } from "hooks/use-audit"
import { ENV } from "constants/env"
import { useHasReadWriteRole } from "utils/user"
import type { ISupplierListState } from "../models/supplier"
import { getColumns } from "../constants/suppliersTable"
import { ManageSupplierDialog } from "../components"

const SupplierList = (): JSX.Element => {
  const userHasReadWriteRole = useHasReadWriteRole()

  const [state, setState] = React.useState<ISupplierListState>({
    data: null,
    modal: null,
  })

  const { getAuditLog } = useAuditLog({
    getAuditLogService: auditSuppliers,
    tableHeaders: AUDIT_TABLE_HEADERS,
    fileName: "Suppliers Audit Log",
  })

  const updater = (newState: Partial<ISupplierListState>) => {
    setState(prevState => ({
      ...prevState,
      ...newState,
    }))
  }

  const { isLoading, data, refetch } = useQuery("getAllSuppliers", getAllSuppliers)

  const handleRefetch = async () => {
    await refetch()

    updater({ modal: undefined })
  }

  const handleCloseModal = () => updater({ modal: undefined })

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const id = event.currentTarget.id || ""

    updater({ modal: id as ISupplierListState["modal"] })
  }

  const columns = React.useMemo(() => getColumns(updater, userHasReadWriteRole), [userHasReadWriteRole])

  return (
    <>
      <Header
        title="List of Suppliers"
        actions={
          <Button
            id="create-supplier"
            onClick={handleOpenModal}
            isDisabled={Boolean(state.modal) || !userHasReadWriteRole}
          >
            Create Supplier
          </Button>
        }
      />

      <Table
        rows={data || []}
        columns={columns}
        loading={isLoading}
        pagination
        components={{
          LoadingOverlay: TableLoadingOverlay,
          Toolbar: () => (
            <Box display="flex">
              <TableToolbar showExportButton />
              <TooltipWrapperButton>
                <Button variant="text" onClick={getAuditLog} size="small" icon={<CloudDownload />}>
                  Download suppliers audit log
                </Button>
              </TooltipWrapperButton>
            </Box>
          ),
        }}
        disableSelectionOnClick
        autoPageSize={false}
        pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
      />
      <ManageSupplierDialog
        data={state.data}
        modal={state.modal}
        handleClose={handleCloseModal}
        handleRefetch={handleRefetch}
      />
    </>
  )
}

export { SupplierList }

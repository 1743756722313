import type {
  IProduct,
  IProductData,
  IProductResponse,
  IInputCSV,
  IProductAudit,
} from "modules/Products/models/product"
import { IBulkResponse } from "models/bulk"
import { ICalculatorResult } from "modules/Products/models/newProductWizard"
import { GridFilterItem, GridSortItem } from "@mui/x-data-grid"
import { api } from "./api"

export const getProducts = async (
  page: number,
  size: number,
  sortModel?: GridSortItem[],
  filterValue?: GridFilterItem
): Promise<IProductResponse> => {
  const { data } = await api.products.get({
    page: page.toString(),
    size: size.toString(),
    sortModel,
    filterValue,
  })

  return data
}

export const getProductsWithCustomerGroups = async (
  page: number,
  size: number,
  sortModel?: GridSortItem[],
  filterValue?: GridFilterItem
): Promise<IProductResponse> => {
  const { data } = await api.products.getWithCustomerGroups({
    page: page.toString(),
    size: size.toString(),
    sortModel,
    filterValue,
  })

  return data
}

export const getAllProductsWithCustomerGroups = async (): Promise<IProduct[]> => {
  const { data } = await api.products.getAllWithCustomerGroups()
  return data
}

export const getAllProducts = async (): Promise<IProduct[]> => {
  const { data } = await api.products.getAll()
  return data
}

export const createProduct = async (productData: IProductData): Promise<ICalculatorResult[]> => {
  const { data } = await api.products.create(productData)

  return data
}

export const updateProduct = async (id: number, productData: IProductData): Promise<ICalculatorResult[]> => {
  const { data } = await api.products.update(id, productData)

  return data
}

export const getProductById = async (productId: number): Promise<IProduct> => {
  const { data } = await api.products.getById(productId)

  return data
}

export const updateProducts = async (inputCSV: IInputCSV[]): Promise<IBulkResponse> => {
  const { data } = await api.products.updateProducts(inputCSV)
  return data
}

export const updateProductsByCustomersGroups = async (inputCSV: IInputCSV[]): Promise<IBulkResponse> => {
  const { data } = await api.products.updateProductsByCustomerGroups(inputCSV)
  return data
}

export const auditProducts = async (): Promise<Array<IProductAudit>[]> => {
  const { data } = await api.products.audit()
  return data.reverse()
}

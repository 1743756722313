import { useMutation } from "react-query"
import { updateCompetitorProduct } from "services/competitors"
import { uiActions } from "store/ui-slice"
import { setAlertContent } from "utils/general"
import { BULK_STATUS } from "constants/bulk"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store"
import { getDynamicRows } from "utils/competitors"
import React, { useMemo } from "react"
import { translations } from "utils/translations"
import { ICompetitorsPrice, ICompetitorsProduct } from "../models/competitor"

const useUpdateCompetitorProduct = (getCompetitorsProductsData: ICompetitorsProduct[]) => {
  const listRef = React.useRef(null)

  const dispatch = useDispatch()

  const bulkStatus = useSelector((state: RootState) => state.bulk.status)

  const competitorsProductsRows = useMemo(() => getDynamicRows(getCompetitorsProductsData), [
    getCompetitorsProductsData,
  ])

  const { mutate: useUpdateCompetitorProductMutate } = useMutation(
    (competitorPrice: ICompetitorsPrice) => updateCompetitorProduct(competitorPrice),
    {
      onSuccess: (data: ICompetitorsPrice) => {
        if (data) {
          const { children, severity } = setAlertContent(bulkStatus)

          dispatch(
            uiActions.showNotification({
              children,
              severity,
            })
          )
        }
      },
      onError: () => {
        const { children, severity } = setAlertContent(BULK_STATUS.FAILED)

        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )
      },
    }
  )
  React.useEffect(() => {
    if (competitorsProductsRows.length) {
      dispatch(
        uiActions.showNotification({
          children: translations.general.valuesEditable,
          severity: "info",
        })
      )
    }
  }, [dispatch, competitorsProductsRows.length])

  return { useUpdateCompetitorProductMutate }
}

export default useUpdateCompetitorProduct

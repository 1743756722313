import { makeStyles } from "@material-ui/core"
import { rem } from "polished"
import { palette, typography } from "../theme"

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  color: {
    width: rem("9px"),
    height: rem("9px"),
    borderRadius: "50%",
    marginRight: rem("10px"),
    backgroundColor: active => (active ? palette.success.main : palette.warning.main),
  },
  status: {
    fontSize: typography.body1.fontSize,
    fontWeight: typography.body1.fontWeight,
    color: palette.grey[800],
    lineHeight: rem("20px"),
  },
}))

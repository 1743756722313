import { GridValueFormatterParams } from "@mui/x-data-grid"
import { formatDateFromString } from "./general"

export const getStringIndexBySelectionModel = (index: string | number): number =>
  typeof index === "string" ? parseInt(index, 10) : index

export type ITableRow<Value = number, Inheritance = unknown> = {
  id: number
  name: string
  value: Value
} & Inheritance

export const dateValueFormatter = (params: GridValueFormatterParams): string => {
  const { value } = params
  return formatDateFromString(value as string).toLocaleString()
}

export const productsAmountValueFormatter = (params: GridValueFormatterParams): string => {
  const valueFormatted = (params.value as number) === 1 ? "product" : "products"

  return ` ${params.value} ${valueFormatted}`
}

import * as React from "react"
import { Redirect, useHistory, useLocation, useParams } from "react-router"
import { useSelector } from "react-redux"
import { Typography } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import _ from "lodash"

import type { IRuleState, StepGroupRulesLocationState } from "typings/modules"
import { RootState } from "store"
import { useQueryCache } from "hooks"
import { Button, HeaderContainer, Spacer } from "components"
import { ROUTES } from "constants/routes"
import StepGroupRulesProvider from "../context/StepGroupRulesContext"
import useStepGroupRules from "../hooks/use-step-group-rules"
import StepGroupStepRule from "../components/StepGroupStepRule"

const StepGroupRulesUpsertContent = () => {
  const { ruleStates, handleAddRule } = useStepGroupRules()

  const history = useHistory<StepGroupRulesLocationState>()

  const { mode, stepGroup, step } = history.location.state

  const stepQueryKey = `stepGroup-${stepGroup.id}-step-${step.id}`

  const cachedStepQuery = useQueryCache(stepQueryKey)

  const handleCancel = () => {
    history.push(`${ROUTES.STEP_GROUPS.path}/${stepGroup.id}`)
  }

  const actionStatus = useSelector((selectorState: RootState) => selectorState.ui)

  /**
   * @description read-only mode
   */
  const isWatchMode = mode === "watch"

  const title = `${stepGroup.name} - ${step.name}`

  const [defaultRule, childrenRules] = React.useMemo(() => {
    if (mode === "watch") {
      return [null, ruleStates]
    }

    const [newFirstRule, ...rules] = ruleStates

    const isEmptyRestRules = _.size(rules) === 0

    let children: IRuleState[] = rules

    if (!isEmptyRestRules && newFirstRule.id) {
      children = ruleStates
    }

    let firstRule = newFirstRule

    if (!mode || mode === "edit") {
      if (isEmptyRestRules) {
        children.push(newFirstRule)
      }

      firstRule = null
    }

    return [firstRule, children]
  }, [ruleStates, mode])

  return (
    <>
      <HeaderContainer>
        <div>
          <Button variant="text" onClick={handleCancel} icon={<ArrowBack />} size="small">
            Back
          </Button>
          <Spacer size={1} />
          <Typography variant="h5">{title}</Typography>
        </div>
        {!isWatchMode && (
          <Button onClick={handleCancel} variant="outlined" isDisabled={actionStatus.isLoading}>
            Cancel
          </Button>
        )}
      </HeaderContainer>
      <div>
        {defaultRule && (
          <StepGroupStepRule key={defaultRule.internalId} rule={defaultRule} isFakeRule canEditCondition={false} />
        )}
        {!defaultRule && (
          <Button onClick={handleAddRule} variant="outlined" isDisabled={cachedStepQuery.state.isFetching}>
            + Add New Rule
          </Button>
        )}
        <Spacer size={2} />
        {childrenRules.map(rule => (
          <StepGroupStepRule key={rule.internalId} rule={rule} isFakeRule={false} canEditCondition />
        ))}
      </div>
    </>
  )
}

/**
 * @todo
 * - format SysObjects after deleting (List of Rules)
 * - remove empty step from step group main from calculator when step group main does not have any steps
 */
const StepGroupRulesUpsert = () => {
  const location = useLocation()

  const params = useParams<{ stepGroupId: string }>()

  if (!location.state) {
    const pathname = params.stepGroupId ? `${ROUTES.STEP_GROUPS.path}/${params.stepGroupId}` : ROUTES.STEP_GROUPS.path

    return <Redirect to={{ pathname, state: null }} push={false} />
  }

  return (
    <StepGroupRulesProvider>
      <StepGroupRulesUpsertContent />
    </StepGroupRulesProvider>
  )
}

export { StepGroupRulesUpsert }

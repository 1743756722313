import type { FormikProps } from "formik"
import * as React from "react"
import { useFormik } from "formik"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import { Box, Card, CardContent, Container, IconButton, Typography } from "@material-ui/core"

import { Button } from "components"
import { formatCustomersCount } from "utils/customerGroup"
import { WizardHeader } from "./WizardHeader"
import {
  CustomersAndPricingStepInitialValuesSchema,
  ICustomerGroupStep,
  ICustomerStepProps,
} from "../models/newProductWizard"
import { StepContent, CollapseContainer, useCustomerAndPricingStyles } from "../styles"
import { NewProductWizardFooter } from "./NewProductWizardFooter"

const CustomersAndPricingStep = React.forwardRef<FormikProps<ICustomerGroupStep>, ICustomerStepProps>(
  ({ customerStepState, removeItemHandler, addGroupButtonHandler, handleBack, handleNext }, formikRef) => {
    const formik = useFormik({
      initialValues: { customerGroups: customerStepState || [] },
      validationSchema: CustomersAndPricingStepInitialValuesSchema,
      onSubmit: () => {
        handleNext()
      },
    })

    React.useImperativeHandle(
      formikRef,
      () => ({
        ...formik,
      }),
      [formik]
    )

    React.useEffect(() => {
      if (formik.values.customerGroups !== customerStepState) {
        formik.setFieldValue("customerGroups", customerStepState || [])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerStepState])

    const styles = useCustomerAndPricingStyles()

    return (
      <>
        <StepContent fullWidth style={{ padding: "0 0 25px" }}>
          <WizardHeader
            title="Who is going to be selling this product"
            subtitle="Add all the Customer groups selling this product"
          />
          <CollapseContainer columnClassName="collapse-column">
            {customerStepState?.map(customerGroup => (
              <Container key={customerGroup.id} className={styles.container}>
                <Card variant="outlined">
                  <CardContent className={styles.cardContent}>
                    <Box>
                      <Typography variant="h4" className={styles.title}>
                        {customerGroup.name}
                      </Typography>
                      <Typography variant="caption" className={styles.subtitle}>
                        ID: {customerGroup.id}
                        <Typography variant="caption" className={styles.middle}>
                          *
                        </Typography>
                        {formatCustomersCount(customerGroup.customersCount)}
                      </Typography>
                    </Box>
                    <IconButton
                      color="default"
                      className={styles.button}
                      onClick={e => {
                        e.stopPropagation()

                        removeItemHandler(customerGroup)
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              </Container>
            ))}
          </CollapseContainer>
          <Button variant="contained" color="primary" onClick={addGroupButtonHandler} data-testid="button-next">
            Add Customer Group
          </Button>
        </StepContent>
        <NewProductWizardFooter isLastStep handleBack={handleBack} handleNext={handleNext} />
      </>
    )
  }
)

export { CustomersAndPricingStep }

import React from "react"
import { Alert as MuiAlert } from "@material-ui/lab"
import { ThemeProvider } from "@material-ui/core"
import { Warning, Error, Info } from "@material-ui/icons"
import { AlertTheme } from "./styles"

export interface IAlertProps {
  children: React.ReactNode
  severity: "warning" | "error" | "info" | "success"
  action?: React.ReactNode
}

const Alert = ({ children, severity, action }: IAlertProps): JSX.Element => {
  return (
    <ThemeProvider theme={AlertTheme}>
      <MuiAlert
        severity={severity}
        iconMapping={{
          warning: <Warning />,
          error: <Error />,
          info: <Info />,
          success: <Error />,
        }}
        action={action}
      >
        {children}
      </MuiAlert>
    </ThemeProvider>
  )
}

export { Alert }

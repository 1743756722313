import { ILevelResponse, ILevelTable, ITierResponse } from "modules/Parameters/models/parameters"
import numeral from "numeral"

export const mapNumberIntoCurrency = (number: number): string => numeral(number).format("0,0.00")

export const getTierKey = (tierName: string): string => tierName.replace(/\s/g, "")

export const getTierNameFromKey = (tierKey: string): string =>
  `${tierKey.substring(0, tierKey.length - 1)} ${tierKey[tierKey.length - 1]}`

export const getTierValues = (tiers: ITierResponse[]): { [index: string]: number } =>
  tiers.reduce((map, obj) => {
    map[getTierKey(obj.name)] = obj.value
    return map
  }, {} as { [index: string]: number })

export const formatLevelResponse = (data: ILevelResponse[]): ILevelTable[] =>
  data.map((level: ILevelResponse) => ({
    id: level.id,
    name: level.name,
    value: level.value,
    ...getTierValues(level.tiers),
  }))

import React, { MouseEventHandler, useMemo, useEffect } from "react"
import { ThemeProvider, Card as MuiCard, ButtonBase } from "@material-ui/core"
import classNames from "classnames"
import _ from "lodash"

import { StepGroupItemEditable } from "components"
import { palette } from "components/theme"
import { splitCamelCase } from "utils/general"
import type { IStepGroupResponse } from "typings/modules"
import { StepGroupSelectorTheme, cardStyles, CollapsedStepGroupItem } from "./styles"
import { ISelectOption } from "../Form"

export interface IStepGroupItem {
  onCardClick?: MouseEventHandler
  isSelected?: boolean
  stepGroupTypeOptions: ISelectOption[]
  allStepGroupTypeOptions: ISelectOption[]
  onCheckClick?: (stepGroup: IStepGroupResponse) => void
  onDeleteStepGroup: (stepGroup: IStepGroupResponse) => void
  stepGroup: IStepGroupResponse
  expanded: boolean
  setIsExpandedButtonDisabled?: (disabled: boolean) => void
  onCloseClick?: () => void
  isDisabled?: boolean
}

const avatarColors = {
  acquisitionCost: palette.error.dark,
  fee: palette.primary.main,
  retailPrice: palette.warning.dark,
  vetPrice: palette.success.dark,
  wholesalePrice: palette.info.dark,
} as const

const StepGroupItem = ({
  onCardClick,
  isSelected,
  stepGroupTypeOptions,
  allStepGroupTypeOptions,
  onCheckClick,
  onDeleteStepGroup,
  stepGroup,
  expanded,
  setIsExpandedButtonDisabled,
  onCloseClick,
  isDisabled,
}: IStepGroupItem): JSX.Element => {
  const [isEditing, setIsEditing] = React.useState(false)

  const classes = cardStyles()

  useEffect(() => {
    if (!stepGroup.id) {
      setIsEditing(true)
    }
  }, [stepGroup.id])

  const mappedStepGroupType = useMemo(() => {
    const stepGroupTypeLabel = (_.find(allStepGroupTypeOptions, {
      value: stepGroup.type,
    })?.label || "") as keyof typeof avatarColors

    const avatarKeys = _.reduce(
      allStepGroupTypeOptions,
      (collection, item) => {
        const [firstWord, secondWord] = splitCamelCase(item.value)

        let flag = firstWord.slice(0, 2).toUpperCase()

        if (secondWord) {
          flag = `${firstWord.slice(0, 1).toUpperCase()}${secondWord.slice(0, 1).toUpperCase()}`
        }

        collection[item.value as keyof typeof avatarColors] = flag

        return collection
      },
      {} as Record<keyof typeof avatarColors, string>
    )

    return {
      label: stepGroupTypeLabel,
      avatar: avatarKeys[stepGroupTypeLabel],
      color: avatarColors[stepGroupTypeLabel] || palette.primary.main,
    }
  }, [stepGroup.type, allStepGroupTypeOptions])

  const handleEdit = () => {
    setIsExpandedButtonDisabled(true)
    setIsEditing(true)
  }

  const handleClose = () => {
    setIsExpandedButtonDisabled(false)
    setIsEditing(false)
    onCloseClick()
  }

  return (
    <ThemeProvider theme={StepGroupSelectorTheme}>
      {expanded ? (
        <MuiCard
          className={classNames({
            [classes.selected]: isSelected === true,
            [classes.small]: !isEditing,
          })}
          onClick={onCardClick}
        >
          <StepGroupItemEditable
            stepGroup={stepGroup}
            mappedStepGroupType={mappedStepGroupType}
            onCloseClick={handleClose}
            onCheckClick={onCheckClick}
            onDeleteStepGroup={onDeleteStepGroup}
            onEditStepGroup={handleEdit}
            stepGroupTypeOptions={stepGroupTypeOptions}
            isEditing={isEditing}
            isDisabled={isDisabled}
          />
        </MuiCard>
      ) : (
        <ButtonBase onClick={onCardClick}>
          <CollapsedStepGroupItem color={mappedStepGroupType.color}>
            {mappedStepGroupType.avatar}
          </CollapsedStepGroupItem>
        </ButtonBase>
      )}
    </ThemeProvider>
  )
}

export { StepGroupItem }

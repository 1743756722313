import * as React from "react"

import { CreateSupplierDialog } from "./CreateSupplierDialog"
import { UpdateSupplierDialog } from "./UpdateSupplierDialog"
import { DeleteSupplierDialog } from "./DeleteSupplierDialog"
import type { ISupplier, ISupplierListState } from "../models/supplier"

interface IProps extends ISupplierListState {
  handleClose: () => void
  handleRefetch: () => void
}

const ManageSupplierDialog = ({ data, modal, handleClose, handleRefetch }: IProps): JSX.Element => {
  if (modal === "create-supplier") {
    return <CreateSupplierDialog isOpen handleClose={handleClose} handleRefetch={handleRefetch} />
  }

  if (modal === "update-supplier") {
    return (
      <UpdateSupplierDialog isOpen data={data as ISupplier} handleClose={handleClose} handleRefetch={handleRefetch} />
    )
  }

  if (modal === "delete-supplier") {
    return (
      <DeleteSupplierDialog isOpen data={data as ISupplier} handleClose={handleClose} handleRefetch={handleRefetch} />
    )
  }

  return (null as unknown) as JSX.Element
}

export { ManageSupplierDialog }

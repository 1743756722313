import type { GridRenderEditCellParams } from "@mui/x-data-grid"
import * as React from "react"
import _ from "lodash"

import { IMaskAccept, MaskedField } from "components"
import { useStyles } from "./styles"

interface IPriceInputProps extends Pick<GridRenderEditCellParams, "id" | "field" | "value" | "api" | "row"> {
  rowIdentifier?: string
  allowEmpty?: boolean
}

const PriceInput = (props: IPriceInputProps) => {
  const { id, field, value: initialValue, api, row, rowIdentifier, allowEmpty } = props

  const [value, setValue] = React.useState(() => {
    const castedValue = (initialValue || 0).toString()

    if (rowIdentifier) {
      const identifierValue = String(row[rowIdentifier])

      const isError =
        String(initialValue).includes(identifierValue) || (!_.isNil(initialValue) && _.isNaN(Number(initialValue)))

      if (isError) {
        return allowEmpty ? null : "0"
      }
    }

    return castedValue
  })

  const handleAccept: IMaskAccept = (newValue, inputMask) => {
    setValue(newValue)

    const numericOrEmpty = inputMask.unmaskedValue === "" ? "" : Number(inputMask.unmaskedValue)

    api.setEditCellValue({
      id,
      field,
      value: numericOrEmpty,
    })
  }

  const classes = useStyles()

  return (
    <MaskedField
      field={{
        classes: { root: classes.formControl },
        id: id.toString(),
        name: "price",
        type: "text",
        value,
        InputProps: {
          inputProps: {
            maxLength: 11,
          },
          classes: {
            root: classes.root,
            input: classes.input,
          },
        },
        inputMode: "decimal",
        autoFocus: true,
      }}
      mask={{
        mask: "$pricing",
        blocks: {
          pricing: {
            mask: Number,
            padFractionalZeros: true,
            thousandsSeparator: ",",
            radix: ".",
            mapToRadix: ["."],
          },
        },
        lazy: true,
      }}
      handleAccept={handleAccept}
    />
  )
}

PriceInput.defaultProps = {
  rowIdentifier: null,
}

const renderPriceEditInputCell = (props: GridRenderEditCellParams): JSX.Element => {
  const { id, field, value, api, row } = props

  return <PriceInput id={id} field={field} value={value} api={api} row={row} />
}

const renderAssertPriceEditInputCell = (params: GridRenderEditCellParams, rowIdentifier: string): JSX.Element => {
  const { id, field, value, api, row } = params

  if (id && (value ?? "").toString()) {
    return <PriceInput id={id} field={field} value={value} api={api} row={row} rowIdentifier={rowIdentifier} />
  }

  return <></>
}

export { renderAssertPriceEditInputCell, renderPriceEditInputCell }

import { makeStyles } from "@material-ui/core"
import { rem } from "polished"

export const buttonGroupStyles = makeStyles(() => ({
  buttonGroup: {
    minWidth: rem("140px"),
    "& button": {
      textTransform: "capitalize",
      fontSize: rem("16px"),
    },
  },
  paper: {
    "& li": {
      whiteSpace: "normal",
    },
  },
  menuList: {
    zIndex: 999,
  },
}))

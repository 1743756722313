import React from "react"
import { DropzoneDialog as MuiDropzoneDialog } from "material-ui-dropzone"
import { DialogProps, ThemeProvider } from "@material-ui/core"
import { ENV } from "constants/env"
import { DropzoneDialogTheme } from "./styles"

export interface IDropzoneDialog extends DialogProps {
  open: boolean
  onSave: (files: File[]) => void
  onDelete?: (file: File) => void
  onClose: () => void
  acceptedFiles?: string[]
  showPreviews?: boolean
  showPreviewsInDropzone?: boolean
  showFileNamesInPreview?: boolean
  showAlerts?: boolean
  cancelButtonText?: string
  submitButtonText?: string
  dialogTitle?: string
  dropzoneText?: string
  maxFileSize?: number
  initialFiles?: File[]
}

export const DropzoneDialog = ({
  onClose,
  onSave,
  onDelete,
  acceptedFiles,
  open,
  cancelButtonText,
  submitButtonText,
  dialogTitle,
  dropzoneText,
  showPreviews,
  maxFileSize = ENV.MAX_FILE_SIZE,
  initialFiles,
}: IDropzoneDialog): JSX.Element => {
  return (
    <ThemeProvider theme={DropzoneDialogTheme}>
      <MuiDropzoneDialog
        open={open}
        onClose={onClose}
        onSave={onSave}
        onDelete={onDelete}
        acceptedFiles={acceptedFiles}
        dialogTitle={dialogTitle}
        dropzoneText={dropzoneText}
        showPreviews={showPreviews}
        cancelButtonText={cancelButtonText}
        submitButtonText={submitButtonText}
        maxFileSize={maxFileSize}
        initialFiles={initialFiles}
        data-testid="dropzoneDialog"
      />
    </ThemeProvider>
  )
}

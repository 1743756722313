import React from "react"
import { ListItem as MuiListItem, ListItemSecondaryAction, ListItemText, ThemeProvider } from "@material-ui/core"
import { IconButton, FilledCloseIcon, Spacer } from "components"
import { ListTheme, useStyles } from "./styles"
import { IListItemProps } from "./props"

const ListItem = ({ option, onDeleteClick, children, hasHeader }: IListItemProps): JSX.Element => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={ListTheme}>
      <MuiListItem data-testid="list-item">
        {option.text && (
          <>
            <ListItemText primary={option.text} />
            <ListItemSecondaryAction className={classes.iconButton}>
              <IconButton
                color="inherit"
                data-testid="delete-button"
                icon={<FilledCloseIcon />}
                onClick={() => onDeleteClick(option)}
              />
            </ListItemSecondaryAction>
          </>
        )}
        {option.subOptions && (
          <ul className={classes.ulList}>
            {hasHeader ? <Spacer size={3} /> : <Spacer size={1} />}
            {children}
          </ul>
        )}
      </MuiListItem>
    </ThemeProvider>
  )
}

ListItem.defaultProps = {
  children: <></>,
  hasHeader: false,
}

export { ListItem }

import { createTheme, makeStyles } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const FormControlTheme = createTheme({
  palette,
  typography,
  overrides: {
    MuiFormControl: {
      root: {
        backgroundColor: "transparent",
        fontSize: typography.body2.fontSize,
        textAlign: "left",
        width: "100%",
        "& fieldset": {
          display: "none",
        },
      },
    },
    MuiList: {
      padding: {
        padding: `0 !important`,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: palette.background.default,
        borderColor: palette.grey[600],
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "4px",
        minHeight: rem("40px"),
        "& input[class*='MuiOutlinedInput-input']": {
          padding: rem("12px"),
        },
        "&.Mui-focused": {
          borderColor: palette.primary.main,
          color: palette.primary.main,
          backgroundColor: palette.background.default,
          "& svg": {
            fill: palette.primary.main,
          },
        },
        "&.Mui-error": {
          borderColor: palette.error.main,
          color: palette.error.main,
          "&& svg": {
            fill: palette.error.main,
          },
        },
        "&.Mui-disabled": {
          backgroundColor: palette.grey[200],
          borderColor: palette.grey[500],
          color: palette.grey[600],
          "& svg": {
            fill: palette.grey[600],
          },
        },
      },
      adornedStart: {
        paddingLeft: spacing(1),
      },
      adornedEnd: {
        paddingRight: spacing(1),
      },
      inputMarginDense: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        height: rem("30px"),
        color: palette.text.primary,
        boxSizing: "border-box",
      },
    },
    MuiInputBase: {
      root: {
        "& textarea, input": {
          "&::placeholder": {
            color: palette.grey[600],
            fontWeight: typography.fontWeightRegular,
            fontSize: rem("14px"),
            opacity: 1,
          },
        },
        "& svg[class*='MuiSvgIcon-root']": {
          fill: palette.grey[500],
        },
      },
      inputMultiline: {
        height: "100% !important",
        "&::-webkit-scrollbar": {
          backgroundColor: palette.grey[400],
          width: "5px",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: palette.grey[600],
          borderRadius: "5px",
        },
        overflow: "auto !important",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: spacing(3),
        right: spacing(1),
      },
    },
    MuiInput: {
      root: {
        marginTop: 0,
      },
    },
    MuiSelect: {
      root: {
        "& em": {
          fontStyle: "normal",
          fontSize: rem("14px"),
          fontWeight: typography.fontWeightRegular,
          color: palette.grey[600],
          opacity: 1,
        },
      },
      select: {
        minWidth: rem("45px"),
        padding: spacing(1, 2),
        fontSize: typography.body2.fontSize,
        fontWeight: typography.fontWeightRegular as number,
        lineHeight: rem("14px"),
        height: rem("30px"),
        color: palette.text.primary,
        backgroundColor: palette.background.default,
        "&:focus": {
          backgroundColor: palette.background.default,
          borderRadius: "10px",
        },
        "&.Mui-disabled": {
          backgroundColor: palette.grey[200],
          borderColor: palette.grey[500],
          color: palette.grey[600],
          "& svg": {
            fill: palette.grey[600],
          },
        },
      },
      outlined: {
        borderRadius: "10px",
        backgroundColor: palette.background.default,
      },
    },
    MuiMenuItem: {
      root: {
        backgroundColor: palette.background.default,
        height: rem("40px"),
        padding: `${spacing(1)}px !important`,
        "&$selected": {
          backgroundColor: palette.info.main,
        },
        "&:hover": {
          backgroundColor: palette.grey[200],
          fontWeight: typography.h5.fontWeight,
        },
      },
    },

    MuiPaper: {
      root: {
        backgroundColor: palette.background.default,
        borderColor: palette.grey[500],
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "5px",
      },
    },
    MuiInputLabel: {
      shrink: {
        color: palette.grey[800],
        position: "static",
        transform: "none !important",
        marginBottom: spacing(1),
        fontWeight: typography.fontWeightRegular as number,
        fontSize: rem("12px"),
      },
    },
    MuiInputAdornment: {
      root: {
        "& button[class*='MuiIconButton-root']": {
          padding: spacing(1),
        },
        "& p": {
          marginBottom: `0 !important`,
        },
      },
    },
    MuiAutocomplete: {
      root: {
        display: "flex",
      },
    },
  },
})

export const multilineStyles = makeStyles(() => ({
  collapsedMultiline: {
    "& div[class*='MuiOutlinedInput-multiline']": {
      maxHeight: rem("40px"),

      "&.Mui-focused": {
        minHeight: rem("100px"),
      },
    },
  },
}))

import { Box, createStyles, makeStyles, styled } from "@material-ui/core"
import { palette, spacing } from "components/theme"
import { rem } from "polished"

export const TreeViewStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: rem("300px"),
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      marginLeft: spacing(4),
      "& div[class*='MuiTreeItem-content']": {
        "&:first-child div": {
          fontWeight: 600,
        },
      },
      "& div[class*='MuiCollapse-wrapperInner']": {
        "& div[class*='MuiTreeItem-label']": {
          fontWeight: `400 !important`,
        },
      },
    },
    title: {
      display: "inline-flex",
      marginBottom: spacing(2),
      "& svg": {
        fill: palette.primary.light,
        marginRight: spacing(1),
      },
    },
  })
)

export const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}))

import * as React from "react"
import { CardHeader, IconButton, CardContent } from "@material-ui/core"
import { Check, Close, DeleteOutlined, EditOutlined } from "@material-ui/icons"

import { getStepGroupCardTitle } from "utils/calculators"
import { Spacer, Input, Select, ISelectOption, StepGroupAvatar, Tooltip } from "components"
import type { IStepGroupResponse } from "typings/modules"
import type { CalculatorStepGroupType } from "constants/calculator"

type StepGroupItemEditableProps = {
  stepGroup: IStepGroupResponse
  stepGroupTypeOptions?: ISelectOption[]
  onCheckClick?: (stepGroup: IStepGroupResponse) => void
  onCloseClick?: () => void
  onDeleteStepGroup: (stepGroup: IStepGroupResponse) => void
  onEditStepGroup: () => void
  mappedStepGroupType: { label: string; avatar: string; color: string }
  isEditing: boolean
  isDisabled: boolean
}

const StepGroupItemEditable = ({
  stepGroup,
  onCheckClick,
  onCloseClick,
  onDeleteStepGroup,
  onEditStepGroup,
  stepGroupTypeOptions,
  mappedStepGroupType,
  isEditing,
  isDisabled,
}: StepGroupItemEditableProps) => {
  const [stepGroupState, setStepGroupState] = React.useState<IStepGroupResponse>(stepGroup)

  const descriptionLegend = "Add your description of the Rule Set item here"

  const handleCheckClick = () => {
    onCheckClick(stepGroupState)
    onCloseClick()
  }

  const handleGroupNameChange = (event: React.ChangeEvent<{ value: string }>) => {
    setStepGroupState({
      ...stepGroupState,
      name: event.target.value,
    })
  }

  const handleGroupTypeChange = (event: React.ChangeEvent<{ value: string }>) => {
    setStepGroupState({
      ...stepGroupState,
      type: event.target.value as CalculatorStepGroupType,
    })
  }

  const handleDescriptionChange = (event: React.ChangeEvent<{ value: string }>) => {
    setStepGroupState({
      ...stepGroupState,
      description: event.target.value,
    })
  }

  const handleCloseClick = () => {
    setStepGroupState(stepGroup)
    onCloseClick()
  }

  if (isEditing) {
    return (
      <>
        <CardHeader
          title={getStepGroupCardTitle(stepGroupState.name)}
          action={
            <>
              <IconButton aria-label="settings" onClick={handleCheckClick} disabled={!stepGroupState.type}>
                <Check />
              </IconButton>
              <IconButton aria-label="settings" onClick={handleCloseClick}>
                <Close />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Input
            label="Rule Set Name"
            value={stepGroupState.name}
            onChange={event => handleGroupNameChange(event)}
            placeholder="Type rule set name"
          />
          <Spacer size={3} />
          <Select
            options={stepGroupTypeOptions || []}
            handleChange={e => {
              handleGroupTypeChange(e)
            }}
            value={stepGroupState.type}
            label="Rule Set Type"
            placeholder="Select rule set type"
          />
          <Spacer size={3} />
          <Input
            label="Description"
            placeholder="Add rule set description"
            onChange={handleDescriptionChange}
            multiline
            minRows={4}
            value={stepGroupState.description ?? ""}
          />
        </CardContent>
      </>
    )
  }

  return (
    <>
      <CardHeader
        title={stepGroupState.name}
        action={
          stepGroupState.main ? null : (
            <>
              <IconButton aria-label="settings" onClick={onEditStepGroup} disabled={isDisabled}>
                <EditOutlined />
              </IconButton>
              <IconButton aria-label="settings" onClick={() => onDeleteStepGroup(stepGroupState)} disabled={isDisabled}>
                <DeleteOutlined />
              </IconButton>
            </>
          )
        }
      />
      <CardContent>
        <StepGroupAvatar
          id={mappedStepGroupType.avatar}
          name={mappedStepGroupType.label}
          key={mappedStepGroupType.avatar}
          color={mappedStepGroupType.color}
          variant="body1"
        />
        <Tooltip title={<>{stepGroupState.description || descriptionLegend}</>}>
          <span>{stepGroupState.description || descriptionLegend}</span>
        </Tooltip>
      </CardContent>
    </>
  )
}

export { StepGroupItemEditable }

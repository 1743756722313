import {
  ICompetitor,
  ICompetitorsProduct,
  IInputCSV,
  IBulkResponse,
  ICompetitorsAudit,
  ICompetitorsPrice,
} from "modules/Competitors/models/competitor"
import { api } from "./api"

export const getCompetitors = async (): Promise<ICompetitor[]> => {
  const { data } = await api.competitors.get()
  return data
}

export const updateCompetitors = async (): Promise<ICompetitor[]> => {
  const { data } = await api.competitors.post()
  return data
}

export const getCompetitorsProducts = async (): Promise<ICompetitorsProduct[]> => {
  const { data } = await api.competitors.getProducts()
  return data
}

export const updateCompetitorsProducts = async (inputCSV: IInputCSV[]): Promise<IBulkResponse> => {
  const { data } = await api.competitors.updateProducts(inputCSV)
  return data
}

export const getUploadStatus = async (uuid?: string): Promise<IBulkResponse> => {
  const { data } = await api.competitors.geUploadStatus(uuid)

  return data
}

export const deleteCompetitorsProduct = async (productId: string): Promise<number> => {
  const { status } = await api.competitors.deleteProduct(productId)
  return status
}

export const deleteCompetitor = async (competitorId: number): Promise<number> => {
  const { status } = await api.competitors.deleteCompetitor(competitorId)
  return status
}

export const auditCompetitors = async (): Promise<Array<ICompetitorsAudit>[]> => {
  const { data } = await api.competitors.audit()
  return data.reverse()
}

export const updateCompetitorProduct = async (competitorProduct: ICompetitorsPrice): Promise<ICompetitorsPrice> => {
  const { data } = await api.competitors.updateProductPrice(competitorProduct)
  return data
}

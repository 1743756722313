import React, { useEffect } from "react"
import {
  DashboardContentHeader,
  dashboardLayoutStyles,
  DashboardAsideContent,
  RuleSetHeader,
  IconButtonContainer,
} from "modules/Rules-Pricing/styles"
import classNames from "classnames"
import { Typography, IconButton as MuiIconButton } from "@material-ui/core"
import { Add, ChevronRight } from "@material-ui/icons"
import { IconButton, ISelectOption, StepGroupItem, Dialog } from "components"
import { useStepGroupTypes } from "services/hooks/useStepGroupTypes"
import type { IStepGroupResponse } from "typings/modules"

import some from "lodash/some"
import reduce from "lodash/reduce"
import { IStepGroupType } from "modules/Rules-Pricing/models/calculator"
import { CalculatorStepGroupType } from "constants/calculator"
import { useHasReadWriteRole } from "utils/user"

interface IProps {
  selectedGroup: IStepGroupResponse
  setSelectedGroup: (stepGroup: IStepGroupResponse) => void
  stepGroups: IStepGroupResponse[]
  setStepGroups: (stepGroups: IStepGroupResponse[]) => void
  updateCalculator: (stepGroups: IStepGroupResponse[]) => void
}

const AsideContent = (props: IProps) => {
  const { selectedGroup, setSelectedGroup, stepGroups, setStepGroups, updateCalculator } = props

  const [asideContentExpanded, setAsideContentExpanded] = React.useState(true)

  const [isExpandedButtonDisabled, setIsExpandedButtonDisabled] = React.useState(false)

  const [groupToDelete, setGroupToDelete] = React.useState<IStepGroupResponse>()

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  useEffect(() => {
    setSelectedGroup(stepGroups[stepGroups.length - 1])
  }, [stepGroups, setSelectedGroup])

  const { data: stepGroupTypesData } = useStepGroupTypes({})

  const classes = dashboardLayoutStyles()

  const updateStepGroup = (stepGroup: IStepGroupResponse) => {
    const parseStepGroups = stepGroups.reduce((acc: IStepGroupResponse[], item) => {
      const parseStepGroup = item?.id === selectedGroup?.id ? stepGroup : item

      return [...acc, parseStepGroup]
    }, [])

    updateCalculator(parseStepGroups)
  }

  const handleDeleteClick = (stepGroupParam: IStepGroupResponse) => {
    setGroupToDelete(stepGroupParam)

    setShowDeleteDialog(true)
  }

  const handleDeleteStepGroup = () => {
    const parseStepGroups = stepGroups.filter(step => step.id !== groupToDelete.id)

    updateCalculator(parseStepGroups)

    setShowDeleteDialog(false)
  }

  const addNewStepGroup = () => {
    const newStepGroup: Partial<IStepGroupResponse> = {
      name: "",
      type: "" as CalculatorStepGroupType,
      steps: [],
      main: false,
    }

    setSelectedGroup(newStepGroup as IStepGroupResponse)

    const parseStepGroups = [...stepGroups, newStepGroup]

    setStepGroups(parseStepGroups as IStepGroupResponse[])
  }

  const handleClose = () => {
    if (!selectedGroup?.id) {
      setStepGroups(stepGroups.filter(step => step.id))
    } else {
      setStepGroups(stepGroups)
    }
  }

  const { stepGroupTypeOptions, allStepGroupTypeOptions } = React.useMemo(() => {
    const formatItem = (item: IStepGroupType) => ({
      value: item.name.toString(),
      label: item.name,
      isDisabled: some(stepGroups, {
        type: item.name,
      }),
    })

    const options = reduce(
      stepGroupTypesData as IStepGroupType[],
      (collection, current) => {
        const stepGroupType = formatItem(current)

        if (!current.masterType) {
          collection.stepGroupTypeOptions.push(stepGroupType)
        }

        collection.allStepGroupTypeOptions.push(stepGroupType)

        return collection
      },
      {
        stepGroupTypeOptions: [] as ISelectOption[],
        allStepGroupTypeOptions: [] as ISelectOption[],
      }
    )

    return options
  }, [stepGroupTypesData, stepGroups])

  const checkIfStepGroupIsSelected = (stepGroupId: number) => {
    if (!stepGroupId) {
      return true
    }
    return stepGroupId === selectedGroup?.id
  }

  const secondaryStepGroupsLength = stepGroups.filter(step => !step.main).length

  const revertedStepGroupArray = stepGroups && [...stepGroups].reverse()

  const isAddStepGroupButtonDisabled =
    secondaryStepGroupsLength === stepGroupTypeOptions?.length || stepGroups.some(({ id }) => !id)

  const userHasReadWriteRole = useHasReadWriteRole()

  return (
    <>
      <Dialog
        open={showDeleteDialog}
        title="Delete rule set"
        onClose={() => setShowDeleteDialog(false)}
        primaryActionHandler={handleDeleteStepGroup}
        secondaryActionLabel="Cancel"
        primaryActionLabel="Delete"
      >
        <Typography variant="h4">Are you sure you want to delete the rule set {groupToDelete?.name}?</Typography>
      </Dialog>
      <DashboardAsideContent
        className={classNames(classes.asideOpen, {
          [classes.asideClose]: !asideContentExpanded,
        })}
      >
        <DashboardContentHeader>
          <RuleSetHeader>
            <MuiIconButton
              className={classNames(classes.expand, {
                [classes.expandOpen]: asideContentExpanded,
              })}
              onClick={() => setAsideContentExpanded(prevState => !prevState)}
              aria-expanded={asideContentExpanded}
              color="inherit"
              disabled={isExpandedButtonDisabled}
            >
              <ChevronRight />
            </MuiIconButton>

            {asideContentExpanded && <Typography variant="h5">Rule Set</Typography>}
          </RuleSetHeader>
          {asideContentExpanded && (
            <IconButtonContainer>
              <IconButton
                size="small"
                icon={<Add />}
                onClick={addNewStepGroup}
                isDisabled={isAddStepGroupButtonDisabled || !userHasReadWriteRole}
              />
            </IconButtonContainer>
          )}
        </DashboardContentHeader>

        {stepGroups.length === 0 ? (
          <Typography variant="h5">There are no rule sets</Typography>
        ) : (
          revertedStepGroupArray?.map(
            (stepGroup): JSX.Element => (
              <StepGroupItem
                key={stepGroup.id || stepGroup.name}
                expanded={asideContentExpanded}
                setIsExpandedButtonDisabled={setIsExpandedButtonDisabled}
                isSelected={checkIfStepGroupIsSelected(stepGroup?.id)}
                onCardClick={() => setSelectedGroup(stepGroup)}
                onCheckClick={stepGroupData => updateStepGroup(stepGroupData)}
                stepGroupTypeOptions={stepGroupTypeOptions}
                allStepGroupTypeOptions={allStepGroupTypeOptions}
                onDeleteStepGroup={handleDeleteClick}
                stepGroup={stepGroup}
                onCloseClick={handleClose}
                isDisabled={!userHasReadWriteRole}
              />
            )
          )
        )}
      </DashboardAsideContent>
    </>
  )
}

export default AsideContent

import React from "react"
import { Button as MuiButton, ButtonProps, ThemeProvider } from "@material-ui/core"
import { ButtonTheme } from "./styles"

export interface IButtonProps extends ButtonProps {
  id?: string
  children?: React.ReactNode
  color?: "default" | "inherit" | "primary" | "secondary"
  variant?: "contained" | "outlined" | "text"
  size?: "large" | "small"
  icon?: JSX.Element
  onClick?: React.MouseEventHandler<any>
  isDisabled?: boolean
  className?: string
}

const Button = ({
  id,
  children,
  color = "primary",
  variant = "contained",
  size = "large",
  icon,
  onClick,
  isDisabled,
  className,
  type = "button",
}: IButtonProps): JSX.Element => {
  return (
    <ThemeProvider theme={ButtonTheme}>
      <MuiButton
        id={id}
        disableRipple
        className={className}
        color={color}
        size={size}
        onClick={onClick}
        variant={variant}
        data-testid="button"
        disabled={isDisabled}
        type={type}
      >
        {icon}
        {children}
      </MuiButton>
    </ThemeProvider>
  )
}

export { Button }

import React, { ReactNode } from "react"
import {
  ThemeProvider,
  Select as MuiSelect,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Box,
  SelectProps,
} from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { FormControlTheme } from "./styles"
import { Chip } from "../.."

export enum MultipleSelectType {
  CHIP = "CHIP",
}
export interface ISelectProps {
  error?: boolean
  disabled?: boolean
  label?: string
  value?: string[]
  helperText?: string
  handleChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  children?: React.ReactNode
  type: MultipleSelectType
  displayEmpty?: boolean
  placeholder?: string
}

const MultipleSelect = ({
  error,
  disabled,
  label,
  helperText,
  value,
  children,
  handleChange,
  type,
  displayEmpty,
  placeholder,
}: ISelectProps): JSX.Element => {
  const renderValue = (selected: SelectProps["value"]): ReactNode => {
    if ((selected as string[])?.length === 0 && placeholder) {
      return <em>{placeholder}</em>
    }

    switch (type) {
      case MultipleSelectType.CHIP:
        return (
          <Box display="flex" gridGap="8px" flexWrap="wrap">
            {(selected as string[])?.map(item => (
              <Chip size="small" key={item} label={item} />
            ))}
          </Box>
        )

      default:
        return (selected as string[]).join(", ")
    }
  }

  return (
    <ThemeProvider theme={FormControlTheme}>
      <FormControl error={error} disabled={disabled}>
        <InputLabel shrink>{label}</InputLabel>
        <MuiSelect
          data-testid="multiple-select"
          multiple
          variant="outlined"
          renderValue={renderValue}
          value={value}
          input={<Input />}
          onChange={handleChange}
          IconComponent={({ className }) => {
            return <KeyboardArrowDownIcon className={className} />
          }}
          displayEmpty={displayEmpty}
        >
          {children}
        </MuiSelect>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </ThemeProvider>
  )
}

export { MultipleSelect }

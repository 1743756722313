/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import { IMaskInputProps } from "react-imask"
import { TextFieldProps } from "@material-ui/core"
import IMask from "imask"

import { MixinField, MixinInput } from "./MixinInput"

export type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>

export type IMaskAccept = (
  value: string,
  inputMask: IMask.InputMask<IMask.AnyMaskedOptions>,
  inputEvent: ChangeEvent
) => void

export type Props = {
  mask: IMaskInputProps
  field: TextFieldProps
  handleAccept: IMaskAccept
}

const MaskedField = ({ field, mask, handleAccept }: Props): JSX.Element => {
  return (
    <MixinField
      classes={field.classes}
      id={field.id}
      name={field.name}
      type={field.type}
      InputProps={field.InputProps}
      inputMode={field.inputMode}
      autoFocus={field.autoFocus}
      value={field.value}
      mask={mask.mask}
      blocks={mask.blocks}
      lazy={mask.lazy}
      scale={mask.scale}
      signed={mask.signed}
      padFractionalZeros={mask.padFractionalZeros}
      normalizeZeros={mask.normalizeZeros}
      min={mask.min}
      max={mask.max}
      onAccept={handleAccept}
    />
  )
}

const MaskedInput = (props: Props): JSX.Element => {
  const { field, mask, handleAccept } = props

  return <MixinInput {...field} value={field.value} {...mask} onAccept={handleAccept} />
}

export { MaskedField, MaskedInput }

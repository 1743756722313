import React from "react"

import { RuleSelectProps } from "typings/modules"
import { Select, Tooltip } from "components"

const RuleSelect = (props: RuleSelectProps) => {
  const { name, value, onChange, options = [], label, disabled } = props

  const option = options.find(item => item.value === value)

  if (option?.label?.length > 14) {
    return (
      <Tooltip title={option.label}>
        <Select name={name} options={options} handleChange={onChange} value={value} label={label} disabled={disabled} />
      </Tooltip>
    )
  }

  return (
    <Select name={name} options={options} handleChange={onChange} value={value} label={label} disabled={disabled} />
  )
}

RuleSelect.defaultProps = {
  onChange: () => undefined as unknown,
  label: "",
  options: [],
  disabled: false,
  name: "",
}

export { RuleSelect }

import React from "react"

export interface IObjectTab {
  id: number
  label: string
  icon?: JSX.Element
  children?: React.ReactNode
  quantity?: number
}

export enum TabsOrientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum TabsVariant {
  FULL_WIDTH = "fullWidth",
  SCROLLABLE = "scrollable",
  STANDARD = "standard",
}

export enum TabsIndicatorColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export interface ITabs {
  orientation?: TabsOrientation
  variant?: TabsVariant
  value: number
  onChange: (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => void
  className?: string
  indicatorColor?: TabsIndicatorColor
  children: React.ReactNode
}

export interface ITabsItem {
  onClick: () => void
  icon?: JSX.Element
  label: string
}

export interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

import React from "react"

const CalculatorIcon = (): JSX.Element => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 0.202332H2.5C1.4 0.202332 0.5 1.10233 0.5 2.20233V16.2023C0.5 17.3023 1.4 18.2023 2.5 18.2023H16.5C17.6 18.2023 18.5 17.3023 18.5 16.2023V2.20233C18.5 1.10233 17.6 0.202332 16.5 0.202332ZM16.5 16.2023H2.5V2.20233H16.5V16.2023Z"
      fill="#1AA6DE"
    />
    <path d="M8.75 4.92233H3.75V6.42233H8.75V4.92233Z" fill="#1AA6DE" />
    <path d="M15.5 12.9523H10.5V14.4523H15.5V12.9523Z" fill="#1AA6DE" />
    <path d="M15.5 10.4523H10.5V11.9523H15.5V10.4523Z" fill="#1AA6DE" />
    <path d="M5.5 15.2023H7V13.2023H9V11.7023H7V9.70233H5.5V11.7023H3.5V13.2023H5.5V15.2023Z" fill="#1AA6DE" />
    <path
      d="M11.59 8.15233L13 6.74233L14.41 8.15233L15.47 7.09233L14.06 5.67233L15.47 4.26233L14.41 3.20233L13 4.61233L11.59 3.20233L10.53 4.26233L11.94 5.67233L10.53 7.09233L11.59 8.15233Z"
      fill="#1AA6DE"
    />
  </svg>
)

export { CalculatorIcon }

import { Box, styled } from "@material-ui/core"
import { rem } from "polished"
import { theme } from "../theme"

export const IconWrapper = styled(Box)(() => ({
  padding: theme.spacing(2),
  lineHeight: 0,
  width: rem("20px"),
  "& svg": {
    fill: "currentColor",
  },
}))

import * as React from "react"
import { Redirect, useLocation, RedirectProps } from "react-router-dom"

const CalculatorRuleWrapper = (props: React.PropsWithChildren<RedirectProps>) => {
  const { children, ...rest } = props

  const location = useLocation()

  if (!location.state) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Redirect {...rest} />
  }

  return children as JSX.Element
}

export default CalculatorRuleWrapper

import { useLocation } from "react-router-dom"

const useLocationState = <T>() => {
  const location = useLocation()

  const locationState = location.state as T

  return locationState
}

export default useLocationState

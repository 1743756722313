import * as React from "react"
import { AccordionSummary, Box } from "@material-ui/core"
import { KeyboardArrowDown } from "@material-ui/icons"

import type { StepGroupRulesLocationState } from "typings/modules"
import { useLocationState } from "hooks"
import { Accordion, Button, EditInline, UncontrolledAlert } from "components"
import { CalculatorConditionValue } from "constants/calculator"
import { getErrorMessage } from "utils"
import { RuleContent, RuleDetailsContainer } from "../containers/styles"
import { RuleOperator, RuleStatusField, StepGroupRuleArgument, StepGroupRuleConditional } from "./RuleControls"
import DialogCalculatorStepRule from "./DialogCalculatorStepRule"
import useStepGroupRules from "../hooks/use-step-group-rules"
import useRuleManagement from "../hooks/use-rule-management"
import { BLOCKED_CONDITIONAL_ARGUMENTS } from "../constants/calculator"

type StepGroupRuleProps = Parameters<typeof useRuleManagement>[0]

const StepGroupStepRule = (props: StepGroupRuleProps) => {
  const { isFakeRule, canEditCondition } = props

  const {
    isLoading: isBlocking,
    status,
    message,
    handleAddRule,
    handleSubmit,
    rulesRef,
    systemObjectOptions,
  } = useStepGroupRules()

  const {
    state,
    handleAccordion,
    handleInputChange,
    handleChangeArgument,
    handleChangeField,
    handleEdit,
    handleRevert,
  } = useRuleManagement(props, {
    rulesRef,
    systemObjectOptions,
  })

  const locationState = useLocationState<StepGroupRulesLocationState>()

  const isWatchMode = locationState.mode === "watch"

  const { rule, isOpen, isEditable } = state

  const { firstArgument, secondArgument } = rule

  const showOperations =
    Boolean(firstArgument.value && firstArgument.attribute) || !(!secondArgument.value && !secondArgument.attribute)

  const handleUpsert = () => {
    handleSubmit({
      id: rule.id,
      mode: rule.id ? "update" : "create",
    })
  }

  return (
    <Box>
      <Accordion size="large" expanded={isOpen} onChange={handleAccordion}>
        <AccordionSummary expandIcon={<KeyboardArrowDown />}>
          <EditInline
            name="ruleName"
            isEditable={isEditable}
            onInputChange={handleInputChange}
            text={rule.ruleName}
            placeholder="Untitled Rule Name"
            variant="h3"
            onEdit={isWatchMode ? null : handleEdit}
            onCheckClick={handleUpsert}
            onClose={handleRevert}
            maxLength="100"
            isDisabled={isBlocking}
            isCheckDisabled={!rule.isValid || isBlocking}
          />
          {!isWatchMode && rule.id && <DialogCalculatorStepRule rule={rule} handleSubmit={handleSubmit} />}
        </AccordionSummary>
        <RuleDetailsContainer>
          <RuleContent>
            <RuleStatusField
              rule={rule}
              handleChange={handleChangeField}
              canEditCondition={canEditCondition}
              disabled={isWatchMode || !isEditable}
            />
            <StepGroupRuleArgument
              rule={rule}
              argKey="firstArgument"
              handleChange={handleChangeArgument}
              disabled={!isEditable}
            />
            {showOperations && (
              <RuleOperator rule={rule} handleChange={handleChangeField} disabled={isWatchMode || !isEditable} />
            )}
            {Boolean(rule.operator) &&
              !BLOCKED_CONDITIONAL_ARGUMENTS.includes(
                rule.operator as typeof BLOCKED_CONDITIONAL_ARGUMENTS[number]
              ) && (
                <StepGroupRuleArgument
                  rule={rule}
                  argKey="secondArgument"
                  handleChange={handleChangeArgument}
                  disabled={isWatchMode || !isEditable}
                />
              )}
          </RuleContent>
          {rule.conditionValue === CalculatorConditionValue.SELECTIVE && (
            <StepGroupRuleConditional
              rule={rule}
              handleChange={handleChangeArgument}
              disabled={isWatchMode || !isEditable}
            />
          )}
        </RuleDetailsContainer>
      </Accordion>
      {isFakeRule && (
        <Button onClick={handleAddRule} variant="outlined" isDisabled={isBlocking || !rule.isValid || !rule.id}>
          + Add New Rule
        </Button>
      )}
      {(status === "success" || status === "error") && (
        <UncontrolledAlert severity={status}>
          {status === "success" ? "Your changes have been saved" : getErrorMessage(message)}
        </UncontrolledAlert>
      )}
    </Box>
  )
}

export default StepGroupStepRule

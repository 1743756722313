import { palette } from "components/theme"

enum TabEvent {
  CALCULATOR = "CALCULATOR",
  PUBLICATION = "PUBLICATION",
  SCHEDULED_PUBLICATION = "SCHEDULED_PUBLICATION",
  IMPORT = "IMPORT",
}

enum EventFilterDate {
  LAST_DAY = "LAST_DAY",
  LAST_TWO_DAYS = "LAST_TWO_DAYS",
  LAST_WEEK = "LAST_WEEK",
}

enum EventStatus {
  CREATED = "CREATED",
  PROCESSING = "PROCESSING",
  ERROR = "ERROR",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  COMPLETED = "COMPLETED",
  CALCULATION_ERROR = "CALCULATION_ERROR",
  CHECKING = "CHECKING",
  VALIDATED = "VALIDATED",
}

const STATUS_COLORS = {
  [EventStatus.CREATED]: palette.primary.main,
  [EventStatus.PROCESSING]: palette.success.light,
  [EventStatus.ERROR]: palette.error.main,
  [EventStatus.PARTIALLY_COMPLETED]: palette.success.dark,
  [EventStatus.COMPLETED]: palette.success.main,
  [EventStatus.CALCULATION_ERROR]: palette.error.dark,
  [EventStatus.CHECKING]: palette.success.light,
  [EventStatus.VALIDATED]: palette.info.dark,
} as const

export { TabEvent, EventFilterDate, EventStatus, STATUS_COLORS }

import { useQueries, UseQueryResult } from "react-query"
import { useLocation } from "react-router-dom"

import type { StepGroupRulesLocationState, IStep } from "typings/modules"
import { getStepGroupTypes, getSystemObjects } from "services"
import { getStepById, getStepsByStepGroupId } from "services/calculator"
import type { IStepGroupType } from "modules/Rules-Pricing/models/calculator"
import { ISystemObject } from "modules/Rules-Pricing/models/systemObject"

type StepGroupRulesQueries = [
  UseQueryResult<IStep>,
  UseQueryResult<IStepGroupType[]>,
  UseQueryResult<IStep[]>,
  UseQueryResult<ISystemObject[]>
]

const useStepGroupRulesSetup = () => {
  const location = useLocation()

  const locationState = location.state as StepGroupRulesLocationState

  const { stepGroup, step: currentStep } = locationState

  const stepQueryKey = `stepGroup-${stepGroup.id}-step-${currentStep.id}`

  const systemObjectsQueryKey = `stepGroup-${stepGroup.id}-system-objects`

  const stepGroupTypesQueryKey = `stepGroup-${stepGroup.id}-step-groups-types`

  const stepsQueryKey = `stepGroup-${stepGroup.id}-steps`

  const queries = useQueries([
    {
      queryKey: stepQueryKey,
      queryFn: () => getStepById(currentStep.id),
    },
    {
      queryKey: stepGroupTypesQueryKey,
      queryFn: () => getStepGroupTypes(),
    },
    {
      queryKey: stepsQueryKey,
      queryFn: () => getStepsByStepGroupId(stepGroup.id),
    },
    {
      queryKey: systemObjectsQueryKey,
      queryFn: () => getSystemObjects(),
    },
  ]) as StepGroupRulesQueries

  const isLoading = queries.some(query => query.isLoading)

  return { isLoading }
}

export default useStepGroupRulesSetup

export interface IRoute {
  path: string
  label: string
}

export const ROUTES = {
  HOME: { path: "/", label: "Home" },
  SIGN_IN: { path: "/sign-in", label: "Home" },
  HEALTH: { path: "/health", label: "Health" },
  PRODUCTS: { path: "/products", label: "Products" },
  EVENTS: { path: "/events", label: "Events" },
  CUSTOMERS: { path: "/customers", label: "Customers" },
  SUPPLIERS: { path: "/suppliers", label: "Suppliers" },
  RULES_PRICING: { path: "/calculators", label: "Rules & Pricing" },
  PRICE_LIST: { path: "/price-list", label: "Price List" },
  COMPETITORS: { path: "/competitors", label: "Competitors" },
  PARAMETERS: { path: "/parameters", label: "Parameters" },
  SETTINGS: { path: "/settings", label: "Settings" },
  STEP_GROUPS: { path: "/step-groups", label: "Step Groups" },

  get NEW_PRODUCT(): IRoute {
    return {
      path: `${ROUTES.PRODUCTS.path}/new`,
      label: "Create new product",
    }
  },
  get UPDATE_PRODUCT(): IRoute {
    return {
      path: `${ROUTES.PRODUCTS.path}/:productId`,
      label: "Update product",
    }
  },
  get NEW_CALCULATOR(): IRoute {
    return {
      path: `${ROUTES.RULES_PRICING.path}/new`,
      label: "New Calculator",
    }
  },
  get CALCULATOR_EDITOR(): IRoute {
    return {
      path: `${ROUTES.RULES_PRICING.path}/:calculatorId`,
      label: "Calculator Editor",
    }
  },
  get CALCULATOR_RESULTS(): IRoute {
    return {
      path: `${ROUTES.CALCULATOR_EDITOR.path}/price-history`,
      label: "Price history",
    }
  },
  get NEW_STEP_GROUP(): IRoute {
    return {
      path: `${ROUTES.STEP_GROUPS.path}/new`,
      label: "New Step Group",
    }
  },
  get STEP_GROUP_EDITOR(): IRoute {
    return {
      path: `${ROUTES.STEP_GROUPS.path}/:stepGroupId`,
      label: "Step Group Editor",
    }
  },
  get CALCULATOR_RULE_UPSERT(): IRoute {
    return {
      path: `${ROUTES.CALCULATOR_EDITOR.path}/rules-management`,
      label: "Rules Management",
    }
  },
  get STEP_GROUP_RULE_UPSERT(): IRoute {
    return {
      path: `${ROUTES.STEP_GROUP_EDITOR.path}/rules-management`,
      label: "Create new rule",
    }
  },
  get CALCULATION_EVENTS(): IRoute {
    return {
      path: `${ROUTES.EVENTS.path}/calculations`,
      label: "Events",
    }
  },
  get PUBLICATION_EVENTS(): IRoute {
    return {
      path: `${ROUTES.EVENTS.path}/publications`,
      label: "Events",
    }
  },
  get SCHEDULED_PUBLICATIONS_EVENTS(): IRoute {
    return {
      path: `${ROUTES.EVENTS.path}/scheduled-publications`,
      label: "Events",
    }
  },
  get IMPORT_EVENTS(): IRoute {
    return {
      path: `${ROUTES.EVENTS.path}/imports`,
      label: "Events",
    }
  },
  get CALCULATION_CHILDREN_EVENTS(): IRoute {
    return {
      path: `${ROUTES.EVENTS.path}/calculations/:uuid`,
      label: "Events",
    }
  },
  get PUBLICATION_CHILDREN_EVENTS(): IRoute {
    return {
      path: `${ROUTES.EVENTS.path}/publications/:uuid`,
      label: "Events",
    }
  },
  get IMPORT_CHILDREN__EVENTS(): IRoute {
    return {
      path: `${ROUTES.EVENTS.path}/imports/:uuid`,
      label: "Events",
    }
  },
}

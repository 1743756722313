import * as React from "react"

import type { INewProductWizardFooterProps } from "modules/Products/models/newProductWizard"
import { Button, StepFooter } from "components"

const NewProductWizardFooter = ({ isLastStep, isDisabled, handleBack }: INewProductWizardFooterProps): JSX.Element => {
  return (
    <StepFooter>
      <Button
        isDisabled={!handleBack}
        onClick={handleBack}
        color="primary"
        variant="outlined"
        data-testid="button-back"
      >
        Back
      </Button>
      <Button isDisabled={isDisabled} variant="contained" color="primary" type="submit" data-testid="button-next">
        {isLastStep ? "Save" : "Continue"}
      </Button>
    </StepFooter>
  )
}

export { NewProductWizardFooter }

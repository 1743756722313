import type { GridFilterItem, GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import * as React from "react"
import { useQuery, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"

import type { IEventResponse, IInputCSV } from "models/bulk"
import { getCustomers, getProductsWithCustomerGroups } from "services"
import { useCustomerGroups } from "services/hooks/useCustomerGroups"
import { getUploadStatus } from "services/bulk"
import { BULK_STATUS } from "constants/bulk"
import { uiActions } from "store/ui-slice"
import { setAlertContent } from "utils/general"
import { getDynamicColumns, getProductDynamicRows } from "utils/products"
import type { ICustomerListState } from "modules/Customers/models/customer"
import { baseColumns } from "modules/Customers/constants/productCustomersTable"
import { ENV } from "constants/env"
import { ProductPrices } from "modules/Customers/models/customerGroup"

export interface ICreateCustomer {
  name: string
  resellerId: string
  customerGroupName: string
}

export interface IUseCustomerListState extends ICustomerListState {
  import: "customers" | "customer-groups" | "product-whole-sale-price"
  tabValue: number
  customersFiles: File[]
  csvConverted: IInputCSV[]
  customersPage: number
  customersSortModel: GridSortModel
  customersFilterValue: GridFilterItem | undefined
  productFilterValue: GridFilterItem | undefined
  productsPage: number
  productsSortModel: GridSortModel
}

const customerListInitialState: IUseCustomerListState = {
  import: null,
  tabValue: 0,
  customersFiles: [],
  csvConverted: [],
  customersPage: 0,
  customersSortModel: [],
  customersFilterValue: undefined,
  modal: undefined,
  data: null,
  productFilterValue: undefined,
  productsPage: 0,
  productsSortModel: [] as GridSortModel,
}

export enum UseCustomerListActionType {
  UPDATER = "updater",
}

export interface IUseCustomerListAction {
  type: UseCustomerListActionType
  payload: Partial<IUseCustomerListState>
}

const handleReducer = (state: IUseCustomerListState, action: IUseCustomerListAction) => {
  switch (action.type) {
    case UseCustomerListActionType.UPDATER: {
      return { ...state, ...action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCustomerList = () => {
  const [state, dispatchState] = React.useReducer(handleReducer, customerListInitialState)

  const updater = (newState: Partial<IUseCustomerListState>) => {
    dispatchState({
      type: UseCustomerListActionType.UPDATER,
      payload: newState,
    })
  }

  const query = useQuery(
    ["getProductsWithCustomerGroups", state.productsPage, state.productsSortModel, state.productFilterValue],
    () =>
      getProductsWithCustomerGroups(
        state.productsPage,
        ENV.PAGINATION.DEFAULT_PAGE_SIZE,
        state.productsSortModel,
        state.productFilterValue
      )
  )

  const { isLoading: getProductsIsLoading, data: products } = query

  const {
    isLoading: isCustomerGroupsLoading,
    data: customerGroups,
    refetch: refetchCustomerGroups,
  } = useCustomerGroups()

  const { isLoading: isCustomersLoading, data: customers, refetch: refetchCustomers } = useQuery(
    ["getCustomers", state.customersPage, state.customersSortModel, state.customersFilterValue],
    () =>
      getCustomers(
        state.customersPage,
        ENV.PAGINATION.DEFAULT_PAGE_SIZE,
        state.customersSortModel,
        state.customersFilterValue
      )
  )

  const onCustomersFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    if (filterModel.items.length) {
      const [item] = filterModel.items

      if (item) {
        updater({ customersFilterValue: item })
      }
    }
  }, [])

  const onProductFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    if (filterModel.items.length) {
      const [item] = filterModel.items

      if (item) {
        updater({ productFilterValue: item })
      }
    }
  }, [])

  const totalCustomers = customers?.content.length ? customers.totalElements : undefined

  const totalProducts = products?.content.length ? products.totalElements : undefined

  const { current: cachedProductColumns } = React.useRef([...baseColumns])

  const productsColumns = React.useMemo(() => {
    if (products?.content) {
      cachedProductColumns.length = 0

      const updatedColumns = getDynamicColumns(products.content, cachedProductColumns)

      return updatedColumns
    }

    return cachedProductColumns
  }, [products, cachedProductColumns])

  const productByWholesaleRows = React.useMemo(
    () => getProductDynamicRows(ProductPrices.WHOLESALE, products?.content),
    [products]
  )

  const productByRetailRows = React.useMemo(() => getProductDynamicRows(ProductPrices.RETAIL, products?.content), [
    products,
  ])

  return [
    [
      state,
      {
        customers,
        totalCustomers,
        isCustomersLoading,
        refetchCustomers,
        refetchCustomerGroups,
        onCustomersFilterChange,
        customerGroups,
        isCustomerGroupsLoading,
        onProductFilterChange,
        totalProducts,
        productsColumns,
        productByWholesaleRows,
        productByRetailRows,
        getProductsIsLoading,
      },
    ],
    { updater },
  ] as const
}

const useUploadStatus = (bulkId: string, state: IUseCustomerListState) => {
  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const cacheUploadStatusData = queryClient.getQueryData<IEventResponse>(["getUploadStatus", bulkId])

  const { data: uploadStatusData, isFetching } = useQuery(["getUploadStatus", bulkId], () => getUploadStatus(bulkId), {
    enabled: !!bulkId && cacheUploadStatusData?.status !== BULK_STATUS.COMPLETED,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    onSuccess: response => {
      if (response?.status === BULK_STATUS.PROCESSED || response?.status === BULK_STATUS.COMPLETED) {
        const { children, severity } = setAlertContent(response?.status)

        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )

        queryClient.invalidateQueries(
          // eslint-disable-next-line no-nested-ternary
          state.import === "product-whole-sale-price"
            ? "getProductsWithCustomerGroups"
            : state.import === "customers"
            ? "getCustomers"
            : "getAllCustomerGroups"
        )
      }
    },
    onError: () => {
      const { children, severity } = setAlertContent(BULK_STATUS.FAILED)

      dispatch(
        uiActions.showNotification({
          children,
          severity,
        })
      )
    },
  })

  const isUploading = isFetching || uploadStatusData?.status === BULK_STATUS.PROCESSING

  return { isUploading }
}

export { useCustomerList, useUploadStatus }

import { createTheme } from "@material-ui/core/styles"
import { spacing, typography, palette } from "components/theme"
import { rem } from "polished"

export const AlertTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiAlert: {
      root: {
        fontSize: rem("16px"),
        fontWeight: typography.fontWeightRegular as number,
        color: palette.grey[900],
        display: "flex",
        alignItems: "center",
        padding: spacing(3),
        borderRadius: 0,
        height: spacing(2),
        position: "fixed",
        left: 0,
        right: 0,
        margin: "auto",
        top: 0,
        width: "fit-content",
        zIndex: 5000,
      },
      standardError: {
        backgroundColor: palette.error.light,
      },
      standardInfo: {
        backgroundColor: palette.info.main,
        "& div[class*='MuiAlert-icon']": {
          "& svg": {
            color: palette.primary.main,
          },
        },
      },
      standardWarning: {
        backgroundColor: palette.warning.light,
      },
      standardSuccess: {
        backgroundColor: palette.success.light,
      },
      icon: {
        marginRight: spacing(3),
        height: rem("20px"),
        width: rem("20px"),
        alignItems: "center",
        padding: 0,
      },
      message: {
        padding: 0,
      },
      action: {
        paddingRight: spacing(3),
        width: spacing(2),
        "& svg": {
          width: spacing(3),
        },
      },
    },
  },
})

import * as React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory, useParams } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import {
  GridEditRowsModel,
  GridFilterItem,
  GridFilterModel,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  getGridStringOperators,
  GridLinkOperator,
} from "@mui/x-data-grid"
import { Box, Link } from "@material-ui/core"
import { ErrorRounded } from "@material-ui/icons"
import { format } from "date-fns"
import FileSaver from "file-saver"
import _ from "lodash"

import {
  Button,
  ButtonsGroup,
  Header,
  ISelectOption,
  Select,
  Spacer,
  Table,
  TableLoadingOverlay,
  HardTableToolbar,
  TreeView,
} from "components"
import {
  exportAllCalculationResult,
  getCalculationResult,
  getCalculationsById,
  publishCalculator,
  updateCalculationResult,
} from "services/calculator"
import { RootState } from "store"
import { getUploadStatus } from "services/bulk"
import { fetchStepGroupTypesActionCreator, getCalculatorByIdActionCreator } from "store/calculator-actions"
import { calculatorActions } from "store/calculator-slice"
import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"
import { ALLOWED_FILTER_OPERATORS, formatDate, operatorIn } from "utils/general"
import { getAuthCookie, useHasReadWriteRole } from "utils/user"
import { getCalculatorIdTrigger } from "constants/calculator"
import { CurrentSwitch } from "modules/Products/components/CurrentPriceSwitch"
import { CurrentSwitchWrapper } from "modules/Products/styles"
import { ICustomerGroup } from "modules/Customers/models/customerGroup"
import { mapCalculationResultIntoTree } from "utils/calculators"
import { BULK_STATUS } from "constants/bulk"
import { ENV } from "constants/env"
import { ROUTES } from "constants/routes"
import { calculatorInitialValues } from "store/state"
import {
  IAPICalculatorResult,
  ICalculation,
  IResultRows,
  IStepGroupResultRequest,
  IPublicationData,
  PublishMode,
  IApiCalculationResultRequestBody,
} from "../models/calculator"
import { getColumns } from "../constants/calculatorResultColumns"
import { CalculationErrorButton, CalculatorResultButtonHeader } from "./styles"
import { PublishResultsDialog } from "../components"
import { mapPriceHistoryRows } from "../constants/utils"

const CalculatorResultListContent = () => {
  const userHasReadWriteRole = useHasReadWriteRole()

  const [calculatorState, ui] = useSelector((state: RootState) => [state.calculator, state.ui] as const)

  const dispatch = useDispatch()

  const history = useHistory<{ calculationUuid: string }>()

  const { calculatorId } = useParams<{ calculatorId: string }>()

  const [selectedCalculation, setSelectedCalculation] = React.useState<ICalculation>()

  const [editRowsModel, setEditRowsModel] = React.useState({})

  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowId[]>([])

  const [rowSelectionModelTotal, setRowSelectionModelTotal] = React.useState<GridRowId[]>([])

  const [page, setPage] = React.useState(0)

  const [totalResults, setTotalResults] = React.useState<number>()

  const [sortModel, setSortModel] = React.useState<GridSortModel>([] as GridSortModel)

  const [publishDialog, setPublishDialog] = React.useState<PublishMode>(null)

  const [publishDate, setPublishDate] = React.useState<Date>(new Date())

  const [existSelection, setExistSelection] = React.useState<boolean>(false)

  const [onNewPage, setOnNewPage] = React.useState<boolean>(false)

  const [prevPage, setPrevPage] = React.useState(0)

  const [prevPageMemo, setPrevPageMemo] = React.useState(0)

  const onSelectionModelChange = (model: GridSelectionModel) => {
    const filterVisible = document.getElementsByClassName("MuiGridPanelWrapper-root").length > 0
    let selectionModel = [...model, ...[]]
    if ((selectionModel.length === 0 && existSelection && onNewPage) || page !== prevPage || filterVisible) {
      selectionModel = [...rowSelectionModelTotal]
    }
    setOnNewPage(false)
    setPrevPage(page)
    const selection = [...model, ...rowSelectionModelTotal]
    let filterSelection = selection.filter(item => selectionModel.indexOf(item) !== -1)
    filterSelection = filterSelection.filter((value, index, array) => array.indexOf(value) === index)
    setRowSelectionModel(filterSelection)
    setRowSelectionModelTotal(filterSelection)
    setExistSelection(filterSelection.length > 0)
  }

  const [filterValue, setFilterValue] = React.useState<GridFilterItem>(() => {
    const [defaultCustomerGroup] = calculatorState.customerGroups || []

    const initialFilterValue: GridFilterItem = {
      id: 0,
      columnField: "customerGroups",
      operatorValue: "equals",
      value: defaultCustomerGroup || "",
    }

    return initialFilterValue
  })

  const [switchKey, setSwitchKey] = React.useState<IApiCalculationResultRequestBody["switchKey"]>(null)

  const calculationUuid = history.location.state?.calculationUuid

  const columns = React.useMemo(() => {
    return getColumns(calculatorState.stepGroupTypes || []).map(column => {
      let gridStringOperators = getGridStringOperators()
      gridStringOperators = [...gridStringOperators, operatorIn]
      const parsedColumn = {
        ...column,
        filterOperators: gridStringOperators.filter(({ value }) => {
          return ALLOWED_FILTER_OPERATORS.includes(value)
        }),
      }

      /**
       * strange workaround to remove the columns options from the filter panel except VS ID and Customer Group
       */
      if (column.field !== "productIds" && column.field !== "customerGroups") {
        return {
          ...parsedColumn,
          filterable: false,
        }
      }

      return parsedColumn
    })
  }, [calculatorState.stepGroupTypes])

  const { isLoading: isBulkLoading, data: calculationStatus, isFetching: isBulkFetching } = useQuery(
    ["getCalculateStatus", calculatorId],
    () => {
      return getUploadStatus(calculationUuid)
    },
    {
      enabled: Boolean(calculationUuid && calculatorId),
      refetchInterval: 3000,
      refetchIntervalInBackground: true,
      onSuccess: result => {
        if (result.status === "COMPLETED") {
          history.replace({
            ...history.location,
            state: { calculationUuid: "" },
          })

          dispatch(
            uiActions.showNotification({
              children: translations.alert.calculator.success,
              severity: "success",
            })
          )

          dispatch(uiActions.hideSpinner())
        }
      },
      onError: () => {
        dispatch(calculatorActions.updateCalculationUuid(""))

        history.replace({
          ...history.location,
          state: { calculationUuid: "" },
        })

        dispatch(
          uiActions.showNotification({
            children: translations.alert.calculator.calculation.error,
            severity: "error",
          })
        )
      },
    }
  )

  const isBulkProcessed = calculationStatus?.status === BULK_STATUS.PROCESSED

  const { isLoading: getCalculationsIsLoading, data: calculationsData, isSuccess } = useQuery(
    ["getCalculationResult", calculatorId],
    () => getCalculationsById(calculatorId),
    {
      enabled: calculationUuid ? isBulkProcessed && !!calculatorId : !!calculatorId,
    }
  )

  const selectedCalculationId = selectedCalculation?.id

  const { isLoading: getCalculationResultIsLoading, data: calculationResultData, isSuccess: isSuccessGet } = useQuery(
    [
      "getCalculationResult",
      calculatorId,
      page,
      ENV.PAGINATION.DEFAULT_PAGE_SIZE,
      selectedCalculationId,
      sortModel,
      filterValue,
      switchKey,
    ],
    () =>
      getCalculationResult({
        calculatorId,
        page,
        size: ENV.PAGINATION.DEFAULT_PAGE_SIZE,
        calculationId: selectedCalculationId,
        sortModel,
        filterValue,
        switchKey,
      }),
    {
      enabled: !!selectedCalculationId,
      retry: 1,
    }
  )

  React.useEffect(() => {
    setOnNewPage(true)
    setPrevPage(prevPageMemo)
  }, [calculationResultData, calculatorId, dispatch, history, isSuccessGet, prevPageMemo])

  React.useEffect(() => {
    setOnNewPage(true)
    setPrevPage(prevPageMemo)
  }, [filterValue, prevPageMemo])

  const isLoading = isBulkFetching || getCalculationResultIsLoading || getCalculationsIsLoading || isBulkLoading

  const queryClient = useQueryClient()

  const { mutate } = useMutation(
    (overriddenResult: IStepGroupResultRequest) => updateCalculationResult(overriddenResult),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getCalculationResult")
      },
      onError: error => {
        dispatch(
          uiActions.showNotification({
            children: (error as Error).toString(),
            severity: "error",
          })
        )
      },
    }
  )

  const { mutate: publish } = useMutation((publicationData: IPublicationData) => publishCalculator(publicationData), {
    onSuccess: () => {
      history.push(`${ROUTES.PUBLICATION_EVENTS.path}`)
      dispatch(
        uiActions.showNotification({
          children: (
            <>
              {calculatorState.name} calculator has been published.{" "}
              <Link color="primary" href="/products?showPrices=true">
                Go to Published Prices
              </Link>
            </>
          ),
          severity: "success",
        })
      )
    },
    onError: (error: string) => {
      dispatch(
        uiActions.showNotification({
          children: error.toString(),
          severity: "error",
        })
      )
    },
  })

  const handleCalculationDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.value

    const item = calculationsData?.find(calculation => calculation.id.toString() === selected)

    if (item) {
      setSelectedCalculation(prevState => item || prevState)
    }
  }

  const lastCalculation = React.useMemo(() => {
    if (calculationsData?.length) {
      return calculationsData?.reduce((calculationA, calculationB) =>
        new Date(calculationA.created) > new Date(calculationB.created) ? calculationA : calculationB
      )
    }
    return undefined
  }, [calculationsData])

  const calculationOptions = React.useMemo(
    (): ISelectOption[] =>
      calculationsData?.map(calculation => ({
        value: calculation.id.toString(),
        label: format(new Date(calculation.created), "MM/dd/yyyy HH:mm"),
      })) || [],
    [calculationsData]
  )

  const resultRows = React.useMemo((): IResultRows[] => {
    const calculationResult = calculationResultData?.content || []

    const rows = mapPriceHistoryRows(calculationResult)

    return rows
  }, [calculationResultData])

  const handleEditRowsModelChange = React.useCallback(
    (model: GridEditRowsModel) => {
      const rowId = Object.keys(model)[0]

      if (model[rowId]) {
        const field = model[rowId]

        if (field) {
          const [fieldName] = Object.keys(field)

          const updatedValue = (field[fieldName].value || 0) as string

          const [, productId, customerGroupId] = rowId.split("-")

          const updatedRow =
            _.find(calculationResultData?.content, result => result.product?.id?.toString() === productId) ||
            ({} as IAPICalculatorResult)

          const customerGroup =
            _.find(updatedRow.customerGroups, item => item.id.toString() === customerGroupId) || ({} as ICustomerGroup)

          const updatedStepGroup = _.find(customerGroup.stepGroupResults, {
            type: fieldName,
          })

          const isUpdatedValueValid = !_.isNaN(updatedValue)

          if (isUpdatedValueValid && updatedRow?.product?.id && updatedStepGroup?.id) {
            mutate({ id: updatedStepGroup?.id, price: updatedValue })
          }
        }
      }
      setEditRowsModel(model)
    },
    [calculationResultData, mutate]
  )

  React.useEffect(() => {
    if (calculationResultData?.content.length) {
      setTotalResults(calculationResultData.totalElements)
    }
    setOnNewPage(true)
  }, [calculationResultData?.content.length, calculationResultData?.totalElements])

  React.useEffect(() => {
    if (lastCalculation && !selectedCalculation) {
      setSelectedCalculation(lastCalculation)
    }
  }, [lastCalculation, selectedCalculation])

  React.useEffect(() => {
    if (resultRows.length) {
      dispatch(
        uiActions.showNotification({
          children: translations.general.valuesEditable,
          severity: "info",
        })
      )
    }
    setOnNewPage(true)
    setPrevPage(prevPageMemo)
  }, [dispatch, resultRows.length, prevPageMemo])

  React.useEffect(() => {
    if (isSuccess && !calculationsData?.length) {
      dispatch(
        uiActions.showNotification({
          children: translations.alert.calculator.priceHistory.info,
          severity: "info",
        })
      )
    }
  }, [calculationsData?.length, calculatorId, dispatch, history, isSuccess])

  // initial fetching of step group types, if needed
  React.useEffect(() => {
    if (!calculatorState.stepGroupTypes?.length) {
      dispatch(fetchStepGroupTypesActionCreator(calculatorState.id))
    }
  }, [dispatch, calculatorState.stepGroupTypes?.length, calculatorState.id])

  const publishHandler = () => {
    if (selectedCalculationId && publishDate) {
      const auth = getAuthCookie()

      let productList = [] as { productId: number; customerGroupId: number }[]

      if (publishDialog === "publish-selected") {
        productList = rowSelectionModel.map(item => {
          const [, productId, customerGroupId] = (item as string).split("-")

          return {
            productId: Number(productId),
            customerGroupId: Number(customerGroupId),
          }
        })
      }

      const publishData: IPublicationData = {
        resultId: selectedCalculationId,
        productIdList: productList,
        publicationDate: formatDate(publishDate),
        userId: auth.data.userId,
        forcePublication: null,
        calculatorId: null,
      }

      if (publishDialog === "force-publication") {
        publishData.forcePublication = true
      }

      publish(publishData)

      setPublishDialog(null)

      setRowSelectionModel([])
    }
  }

  const onFilterChange = (filterModel: GridFilterModel) => {
    if (filterModel.items.length) {
      const [item] = filterModel.items

      if (item) {
        setFilterValue(item)
      }
    }
    setPrevPageMemo(1)
    setPrevPage(1)
    setPage(0)
    setOnNewPage(true)
  }

  const seeCalculationErrorsHandler = () =>
    dispatch(
      uiActions.showDialog({
        primaryActionHandler: () => {
          dispatch(uiActions.hideDialog())
        },
        primaryActionLabel: "Close",
        children: (
          <TreeView
            title="Calculation Errors"
            object={mapCalculationResultIntoTree(calculatorState?.calculationErrors)}
          />
        ),
      })
    )

  const handleExportList = async () => {
    dispatch(uiActions.showSpinner())

    const response = await exportAllCalculationResult(Number(selectedCalculationId))

    const blob = new Blob([response], { type: "text/csv;charset=utf-8" })

    const fileName = `Price History - ${calculatorState.name}.csv`

    FileSaver.saveAs(blob, fileName)

    dispatch(uiActions.hideSpinner())
  }

  const tableHeaderOptions = [
    {
      label: "Publish all",
      action: () => setPublishDialog("publish-all"),
      disabled: ui.isLoading || !userHasReadWriteRole,
    },
    {
      label: "Publish selected",
      action: () => setPublishDialog("publish-selected"),
      disabled: !rowSelectionModel.length || ui.isLoading || !userHasReadWriteRole,
    },
    {
      label: "Force publication",
      action: () => setPublishDialog("force-publication"),
      disabled: ui.isLoading || !userHasReadWriteRole,
    },
    {
      label: "Export all",
      action: handleExportList,
      disabled: ui.isLoading || !userHasReadWriteRole,
    },
  ]

  return (
    <>
      {Boolean(publishDialog) && (
        <PublishResultsDialog
          isOpen={Boolean(publishDialog)}
          onClose={() => setPublishDialog(null)}
          publishDate={publishDate}
          onPublishDateChange={(date: Date) => setPublishDate(date)}
          onPublish={publishHandler}
        />
      )}
      <Header
        title="Price History"
        subtitle={calculatorState.name}
        actions={
          <CalculatorResultButtonHeader>
            <ButtonsGroup options={tableHeaderOptions} disabled={ui.isLoading} />
            <Spacer size={2} />
            <Select
              options={calculationOptions || []}
              handleChange={handleCalculationDateChange}
              value={selectedCalculation?.id.toString() ?? ""}
              label="List of Runs"
            />
          </CalculatorResultButtonHeader>
        }
      />
      <Box position="relative">
        <CurrentSwitchWrapper>
          <CurrentSwitch
            isChecked={switchKey === "error"}
            onSwitchChange={() => setSwitchKey(prevState => (prevState === "error" ? null : "error"))}
            label="Retrieve prices with error"
          />
          <CurrentSwitch
            isChecked={switchKey === "atypical"}
            onSwitchChange={() => setSwitchKey(prevState => (prevState === "atypical" ? null : "atypical"))}
            label="Show atypical prices"
          />
        </CurrentSwitchWrapper>
        <Table
          rows={resultRows}
          columns={columns}
          loading={isLoading}
          components={{
            LoadingOverlay: TableLoadingOverlay,
            Toolbar: () => (
              <Box display="flex">
                <HardTableToolbar showExportButton showColumnsButton />
                {calculatorState.calculationErrors?.length > 0 && lastCalculation === selectedCalculation && (
                  <CalculationErrorButton>
                    <Button
                      color="primary"
                      variant="text"
                      size="small"
                      icon={<ErrorRounded />}
                      onClick={seeCalculationErrorsHandler}
                    >
                      Show calculation errors
                    </Button>
                  </CalculationErrorButton>
                )}
              </Box>
            ),
          }}
          pagination
          hideFooterPagination={!resultRows.length}
          autoPageSize={false}
          pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={rowSelectionModel}
          onEditRowsModelChange={handleEditRowsModelChange}
          editRowsModel={editRowsModel}
          paginationMode="server"
          onPageChange={(newPage: number) => {
            setPrevPage(page)
            setPrevPageMemo(page)
            setPage(newPage)
            setOnNewPage(true)
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(newModel: GridSortModel) => setSortModel(newModel)}
          rowCount={totalResults}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          filterModel={{ items: [filterValue], linkOperator: GridLinkOperator.And }}
        />
      </Box>
    </>
  )
}

const CalculatorResultList = () => {
  const { calculatorId } = useParams<{ calculatorId: string }>()

  const calculatorState = useSelector((state: RootState) => state.calculator)

  const dispatch = useDispatch()

  // Fetch calculator details ffff
  React.useEffect(() => {
    dispatch(
      getCalculatorByIdActionCreator({
        calculatorId: parseInt(calculatorId, 10),
        trigger: getCalculatorIdTrigger.RESULT_LIST,
      })
    )

    return () => {
      dispatch(calculatorActions.calculatorUpdated(calculatorInitialValues))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (calculatorState.isLoading) {
    return null
  }

  return <CalculatorResultListContent />
}

export { CalculatorResultList }

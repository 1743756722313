import { GridColDef, GridCellParams } from "@mui/x-data-grid"
import React from "react"
import { centerColumn, leftNonEditableColumn } from "modules/TableGlobalStyles"
import { CompetitorsListAction } from "../containers/CompetitorsListAction"

export const competitorsColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Competitors",
    type: "string",
    flex: 0.6,
    ...leftNonEditableColumn,
  },
  {
    field: "quantity",
    headerName: "QTY",
    type: "number",
    flex: 0.6,
    ...leftNonEditableColumn,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 120,
    ...centerColumn,
    renderCell: ({ id, row }: GridCellParams): JSX.Element => {
      return <CompetitorsListAction id={id as number} name={row.name} />
    },
  },
]

import type { GridRenderEditCellParams } from "@mui/x-data-grid"
import * as React from "react"
import numeral from "numeral"

import { IMaskAccept, MaskedField } from "components"
import { useStyles } from "./styles"

const PercentInput = ({
  id,
  field,
  value: initialValue,
  api,
}: Pick<GridRenderEditCellParams, "id" | "field" | "value" | "api">) => {
  const [value, setValue] = React.useState(() => {
    const numeric = numeral(initialValue || 0).multiply(100)

    return (numeric.value() || 0).toString()
  })

  const handleAccept: IMaskAccept = (newValue, inputMask) => {
    setValue(newValue)

    const numeric = numeral(inputMask.unmaskedValue || 0)

    const percent = numeric.divide(100)

    api.setEditCellValue({
      id,
      field,
      value: percent.value(),
    })
  }

  const classes = useStyles()

  return (
    <MaskedField
      field={{
        classes: { root: classes.formControl },
        id: id.toString(),
        name: "percent",
        type: "text",
        value,
        InputProps: {
          inputProps: {
            maxLength: 6,
          },
          classes: {
            root: classes.root,
            input: classes.input,
          },
        },
        inputMode: "numeric",
        autoFocus: true,
      }}
      mask={{
        mask: Number,
        scale: 0,
        signed: false,
        padFractionalZeros: false,
        normalizeZeros: true,
        min: 0,
        max: 100,
      }}
      handleAccept={handleAccept}
    />
  )
}

const renderPercentEditInputCell = ({ id, field, value, api }: GridRenderEditCellParams): JSX.Element => {
  return <PercentInput id={id} field={field} value={value} api={api} />
}

export { renderPercentEditInputCell }

import React from "react"

const DebugIcon = (): JSX.Element => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9134 7.77474V0.441406H12.4967V3.19141H6.99675V0.441406H0.580078V7.77474H6.99675V5.02474H8.83008V14.1914H12.4967V16.9414H18.9134V9.60807H12.4967V12.3581H10.6634V5.02474H12.4967V7.77474H18.9134ZM5.16341 5.94141H2.41341V2.27474H5.16341V5.94141ZM14.3301 11.4414H17.0801V15.1081H14.3301V11.4414ZM14.3301 2.27474H17.0801V5.94141H14.3301V2.27474Z"
      fill="#A0CCDD"
    />
  </svg>
)

export { DebugIcon }

import { configureStore } from "@reduxjs/toolkit"
import { ruleCreatorReducer } from "./ruleCreator-slice"
import { calculatorReducer } from "./calculator-slice"
import { uiReducer } from "./ui-slice"
import { userReducer } from "./user-slice"
import { bulkReducer } from "./bulk-slice"

export const store = configureStore({
  reducer: {
    calculator: calculatorReducer,
    ruleCreator: ruleCreatorReducer,
    ui: uiReducer,
    user: userReducer,
    bulk: bulkReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

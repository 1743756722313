import React, { useState } from "react"
import { IconButton } from "components"
import { deleteCompetitor } from "services"
import { uiActions } from "store/ui-slice"
import { DeleteOutlined } from "@material-ui/icons"
import { useMutation, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { setAlertContent } from "utils/general"
import { BULK_STATUS } from "constants/bulk"
import { useHasReadWriteRole } from "utils/user"
import { DeleteCompetitorDialog } from "./DeleteCompetitorDialog"

interface ICompetitorsListAction {
  id: number
  name: string
}

export const CompetitorsListAction = ({ id, name }: ICompetitorsListAction): JSX.Element => {
  const userHasReadWriteRole = useHasReadWriteRole()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const [competitorToDelete, setCompetitorToDelete] = useState<number | undefined>(undefined)

  const { mutate } = useMutation(() => deleteCompetitor(id), {
    onSuccess: () => {
      setCompetitorToDelete(undefined)
      queryClient.invalidateQueries("getCompetitors")
      queryClient.invalidateQueries("getCompetitorsProducts")
      const { children, severity } = setAlertContent(BULK_STATUS.DELETED)
      dispatch(
        uiActions.showNotification({
          children,
          severity,
        })
      )
    },
  })

  return (
    <>
      <IconButton
        color="inherit"
        size="small"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault()
          setCompetitorToDelete(id)
        }}
        icon={<DeleteOutlined />}
        isDisabled={!userHasReadWriteRole}
      />
      <DeleteCompetitorDialog
        onClose={() => setCompetitorToDelete(undefined)}
        isOpen={Boolean(competitorToDelete)}
        confirmHandler={() => mutate()}
        competitorName={name}
      />
    </>
  )
}

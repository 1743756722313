import { GridFilterItem } from "@mui/x-data-grid"

import type { IStep } from "typings/modules"
import type {
  IAPICalculatorRequest,
  ICalculateEventRequest,
  IAPICalculatorResponse,
  IAPIRuleResponse,
  IAPICalculatorResult,
  ICalculation,
  IStepGroupResultRequest,
  ICalculatorResultResponse,
  IPublicationResponse,
  IDebugResult,
  IStepExportResponse,
  IStepExport,
  ICalculatorsAudit,
  IPublicationPriceParams,
  IUpdateStepRequestPayload,
  IMakeDebugRequestPayload,
  IApiCalculationResultRequestBody,
} from "modules/Rules-Pricing/models/calculator"
import { IPublicationData } from "modules/Rules-Pricing/models/calculator"
import { api } from "./api"

export const getCalculatorById = async (calculatorId: number): Promise<IAPICalculatorResponse> => {
  const { data } = await api.calculators.getById(calculatorId)

  return data
}

export const getCalculationsById = async (calculatorId: string): Promise<ICalculation[]> => {
  const { data } = await api.calculators.getCalculationsById(calculatorId)

  return data
}

export const getCalculationResult = async (
  params: IApiCalculationResultRequestBody
): Promise<ICalculatorResultResponse> => {
  const { page, filterValue, size, sortModel, calculatorId, calculationId, switchKey } = params
  const filters: GridFilterItem = { ...filterValue }
  if (filters.columnField === "customerGroups") {
    filters.columnField = "customerGroup"
  }
  const { data } = await api.calculators.getCalculationResult(
    {
      page: page.toString(),
      size: size.toString(),
      sortModel,
      filterValue: filters,
    },
    calculatorId,
    calculationId,
    switchKey
  )

  return data
}

export const getAllCalculationResult = async (calculationId: number) => {
  const { data } = await api.calculators.getAllCalculationsResult(calculationId)

  return data
}

export const exportAllCalculationResult = async (calculationId: number) => {
  const { data } = await api.calculators.exportAllCalculationResult(calculationId)

  return data
}

export const updateCalculationResult = async (
  overriddenResult: IStepGroupResultRequest
): Promise<IAPICalculatorResult[]> => {
  const { data } = await api.calculators.updateCalculationResult(overriddenResult)

  return data
}

export const putCalculator = async (
  calculator: Partial<IAPICalculatorRequest>,
  calculatorId?: number
): Promise<IAPICalculatorResponse> => {
  const {
    calculators: { put, post },
  } = api
  const request = calculatorId ? put(calculator, calculatorId) : post(calculator)
  const { data } = await request
  return data
}

export const duplicateCalculator = async (
  calculatorId: number,
  calculatorName: string
): Promise<IAPICalculatorResponse> => {
  const { data } = await api.calculators.duplicate(calculatorId, calculatorName)
  return data
}

export const deleteCalculator = async (calculatorId: number): Promise<any> => {
  const { data } = await api.calculators.delete(calculatorId.toString())
  return data
}

export const runCalculation = async (calculateEventRequest: ICalculateEventRequest) => {
  const { data } = await api.calculators.calculate(calculateEventRequest)

  return data
}

export const publishCalculator = async (publicationData: IPublicationData): Promise<IPublicationResponse[]> => {
  const { data } = await api.calculators.publish(publicationData)

  return data
}

export const getPublishedPrices = async (params: IPublicationPriceParams) => {
  const { data } = await api.calculators.getPublishedPrices(params)

  return data
}

export const publishCalculatorList = async (publicationData: IPublicationData[]): Promise<IPublicationResponse[]> => {
  const { data } = await api.calculators.publishList(publicationData)

  return data
}

export const getRules = async (calculatorId?: number): Promise<IAPIRuleResponse[]> => {
  const { data } = await api.rules.get(calculatorId)

  return data
}

export const getCalculatorRulesByStepId = async (stepId: number): Promise<IAPIRuleResponse[]> => {
  const { data } = await api.rules.getAllByStepId(stepId)

  return data
}

export const getSteps = async (calculatorId?: number): Promise<IStep[]> => {
  const { data } = await api.steps.getOne(calculatorId)

  return data
}

export const getStepsByStepGroupId = async (stepGroupId: number) => {
  const { data } = await api.steps.getAllByStepGroupId(stepGroupId)

  return data
}

export const updateStep = async (params: IUpdateStepRequestPayload) => {
  const { data } = await api.steps.update(params)

  return data
}

export const getStepById = async (stepId: number) => {
  const { data } = await api.steps.getAllById(stepId)

  return data
}

export const getStepGroupByImport = async (stepGroupId: number, stepId: number) => {
  const { data } = await api.steps.getByImport(stepGroupId, stepId)

  return data
}

export const makeDebug = async (values: IMakeDebugRequestPayload): Promise<IDebugResult> => {
  const { data } = await api.calculators.debug(values)

  return data
}

export const exportStep = async (calculatorId: string, stepId: string): Promise<IStepExport[]> => {
  const { data }: { data: IStepExportResponse[] } = await api.calculators.exportStep(calculatorId, stepId)

  return data.map((stepResult: IStepExportResponse) => ({
    ...stepResult.product,
    result: stepResult.result,
  }))
}

export const exportStepCsv = async (calculatorId: string, stepId: string) => {
  const { data: blobs } = await api.calculators.exportStepCsv(calculatorId, stepId)

  return blobs
}

export const auditCalculators = async (): Promise<Array<ICalculatorsAudit>[]> => {
  const { data } = await api.calculators.audit()

  return data.reverse()
}

import FileSaver from "file-saver"
import { useDispatch } from "react-redux"

import type { ITableColumn } from "models/tableColumn"
import { formatDate } from "utils/general"
import { getAuditCsvContent } from "utils/csvHelper"
import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"

interface IUseAuditLog {
  getAuditLogService: () => Promise<any>
  tableHeaders: ITableColumn[]
  fileName: string
}
const useAuditLog = ({
  getAuditLogService,
  tableHeaders,
  fileName,
}: IUseAuditLog): { getAuditLog: () => Promise<void> } => {
  const dispatch = useDispatch()

  const getAuditLog = async () => {
    dispatch(uiActions.showSpinner())
    try {
      const auditLog = await getAuditLogService()

      const csv = auditLog?.length > 0 ? getAuditCsvContent(tableHeaders, auditLog) : ""

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })

      const date = formatDate(new Date())

      const fileDateName = `${date} ${fileName}`

      FileSaver.saveAs(blob, `${fileDateName}.csv`)
    } catch (e) {
      dispatch(
        uiActions.showNotification({
          children: translations.general.somethingWrong,
          severity: "error",
        })
      )
    }
    dispatch(uiActions.hideSpinner())
  }

  return { getAuditLog }
}

export { useAuditLog }

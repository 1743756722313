import React from "react"
import {
  Icon,
  IMenuTab,
  ConfigValuesIcon,
  CustomersIcon,
  FunctionIcon,
  LogOutIcon,
  ProductIcon,
  SuppliersIcon,
} from "components"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined"
import EventIcon from "@material-ui/icons/Event"
import { removeAuthCookie } from "utils/user"
import { ROUTES } from "./routes"

const menuTabs: IMenuTab[] = [
  {
    id: 1,
    label: "Rules & Pricing",
    path: ROUTES.RULES_PRICING.path,
    icon: <Icon icon={<FunctionIcon />} />,
  },
  {
    id: 2,
    label: "Products",
    path: ROUTES.PRODUCTS.path,
    icon: <Icon icon={<ProductIcon />} />,
  },
  {
    id: 3,
    label: "Events",
    path: ROUTES.EVENTS.path,
    icon: <Icon icon={<EventIcon fontSize="small" />} />,
  },
  {
    id: 4,
    label: "Competitors",
    path: ROUTES.COMPETITORS.path,
    icon: <Icon icon={<ConfigValuesIcon />} />,
  },
  {
    id: 5,
    label: "Parameters",
    path: ROUTES.PARAMETERS.path,
    icon: <Icon icon={<FileCopyOutlinedIcon fontSize="small" />} />,
  },
  {
    id: 6,
    label: "Supplier",
    path: ROUTES.SUPPLIERS.path,
    icon: <Icon icon={<SuppliersIcon />} />,
  },

  {
    id: 7,
    label: "Customers",
    path: ROUTES.CUSTOMERS.path,
    icon: <Icon icon={<CustomersIcon />} />,
  },
  {
    id: 8,
    label: "Settings",
    path: ROUTES.SETTINGS.path,
    icon: <Icon icon={<SettingsOutlinedIcon fontSize="small" />} />,
  },
  {
    id: 9,
    path: "/log-out",
    label: "Log Out",
    icon: <Icon icon={<LogOutIcon />} />,
    onClick: () => removeAuthCookie(),
  },
]

export { menuTabs }

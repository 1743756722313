import * as React from "react"

import { StepGroupRulesContext } from "../context/StepGroupRulesContext"

const useStepGroupRules = () => {
  const context = React.useContext(StepGroupRulesContext)

  if (!context) {
    throw new Error("useStepGroupRules must be used within a StepGroupRulesProvider")
  }

  return context
}

export default useStepGroupRules

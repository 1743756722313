import { makeStyles } from "@material-ui/core"

export const autocompleteStyles = makeStyles(() => ({
  autocomplete: {
    "& [class*='MuiOutlinedInput-root']": {
      "& input[class*='MuiInputBase-input'][class*='MuiAutocomplete-input']": {
        padding: 0,
        height: "auto",
      },
    },
  },
}))

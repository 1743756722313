import React, { useState } from "react"
import { Box } from "@material-ui/core"
import { IMenu, Spacer, IconButton, ChevronIcon } from "components"
import { sidebarStyles } from "../styles"

export const Sidebar = ({ children }: IMenu): JSX.Element => {
  const classes = sidebarStyles()
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(false)

  const collapseHandler = () => {
    setIsSideBarCollapsed(prevState => !prevState)
  }

  return (
    <Box className={`${classes.root} ${isSideBarCollapsed && "collapsed"}`}>
      <Box
        className={`${classes.navTabs} ${isSideBarCollapsed ? classes.collapse : ""}`}
        onClick={() => setIsSideBarCollapsed(false)}
      >
        <Spacer size={5} />
        {children}
      </Box>
      <IconButton
        className={`${classes.iconCollapse} ${isSideBarCollapsed ? classes.iconOpacity : ""}`}
        color="inherit"
        icon={<ChevronIcon />}
        onClick={collapseHandler}
      />
    </Box>
  )
}

import * as React from "react"
import { Box, Typography } from "@material-ui/core"
import { DashboardContentHeader, IconButtonContainer, DashboardMainContent } from "modules/Rules-Pricing/styles"
import { IconButton, Spacer, Select, Dialog } from "components"
import { getStepGroupName } from "utils/calculators"
import type { IStepGroupResponse, IStep } from "typings/modules"
import { Add } from "@material-ui/icons"
import { StepComponent } from "modules/Rules-Pricing/components/StepComponent"
import { useStepGroup, useUpdateStepGroup } from "services/hooks/useStepGroup"
import { useStepsImport, StepImport, useSaveImportStep } from "services/hooks/useStepsImport"
import { ShowFetchError } from "modules/Rules-Pricing/constants/utils"
import Loader from "components/Loader"

interface IProps {
  selectedGroup: IStepGroupResponse
  calculatorId: number
  isMainEdition?: boolean
}

export type StepState = IStep & { new?: boolean }

export type UpdateStepOnStepGroupsParam = {
  stepToUpdate: StepState
  isDeleting?: boolean
  isEmpty?: boolean
}

const NEW_STEP = (order: number) => ({
  name: "",
  cycleReference: false,
  order,
  new: true,
})

const MainContent = (props: IProps) => {
  const { selectedGroup, calculatorId, isMainEdition } = props

  const { data: stepGroup, isLoading, isError, error } = useStepGroup({ stepGroupId: selectedGroup.id })

  const { data: stepsImport } = useStepsImport({
    calculatorId,
    stepGroupId: selectedGroup.id,
    hasSteps: !!stepGroup?.steps,
    options: { enabled: !isLoading },
  })

  const { updateStepGroup, isLoading: isStepGroupUpdating } = useUpdateStepGroup()

  const { saveImportStep, isLoading: isSaveImportLoading } = useSaveImportStep()

  const [showDialog, setShowDialog] = React.useState(false)

  const [selectedImportStep, setSelectedImportStep] = React.useState<StepImport | undefined>(undefined)

  const [stepList, setStepList] = React.useState<StepState[]>([])

  React.useEffect(() => {
    if (stepGroup?.steps?.length) {
      setStepList(stepGroup.steps)
    }

    if (!stepGroup?.steps && !isLoading) {
      setStepList([NEW_STEP(1)] as StepState[])
    }
  }, [stepGroup, isLoading])

  if (isError) {
    ShowFetchError(error)
  }

  const getLastOrder = () => Math.max(...stepList.map(o => o.order), 0)

  const addStep = () => {
    const newStep = NEW_STEP(getLastOrder() + 1)

    setStepList(prevState => [...prevState, newStep] as IStep[])
  }

  const updateStepOnStepGroups = React.useCallback(
    ({ stepToUpdate, isDeleting, isEmpty }: UpdateStepOnStepGroupsParam) => {
      const parseStepList = stepList.reduce((acc, curr: StepState) => {
        if (isDeleting && curr.order === stepToUpdate.order) {
          return acc
        }

        if (curr.order === stepToUpdate.order) {
          delete curr.new

          return [...acc, stepToUpdate]
        }

        return [...acc, curr]
      }, [])

      if (isEmpty) {
        setStepList(parseStepList)

        return
      }

      updateStepGroup({ ...stepGroup, steps: parseStepList })
    },
    [stepList, updateStepGroup, stepGroup]
  )

  const orderedSteps = stepList?.sort((a, b) => b.order - a.order)

  const hasAnyCycleReferenceOnSteps = stepList?.some(step => step.cycleReference)

  const stepsImportOptions = stepsImport?.map(({ id, name }) => ({
    value: `${id}`,
    label: name,
  }))

  const hasImportSteps = stepsImportOptions?.length > 0

  const handleStepsImportSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDialog(true)

    const stepImportToAdd = stepsImport.find(({ id }) => id === Number(event.target.value))

    setSelectedImportStep(stepImportToAdd)
  }

  const saveImport = async () => {
    saveImportStep({ stepGroupId: selectedGroup.id, stepId: selectedImportStep.id })

    setShowDialog(false)
  }

  const calculatorStepComponents = React.useMemo(() => {
    return orderedSteps?.map((step: StepState) => (
      <StepComponent
        step={step}
        stepGroup={selectedGroup}
        hasAnyCycleReferenceOnSteps={hasAnyCycleReferenceOnSteps}
        key={step.id || step.order}
        expanded={step.new}
        updateStepOnStepGroups={updateStepOnStepGroups}
        isMainEdition={isMainEdition}
      />
    ))
  }, [orderedSteps, hasAnyCycleReferenceOnSteps, selectedGroup, updateStepOnStepGroups, isMainEdition])

  return (
    <>
      {(isLoading || isStepGroupUpdating || isSaveImportLoading) && <Loader />}
      <Dialog
        open={showDialog}
        title={`Import Rule Group ${selectedImportStep?.name}`}
        onClose={() => setShowDialog(false)}
        primaryActionHandler={saveImport}
        secondaryActionLabel="Cancel"
        primaryActionLabel="Import"
      >
        <Spacer size={3} />
        <Typography variant="h4">
          Are you sure you want to import <strong>{selectedImportStep?.name}</strong>?
        </Typography>
        <Spacer size={3} />
      </Dialog>
      <DashboardMainContent>
        <DashboardContentHeader>
          <Box>
            <Typography variant="h5">Rule Groups</Typography>
            <Spacer size={1} />
            {!isMainEdition && <Typography variant="h3">{getStepGroupName(selectedGroup?.name)}</Typography>}
          </Box>
          {!isMainEdition && selectedGroup.main ? null : (
            <Box
              display="flex"
              alignItems={hasImportSteps ? "center" : "flex-end"}
              maxWidth={280}
              width={hasImportSteps ? "100%" : "auto"}
            >
              {hasImportSteps && (
                <Select
                  options={stepsImportOptions || []}
                  label="Import Rule Group"
                  placeholder="Choose a rule group"
                  handleChange={e => handleStepsImportSelect(e)}
                  value=""
                />
              )}
              <IconButtonContainer marginLeft={2} marginTop={1}>
                <IconButton
                  size="small"
                  icon={<Add />}
                  onClick={addStep}
                  isDisabled={stepList.some(step => step.new)}
                />
              </IconButtonContainer>
            </Box>
          )}
        </DashboardContentHeader>
        {!isLoading && calculatorStepComponents}
      </DashboardMainContent>
    </>
  )
}

export default MainContent

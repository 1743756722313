import { Typography } from "@material-ui/core"
import React from "react"
import { Switch } from "components"

interface ICurrentSwitch {
  isChecked: boolean
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
}

const CurrentSwitch = (props: ICurrentSwitch): JSX.Element => {
  const { isChecked, onSwitchChange, label } = props

  return (
    <>
      <Typography variant="body1">{label}</Typography>
      <Switch color="primary" checked={isChecked} onChange={onSwitchChange} />
    </>
  )
}

CurrentSwitch.defaultProps = {
  label: "",
}

export { CurrentSwitch }

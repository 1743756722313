import { Typography } from "@material-ui/core"
import React from "react"
import { StepGroupContainer, IDContainer } from "./styles"

interface ICalculator {
  id: string
  name: string
  color: string
  variant?: "caption" | "body1"
}

const StepGroupAvatar = ({ id, name, color, variant }: ICalculator): JSX.Element => {
  return (
    <StepGroupContainer data-testid="Calculator">
      <IDContainer color={color}>{id}</IDContainer>
      <Typography variant={variant}>{name}</Typography>
    </StepGroupContainer>
  )
}

export { StepGroupAvatar }

StepGroupAvatar.defaultProps = {
  variant: "caption",
}

import { createTheme } from "@material-ui/core/styles"
import { palette, spacing } from "../theme"

export const styles = createTheme({
  palette,
  overrides: {
    MuiIconButton: {
      root: {
        marginLeft: spacing(3),
        color: palette.primary.light,
        "& button": {
          margin: 0,
        },
      },
    },
  },
})

import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette } from "../theme"

export const SwitchTheme = createTheme({
  palette,
  overrides: {
    MuiSwitch: {
      root: {},
      track: {
        "&&&": {
          backgroundColor: palette.grey[400],
        },
      },
      switchBase: {
        color: palette.grey[600],
        top: rem("1.3px"),
      },
      thumb: {
        boxShadow: "none",
        width: rem("17px"),
        height: rem("17px"),
      },
    },
  },
})

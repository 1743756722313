import * as React from "react"
import { Box, Typography } from "@material-ui/core"
import { useHistory } from "react-router"
import { DeleteOutlined, EditOutlined } from "@material-ui/icons"

import type { IStepGroupResponse } from "typings/modules"
import { IconButton, Dialog } from "components"
import { ROUTES } from "constants/routes"
import { useStepGroupList } from "./StepGroupListContext"

interface IStepGroupListActions {
  stepGroup: IStepGroupResponse
}
const StepGroupListActions = (props: IStepGroupListActions) => {
  const { stepGroup } = props

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  const history = useHistory()

  const { isStepGroupDeleting, handleDelete: handleDeleteStepGroup } = useStepGroupList()

  const handleEdit = () => {
    history.push(`${ROUTES.STEP_GROUPS.path}/${stepGroup.id}`)
  }

  return (
    <>
      <Dialog
        open={showDeleteDialog}
        title="Delete rule group"
        onClose={() => setShowDeleteDialog(false)}
        primaryActionHandler={() => handleDeleteStepGroup(stepGroup.id)}
        secondaryActionLabel="Cancel"
        primaryActionLabel="Delete"
      >
        <Typography variant="h4">Are you sure you want to delete the rule set {stepGroup.name}?</Typography>
      </Dialog>
      <Box>
        <IconButton
          size="small"
          color="inherit"
          icon={<EditOutlined />}
          onClick={handleEdit}
          disabled={isStepGroupDeleting}
        />
        <IconButton
          size="small"
          color="inherit"
          icon={<DeleteOutlined />}
          onClick={() => setShowDeleteDialog(true)}
          disabled={isStepGroupDeleting}
        />
      </Box>
    </>
  )
}

export { StepGroupListActions }

import React from "react"
import { Box, Typography } from "@material-ui/core"
import { useStyles } from "./styles"

export interface ITableHeader {
  title: string
}

export const TableHeader = ({ title }: ITableHeader): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.tableHeaderContainer} data-testid="tableHeader">
      <Typography variant="h4" className={classes.tableHeaderTitle}>
        {title}
      </Typography>
    </Box>
  )
}

import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, theme } from "../theme"

export const IconButtonTheme = createTheme({
  palette,
  overrides: {
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
        width: rem("40px"),
        height: rem("40px"),
        boxShadow: "none !important",
        borderRadius: rem("4px"),
        "& svg": {
          fill: palette.primary.contrastText,
        },
        "&.Mui-disabled": {
          backgroundColor: "#fff",
          opacity: 0.5,
        },
      },

      colorSecondary: {
        backgroundColor: palette.secondary.main,
        "&:hover": {
          backgroundColor: palette.secondary.dark,
        },
        "&:active": {
          backgroundColor: palette.secondary.light,
        },
      },
      colorPrimary: {
        backgroundColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.dark,
        },
        "&:active": {
          backgroundColor: palette.primary.light,
        },
      },
      colorInherit: {
        backgroundColor: "inherit",
        "& svg": {
          fill: palette.grey[400],
        },
      },
      sizeSmall: {
        width: rem("30px"),
        height: rem("30px"),
      },
    },
  },
})

import { rem } from "polished"
import { createTheme } from "@material-ui/core/styles"
import { palette, spacing, typography } from "../theme"

export const tabsTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: palette.background.default,
        boxShadow: "unset",
        flexDirection: "row",
        borderBottom: `1px solid ${palette.grey[500]}`,
      },
    },
    MuiTabs: {
      root: {
        "& span": {
          flexDirection: "row",
        },
        "& svg": {
          marginRight: spacing(1),
        },
      },
      indicator: {
        height: rem("4px"),
      },
      flexContainer: {
        justifyContent: "center",
      },
    },
    MuiButtonBase: {
      root: {
        paddingBottom: spacing(1),
      },
    },

    MuiTab: {
      root: {
        minWidth: rem("158px"),
        padding: 0,
        "& svg": {
          fill: palette.grey[500],
        },

        "&:hover": {
          backgroundColor: palette.grey[200],
        },
      },
      textColorInherit: {
        textTransform: "capitalize",
        color: palette.grey[600],
        fontWeight: typography.body1.fontWeight,
        fontSize: typography.body1.fontSize,
        "&$selected": {
          color: palette.text.primary,
          fontWeight: typography.body2.fontWeight,
          "& svg": {
            fill: palette.primary.main,
          },
        },
        "& < .MuiTabs-indicator": {
          bottom: "0 !important",
        },
      },
      labelIcon: {
        minHeight: rem("48px"),
        paddingTop: 0,
      },
    },
  },
})

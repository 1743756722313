import React from "react"
import { IconButton, Box, ThemeProvider } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import Close from "@material-ui/icons/Close"
import BlockIcon from "@material-ui/icons/Block"
import { styles } from "./styles"

type Props = {
  onCheckClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onCloseClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  disabled?: boolean
}

const CheckAndClearButtons = (props: Props) => {
  const { onCheckClick, onCloseClick, disabled } = props

  const handleCheckClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    onCheckClick?.(event)
  }

  return (
    <ThemeProvider theme={styles}>
      <Box display="flex" alignItems="center">
        <Box>
          <IconButton onClick={handleCheckClick} disabled={disabled}>
            {disabled ? <BlockIcon fontSize="small" /> : <CheckIcon fontSize="small" />}
          </IconButton>
        </Box>
        <Box>
          <IconButton aria-label="settings" onClick={onCloseClick}>
            <Close />
          </IconButton>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default CheckAndClearButtons

import { GridSortItem } from "@mui/x-data-grid"
import {
  ICreateSupplierRequest,
  ICreateSupplierResponse,
  ISupplier,
  ISupplierResponse,
  ISuppliersAudit,
  IUpdateSupplierRequest,
  IUpdateSupplierResponse,
} from "modules/Suppliers/models/supplier"
import { api } from "./api"

export const getSuppliers = async (
  page: number,
  size: number,
  sortModel?: GridSortItem[]
): Promise<ISupplierResponse> => {
  const { data } = await api.suppliers.get({
    page: page.toString(),
    size: size.toString(),
    sortModel,
  })
  return data
}

export const getAllSuppliers = async (): Promise<ISupplier[]> => {
  const { data } = await api.suppliers.getAll()
  return data
}

export const createSupplier = async (payload: ICreateSupplierRequest): Promise<ICreateSupplierResponse> => {
  const { data } = await api.suppliers.create(payload)

  return data
}

export const updateSupplier = async (id: number, payload: IUpdateSupplierRequest): Promise<IUpdateSupplierResponse> => {
  const { data } = await api.suppliers.update(id, payload)

  return data
}

export const deleteSupplier = async (id: number): Promise<unknown> => {
  const { data } = await api.suppliers.delete(id)

  return data
}

export const auditSuppliers = async (): Promise<Array<ISuppliersAudit>[]> => {
  const { data } = await api.suppliers.audit()
  return data.reverse()
}

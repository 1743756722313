import React from "react"
import { Box, Typography } from "@material-ui/core"
import { useStyles } from "./styles"

export interface ISubtitleIcon {
  icon: React.ReactNode
  title: string
}

export const SubtitleIcon = ({ icon, title }: ISubtitleIcon): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>{icon}</Box>
      <Typography component="p" className={classes.titleContainer}>
        {title}
      </Typography>
    </Box>
  )
}

import { makeStyles } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import type {} from "@material-ui/lab/themeAugmentation"
import { rem } from "polished"
import { palette, spacing, typography } from "../../theme"

export const ButtonCardTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiToggleButton: {
      root: {
        minWidth: "100%",
        maxHeight: rem("56px"),
        backgroundColor: palette.background.default,
        border: "1px solid",
        borderColor: palette.grey[300],
        borderRadius: spacing(1),
        fontWeight: typography.fontWeightMedium as number,
        fontSize: typography.h3.fontSize,
        justifyContent: "left",
        textTransform: "capitalize",
        paddingLeft: spacing(3),
        paddingRight: spacing(2),
        color: palette.grey[900],
        "& svg": {
          position: "absolute",
          right: spacing(2),
          fill: palette.grey[500],
        },
        "&$selected": {
          backgroundColor: palette.primary.light,
          borderColor: palette.primary.main,
          color: palette.text.primary,
        },
      },
      sizeSmall: {
        minWidth: rem("276px"),
        minHeight: rem("42px"),
        paddingLeft: spacing(3),
        paddingRight: spacing(2),
        fontWeight: typography.fontWeightMedium as number,
        fontSize: typography.h3.fontSize,
      },
      sizeLarge: {
        minHeight: "75px",
        minWidth: "151px",
        justifyContent: "center",
        fontSize: rem("15px"),
        fontWeight: typography.fontWeightRegular as number,
      },
    },
  },
})

export const buttonIcon = makeStyles(() => ({
  buttonIcon: {
    color: palette.primary.main,
    minWidth: rem("75px"),
    minHeight: rem("75px"),
    border: `1px dashed ${palette.grey[300]}`,
  },
}))

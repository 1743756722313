import * as React from "react"
import { Step, StepIconProps, StepLabel, Stepper } from "@material-ui/core"
import classNames from "classnames"
import { ProviderWizardIcon, ProductWizardIcon } from "components"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { IWizardStep } from "modules/Products/constants/wizard"
import { CustomIcon } from "../styles"

export interface IWizardStepper {
  activeStep: number
  steps: IWizardStep[]
}

const icons: { [index: string]: React.ReactElement } = {
  1: <ProviderWizardIcon />,
  2: <ProductWizardIcon />,
  3: <EditOutlinedIcon />,
}

const StepIcon = (props: StepIconProps): JSX.Element => {
  const { active, completed, icon } = props

  return completed ? (
    <CheckCircleIcon />
  ) : (
    <CustomIcon className={classNames({ active })}>{icons[String(icon)]}</CustomIcon>
  )
}

const WizardStepper = ({ activeStep, steps }: IWizardStepper): JSX.Element => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map(step => (
        <Step key={step.id}>
          <StepLabel StepIconComponent={StepIcon}>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export { WizardStepper }

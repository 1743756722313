import * as React from "react"
import _ from "lodash"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

import type { CalculatorRulesLocationState, IRuleState, IStep } from "typings/modules"
import { ISelectOption } from "components"
import { updateStep } from "services/calculator"
import { formatStepRules, mapEmptyRule } from "modules/Rules-Pricing/constants/calculator-rules"
import { useLocationState, useQueryCache } from "hooks"
import { optimisticUpdates } from "utils"
import { mapSystemObjectsIntoOptions } from "utils/calculators"
import { formatCalculatorStepRules, getNewRuleState } from "../constants/utils"
import { ISystemObject } from "../models/systemObject"
import { IAPIRuleResponse, IFormatStepRulesParams, IStepGroupType } from "../models/calculator"

type UpsertRule = Pick<IFormatStepRulesParams, "id" | "mode">

const formatRuleStates = (
  step: IStep,
  systemObjectOptions: ISelectOption[],
  mode: CalculatorRulesLocationState["mode"]
) => {
  const rules = formatStepRules(step, systemObjectOptions)

  if (mode === "new" || rules.length === 0) {
    const newRule = getNewRuleState(rules)

    rules.unshift(newRule)
  }

  return rules
}

const mapRuleOptions = (rules: IAPIRuleResponse[], steps: IStep[], systemObjects: ISystemObject[]) => {
  const mappedSystemOptions = mapSystemObjectsIntoOptions(systemObjects, rules, steps)

  return mappedSystemOptions
}

const useManageCalculatorRules = () => {
  const locationState = useLocationState<CalculatorRulesLocationState>()

  const history = useHistory<CalculatorRulesLocationState>()

  const { calculator, stepGroup, rule: currentRule, step } = locationState

  const rulesRef = React.useRef<_.Dictionary<IRuleState>>({})

  const stepQueryKey = `calculator-${calculator.id}-step-${step.id}`

  const systemObjectsQueryKey = `calculator-${calculator.id}-system-objects-${calculator.tier}`

  const stepGroupTypesQueryKey = `calculator-${calculator.id}-step-groups-types`

  const stepsQueryKey = `calculator-${calculator.id}-steps`

  const cachedStepQuery = useQueryCache<IStep>(stepQueryKey)

  const cachedSystemObjectsQuery = useQueryCache<ISystemObject[]>(systemObjectsQueryKey)

  const cachedStepGroupTypesQuery = useQueryCache<IStepGroupType[]>(stepGroupTypesQueryKey)

  const cachedStepsQuery = useQueryCache<IStep[]>(stepsQueryKey)

  const systemObjectOptions = React.useMemo(() => {
    const options = mapRuleOptions(
      cachedStepQuery.state.data.rules || [],
      cachedStepsQuery.state.data,
      cachedSystemObjectsQuery.state.data
    )

    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedStepQuery.state.data, cachedStepsQuery.state.data, cachedSystemObjectsQuery.state.data])

  const [ruleStates, setRuleStates] = React.useState(() => {
    const rules = formatRuleStates(cachedStepQuery.state.data, systemObjectOptions, locationState.mode)

    return rules
  })

  const queryClient = useQueryClient()

  const prepareRules = async (params: UpsertRule) => {
    const ruleStateValues = Object.values(rulesRef.current)

    const rules = await formatCalculatorStepRules({
      ...params,
      calculatorId: calculator.id,
      stepGroupId: stepGroup.id,
      step: cachedStepQuery.state.data,
      rules: ruleStateValues,
      systemObjects: systemObjectOptions,
    })

    return rules
  }

  const mutationKey = `calculator-${calculator.id}-step-group-${stepGroup.id}-step-${step.id}-rule-${currentRule?.id}`

  const mutation = useMutation(
    async (params: UpsertRule) => {
      const rules = await prepareRules(params)

      const newStep: IStep = {
        ...cachedStepQuery.state.data,
        rules,
      }

      return updateStep({
        calculatorId: calculator.id,
        stepGroupId: stepGroup.id,
        step: newStep,
      })
    },
    {
      mutationKey,
      onMutate: async params => {
        const { mode } = params

        if (locationState.mode === "edit") {
          history.replace(history.location.pathname, {
            ...locationState,
            rule: null,
            mode: null,
          })
        }

        if (mode === "update" || mode === "delete") {
          const rules = await prepareRules(params)

          const rollback = optimisticUpdates<IStep>(queryClient, stepQueryKey, previousResponse => {
            const newStep = {
              ...previousResponse,
              rules,
            }

            const newRuleStates = formatRuleStates(newStep, systemObjectOptions, locationState.mode)

            setRuleStates(newRuleStates)

            return newStep
          })

          return rollback
        }

        return null
      },
      onSuccess: (data, variables) => {
        const rules = formatRuleStates(data, systemObjectOptions, locationState.mode)

        cachedStepQuery.setData({
          ...data,
          rules: data.rules || [],
        })

        if (variables.mode === "create") {
          setRuleStates(rules)
        }
      },
      onError: (error, data, rollback) => {
        if (typeof rollback === "function") {
          const oldData = rollback()

          queryClient.setQueryData(stepQueryKey, oldData)

          const revertedRules = formatRuleStates(oldData, systemObjectOptions, locationState.mode)

          setRuleStates(revertedRules)
        }
      },
    }
  )

  const handleAddRule = () => {
    const newRule = mapEmptyRule(ruleStates)

    setRuleStates([newRule, ...ruleStates])

    history.replace(history.location.pathname, {
      ...locationState,
      mode: "new",
    })
  }

  const handleSubmit = (params: UpsertRule) => {
    mutation.mutate(params)
  }

  React.useEffect(() => {
    rulesRef.current = _.keyBy(ruleStates, "internalId")
  }, [ruleStates])

  return {
    status: mutation.status,
    message: mutation.error,
    rulesRef,
    ruleStates,
    handleSubmit,
    handleAddRule,
    step: cachedStepQuery.state.data,
    steps: cachedStepsQuery.state.data,
    systemObjects: cachedSystemObjectsQuery.state.data,
    stepGroupTypes: cachedStepGroupTypesQuery.state.data,
    systemObjectOptions,
    isLoading: mutation.status === "loading",
  }
}

export default useManageCalculatorRules

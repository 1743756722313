import React from "react"
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEditRowsModel,
  GridFeatureMode,
  GridFilterModel,
  GridRowData,
  GridRowId,
  GridRowIdGetter,
  GridRowParams,
  GridSelectionModel,
  GridSlotsComponent,
  GridSortModel,
} from "@mui/x-data-grid"

import { Container } from "@material-ui/core"
import { ENV } from "constants/env"
import { tableStyles } from "./styles"

export interface ITableProps {
  columns: GridColDef[]
  rows: GridRowData[]
  pageSize?: number
  autoPageSize?: boolean
  checkboxSelection?: boolean
  loading?: boolean
  onSelectionModelChange?: (model: GridSelectionModel, details?: unknown) => void
  className?: string
  rowsPerPageOptions?: number[]
  components?: GridSlotsComponent
  selectionModel?: GridRowId[]
  disableSelectionOnClick?: boolean
  getRowId?: GridRowIdGetter
  hideFooter?: boolean
  paginationMode?: GridFeatureMode
  pagination?: true | undefined
  rowCount?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  isCellEditable?: (params: GridCellParams) => boolean
  sortingMode?: GridFeatureMode
  sortModel?: GridSortModel
  onSortModelChange?: (model: GridSortModel) => void
  hideFooterPagination?: boolean
  hideFooterSelectedRowCount?: boolean
  onEditRowsModelChange?: (editRowsModel: GridEditRowsModel, details?: any) => void
  editRowsModel?: GridEditRowsModel
  autoHeight?: boolean | undefined
  disableColumnMenu?: boolean
  getRowClassName?: (params: GridRowParams) => string
  filterMode?: GridFeatureMode
  onFilterModelChange?: (model: GridFilterModel, details?: any) => void
  filterModel?: GridFilterModel
}

const Table = ({
  columns,
  rows,
  pageSize,
  autoPageSize = true,
  checkboxSelection,
  loading,
  className,
  onSelectionModelChange,
  rowsPerPageOptions = [ENV.PAGINATION.DEFAULT_PAGE_SIZE],
  components,
  selectionModel,
  disableSelectionOnClick,
  getRowId,
  hideFooter,
  paginationMode,
  pagination,
  rowCount,
  onPageChange,
  onPageSizeChange,
  isCellEditable,
  sortingMode,
  sortModel,
  onSortModelChange,
  hideFooterPagination,
  hideFooterSelectedRowCount,
  onEditRowsModelChange,
  editRowsModel,
  autoHeight,
  disableColumnMenu,
  getRowClassName,
  filterMode,
  filterModel,
  onFilterModelChange,
}: ITableProps): JSX.Element => {
  const classes = tableStyles()

  return (
    <Container className={`${className} ${classes.container}`} data-testid="table-container">
      <DataGrid
        className={classes.root}
        columns={columns}
        rows={rows}
        autoHeight={autoHeight}
        autoPageSize={autoPageSize}
        checkboxSelection={checkboxSelection}
        pageSize={pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        rowHeight={42}
        headerHeight={46}
        loading={loading}
        onSelectionModelChange={onSelectionModelChange}
        pagination={pagination}
        components={components}
        selectionModel={selectionModel}
        disableSelectionOnClick={disableSelectionOnClick}
        getRowId={getRowId}
        hideFooter={hideFooter}
        paginationMode={paginationMode}
        rowCount={rowCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        isCellEditable={isCellEditable}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        hideFooterPagination={hideFooterPagination}
        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        onEditRowsModelChange={onEditRowsModelChange}
        editRowsModel={editRowsModel}
        disableColumnMenu={disableColumnMenu}
        getRowClassName={getRowClassName}
        filterMode={filterMode}
        onFilterModelChange={onFilterModelChange}
        filterModel={filterModel}
      />
    </Container>
  )
}

export { Table }

import React from "react"
import { MainContainer, Table, Dialog } from "components"
import { GridToolbar } from "@mui/x-data-grid"
import { IAddCustomerGroupModalProps } from "../models/newProductWizard"

const AddCustomerGroupModal = ({
  isOpen,
  handleAccept,
  handleClose,
  onItemSelectionChange,
  columns,
  rows,
  selectionModel,
  isLoading,
  getRowId,
}: IAddCustomerGroupModalProps): JSX.Element => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      title="Select Customer Groups"
      primaryActionLabel="Confirm"
      primaryActionHandler={handleAccept || handleClose}
      secondaryActionHandler={handleClose}
      maxWidth="xl"
      fullWidth
    >
      <MainContainer
        style={{
          minWidth: "100%",
          minHeight: "100%",
        }}
      >
        <Table
          columns={columns}
          rows={rows}
          checkboxSelection
          onSelectionModelChange={onItemSelectionChange}
          selectionModel={selectionModel}
          loading={isLoading}
          getRowId={getRowId}
          components={{ Toolbar: GridToolbar }}
          pagination
          autoHeight
        />
      </MainContainer>
    </Dialog>
  )
}
export { AddCustomerGroupModal }

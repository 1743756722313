import { GridColDef, GridRowModel } from "@mui/x-data-grid"
import { translations } from "utils/translations"
import { IAlertProps } from "components/Alert/Alert"
import { ICompetitorsPrice, ICompetitorsProduct } from "modules/Competitors/models/competitor"
import {
  leftNonEditableColumn,
  leftNonEditableTooltipColumn,
  leftEditableColumn,
  priceFormatterParser,
} from "modules/TableGlobalStyles"
import { BULK_STATUS } from "../constants"

export const getDynamicColumns = (response?: ICompetitorsProduct[]): GridColDef[] => {
  const columnsFields: string[] = []
  const columns: GridColDef[] = []
  columns.push(
    {
      field: "SKU",
      headerName: "SKU",
      type: "string",
      width: 120,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "ecomname",
      headerName: "eCom Name",
      type: "string",
      width: 220,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "averagePrice",
      headerName: "Average",
      type: "number",
      width: 120,
      ...leftNonEditableColumn,
      ...priceFormatterParser,
    }
  )

  if (response) {
    response?.forEach((resObj: ICompetitorsProduct | any) => {
      resObj.competitors.forEach((competitorsObj: ICompetitorsPrice) => {
        if (columnsFields.indexOf(competitorsObj.name) === -1) {
          columnsFields.push(competitorsObj.name)
        }
      })
    })
  }

  columnsFields.forEach((cf: string) => {
    columns.push({
      field: cf,
      headerName: cf,
      type: "number",
      width: 130,
      ...leftEditableColumn,
      ...priceFormatterParser,
    })
  })
  return columns
}

export const getDynamicRows = (response?: ICompetitorsProduct[]): GridRowModel[] => {
  const rows: GridRowModel[] = []
  if (response) {
    response?.forEach((resObj: ICompetitorsProduct) => {
      let competitorsRow: any = {}
      resObj.competitors?.forEach((competitorsObj: ICompetitorsPrice) => {
        competitorsRow = {
          ...competitorsRow,
          [competitorsObj.name]: competitorsObj.price,
        }
      })

      rows.push({
        SKU: resObj.vsId,
        id: resObj.id,
        ecomname: resObj.name,
        averagePrice: resObj.averagePrice,
        ...competitorsRow,
      })
    })
  }
  return rows
}

export const setAlertContent = (
  status: BULK_STATUS
): {
  children: string
  severity: IAlertProps["severity"]
} => {
  switch (status) {
    case BULK_STATUS.PROCESSING:
      return {
        children: translations.alert.bulk.processing,
        severity: "info",
      }
    case BULK_STATUS.PROCESSED:
      return {
        children: translations.alert.bulk.success,
        severity: "success",
      }
    case BULK_STATUS.FAILED:
      return {
        children: translations.alert.bulk.error,
        severity: "error",
      }
    case BULK_STATUS.DELETED:
      return {
        children: translations.alert.bulk.deleted,
        severity: "info",
      }
    default:
      return {
        children: translations.alert.bulk.processing,
        severity: "info",
      }
  }
}

import * as React from "react"
import { useMutation, useQuery } from "react-query"
import { useDispatch } from "react-redux"

import type { IStepGroupResponse } from "typings/modules"
import { getStepGroups } from "services"
import { deleteStepGroup } from "services/stepGroups"
import { uiActions } from "store/ui-slice"

type StepGroupListContextValues = {
  refetch: () => void
  data: IStepGroupResponse[]
  isStepGroupsLoading: boolean
  isStepGroupDeleting: boolean
  handleDelete: (stepGroupId: number) => void
}

const StepGroupListContext = React.createContext((undefined as unknown) as StepGroupListContextValues)

const StepGroupListProvider = (props: React.PropsWithChildren<unknown>) => {
  const { children } = props

  const { isLoading: isStepGroupsLoading, data, refetch } = useQuery("getStepGroups", getStepGroups)

  const dispatch = useDispatch()

  const { isLoading: isStepGroupDeleting, mutate } = useMutation(
    "deleteStepGroup",
    (stepGroupId: number) => deleteStepGroup(stepGroupId),
    {
      onSuccess: () => {
        refetch()

        dispatch(
          uiActions.showNotification({
            children: "The item was deleted",
            severity: "success",
          })
        )
      },
      onError: (error: InstanceType<typeof Error>) => {
        dispatch(
          uiActions.showNotification({
            children: error.message || JSON.stringify(error),
            severity: "error",
          })
        )
      },
    }
  )

  const values = {
    refetch,
    data: data || ([] as IStepGroupResponse[]),
    isStepGroupsLoading,
    isStepGroupDeleting,
    handleDelete: mutate,
  }

  return <StepGroupListContext.Provider value={values}>{children}</StepGroupListContext.Provider>
}

const useStepGroupList = () => {
  const context = React.useContext(StepGroupListContext)

  if (context === undefined) {
    throw new Error("useStepGroupList must be used within a StepGroupListProvider")
  }

  return context
}

export { StepGroupListProvider, useStepGroupList }

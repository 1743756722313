import * as React from "react"
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid"
import { useSelector } from "react-redux"

import { RootState } from "store"
import { getColumns } from "modules/Rules-Pricing/constants/calculatorResultColumns"
import type { IStepGroupType } from "modules/Rules-Pricing/models/calculator"
import { useQueryClient } from "react-query"

type TableToolbarProps = {
  showColumnsButton?: boolean
  showExportButton?: boolean
  showFilterButton?: boolean
}

const TableToolbar = (props: TableToolbarProps & { csvFields?: string[] }) => {
  const { showColumnsButton, showExportButton, showFilterButton, csvFields } = props

  return (
    <GridToolbarContainer>
      {showColumnsButton && <GridToolbarColumnsButton />}
      {showExportButton && <GridToolbarExport csvOptions={{ fields: csvFields }} />}
      {showFilterButton && <GridToolbarFilterButton />}
    </GridToolbarContainer>
  )
}

TableToolbar.defaultProps = {
  showColumnsButton: false,
  showExportButton: false,
  showFilterButton: true,
}

const CacheTableToolbar = (props: TableToolbarProps) => {
  const { showColumnsButton, showExportButton, showFilterButton } = props

  const queryClient = useQueryClient()

  const queryKey = ["StepGroupTypes", "masterType:false"]

  const stepGroupTypes = queryClient.getQueryData(queryKey)

  const allowedColumns = getColumns((stepGroupTypes as IStepGroupType[]) || [])?.filter(column => !column.disableExport)

  const csvFields = allowedColumns?.map(column => column.field)

  return (
    <GridToolbarContainer>
      {showColumnsButton && <GridToolbarColumnsButton />}
      {showExportButton && <GridToolbarExport csvOptions={{ fields: csvFields }} />}
      {showFilterButton && <GridToolbarFilterButton />}
    </GridToolbarContainer>
  )
}

CacheTableToolbar.defaultProps = {
  showColumnsButton: false,
  showExportButton: false,
  showFilterButton: true,
}

const HardTableToolbar = (props: TableToolbarProps) => {
  const { showColumnsButton, showExportButton, showFilterButton } = props

  const calculatorState = useSelector((state: RootState) => state.calculator)

  const { stepGroupTypes } = calculatorState

  const allowedColumns = getColumns((stepGroupTypes as IStepGroupType[]) || [])?.filter(column => !column.disableExport)

  const csvFields = allowedColumns?.map(column => column.field)

  return (
    <GridToolbarContainer>
      {showColumnsButton && <GridToolbarColumnsButton />}
      {showExportButton && <GridToolbarExport csvOptions={{ fields: csvFields }} />}
      {showFilterButton && <GridToolbarFilterButton />}
    </GridToolbarContainer>
  )
}

HardTableToolbar.defaultProps = {
  showColumnsButton: false,
  showExportButton: false,
  showFilterButton: true,
}

export { TableToolbar, CacheTableToolbar, HardTableToolbar }

import React from "react"
import { TreeView as MuiTreeView } from "@material-ui/lab"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { Box, Typography } from "@material-ui/core"
import { TreeViewIcon } from "components"
import { Container, TreeViewStyles } from "./styles"

interface IList {
  nodeId: string
  label: string
  sublistArray?: IList[]
}

interface ITreeView {
  object: IList[]
  title?: string
}

const TreeView = ({ object, title }: ITreeView): JSX.Element => {
  const classes = TreeViewStyles()

  return (
    <Container>
      <Box className={classes.title}>
        <TreeViewIcon />
        <Typography variant="h5">{title}</Typography>
      </Box>
      <MuiTreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        className={classes.root}
      >
        {object.map(obj => {
          return (
            <TreeItem nodeId={obj.nodeId} label={obj.label} key={obj.nodeId}>
              {obj.sublistArray &&
                obj.sublistArray.map(subobj => {
                  return (
                    <TreeItem nodeId={subobj.nodeId} label={subobj.label} key={subobj.nodeId}>
                      {subobj.sublistArray &&
                        subobj.sublistArray.map(subobj2 => {
                          return (
                            <TreeItem nodeId={subobj2.nodeId} label={subobj2.label} key={subobj2.nodeId}>
                              {subobj2.sublistArray &&
                                subobj2.sublistArray.map(subobj3 => {
                                  return <TreeItem nodeId={subobj3.nodeId} label={subobj3.label} key={subobj3.nodeId} />
                                })}
                            </TreeItem>
                          )
                        })}
                    </TreeItem>
                  )
                })}
            </TreeItem>
          )
        })}
      </MuiTreeView>
    </Container>
  )
}

TreeView.defaultProps = {
  title: "",
}

export { TreeView }

import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import { List as MuiList } from "@material-ui/core"
import { ListTheme } from "./styles"
import { IListProps } from "./props"

const List = ({ children }: IListProps): JSX.Element => {
  return (
    <ThemeProvider theme={ListTheme}>
      <MuiList data-testid="list">{children}</MuiList>
    </ThemeProvider>
  )
}

export { List }

import { GridLinkOperator, GridSelectionModel } from "@mui/x-data-grid"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Box, Link } from "@material-ui/core"
import { CloudDownload } from "@material-ui/icons"
import FileSaver from "file-saver"

import type { RootState } from "store"
import { auditCalculators, runCalculation, publishCalculatorList } from "services/calculator"
import { TooltipWrapperButton } from "components/Table/styles"
import { getAuditCsvContent } from "utils/csvHelper"
import { uiActions } from "store/ui-slice"
import { Table, Button, TableLoadingOverlay, FunctionIcon } from "components"
import { temporalInitialCalculatorsResponse, useCalculators } from "services/hooks/useCalculators"
import { CacheTableToolbar } from "components/Table/TableToolbar"
import { joinCustomerGroups } from "utils/calculators"
import { AUDIT_TABLE_HEADERS } from "constants/tables"
import { formatDate } from "utils/general"
import { ROUTES } from "constants/routes"
import { ENV } from "constants/env"
import { useHasReadWriteRole, getAuthCookie } from "utils/user"
import { CALCULATOR_LIST_COLUMNS } from "../constants/rulesPricing"
import { IPublicationData } from "../models/calculator"

const CalculatorList = React.forwardRef(
  (props, ref): JSX.Element => {
    const userHasReadWriteRole = useHasReadWriteRole()

    const dispatch = useDispatch()

    const history = useHistory()

    const isSpinner = useSelector((state: RootState) => state.ui.isLoading)

    const { query, filters, handleFilterChange, handlePage, handleSort } = useCalculators()

    const { isLoading } = query

    const { sortModel } = filters

    const selectedRowsRef = React.useRef<GridSelectionModel>(null)

    const runMutation = useMutation(
      ["RunCalculators", ...sortModel],
      () =>
        runCalculation({
          calculators: selectedRowsRef.current as number[],
        }),
      {
        onSettled: () => {
          history.push(ROUTES.CALCULATION_EVENTS.path, sortModel)
        },
        retry: 1,
      }
    )

    const runMutationPublish = useMutation(
      ["PublishCalculators", ...sortModel],
      (publishList: IPublicationData[]) => publishCalculatorList(publishList),
      {
        onSuccess: () => {
          history.push(`${ROUTES.PUBLICATION_EVENTS.path}`)
          dispatch(
            uiActions.showNotification({
              children: (
                <>
                  Calculators have been published.{" "}
                  <Link color="primary" href="/products?showPrices=true">
                    Go to Published Prices
                  </Link>
                </>
              ),
              severity: "success",
            })
          )
        },
        onError: (error: string) => {
          const arrayError = error.toString().split(":")
          const arrayAllErrors = arrayError[1].split(",")
          dispatch(
            uiActions.showDialog({
              title: "Publication errors",
              primaryActionHandler: () => {
                dispatch(uiActions.hideDialog())
              },
              primaryActionLabel: "Close",
              children: (
                <div style={{ lineHeight: "normal" }}>
                  <ul>
                    {arrayAllErrors.map(calculatorError => (
                      <li>{calculatorError}</li>
                    ))}
                  </ul>
                </div>
              ),
            })
          )
        },
      }
    )

    const isProcessing = isLoading || runMutation.isLoading

    const response = React.useMemo(() => {
      if (!query.data) {
        return temporalInitialCalculatorsResponse
      }

      const calculators = query.data.content.map(calculator => {
        const value = {
          ...calculator,
          customerGroups: joinCustomerGroups(calculator.customerGroups),
        }

        return value
      })

      const result = { ...query.data, content: calculators }

      return result
    }, [query.data])

    const handleAuditLog = async () => {
      dispatch(uiActions.showSpinner())

      const auditLog = await auditCalculators()

      const csv = auditLog?.length > 0 ? getAuditCsvContent(AUDIT_TABLE_HEADERS, auditLog) : ""

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })

      const date = formatDate(new Date())

      const fileName = `${date} Calculators Audit Log`

      dispatch(uiActions.hideSpinner())

      FileSaver.saveAs(blob, `${fileName}.csv`)
    }

    const handleRunCalculators = () => {
      const selected = selectedRowsRef.current

      if (Array.isArray(selected) && selected.length > 0) {
        runMutation.mutate()

        return
      }

      dispatch(
        uiActions.showNotification({
          children: "No selected calculators",
          severity: "error",
        })
      )
    }

    const handleSelectRow = (selected: GridSelectionModel) => {
      selectedRowsRef.current = selected
    }

    const handlePublishCalculators = () => {
      const selected = selectedRowsRef.current
      let publishList = [] as IPublicationData[]
      const auth = getAuthCookie()

      if (Array.isArray(selected) && selected.length > 0) {
        publishList = selected.map(item => {
          const publishData: IPublicationData = {
            resultId: 0,
            productIdList: [],
            publicationDate: formatDate(new Date()),
            userId: auth.data.userId,
            forcePublication: false,
            calculatorId: Number(item),
          }

          return publishData
        })

        if (publishList.length > 0) {
          runMutationPublish.mutate(publishList)

          return
        }
      }

      dispatch(
        uiActions.showNotification({
          children: "No selected calculators",
          severity: "error",
        })
      )
    }

    React.useImperativeHandle(ref, () => ({
      handlePublishCalculators() {
        handlePublishCalculators()
      },
    }))

    return (
      <Table
        rows={response.content}
        columns={CALCULATOR_LIST_COLUMNS}
        loading={isProcessing}
        pagination
        components={{
          Toolbar: () => (
            <Box display="flex">
              <CacheTableToolbar showExportButton showColumnsButton />
              <TooltipWrapperButton>
                <Button
                  variant="text"
                  size="small"
                  onClick={handleAuditLog}
                  icon={<CloudDownload />}
                  isDisabled={isProcessing || isSpinner}
                >
                  Download calculators audit log
                </Button>
              </TooltipWrapperButton>
              <TooltipWrapperButton>
                <Button
                  variant="text"
                  size="small"
                  onClick={handleRunCalculators}
                  icon={<FunctionIcon />}
                  isDisabled={isProcessing || !userHasReadWriteRole}
                >
                  {runMutation.isLoading ? "Running" : "Run"}
                </Button>
              </TooltipWrapperButton>
            </Box>
          ),
          LoadingOverlay: TableLoadingOverlay,
        }}
        hideFooterPagination={!response.content.length}
        paginationMode="server"
        onPageChange={handlePage}
        autoPageSize={false}
        pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSort}
        rowCount={response.totalElements}
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        filterModel={{ items: [filters.searching], linkOperator: GridLinkOperator.And }}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={handleSelectRow}
      />
    )
  }
)

export { CalculatorList }

import { v4 as uuidV4 } from "uuid"
import _ from "lodash"

import type {
  IRule,
  IRuleArgument,
  IRuleAttributeArgument,
  IRuleOperationInput,
  IRuleState,
  IStep,
  IStepGroup,
  IStepGroupRequest,
} from "typings/modules"
import {
  IAPICalculatorResult,
  ICalculatorRow,
  IFormatStepRulesParams,
  IOperationInputRequest,
  IResultRows,
  OperationTypeToLabelMap,
} from "modules/Rules-Pricing/models/calculator"
import { ruleCreatorInitialValues } from "store/state"
import { CalculatorConditionValue, OperationTypes } from "constants/calculator"
import { ISelectOption } from "components"
import { getUniqueID, removeEmptyString } from "utils/general"
import { IStepGroupResult } from "modules/Customers/models/customerGroup"
import { useDispatch } from "react-redux"
import { uiActions } from "store/ui-slice"
import { updateStep } from "services/calculator"
import { BLOCKED_CONDITIONAL_ARGUMENTS, CONDITIONAL_OPERATORS_LIST } from "./calculator"

const getInputTypeByArgument = (argument?: IRuleArgument) => {
  if (argument?.value === OperationTypes.INPUT) {
    if (_.isNaN(Number(argument?.attribute))) {
      return OperationTypes.STRING
    }

    return OperationTypes.NUMBER
  }

  return argument?.type || OperationTypes.NUMBER
}

const getRuleInputs = (rule: IRuleState): IRuleOperationInput[] => {
  const rules = [
    {
      order: 1,
      id: rule.firstArgument.id as number,
      value: rule.firstArgument.attribute || "",
      inputType: getInputTypeByArgument(rule?.firstArgument),
    },
    {
      order: 2,
      id: (rule.secondArgument?.id || undefined) as number, // @todo check this
      value: rule.secondArgument?.attribute || "",
      inputType: getInputTypeByArgument(rule?.secondArgument),
    },
  ]

  return rules
}

const getComparatorRuleInputs = (rule: IRuleState): IRuleOperationInput[] => {
  const inputs = [
    {
      order: 1,
      id: rule.firstArgument.id as number,
      value: rule.firstArgument?.attribute || "",
      inputType: getInputTypeByArgument(rule?.firstArgument),
    },
  ]

  if (!BLOCKED_CONDITIONAL_ARGUMENTS.includes(rule.operator as typeof BLOCKED_CONDITIONAL_ARGUMENTS[number])) {
    inputs.push({
      order: 2,
      id: (rule.secondArgument?.id || undefined) as number, // @todo check this
      value: rule.secondArgument?.attribute || "",
      inputType: getInputTypeByArgument(rule?.secondArgument),
    })
  }

  return inputs
}

const getFirstConditionValue = (rule: IRule) => {
  if (CONDITIONAL_OPERATORS_LIST.includes(rule.operation.name as typeof CONDITIONAL_OPERATORS_LIST[number])) {
    return CalculatorConditionValue.SELECTIVE
  }

  return CalculatorConditionValue.EMPTY
}

const getSystemObjectValue = (inputValue: string, systemObjects: ISelectOption[]) => {
  const foundItem = systemObjects.find((systemObjectOption: ISelectOption) => {
    const children = systemObjectOption.children || []

    const item = children.find(attribute => attribute.value === inputValue)

    return item
  })

  return foundItem?.value || ""
}

const getStateArgument = (input: IOperationInputRequest, systemObjects: ISelectOption[]): IRuleArgument => {
  const value =
    OperationTypeToLabelMap[input.inputType as keyof typeof OperationTypeToLabelMap] ||
    getSystemObjectValue(input.value, systemObjects)

  const arg = {
    id: input.id,
    type: input.inputType,
    name: "" as keyof IRuleAttributeArgument,
    value,
    attribute: input.value.toString(),
  }

  return arg
}

interface IFormatStepGroupRuleParams {
  rule: IRule
  systemObjects: ISelectOption[]
  /**
   * @todo: remove this. It is only for keeping the old functionality
   */
  uuid: boolean
}

const getConditionalRuleInputs = (rule: IRuleState, comparatorRuleId: number): IRuleOperationInput[] => [
  {
    order: 1,
    id: rule.conditionalInputId as number,
    value: String(comparatorRuleId),
    inputType: OperationTypes.RULE,
  },
  {
    order: 2,
    id: rule.thirdArgument?.id as number,
    value: rule.thirdArgument?.attribute || "",
    inputType: getInputTypeByArgument(rule.thirdArgument),
  },
  {
    order: 3,
    id: rule.fourthArgument?.id as number,
    value: rule.fourthArgument?.attribute || "",
    inputType: getInputTypeByArgument(rule.fourthArgument),
  },
]

const formatStepGroupRule = (params: IFormatStepGroupRuleParams) => {
  const { rule, systemObjects, uuid } = params

  const ruleName = rule.name

  const [firstInput, secondInput, thirdInput] = rule.operation.inputs

  let ruleState = {} as IRuleState

  const commonRuleStateValues = {
    internalId: uuid ? uuidV4() : getUniqueID(),
  }

  const firstConditionValue = getFirstConditionValue(rule)

  const firstArgument = getStateArgument(firstInput, systemObjects)

  const secondArgument = getStateArgument(secondInput, systemObjects)

  const isConditionalOperatorValid = CONDITIONAL_OPERATORS_LIST.includes(
    rule.operation.name as typeof CONDITIONAL_OPERATORS_LIST[number]
  )

  if (isConditionalOperatorValid) {
    ruleState = {
      ...commonRuleStateValues,
      comparatorId: rule.id,
      comparatorOperationId: rule.operation.id,
      comparatorOrder: rule.order,
      conditionValue: firstConditionValue,
      firstArgument,
      secondArgument,
      ruleName: "",
      operator: rule.operation.name,
      order: rule.order,
      created: rule.created,
    } as IRuleState
  }

  if (rule.operation.name === "CONDITIONAL") {
    ruleState = {
      ...ruleState,
      id: rule.id,
      ruleName,
      operationId: rule.operation.id,
      thirdArgument: secondInput ? secondArgument : ({} as IRuleArgument),
      fourthArgument: thirdInput ? getStateArgument(thirdInput, systemObjects) : ({} as IRuleArgument),
      conditionalInputId: firstInput.id,
      order: rule.order,
      created: rule.created,
    }
  }

  if (!isConditionalOperatorValid && rule.operation.name !== "CONDITIONAL") {
    ruleState = {
      id: rule.id,
      internalId: uuid ? uuidV4() : getUniqueID(),
      order: rule.order,
      ruleName,
      conditionValue: firstConditionValue,
      firstArgument,
      secondArgument,
      operator: rule.operation.name,
      comparatorOrder: 0,
      operationId: rule.operation.id,
      created: rule.created,
    } as IRuleState
  }

  return ruleState
}

export interface IGetFormattedStepGroupRuleParams extends Pick<IFormatStepGroupRuleParams, "uuid" | "systemObjects"> {
  rules: IRule[]
}

const getFormattedStepGroupRules = (params: IGetFormattedStepGroupRuleParams) => {
  const { rules, systemObjects, uuid } = params

  const newRules = rules?.reduce((collection, current) => {
    const formattedRule = formatStepGroupRule({
      rule: current,
      systemObjects,
      uuid,
    })

    if (formattedRule) {
      if (current.operation.name === "CONDITIONAL") {
        const lastRule = collection[collection.length - 1]

        const newRule: IRuleState = {
          ...lastRule,
          ...formattedRule,
        }

        collection[collection.length - 1] = newRule
      } else {
        collection.push(formattedRule)
      }
    }

    return collection
  }, [] as IRuleState[])

  return newRules
}

const formatUpsertStepGroup = (values: IStepGroup) => {
  const formattedSteps = values.steps.reduce((steps, step) => {
    if (removeEmptyString(step.name)) {
      const formattedStep: IStep = {
        id: (step.id ? step.id : undefined) as number,
        cycleReference: step.cycleReference,
        name: step.name,
        description: step.description || "",
        rules: [],
        order: step.order,
      }

      const formattedRules = step.rules.reduce((collection, rule) => {
        const formattedRule: IRule = {
          id: rule.id as number,
          name: rule.name,
          operation: rule.operation,
          order: rule.order,
          created: rule.created,
        }

        if (removeEmptyString(rule.name)) {
          collection.push(formattedRule)
        }

        return collection
      }, [] as IRule[])

      formattedStep.rules = formattedRules

      steps.push(formattedStep)
    }

    return steps
  }, [] as IStep[])

  const newStepGroup: IStepGroupRequest = {
    ...values,
    id: (values.id || undefined) as number,
    steps: formattedSteps,
  }

  return newStepGroup
}

const getNewRuleState = (rules: IRuleState[]) => {
  const orders = rules?.map(rule => rule.order)

  orders?.push(0)

  const nextOrder = orders && Math.max(...orders) + 1

  const newRule = _.cloneDeep(ruleCreatorInitialValues)

  newRule.internalId = uuidV4()

  newRule.order = nextOrder

  newRule.isValid = false

  return newRule
}

const formatRuleStates = (params: IGetFormattedStepGroupRuleParams) => {
  const { rules, systemObjects, uuid } = params

  const clonedRules = _.cloneDeep(rules)

  const spreadRules = getFormattedStepGroupRules({
    rules: clonedRules,
    systemObjects,
    uuid,
  })

  return spreadRules
}

const mapCalculatorRuleState = async (
  params: Pick<IFormatStepRulesParams, "step" | "calculatorId" | "stepGroupId"> & { rule: IRuleState }
) => {
  const { rule, step, calculatorId, stepGroupId } = params

  const mappedRules = [] as IRule[]

  const { conditionValue } = rule

  let comparatorId = rule.comparatorId as number

  if (conditionValue === CalculatorConditionValue.SELECTIVE) {
    const item = {
      id: rule.comparatorId,
      order: rule.comparatorOrder,
      name: `comparator - ${rule.ruleName}`,
      operation: {
        id: rule.comparatorOperationId,
        name: rule.operator,
        inputs: getComparatorRuleInputs(rule),
      },
    } as IRule

    mappedRules.push(item)

    if (!comparatorId) {
      const updatedStep = await updateStep({
        calculatorId,
        stepGroupId,
        step: {
          ...step,
          rules: [...(step.rules || []), ...mappedRules],
        },
      })

      const lastIndex = updatedStep.rules.length - 1

      const lastRule = updatedStep.rules[lastIndex]

      comparatorId = lastRule.id

      mappedRules[mappedRules.length - 1] = lastRule
    }

    mappedRules.push({
      id: rule.id,
      order: rule.order,
      name: rule.ruleName,
      operation: {
        id: rule.operationId,
        name: "CONDITIONAL",
        inputs: getConditionalRuleInputs(rule, comparatorId),
      },
    } as IRule)
  } else {
    mappedRules.push({
      id: rule.id,
      order: rule.order,
      name: rule.ruleName,
      operation: {
        id: rule.operationId,
        name: rule.operator,
        inputs: getRuleInputs(rule),
      },
    } as IRule)
  }

  return mappedRules
}

const formatCalculatorStepRules = async (params: IFormatStepRulesParams) => {
  const { rules: ruleStates, step, id: currentRuleId, mode } = params

  if (mode === "create") {
    const rulesToUpdate = _.size(step.rules) > 0 ? [...step.rules] : []

    const [ruleState] = ruleStates

    const newRules = await mapCalculatorRuleState({
      rule: ruleState,
      step,
      calculatorId: params.calculatorId,
      stepGroupId: params.stepGroupId,
    })

    rulesToUpdate.push(...newRules)

    return rulesToUpdate
  }

  if (mode === "update" && currentRuleId) {
    const ruleIndex = _.findIndex(step.rules, item => item.id === currentRuleId)

    const ruleState = ruleStates.find(item => item.id === currentRuleId)

    const [stepRule, conditionalStepRule] = await mapCalculatorRuleState({
      rule: ruleState,
      step,
      calculatorId: params.calculatorId,
      stepGroupId: params.stepGroupId,
    })

    const rulesToUpdate = [...step.rules]

    rulesToUpdate[ruleIndex] = stepRule

    if (conditionalStepRule) {
      rulesToUpdate[ruleIndex - 1] = stepRule

      rulesToUpdate[ruleIndex] = conditionalStepRule
    }

    return rulesToUpdate
  }

  const filteredRules = _.reduce(
    step.rules,
    (collection, item, index, list) => {
      const nextComparatorRule = list[index + 1]

      const comparatorName = `comparator - ${nextComparatorRule?.name}`

      if (item.name === comparatorName && nextComparatorRule.id === currentRuleId) {
        return collection
      }

      if (item.id !== currentRuleId) {
        collection.push(item)
      }

      return collection
    },
    [] as IRule[]
  )

  return filteredRules
}

const getNewRules = (rules: IRule[], sysObjects: ISelectOption[]) => {
  const newRules = formatRuleStates({
    rules,
    systemObjects: sysObjects,
    uuid: true,
  })

  const newRule = getNewRuleState(newRules)

  newRules?.unshift(newRule)

  return newRules
}

const getStepGroupResult = (
  stepGroupResults: IStepGroupResult[],
  stepGroupName: string,
  keyName: keyof IStepGroupResult
) => {
  const stepGroup =
    _.find(stepGroupResults, stepGroupResult => stepGroupResult.type === stepGroupName) || ({} as IStepGroupResult)

  const value = stepGroup[keyName]

  return value
}

const mapPriceHistoryRows = (calculations: IAPICalculatorResult[]) => {
  const mappedCustomerGroups = calculations.reduce((collection, calculation) => {
    const result = _.map(calculation.customerGroups, item => ({
      id: `${calculation.product.id}-${item.id}`,
      product: calculation.product,
      customerGroup: item,
    }))

    collection.push(...result)

    return collection
  }, [] as ICalculatorRow[])

  const rows: IResultRows[] = mappedCustomerGroups.map((result, index) => {
    const pricingValues: Record<string, string> = result.customerGroup.stepGroupResults.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.type]: curr.price,
      }
    }, {})

    const excelPricingValues: Record<string, string> = result.customerGroup.stepGroupResults.reduce((acc, curr) => {
      const key = `excel${curr.type[0].toUpperCase()}${curr.type.slice(1)}`
      return {
        ...acc,
        [key]: curr.price,
      }
    }, {})

    const row = {
      id: `${index}-${result.id}`,
      productId: result.product.id,
      productIds: result.product.vsId,
      ecomname: result.product.ecomname,
      customerGroups: result.customerGroup.name,
      customerGroupId: result.customerGroup.id,
      vcost: result.product.vcost,
      manufacturer: result.product.manufacturer,
      specieName: result.product.specieName,
      majorCategoryName: result.product.majorCategoryName,
      ...pricingValues,
      ...excelPricingValues,
      atypicalWholesalePrice: getStepGroupResult(
        result.customerGroup.stepGroupResults,
        "wholesalePrice",
        "atypical"
      ) as boolean,
      atypicalRetailPrice: getStepGroupResult(
        result.customerGroup.stepGroupResults,
        "retailPrice",
        "atypical"
      ) as boolean,
      atypicalVetPrice: getStepGroupResult(result.customerGroup.stepGroupResults, "vetPrice", "atypical") as boolean,
      atypicalFee: getStepGroupResult(result.customerGroup.stepGroupResults, "fee", "atypical") as boolean,
      atypicalAqCost: getStepGroupResult(
        result.customerGroup.stepGroupResults,
        "acquisitionCost",
        "atypical"
      ) as boolean,
    }

    return row
  })

  return rows
}

const ShowFetchError = (error: any) => {
  const dispatch = useDispatch()
  dispatch(
    uiActions.showNotification({
      children: error.message || JSON.stringify(error),
      severity: "error",
    })
  )
}

export {
  getComparatorRuleInputs,
  getInputTypeByArgument,
  getRuleInputs,
  formatStepGroupRule,
  getFormattedStepGroupRules,
  formatUpsertStepGroup,
  formatRuleStates,
  getNewRuleState,
  getConditionalRuleInputs,
  getNewRules,
  mapPriceHistoryRows,
  ShowFetchError,
  formatCalculatorStepRules,
}

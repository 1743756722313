import { createSlice } from "@reduxjs/toolkit"
import { userInitialValues } from "./state"

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialValues,
  reducers: {
    setLoginData: prevState => {
      prevState.loggedIn = true
    },
  },
})

export const userActions = userSlice.actions
export const userReducer = userSlice.reducer

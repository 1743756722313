import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { BULK_STATUS } from "constants/bulk"
import { bulkUploadInitialValues } from "./state"

export const bulkSlice = createSlice({
  name: "Competitor bulk",
  initialState: bulkUploadInitialValues,
  reducers: {
    updateStatus: (prevState, action: PayloadAction<BULK_STATUS>) => {
      prevState.status = action.payload
    },
    clearStatus: prevState => {
      prevState.status = "" as BULK_STATUS
    },
  },
})

export const bulkActions = bulkSlice.actions

export const bulkReducer = bulkSlice.reducer

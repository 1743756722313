import * as React from "react"
import { Box } from "@material-ui/core"
import { GridCellParams, GridColDef } from "@mui/x-data-grid"
import { EditOutlined, DeleteOutlined } from "@material-ui/icons"

import { leftNonEditableColumn, leftNonEditableTooltipColumn } from "modules/TableGlobalStyles"
import { IconButton } from "components"
import type { IUseCustomerListState } from "hooks/use-customer-list"
import type { ICustomer } from "../models/customer"

const getColumns = (
  updater: (newState: Partial<IUseCustomerListState>) => void,
  userHasReadWriteRole: boolean
): GridColDef[] => {
  return [
    {
      field: "resellerId",
      headerName: "Site ID",
      flex: 0.3,
      ...leftNonEditableColumn,
    },
    {
      field: "name",
      headerName: "Customer Name",
      flex: 0.7,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 0.4,
      ...leftNonEditableTooltipColumn,
    },
    {
      field: "productsCount",
      headerName: "Products",
      flex: 0.5,
      sortable: false,
      filterable: false,
      ...leftNonEditableColumn,
    },
    {
      field: "customerGroupName",
      headerName: "Customer Group",
      flex: 0.4,
      ...leftNonEditableColumn,
    },
    {
      field: "customerGroupSubgroup",
      headerName: "Tiers",
      flex: 0.4,
      ...leftNonEditableColumn,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.25,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      filterable: false,
      renderCell: ({ row }: GridCellParams): JSX.Element => {
        return (
          <Box>
            <IconButton
              color="inherit"
              size="small"
              icon={<EditOutlined />}
              onClick={() => updater({ modal: "update-customer", data: row as ICustomer })}
              isDisabled={!userHasReadWriteRole}
            />
            <IconButton
              color="inherit"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => updater({ modal: "delete-customer", data: row as ICustomer })}
              isDisabled={!userHasReadWriteRole}
            />
          </Box>
        )
      },
    },
  ]
}

export { getColumns }

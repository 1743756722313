import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const ChipTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: palette.grey[200],
        color: palette.text.primary,
        minWidth: rem("113px"),
        maxHeight: rem("43px"),
        padding: spacing(0, 1),
        fontWeight: typography.fontWeightRegular as number,
        fontSize: typography.body2.fontSize,
        justifyContent: "space-between",
        borderRadius: spacing(3),
        gridGap: spacing(1),
        "& svg": {
          width: rem("10px"),
          fill: palette.grey[700],
          marginRight: 0,
          padding: 0,
        },
        "& span": {
          backgroundColor: "transparent",
          padding: spacing(0),
        },
      },
      sizeSmall: {
        minWidth: "auto",
        height: rem("22px"),
        fontWeight: typography.caption.fontWeight,
        fontSize: rem("12px"),
        padding: spacing(0, 1),
      },
      colorPrimary: {
        color: palette.background.default,
        "& svg": {
          fill: palette.background.default,
        },
        "&:hover": {
          backgroundColor: palette.primary.dark,
        },
      },
      colorSecondary: {
        backgroundColor: palette.success.light,
        color: palette.grey[800],
        "& svg": {
          width: rem("22px"),
          order: 2,
        },
      },
      outlinedPrimary: {
        "& svg": {
          fill: palette.primary.main,
        },
        "&:hover": {
          backgroundColor: palette.primary.light,
        },
      },
      outlinedSecondary: {
        "& svg": {
          fill: palette.secondary.main,
        },
        "&:hover": {
          backgroundColor: palette.secondary.light,
          color: palette.background.default,
          "& svg": {
            fill: palette.background.default,
          },
        },
      },
    },
  },
})

import type { QueryClient, QueryKey } from "react-query"

function optimisticUpdates<T>(queryClient: QueryClient, key: QueryKey, formatter: (response: T) => T) {
  const previousValue = queryClient.getQueryData(key)

  queryClient.setQueryData(key, previousResponse => {
    const newResponse = formatter(previousResponse as T)

    return newResponse
  })

  return () => previousValue as T
}

export default optimisticUpdates

import React, { useCallback, useEffect, useState } from "react"
import { MutationOptions, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { Box } from "@material-ui/core"
import { GridEditRowsModel, GridRowModel } from "@mui/x-data-grid"
import _ from "lodash"

import type { ITableRow } from "utils/table"
import { Header, Table, TableLoadingOverlay, TableHeader } from "components"
import {
  updateAdjustment,
  updateLevel,
  updateMarkup,
  updateShipping,
  useParameterListQueries,
} from "services/parameters"
import { uiActions } from "store/ui-slice"
import { formatLevelResponse, getTierNameFromKey } from "utils/parameters"
import { translations } from "utils/translations"
import { useHasReadWriteRole } from "utils/user"
import { LeftTable, RightTable } from "./styles"
import { adjustmentColumns, ASCMarkup, ASCShippingEstimate, levelColumns } from "../constants/parametersTable"
import { IAdjustment, IASCMarkupTable, IASCShippingEstimateTable, ILevelResponse } from "../models/parameters"

const ParameterList = (): JSX.Element => {
  const userHasReadWriteRole = useHasReadWriteRole()

  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const [[adjustmentsData, levelsData, markupsData, shippingsData], isTableLoading] = useParameterListQueries()

  const [editRowsModel, setEditRowsModel] = useState({})

  const handleEditRowsModelChange = useCallback(
    (
      key: "getLevels" | "getMarkups" | "getShippingEstimates" | "getAdjustments"
    ): ((model: GridEditRowsModel) => void) => model => {
      setEditRowsModel(model)

      const rowId = parseInt(Object.keys(model)[0], 10)

      if (model[rowId]) {
        const field = model[rowId]

        if (field) {
          const [fieldName] = Object.keys(field)

          const updatedValue = (field[fieldName].value || 0) as number

          const dataSettings = {
            getLevels: levelsData,
            getMarkups: markupsData,
            getShippingEstimates: shippingsData,
            getAdjustments: adjustmentsData,
          } as Record<typeof key, unknown[]>

          const updatedData = _.find(dataSettings[key], { id: rowId })

          const isUpdatedValueValid = !_.isNaN(updatedValue)

          const mutationOptions: MutationOptions = {
            mutationFn: () => Promise.resolve(null),
            onSuccess: () => queryClient.invalidateQueries(key),
          }

          if (isUpdatedValueValid && (updatedData as ITableRow)?.id) {
            if (key === "getLevels") {
              const data = updatedData as ILevelResponse

              if (fieldName === "value") {
                mutationOptions.mutationFn = () =>
                  updateLevel(rowId, {
                    name: data.name,
                    value: Number(updatedValue),
                  })
              } else if (fieldName.includes("Tier")) {
                const tierName = getTierNameFromKey(fieldName)

                const updatedTier = _.find(data.tiers, {
                  name: tierName,
                })

                mutationOptions.mutationFn = () =>
                  updateLevel(rowId, {
                    name: data.name,
                    value: data.value,
                    tiers: [
                      {
                        ...updatedTier,
                        value: parseFloat(updatedValue.toString()),
                      },
                    ],
                  })
              }
            } else if (key === "getMarkups") {
              const data = updatedData as IASCMarkupTable

              if (fieldName === "markup") {
                mutationOptions.mutationFn = () =>
                  updateMarkup({
                    id: rowId,
                    categoryId: data.categoryId,
                    categoryName: data.categoryName,
                    supplierId: data.supplierId,
                    supplierName: data.supplierName,
                    value: updatedValue,
                  })
              }
            } else if (key === "getShippingEstimates") {
              const data = updatedData as IASCShippingEstimateTable

              if (fieldName === "price") {
                mutationOptions.mutationFn = () =>
                  updateShipping({
                    id: rowId,
                    price: updatedValue.toString(),
                    supplierId: data.supplierId,
                    weight: data.weight,
                  })
              }
            } else if (key === "getAdjustments") {
              const data = updatedData as IAdjustment

              if (fieldName === "adjustment") {
                mutationOptions.mutationFn = () =>
                  updateAdjustment({
                    id: rowId,
                    value: updatedValue,
                    name: data.name,
                  })
              }
            }

            queryClient.executeMutation(mutationOptions)
          }
        }
      }
    },
    [queryClient, adjustmentsData, levelsData, markupsData, shippingsData]
  )

  useEffect(() => {
    if (userHasReadWriteRole) {
      dispatch(
        uiActions.showNotification({
          children: translations.general.valuesEditable,
          severity: "info",
        })
      )
    }
  }, [dispatch, userHasReadWriteRole])

  return (
    <>
      <Header title="Parameters" />
      <Box>
        <Box display="flex">
          <LeftTable>
            <TableHeader title="Adjustment" />
            <Table
              autoHeight
              columns={adjustmentColumns.map(column => ({
                ...column,
                editable: column.field !== "name" && userHasReadWriteRole,
              }))}
              rows={adjustmentsData?.length ? (adjustmentsData as GridRowModel[]) : []}
              hideFooterPagination
              hideFooterSelectedRowCount
              loading={isTableLoading}
              components={{
                LoadingOverlay: TableLoadingOverlay,
              }}
              onEditRowsModelChange={handleEditRowsModelChange("getAdjustments")}
              editRowsModel={editRowsModel}
              disableColumnMenu
            />
          </LeftTable>
          <RightTable>
            <TableHeader title="Pricing level and the associated Tier" />
            <Table
              autoHeight
              columns={levelColumns.map(column => ({
                ...column,
                editable: column.field !== "name" && userHasReadWriteRole,
              }))}
              rows={levelsData?.length ? (formatLevelResponse(levelsData) as GridRowModel[]) : []}
              hideFooterPagination
              hideFooterSelectedRowCount
              loading={isTableLoading}
              components={{
                LoadingOverlay: TableLoadingOverlay,
              }}
              onEditRowsModelChange={handleEditRowsModelChange("getLevels")}
              editRowsModel={editRowsModel}
              disableColumnMenu
            />
          </RightTable>
        </Box>
        <Box display="flex">
          <LeftTable>
            <TableHeader title="ASC Markup" />
            <Table
              autoHeight
              columns={ASCMarkup.map(column => ({
                ...column,
                editable: column.field !== "categoryName" && userHasReadWriteRole,
              }))}
              rows={markupsData}
              hideFooterPagination
              hideFooterSelectedRowCount
              loading={isTableLoading}
              components={{
                LoadingOverlay: TableLoadingOverlay,
              }}
              disableColumnMenu
              onEditRowsModelChange={handleEditRowsModelChange("getMarkups")}
              editRowsModel={editRowsModel}
            />
          </LeftTable>
          <RightTable>
            <TableHeader title="ASC Shipping Estimate" />
            <Table
              pagination
              autoPageSize
              columns={ASCShippingEstimate.map(column => ({
                ...column,
                editable: column.field !== "weight" && userHasReadWriteRole,
              }))}
              rows={shippingsData}
              hideFooterSelectedRowCount
              loading={isTableLoading}
              components={{
                LoadingOverlay: TableLoadingOverlay,
              }}
              onEditRowsModelChange={handleEditRowsModelChange("getShippingEstimates")}
              editRowsModel={editRowsModel}
            />
          </RightTable>
        </Box>
      </Box>
    </>
  )
}

export { ParameterList }

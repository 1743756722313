import React from "react"

const FilledCloseIcon = (): JSX.Element => (
  <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M24.0711 9.92898C20.1678 6.02575 13.8322 6.02575 9.92893 9.92898 6.0257 13.8322 6.0257 20.1679 9.92893 24.0711 13.8322 27.9743 20.1678 27.9743 24.0711 24.0711 27.9743 20.1679 27.9743 13.8322 24.0711 9.92898zM19.1213 20.5356 17 18.4143 14.8787 20.5356C14.4898 20.9245 13.8534 20.9245 13.4645 20.5356 13.0756 20.1467 13.0756 19.5103 13.4645 19.1214L15.5858 17 13.4645 14.8787C13.0756 14.4898 13.0756 13.8534 13.4645 13.4645 13.8534 13.0756 14.4898 13.0756 14.8787 13.4645L17 15.5858 19.1213 13.4645C19.5102 13.0756 20.1466 13.0756 20.5355 13.4645 20.9244 13.8534 20.9244 14.4898 20.5355 14.8787L18.4142 17 20.5355 19.1214C20.9244 19.5103 20.9244 20.1467 20.5355 20.5356 20.1466 20.9245 19.5102 20.9245 19.1213 20.5356z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h24v24H0z" transform="translate(17 0.0292969) rotate(45)" />
      </clipPath>
    </defs>
  </svg>
)

export { FilledCloseIcon }

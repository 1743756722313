import * as React from "react"
import Link from "@material-ui/core/Link"
import { Breadcrumbs as MuiBreadcrumbs, Typography, ThemeProvider } from "@material-ui/core"
import { BreadcrumbsTheme } from "./styles"

export const Breadcrumbs = ({ pathnameList }: { pathnameList: string[] }) => {
  return (
    <ThemeProvider theme={BreadcrumbsTheme}>
      <MuiBreadcrumbs aria-label="breadcrumb" separator=">">
        {pathnameList.map((path: string, index: number) => {
          const last = index === pathnameList.length - 1

          const to = `/${pathnameList.slice(0, index + 1).join("/")}`

          return last ? (
            <Typography color="inherit" key={to}>
              {pathnameList[index]}
            </Typography>
          ) : (
            <Link color="primary" href={to.replaceAll(" ", "-")} key={to}>
              {pathnameList[index]}
            </Link>
          )
        })}
      </MuiBreadcrumbs>
    </ThemeProvider>
  )
}

import { createTheme } from "@material-ui/core"
import { rem, transparentize } from "polished"
import { palette, spacing, typography } from "../theme"

export const DropzoneDialogTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiDialog: {
      root: {
        "& div[class*='MuiDropzoneArea-root']": {
          borderRadius: 0,
          border: `1px dashed ${palette.grey[400]}`,
          minHeight: "unset",
        },
        "& div[class*='MuiPaper-rounded']": { borderRadius: rem("16px") },
        "& div[class*='MuiDropzoneArea-textContainer']": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        "& p[class*='MuiDropzoneArea-text']": {
          lineHeight: rem("24px"),
          fontSize: rem("16px"),
          fontWeight: typography.fontWeightBold,
          order: 2,
          margin: spacing(2, 0),
        },
        "& svg[class*='MuiDropzoneArea-icon']": {
          width: "24%",
          height: "24%",
          order: 1,
          marginTop: rem("20px"),
          marginBottom: rem("-20px"),
        },
        "& svg[class*='MuiSvgIcon-root']": {
          fill: palette.grey[200],
        },
        "& span[class*='MuiTypography-subtitle1']": {
          display: "none",
        },
        "& div[class*='MuiDropzonePreviewList-root']": {},
        "& div[class*='MuiDropzonePreviewList-imageContainer']": {
          display: "flex",
          padding: rem("10px"),
          border: `1px solid ${palette.grey[500]}`,
          borderRadius: rem("10px"),
          width: "100%",
          height: rem("61px"),
          marginTop: spacing(3),
        },
        "& svg[class*='MuiDropzonePreviewList-image'], img[class*='MuiDropzonePreviewList-image']": {
          height: rem("41px"),
          objectFit: "contain",
          marginRight: rem("20px"),
        },
        "& p[class*='MuiTypography-body1']": {
          display: "flex",
          alignItems: "center",
          fontSize: rem("14px"),
          fontWeight: typography.fontWeightBold,
          lineHeight: rem("20px"),
          color: palette.grey[800],
        },
        "& div[class*='MuiGrid-spacing-xs-8']": {
          margin: 0,
          width: "unset",
          display: "flex",
          flexDirection: "column",
        },
        "& div[class*='MuiGrid-grid-xs-4']": {
          maxWidth: "unset",
        },
        "& button[class*='MuiDropzonePreviewList-removeButton']": {
          transform: "translateX(480px)",
          opacity: 1,
          "& svg": {
            fill: palette.primary.light,
          },
        },
        "& button[class*='MuiFab-root']": {
          backgroundColor: "unset",
          boxShadow: "none",
        },
        "& div[class*='MuiDropzoneSnackbar-infoAlert']": {
          backgroundColor: palette.primary.main,
        },
      },
    },

    MuiDialogContent: {
      root: {
        overflow: "hidden !important",
        padding: spacing(0, 3, 1, 3),
      },
    },

    MuiDialogTitle: {
      root: {
        padding: spacing(3),
        "& h2[class*='MuiTypography-root']": {
          fontWeight: typography.fontWeightBold,
          color: palette.grey[800],
          fontSize: typography.h3.fontSize,
          lineHeight: rem("21px"),
        },
      },
    },

    MuiDialogActions: {
      root: {
        padding: spacing(3),
        "& button[class*='MuiButton-text']": {
          padding: spacing(1, 3),
          lineHeight: rem("24px"),
          fontSize: rem("16px"),
          textTransform: "capitalize",
          fontWeight: typography.fontWeightRegular,
          minWidth: rem("140px"),
          height: rem("40px"),
        },
        "& button[class*='MuiButton-text']:nth-child(even)": {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
          marginLeft: spacing(2),
        },
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: transparentize(0.2, palette.text.primary),
      },
    },
  },
})

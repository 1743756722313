import { GridColDef } from "@mui/x-data-grid"
import { leftNonEditableColumn, leftNonEditableTooltipColumn } from "modules/TableGlobalStyles"

export const baseColumns: GridColDef[] = [
  {
    field: "vsId",
    headerName: "SKU",
    type: "string",
    sortable: true,
    ...leftNonEditableColumn,
    width: 130,
  },
  {
    field: "name",
    headerName: "Product Name",
    type: "string",
    sortable: true,
    filterable: false,
    ...leftNonEditableTooltipColumn,
    width: 200,
  },
]

import { Box } from "@material-ui/core"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { GridCellParams, GridColDef } from "@mui/x-data-grid"
import { EditOutlined } from "@material-ui/icons"

import { ROUTES } from "constants/routes"
import { IconButton } from "components"
import {
  centerColumn,
  leftNonEditableColumn,
  leftNonEditableTooltipColumn,
  priceFormatterParser,
} from "modules/TableGlobalStyles"
import { dateValueFormatter } from "utils/table"

const getColumns = (history: ReturnType<typeof useHistory>, userHasReadWriteRole: boolean): GridColDef[] => [
  {
    field: "vsId",
    headerName: "VetSource ID",
    type: "string",
    flex: 0.32,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    flex: 0.6,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "vcost",
    headerName: "VS Cost",
    type: "number",
    flex: 0.3,
    filterable: false,
    ...priceFormatterParser,
    ...leftNonEditableColumn,
  },
  {
    field: "size",
    headerName: "Strength/Size",
    type: "string",
    flex: 0.4,
    filterable: false,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "purchaseSize",
    headerName: "Purchase Size",
    type: "string",
    flex: 0.4,
    filterable: false,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "packaging",
    headerName: "Packaging",
    type: "string",
    flex: 0.4,
    filterable: false,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "created",
    headerName: "Creation Date",
    type: "date",
    flex: 0.4,
    filterable: false,
    ...leftNonEditableColumn,
    valueFormatter: dateValueFormatter,
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 0.25,
    ...centerColumn,
    renderCell: ({ id }: GridCellParams): JSX.Element => {
      return (
        <Box>
          <IconButton
            color="inherit"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              const endpoint = `${ROUTES.UPDATE_PRODUCT.path}`.replace(":productId", id.toString())

              history.push(endpoint)
            }}
            isDisabled={!userHasReadWriteRole}
          />
        </Box>
      )
    },
  },
]

export { getColumns }

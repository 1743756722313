import React from "react"
import { Typography } from "@material-ui/core"
import { Spacer, Dialog } from "components"
import { ICustomerGroup } from "modules/Customers/models/customerGroup"

export interface IDeleteCustomerGroupDialogProps {
  onClose: () => void
  confirmHandler: () => void
  isOpen: boolean
  customerGroup?: ICustomerGroup
}

const DeleteCustomerGroupDialog = ({
  onClose,
  isOpen,
  customerGroup,
  confirmHandler,
}: IDeleteCustomerGroupDialogProps): JSX.Element => {
  return (
    <Dialog
      onClose={onClose}
      title="Delete Customer Group"
      open={isOpen}
      secondaryActionLabel="Cancel"
      primaryActionLabel="Delete"
      primaryActionHandler={confirmHandler}
    >
      {customerGroup && (
        <>
          <Spacer size={3} />
          <Typography variant="h4">Are you sure you want to delete {customerGroup.name}?</Typography>
          <Spacer size={3} />
        </>
      )}
    </Dialog>
  )
}

export { DeleteCustomerGroupDialog }

import * as React from "react"
import { GridCellParams, GridColDef } from "@mui/x-data-grid"

import type { IStep, IStepGroupResponse } from "typings/modules"
import { RuleListActions } from "../containers/RuleListActions"
import { StepRuleListActions } from "../components/StepRuleListActions"

export const getColumns = (step: IStep, stepGroup: IStepGroupResponse, isMainEdition?: boolean): GridColDef[] => [
  {
    field: "ruleName",
    headerName: "Rule Name",
    type: "string",
    flex: 0.6,
    editable: false,
  },
  {
    field: "created",
    headerName: "Created Date",
    type: "date",
    flex: 0.6,
    editable: false,
  },
  {
    field: "actions",
    headerName: "Actions",
    align: "center",
    headerAlign: "center",
    flex: 0.6,
    editable: false,
    renderCell: ({ row }: GridCellParams): JSX.Element => {
      return isMainEdition ? (
        <StepRuleListActions row={row} step={step} />
      ) : (
        <RuleListActions row={row} step={step} stepGroup={stepGroup} />
      )
    },
  },
]

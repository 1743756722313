export enum publishingEnabled {
  TRUE = "1",
  FALSE = "0",
}
export interface ISettingsForm {
  MAX_ITERATIONS?: string
  THRESHOLD?: string
  MAX_RUN_HISTORY?: string
  ATYPICAL_PRICE_THRESHOLD?: string
}

export const settingsFormInitialValues: ISettingsForm = {
  MAX_ITERATIONS: "",
  THRESHOLD: "",
  MAX_RUN_HISTORY: "",
  ATYPICAL_PRICE_THRESHOLD: "",
}

export enum settingNames {
  MAX_ITERATIONS = "MAX_ITERATIONS",
  THRESHOLD = "THRESHOLD",
  MAX_RUN_HISTORY = "MAX_RUN_HISTORY",
  ATYPICAL_PRICE_THRESHOLD = "ATYPICAL_PRICE_THRESHOLD",
}

export interface IAPISetting {
  id: string
  name: settingNames
  value: string
}

export interface IAPISettingRequest {
  name: settingNames
  value: string
}

import React from "react"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import { Icon, IconButton } from "components"
import { Box } from "@material-ui/core"
import { buttonMenuStyles } from "./styles"

export enum MenuOptionNames {
  EDIT = "Edit",
  OVERRIDE = "Override",
  DELETE = "Delete",
}

interface IOptionsItems {
  name: MenuOptionNames
  icon: React.ReactNode
  confirm?: React.ReactNode[]
}

interface IButtonMenuProps {
  menuOptions: IOptionsItems[]
  selectedOptionIndex: number
  onMenuItemClick: (event: React.MouseEvent<HTMLElement>, index: number) => void
  anchorEl: HTMLElement | null
  onIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
}

export const ButtonMenu = ({
  menuOptions,
  selectedOptionIndex,
  onMenuItemClick,
  anchorEl,
  onIconClick,
  onClose,
  onConfirm,
  onCancel,
}: IButtonMenuProps): JSX.Element => {
  const classes = buttonMenuStyles()

  return (
    <>
      {
        {
          Edit: <IconButton className={classes.icon} icon={<MoreVertIcon />} onClick={onIconClick} />,
          Override: (
            <Box>
              <IconButton
                color="inherit"
                className={classes.cancelIcon}
                icon={<HighlightOffIcon />}
                onClick={onCancel}
              />
              <IconButton
                color="inherit"
                className={classes.confirmIcon}
                icon={<CheckCircleOutlineIcon />}
                onClick={onConfirm}
              />
            </Box>
          ),
          Delete: <div />,
        }[menuOptions[selectedOptionIndex].name]
      }
      <Menu className={classes.menu} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        {menuOptions.map((option, index) => (
          <MenuItem
            key={option.name}
            selected={index === selectedOptionIndex}
            onClick={event => onMenuItemClick(event, index)}
          >
            <Icon className={classes.iconMenu} icon={option.icon} />
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

import * as React from "react"
import { useParams } from "react-router"
import { useQuery } from "react-query"
import { Box, Portal, Typography } from "@material-ui/core"
import { GridRowData } from "@mui/x-data-grid"

import { makeDebug } from "services/calculator"
import { Dialog, LinearIndeterminate, Switch, TreeView, UncontrolledAlert } from "components"
import { mapDebugResultIntoTree } from "utils/calculators"
import { IMakeDebugRequestPayload } from "../models/calculator"

type DebugDialogProps = {
  handleClose: () => void
  row: GridRowData
}

const DebugDialog = (props: DebugDialogProps) => {
  const { handleClose, row } = props

  const [isAll, setIsAll] = React.useState(false)

  const { calculatorId } = useParams<{ calculatorId: string }>()

  const queryKey = ["makeDebug", calculatorId, row.productId, row.customerGroupId, isAll]

  const query = useQuery(queryKey, async () => {
    const values: IMakeDebugRequestPayload = {
      calculatorId,
      productId: row.productId as string,
      customerGroupId: row.customerGroupId as string,
      showAll: isAll,
    }

    const result = await makeDebug(values)

    return result
  })

  const isError = Boolean(query.error) || (!query.isFetching && !query.data)

  const values = React.useMemo(() => {
    if (!isError && query.data) {
      const result = mapDebugResultIntoTree(query.data)

      return result
    }

    return null
  }, [isError, query.data])

  React.useEffect(() => {
    if (query.isLoading) {
      const loader = document.querySelector("#debug-dialog-loader")

      if (loader) {
        loader.parentElement.style.height = "320px"
      }

      return
    }

    const tree = document.querySelector("#debug-dialog-tree")

    if (tree) {
      tree.parentElement.style.height = "auto"
    }
  }, [query.isLoading])

  if (query.isLoading && !queryKey.includes(true)) {
    return (
      <Portal container={document.body}>
        <Box position="absolute" top="65px" left="0" zIndex="140" width="100%">
          <LinearIndeterminate />
        </Box>
      </Portal>
    )
  }

  if ((query.isError || !query.data) && !query.isLoading) {
    const error = (query.error as ReturnType<typeof Error>)?.message || query.error || "Error getting debug values"

    return <UncontrolledAlert severity="error">{error}</UncontrolledAlert>
  }

  const handleAll = () => {
    setIsAll(prevState => !prevState)
  }

  const response = query.data

  return (
    <Dialog
      open
      title={row.ecomname}
      onClose={handleClose}
      primaryActionHandler={handleClose}
      primaryActionLabel="Close"
      maxWidth="md"
    >
      {query.isLoading ? (
        <Box position="absolute" top="0" left="0" zIndex="141" width="100%" id="debug-dialog-loader">
          <LinearIndeterminate />
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" id="debug-dialog-tree">
          <TreeView title={response.name} object={values} />
          <Box display="flex" alignItems="center" height="40px">
            <Typography variant="body1">Show all</Typography>
            <Switch color="primary" checked={isAll} onChange={handleAll} />
          </Box>
        </Box>
      )}
    </Dialog>
  )
}

export default DebugDialog

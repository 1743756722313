import { Box, styled } from "@material-ui/core"
import { rem, transparentize } from "polished"
import { theme } from "../theme"

export const StepGroupContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  maxWidth: rem("170px"),
  width: "100%",
  marginBottom: theme.spacing(1),
  textTransform: "capitalize",
}))

export interface IIDContainer {
  color?: string
}

export const IDContainer = styled(Box)(({ color = "hsl(0, 84%, 63%)" }: IIDContainer) => ({
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
  width: rem("30px"),
  height: rem("30px"),
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color,
  backgroundColor: transparentize(0.9, color),
  marginRight: theme.spacing(1),
}))

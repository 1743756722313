import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const TooltipTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiTooltip: {
      tooltip: {
        maxWidth: rem("230px"),
        backgroundColor: palette.grey[900],
        color: palette.primary.contrastText,
        textAlign: "center",
        padding: spacing(2),
        borderRadius: rem("12px"),
        fontSize: rem("14px"),
        fontWeight: 400,
      },
      arrow: {
        color: palette.grey[900],
      },
    },
  },
})

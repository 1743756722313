import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import type { IRuleArgument, IRuleAttributeArgument } from "typings/modules"
import { getNextRuleOrder, isConditionalRuleValid, isRuleConditional, isSimpleRuleValid } from "utils/calculators"
import { LabelToOperationTypeMap } from "modules/Rules-Pricing/models/calculator"
import { CalculatorConditionValue, OperationTypes, Operators } from "constants/calculator"
import { getInitialRule, ICalculatorState, ruleCreatorInitialValues } from "./state"

export const ruleCreatorSlice = createSlice({
  name: "Rule creator",
  initialState: ruleCreatorInitialValues,
  reducers: {
    emptyRuleAdded: (prevState, action: PayloadAction<ICalculatorState>) => {
      const ruleOrder = getNextRuleOrder(action.payload)

      Object.assign(prevState, getInitialRule(ruleOrder))
    },
    cleanRuleCreator: prevState => {
      Object.assign(prevState, ruleCreatorInitialValues)
    },
    editableRuleNameChanged: (prevState, action: PayloadAction<any>) => {
      prevState.ruleName = action.payload
    },
    setArgumentAttribute: (
      prevState,
      action: PayloadAction<{
        value: string
        argument: string
      }>
    ) => {
      const argument = prevState[action.payload.argument as keyof IRuleAttributeArgument] as IRuleArgument

      prevState[action.payload.argument as keyof IRuleAttributeArgument] = {
        ...argument,
        attribute: action.payload.value,
      }
    },
    setArgumentOption: (
      prevState,
      action: PayloadAction<{
        value: string
        name: keyof IRuleAttributeArgument
        argument: string
      }>
    ) => {
      const { value, name, argument } = action.payload

      const type = LabelToOperationTypeMap[value as keyof typeof LabelToOperationTypeMap] || OperationTypes.SYSTEM_OBJ

      const ruleArgument = {
        value,
        name,
        type,
        attribute: "",
      }

      prevState[argument as keyof IRuleAttributeArgument] = ruleArgument
    },
    clearArgument: (prevState, action: PayloadAction<string>) => {
      prevState[action.payload as keyof IRuleAttributeArgument] = {} as IRuleArgument
    },
    setConditionValue: (
      prevState,
      action: PayloadAction<{
        conditionValue: CalculatorConditionValue
        order: number
      }>
    ) => {
      const { order, conditionValue } = action.payload

      prevState.firstArgument = {} as IRuleArgument

      prevState.secondArgument = {} as IRuleArgument

      prevState.thirdArgument = {} as IRuleArgument

      prevState.fourthArgument = {} as IRuleArgument

      prevState.operator = "" as Operators

      const currentConditionValue = prevState.conditionValue

      if (currentConditionValue !== conditionValue) {
        if (currentConditionValue === CalculatorConditionValue.EMPTY) {
          prevState.comparatorOrder = order

          prevState.order = order + 1
        } else {
          prevState.order = order - 1
        }
      }

      prevState.conditionValue = conditionValue
    },
    operatorChanged: (prevState, action: PayloadAction<string>) => {
      prevState.operator = action.payload as Operators
    },
    checkRuleValidity: prevState => {
      let ruleIsValid
      if (isRuleConditional(prevState)) {
        ruleIsValid = isConditionalRuleValid(prevState)
      } else {
        ruleIsValid = isSimpleRuleValid(prevState)
      }
      prevState.isValid = ruleIsValid
    },
    switchRuleNameIsEditable: prevState => {
      const currentState = prevState.nameIsEditable
      prevState.nameIsEditable = !currentState
    },
  },
})

export const ruleCreatorActions = ruleCreatorSlice.actions

export const ruleCreatorReducer = ruleCreatorSlice.reducer

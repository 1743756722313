import React from "react"
import { Modal as MuiModal, Typography } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { Button, IconButton } from "components"
import classNames from "classnames"
import { getModalStyle, ModalHeader, ModalContainer, ModalFooter, ModalContent, modalStyles } from "./styles"

interface IModal {
  content: JSX.Element
  title: string
  buttonChildren: string
  isOpen: boolean
  handleClose: () => void
  size?: "small" | "medium" | "large"
}

const Modal = ({ content, title, buttonChildren, isOpen, handleClose, size }: IModal): JSX.Element => {
  const modalStyle = getModalStyle()
  const classes = modalStyles()
  return (
    <>
      <MuiModal open={isOpen} onClose={handleClose} data-testid="modal">
        <ModalContainer
          style={modalStyle}
          className={classNames({
            [classes.small]: size === "small",
            [classes.medium]: size === "medium",
            [classes.large]: size === "large",
          })}
        >
          <ModalHeader>
            <Typography variant="h4">{title}</Typography>
            <IconButton icon={<CloseIcon />} color="inherit" onClick={handleClose} />
          </ModalHeader>
          <ModalContent>{content}</ModalContent>
          <ModalFooter>
            <Button onClick={handleClose}>{buttonChildren}</Button>
          </ModalFooter>
        </ModalContainer>
      </MuiModal>
    </>
  )
}

Modal.defaultProps = {
  size: "large",
}

export { Modal }

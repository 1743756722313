import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IAlertProps } from "components"
import React from "react"

interface IDialog {
  primaryActionHandler: () => void
  secondaryActionHandler?: () => void
  secondaryActionLabel?: string
  primaryActionLabel?: string
  title?: string
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false
  fullWidth?: boolean
  dialogContentText?: JSX.Element
  dialogContentForm?: {
    id: string
    label: string
    type: string
    value: string
    onChange: (e: React.ChangeEvent<{ value: string }>) => void
  }
  children?: JSX.Element | JSX.Element[]
}
export interface IUiState {
  alert: IAlertProps
  showAlert: boolean
  isLoading: boolean
  showDialog: boolean
  dialog: IDialog
}

export const uiInitialValues: IUiState = {
  alert: {} as IAlertProps,
  showAlert: false,
  isLoading: false,
  showDialog: false,
  dialog: {} as IDialog,
}

export const uiSlice = createSlice({
  name: "UI",
  initialState: uiInitialValues,
  reducers: {
    showNotification: (prevState, action: PayloadAction<IAlertProps>) => {
      prevState.showAlert = true
      prevState.alert = action.payload
    },
    hideNotification: prevState => {
      prevState.showAlert = false
      prevState.alert = {} as IAlertProps
    },
    showSpinner: prevState => {
      prevState.isLoading = true
    },
    hideSpinner: prevState => {
      prevState.isLoading = false
    },
    showDialog: (prevState, action: PayloadAction<IDialog>) => {
      prevState.showDialog = true

      Object.assign(prevState.dialog, action.payload)
    },
    hideDialog: prevState => {
      prevState.showDialog = false

      Object.assign(prevState.dialog, {} as IDialog)
    },
  },
})

export const uiActions = uiSlice.actions

export const uiReducer = uiSlice.reducer

import * as React from "react"
import { Box, IconButton as MuiIconButton, Typography } from "@material-ui/core"
import { DeleteOutline } from "@material-ui/icons"

import type { IRuleState } from "typings/modules"
import { Dialog } from "components"
import type { IFormatStepRulesParams } from "../models/calculator"

type DialogCalculatorStepRuleProps = {
  rule: IRuleState
  handleSubmit: (params: Pick<IFormatStepRulesParams, "id" | "mode">) => void
}

const DialogCalculatorStepRule = (props: DialogCalculatorStepRuleProps) => {
  const { rule, handleSubmit } = props

  const [dialog, setDialog] = React.useState(false)

  const handleOpen = (event: React.MouseEvent) => {
    event.stopPropagation()

    setDialog(prevState => !prevState)
  }

  const handleAccept = () => {
    handleSubmit({
      id: rule.id,
      mode: "delete",
    })
  }

  return (
    <Box alignSelf="flex-end">
      <MuiIconButton onClick={handleOpen}>
        <DeleteOutline />
      </MuiIconButton>
      {dialog && (
        <Dialog
          open
          onClose={handleOpen}
          title="Delete rule set"
          primaryActionLabel="Delete"
          primaryActionHandler={handleAccept}
          secondaryActionLabel="Cancel"
          secondaryActionHandler={handleOpen}
          dialogContentText={
            <Typography variant="h4">Are you sure you want to delete the rule {rule.ruleName}?</Typography>
          }
        />
      )}
    </Box>
  )
}

export default DialogCalculatorStepRule

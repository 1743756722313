import { v4 as uuidV4 } from "uuid"

import type { CalculatorState } from "typings/modules"
import type { IStepGroup } from "typings/modules/step-group"

export enum CalculatorConditionValue {
  EMPTY = "1",
  SELECTIVE = "2",
}

export enum CalculatorStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum CalculatorStepGroupType {
  RETAIL_PRICE = "retailPrice",
  FEE = "fee",
  VET_PRICE = "vetPrice",
  ACQUISITION_COST = "acquisitionCost",
  WHOLESALE_PRICE = "wholesalePrice",
  VET_LIST = "vetList",
}

export enum Operators {
  GREATER_THAN = "GREATER_THAN",
  EQUALS_GREATER_THAN = "EQUALS_GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  EQUALS_LESS_THAN = "EQUALS_LESS_THAN",
  EQUALS = "EQUALS",
  EXISTS = "EXISTS",
  SUM = "SUM",
  SUBTRACTION = "SUBTRACTION",
  DIVISION = "DIVISION",
  MULTIPLICATION = "MULTIPLICATION",
  ROUND_UP = "ROUND_UP",
  ROUND_DOWN = "ROUND_DOWN",
  MAXIMUM = "MAXIMUM",
  MINIMUM = "MINIMUM",
  AVERAGE = "AVERAGE",
}

export enum OperationTypes {
  STRING = "STRING",
  NUMBER = "NUMBER",
  INPUT = "Input",
  RULE = "RULE",
  STEP = "STEP",
  SYSTEM_OBJ = "SYSTEM_OBJ",
}

export enum getCalculatorIdTrigger {
  CALCULATOR_LIST = "CALCULATOR_LIST",
  CALCULATOR_DASHBOARD = "CALCULATOR DASHBOARD",
  CALCULATOR_RULE_CREATOR = "RULE CREATOR",
  RESULT_LIST = "RESULT_LIST",
  CANCEL_CHANGES = "CANCEL_CHANGES",
}

export enum saveCalculatorTrigger {
  ADD_RULE = "ADD_RULE",
  SAVE_BUTTON = "SAVE_BUTTON DASHBOARD",
}

export const stepGroupInitialValues: IStepGroup = {
  id: (undefined as unknown) as number,
  name: "",
  type: "" as CalculatorStepGroupType,
  description: "",
  steps: [
    {
      id: undefined,
      internalId: uuidV4(),
      name: "",
      cycleReference: false,
      description: "",
      order: 1,
      rules: [],
    },
  ],
  main: true,
}

export const calculatorInitialValues: CalculatorState = {
  id: undefined,
  internalId: "",
  name: "",
  description: "",
  customerGroups: {},
  stepGroups: {},
  productQty: 0,
  status: CalculatorStatus.ACTIVE,
  tier: "",
  createdBy: "",
  created: "",
  lastModifiedBy: "",
}

import React from "react"
import { Cookies } from "react-cookie"
import type { IAppserverApiResponse, ISubrealmResponse } from "../modules/SignIn/models/user"

const PMT_SUBREALM = "pmt"

const READ_ROLE = "pricingtool_read"

const READ_WRITE_ROLE = "pricingtool_read_write"

export const setAuthCookie = (auth: IAppserverApiResponse): void => {
  const cookie = new Cookies()

  return cookie.set("auth", JSON.stringify(auth))
}

export const getAuthCookie = (): IAppserverApiResponse => {
  const cookie = new Cookies()

  return cookie.get("auth")
}

export const removeAuthCookie = (): void => {
  const cookie = new Cookies()
  cookie.remove("auth")
}

export const useHasRole = (roleName: string): boolean => {
  const auth = getAuthCookie()

  const memorizedIndexof = React.useMemo(() => {
    if (auth?.data) {
      const subRealmPricing: ISubrealmResponse = getAuthCookie().data?.subRealms?.find(
        subRealm => subRealm.subRealm === PMT_SUBREALM
      )

      const subRealmPricingMutable: React.MutableRefObject<ISubrealmResponse> = { current: subRealmPricing }

      return subRealmPricingMutable.current.authorities?.indexOf(roleName) > -1
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasRole = React.useRef(memorizedIndexof)?.current

  return hasRole
}

export const useHasReadRole = (): boolean => {
  return useHasRole(READ_ROLE)
}

export const useHasReadWriteRole = (): boolean => {
  return useHasRole(READ_WRITE_ROLE)
}

import React from "react"
import { useHistory } from "react-router"
import { Tab } from "@material-ui/core"

import type { ITabs } from "components/Tabs"
import { ROUTES } from "constants/routes"
import { Button, Header, Tabs, TabPanel, ButtonsGroup } from "components"
import { useHasReadWriteRole } from "utils/user"
import { CalculatorList } from "./CalculatorList"
import { StepGroupList } from "./StepGroupList"

const RulesPricing = () => {
  const listRef = React.useRef(null)

  const userHasReadWriteRole = useHasReadWriteRole()

  const handlePublish = () => {
    listRef.current.handlePublishCalculators()
  }

  const TABS = [
    {
      id: 0,
      label: "Calculators",
      children: <CalculatorList ref={listRef} />,
    },
    {
      id: 1,
      label: "Rule Sets",
      children: <StepGroupList />,
    },
  ] as const

  type TabKey = typeof TABS[number]["id"]

  const history = useHistory<{ tab: TabKey }>()

  const [state, setState] = React.useState(() => {
    const { tab = 0 } = history.location.state || {}

    return { tab }
  })

  const updater = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }))
  }

  const { label: labelAction, id: currentTab } = TABS[state.tab]

  const handleHeaderAction = () => {
    if (currentTab === 0) {
      history.push(ROUTES.NEW_CALCULATOR.path)

      return
    }

    if (currentTab === 1) {
      history.push(ROUTES.NEW_STEP_GROUP.path)
    }
  }

  const handleTab: ITabs["onChange"] = (event, value) => {
    updater({ tab: value as TabKey })

    history.replace({
      ...history.location,
      state: { ...history.location.state, tab: value as TabKey },
    })
  }

  const title = state.tab === 0 ? "List of Calculators" : "List of Rule Sets"

  const tableHeaderOptions = [
    {
      label: "Create New Calculator",
      action: handleHeaderAction,
      disabled: !userHasReadWriteRole,
    },
    {
      label: "Publish selected",
      action: handlePublish,
      disabled: !userHasReadWriteRole,
    },
  ]

  return (
    <>
      <div>
        <Header
          title={title}
          actions={
            state.tab === 0 ? (
              <ButtonsGroup options={tableHeaderOptions} />
            ) : (
              <Button onClick={handleHeaderAction}>Create New {labelAction.slice(0, labelAction.length - 1)}</Button>
            )
          }
        />
        <Tabs value={state.tab} onChange={handleTab}>
          {TABS.map(tab => (
            <Tab key={tab.id} label={tab.label} disableFocusRipple disableRipple />
          ))}
        </Tabs>
      </div>
      {TABS.map(tab => (
        <TabPanel key={tab.id} value={state.tab} index={tab.id}>
          {tab.children}
        </TabPanel>
      ))}
    </>
  )
}

export { RulesPricing }

import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import * as Yup from "yup"
import { GridColDef, GridRowData, GridRowId, GridRowIdGetter, GridRowModel, GridSelectionModel } from "@mui/x-data-grid"
import { IList, ISelectOption } from "components"
import { ICustomerGroup } from "modules/Customers/models/customerGroup"
import { translations } from "utils/translations"
import { IAction } from "models/store"

export interface INewProductWizardFooterProps {
  activeStep?: number
  handleNext: () => void
  handleBack?: () => void
  isBackDisabled?: boolean
  isLastStep?: boolean
  isDisabled?: boolean
}

export interface IProviderStepProps extends Pick<INewProductWizardFooterProps, "handleNext" | "handleBack"> {
  dispatchProviderStep: (action: IAction) => void
  providerStepState: IProviderStep
}

export interface IProductStepProps extends Pick<INewProductWizardFooterProps, "handleNext" | "handleBack"> {
  dispatchProductStep: (action: IAction) => void
  productStepState: IProductStep
}

export interface ICustomerStepProps extends Pick<INewProductWizardFooterProps, "handleNext" | "handleBack"> {
  customerStepState?: ICustomerGroup[]
  removeItemHandler: (customerGroup: ICustomerGroup) => void
  addGroupButtonHandler: () => void
}

export interface ISummaryStepProps extends Pick<INewProductWizardFooterProps, "handleNext" | "handleBack"> {
  onEditIconClick: (event: React.MouseEvent<HTMLElement>, id: GridRowId, row: GridRowModel) => void

  onOverrideIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, id: GridRowId) => void
  summaryData?: GridRowData[]
  isLoading: boolean
  editableRow: GridRowId | null
  publishAsapChecked: boolean
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  publishDate?: MaterialUiPickersDate | null
  onPublishDateChange: (date: MaterialUiPickersDate | null, value?: string | null) => void
}

export interface IPricingStepProps {
  customerList?: IList[]
  removeItemHandler: (listItem: IList) => void
  addTiersButtonHandler: () => void
  addRulesButtonHandler: () => void
  removeSubItemHandler?: () => void
}

export interface IAddCustomerGroupModalProps {
  isOpen: boolean
  handleAccept?: () => void
  handleClose: () => void
  onItemSelectionChange: (model: GridSelectionModel) => void
  columns: GridColDef[]
  rows: GridRowModel[]
  selectionModel?: GridRowId[]
  isLoading: boolean
  getRowId?: GridRowIdGetter
}

export interface IEditCalculatorModalProps {
  isOpen: boolean
  handleClose: () => void
  editCalculator?: GridRowModel
}

export interface IAddTiersModalProps {
  isOpen: boolean
  handleClose: () => void
  onItemSelectionChange: (model: GridSelectionModel) => void
  selectOptions: ISelectOption[]
  selectedCustomerGroup: string
  rulesSelectHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export enum StepAction {
  FORM_CHANGED = "FORM_CHANGED",
}

export interface IProviderStep {
  supplierName: string
  supplierProductId: string
  manufacturer: string
  upc: string
  isStepValid?: boolean
}

export interface IProductStep {
  productName: string
  ecomName: string
  vsId: string
  map: number
  majorCategoryName: string
  categoryName: string
  vipMarkerName: string
  feeStrategy: string
  marsFeeStrategy?: string
  srpRuleName: string
  specieName: string
  banFeeStrategy: string
  vcost: number
  vetList?: number
  exception: string
  rxrequired: boolean
  weight: number
  purchaseSize: string
  productSize: string
  packaging: string
  breakdown: number
  instantrebate: number
  isStepValid?: boolean
  marginFocused?: number | null
  equineFee?: number
  equineRetailMarkUp?: number
}

export interface ICustomerGroupStep {
  customerGroups: ICustomerGroup[]
}

export interface ICalculatorResult {
  id: number
  customerGroup: string
  vcost: string
  fee: string
  feeStrategy: string
  adqCost: string
  vsPrice: string
  retailPrice: string
  wholesalePrice: string
  actions?: JSX.Element
}

export interface ISummaryStep {
  publishDate: ISummaryStepProps["publishDate"]
}

export const providerStepInitialValues: IProviderStep = {
  supplierName: "",
  supplierProductId: "",
  manufacturer: "",
  upc: "",
  isStepValid: false,
}

export const productStepInitialValues: IProductStep = {
  productName: "",
  ecomName: "",
  vsId: "",
  purchaseSize: "",
  productSize: "",
  packaging: "",
  breakdown: 0,
  majorCategoryName: "",
  categoryName: "",
  feeStrategy: "",
  marsFeeStrategy: "",
  map: 0,
  exception: "",
  banFeeStrategy: "",
  vcost: 0,
  vetList: 0,
  srpRuleName: "",
  specieName: "",
  vipMarkerName: "",
  weight: 0,
  rxrequired: false,
  instantrebate: 0,
  isStepValid: false,
}

export const ProviderStepInitialValuesSchema = Yup.object().shape({
  supplierName: Yup.string().required(translations.general.fieldRequired),
  supplierProductId: Yup.string().required(translations.general.fieldRequired),
  manufacturer: Yup.string().required(translations.general.fieldRequired),
  upc: Yup.string().optional(),
})

export const ProductStepInitialValuesSchema = Yup.object().shape({
  productName: Yup.string().required(translations.general.fieldRequired),
  ecomName: Yup.string().required(translations.general.fieldRequired),
  vsId: Yup.string().min(6, translations.general.fieldRequired).required(translations.general.fieldRequired),
  majorCategoryName: Yup.string().required(translations.general.fieldRequired),
  categoryName: Yup.string().required(translations.general.fieldRequired),
  vipMarkerName: Yup.string().required(translations.general.fieldRequired),
  srpRuleName: Yup.string().required(translations.general.fieldRequired),
  specieName: Yup.string().required(translations.general.fieldRequired),
  banFeeStrategy: Yup.string().optional(),
  feeStrategy: Yup.string().optional(),
  marsFeeStrategy: Yup.string().optional(),
  map: Yup.number().required(translations.general.fieldRequired),
  exception: Yup.string().optional(),
  vcost: Yup.number().required(translations.general.fieldRequired),
  vetList: Yup.number().optional(),
  purchaseSize: Yup.string().required(translations.general.fieldRequired),
  productSize: Yup.string().required(translations.general.fieldRequired),
  weight: Yup.string().required(translations.general.fieldRequired),
  rxrequired: Yup.boolean().required(translations.general.fieldRequired),
  instantrebate: Yup.number().required(translations.general.fieldRequired),
  packaging: Yup.string().required(translations.general.fieldRequired),
  breakdown: Yup.number().required(translations.general.fieldRequired),
  marginFocused: Yup.number()
    .min(-100, translations.general.fieldRequired)
    .max(100, translations.general.fieldRequired),
  equineFee: Yup.number(),
  equineRetailMarkUp: Yup.number(),
})

export const CustomersAndPricingStepInitialValuesSchema = Yup.object().shape({
  customerGroups: Yup.array().required(translations.general.fieldRequired),
})

export const SummaryStepInitialValuesSchema = Yup.object().shape({
  publishDate: Yup.date().required(translations.general.fieldRequired),
})

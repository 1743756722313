import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing } from "../theme"

export const StepGroupButtonTheme = createTheme({
  palette,
  spacing,
  overrides: {
    MuiButton: {
      outlined: {
        padding: spacing(3),
        border: `1px solid ${palette.grey[300]}`,
        minWidth: rem("152px"),
        lineHeight: rem("25px"),
        textTransform: "capitalize",
        "&.active": {
          backgroundColor: palette.primary.light,
          borderColor: palette.primary.main,
        },
      },
    },
  },
})

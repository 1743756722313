import type { UseQueryResult } from "react-query"
import { useQueries } from "react-query"

import type {
  IAdjustment,
  IAdjustmentTable,
  IASCMarkupResponse,
  IASCMarkupTable,
  IASCShippingEstimateResponse,
  IASCShippingEstimateTable,
  ILevelRequest,
  ILevelResponse,
  IUpdateAdjustmentParams,
  IUpdateMarkupParams,
  IUpdateShippingEstimateParams,
} from "modules/Parameters/models/parameters"
import { api } from "./api"

export const getAdjustments = async (): Promise<IAdjustmentTable[]> => {
  const { data } = await api.parameters.getAdjustments()

  return data
}

export const getLevels = async (): Promise<ILevelResponse[]> => {
  const { data } = await api.parameters.getLevels()
  return data
}

export const getMarkups = async (id: number): Promise<IASCMarkupResponse[]> => {
  const { data } = await api.parameters.getMarkups(id)

  return data
}

export const getShippingEstimates = async (id: number): Promise<IASCShippingEstimateResponse[]> => {
  const { data } = await api.parameters.getShipping(id)

  return data
}

export const updateLevel = async (levelId: number, levelData: ILevelRequest): Promise<ILevelResponse[]> => {
  const { data } = await api.parameters.updateLevel(levelId, levelData)
  return data
}

export const updateMarkup = async (params: IUpdateMarkupParams): Promise<IUpdateMarkupParams> => {
  const { data } = await api.parameters.updateMarkup(params)

  return data
}

export const updateShipping = async (params: IUpdateShippingEstimateParams): Promise<IUpdateShippingEstimateParams> => {
  const { data } = await api.parameters.updateShipping(params)

  return data
}

export const updateAdjustment = async (params: IUpdateAdjustmentParams): Promise<IAdjustment> => {
  const { data } = await api.parameters.updateAdjustment(params)

  return data
}

/**
 * @todo remove this hard-code
 */
const SUPPLIER_ID = 3

export const useParameterListQueries = (): Readonly<
  [Readonly<[IAdjustmentTable[], ILevelResponse[], IASCMarkupTable[], IASCShippingEstimateTable[]]>, boolean]
> => {
  const response = useQueries([
    { queryKey: "getAdjustments", queryFn: getAdjustments },
    { queryKey: "getLevels", queryFn: getLevels },
    { queryKey: "getMarkups", queryFn: () => getMarkups(SUPPLIER_ID) },
    {
      queryKey: "getShippingEstimates",
      queryFn: () => getShippingEstimates(SUPPLIER_ID),
    },
  ])

  const [adjustmentsResult, levelsResult, markupsResult, shippingEstimatesResult] = response as [
    UseQueryResult<IAdjustmentTable[]>,
    UseQueryResult<ILevelResponse[]>,
    UseQueryResult<IASCMarkupResponse[]>,
    UseQueryResult<IASCShippingEstimateResponse[]>
  ]

  return [
    [
      (adjustmentsResult.data || []).map(item => ({
        ...item,
        adjustment: item.value,
      })),
      levelsResult.data || [],
      (markupsResult.data || []).map(item => ({
        ...item,
        markup: item.value,
      })),
      shippingEstimatesResult.data || [],
    ],
    adjustmentsResult.isLoading ||
      levelsResult.isLoading ||
      markupsResult.isLoading ||
      shippingEstimatesResult.isLoading,
  ] as const
}

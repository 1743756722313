import * as React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

import { LocationStateWrapper } from "layouts"
import { Layout } from "modules/Layout/components/Layout"
import { ProductsList } from "modules/Products/containers/ProductsList"
import { CustomersList } from "modules/Customers/containers/CustomersList"
import { SupplierList } from "modules/Suppliers/containers/SupplierList"
import { Calculator } from "modules/Rules-Pricing/containers/Calculator"
import { NewCalculator } from "modules/Rules-Pricing/containers/NewCalculator"
import { RulesEditor } from "modules/Rules-Pricing/containers/RulesEditor"
import { CalculatorResultList } from "modules/Rules-Pricing/containers/CalculatorResultList"
import { NewProductWizard } from "modules/Products/containers/NewProductWizard"
import { UpdateProductWizard } from "modules/Products/containers/UpdateProductWizard"
import { getAuthCookie } from "utils/user"
import { SignIn } from "modules/SignIn/containers/SignIn"
import { ROUTES } from "constants/routes"
import { CompetitorsList } from "modules/Competitors/containers/CompetitorsList"
import { ParameterList } from "modules/Parameters/containers/ParameterList"
import { SettingsForm } from "modules/Settings/containers/SettingsForm"
import { RulesPricing } from "modules/Rules-Pricing/containers/RulesPricing"
import { StepGroup } from "modules/Rules-Pricing/containers/StepGroup"
import { StepGroupRulesUpsert } from "modules/Rules-Pricing/containers/StepGroupRulesUpsert"
import { Events } from "./Events/Events"

/**
 * A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
 * @param children JSX.Element
 * @param path string
 * @param exact boolean
 */
const PrivateRoute = ({
  children,
  path,
  exact,
}: {
  children: JSX.Element
  path: string
  exact?: boolean
}): JSX.Element => {
  const cookie = getAuthCookie()

  return (
    <Layout>
      <Route
        path={path}
        exact={exact}
        render={({ location }) =>
          cookie ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.SIGN_IN.path,
                state: { from: location },
              }}
            />
          )
        }
      />
    </Layout>
  )
}

PrivateRoute.defaultProps = {
  exact: false,
}

const Routes = (): JSX.Element => (
  <Switch>
    <Route exact path={ROUTES.SIGN_IN.path}>
      <SignIn />
    </Route>
    <Route exact path={ROUTES.HEALTH.path}>
      <div>{JSON.stringify({ status: "UP" })}</div>
    </Route>
    <PrivateRoute exact path={ROUTES.HOME.path}>
      <Redirect to={ROUTES.RULES_PRICING.path} />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.PRODUCTS.path}>
      <ProductsList />
    </PrivateRoute>
    <PrivateRoute path={ROUTES.NEW_PRODUCT.path}>
      <NewProductWizard />
    </PrivateRoute>
    <PrivateRoute path={ROUTES.UPDATE_PRODUCT.path}>
      <UpdateProductWizard />
    </PrivateRoute>
    <PrivateRoute path={ROUTES.CUSTOMERS.path}>
      <CustomersList />
    </PrivateRoute>
    <PrivateRoute path={ROUTES.SUPPLIERS.path}>
      <SupplierList />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.RULES_PRICING.path}>
      <RulesPricing />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.STEP_GROUPS.path}>
      <Redirect to={{ pathname: ROUTES.RULES_PRICING.path, state: { tab: 1 } }} />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.NEW_CALCULATOR.path}>
      <NewCalculator />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.CALCULATOR_EDITOR.path}>
      <Calculator />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.STEP_GROUP_EDITOR.path}>
      <StepGroup />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.CALCULATOR_RESULTS.path}>
      <CalculatorResultList />
    </PrivateRoute>
    <PrivateRoute path={ROUTES.CALCULATOR_RULE_UPSERT.path}>
      <LocationStateWrapper to={ROUTES.CALCULATOR_RULE_UPSERT.path} push={false}>
        <RulesEditor />
      </LocationStateWrapper>
    </PrivateRoute>
    <PrivateRoute path={ROUTES.STEP_GROUP_RULE_UPSERT.path}>
      <StepGroupRulesUpsert />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.EVENTS.path}>
      <Redirect to={{ pathname: ROUTES.CALCULATION_EVENTS.path }} />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.CALCULATION_EVENTS.path}>
      <Events />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.IMPORT_EVENTS.path}>
      <Events />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.PUBLICATION_EVENTS.path}>
      <Events />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.SCHEDULED_PUBLICATIONS_EVENTS.path}>
      <Events />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.CALCULATION_CHILDREN_EVENTS.path}>
      <Events />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.IMPORT_CHILDREN__EVENTS.path}>
      <Events />
    </PrivateRoute>
    <PrivateRoute exact path={ROUTES.PUBLICATION_CHILDREN_EVENTS.path}>
      <Events />
    </PrivateRoute>
    <PrivateRoute path={ROUTES.COMPETITORS.path}>
      <CompetitorsList />
    </PrivateRoute>
    <PrivateRoute path={ROUTES.PARAMETERS.path}>
      <ParameterList />
    </PrivateRoute>
    <PrivateRoute path={ROUTES.SETTINGS.path}>
      <SettingsForm />
    </PrivateRoute>
    <PrivateRoute path="*">
      <p>Page not found</p>
    </PrivateRoute>
  </Switch>
)

export { Routes }

import React from "react"
import { Typography, Box } from "@material-ui/core"
import { useStyles } from "./styles"

export interface IStatusChip {
  active: boolean
}

export const StatusChip = ({ active }: IStatusChip): JSX.Element => {
  const classes = useStyles(active)

  return (
    <Box className={classes.container}>
      <Box className={classes.color} data-testid="statusChipColor" />
      <Typography className={classes.status} data-testid="statusChip">
        {active ? "Active" : "Inactive"}
      </Typography>
    </Box>
  )
}

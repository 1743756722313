import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const ButtonTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "4px",
        boxShadow: "none !important",
        minWidth: rem("140px"),
        padding: spacing(1, 3),
        whiteSpace: "nowrap",
        "& svg": {
          width: "20px",
          marginRight: spacing(2),
        },
      },
      sizeLarge: {
        height: rem("40px"),
        lineHeight: rem("24px"),
        fontSize: rem("16px"),
      },
      sizeSmall: {
        minWidth: rem("115px"),
        height: rem("30px"),
        lineHeight: rem("20px"),
        fontSize: rem("14px"),
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: palette.secondary.dark,
        },
        "&:active": {
          backgroundColor: palette.secondary.light,
        },
        "& svg": {
          fill: palette.secondary.contrastText,
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: palette.primary.dark,
        },
        "&:active": {
          backgroundColor: palette.primary.light,
        },
        "& svg": {
          fill: palette.primary.contrastText,
        },
      },
      outlinedPrimary: {
        "& svg": {
          fill: palette.primary.main,
        },
      },
      outlinedSecondary: {
        "& svg": {
          fill: palette.secondary.main,
        },
      },
      textSizeSmall: {
        minWidth: "unset !important",
      },
      text: {
        transition: "color 0.25s ease",
        padding: `${spacing(0)} !important`,
        "&:hover": {
          backgroundColor: "transparent !important",
        },
        "&:active": {
          color: palette.text.hint,
        },
      },
    },
  },
})

import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography } from "../../theme"

export const FormControlTheme = createTheme({
  palette,
  typography,
  overrides: {
    MuiList: {
      padding: {
        padding: `0 !important`,
      },
    },
    MuiSelect: {
      root: {
        display: "flex",
        alignItems: "center",
        gridGap: spacing(1),
        "& em": {
          fontStyle: "normal",
          fontSize: rem("14px"),
          fontWeight: typography.fontWeightRegular,
          color: palette.grey[600],
          opacity: 1,
        },
      },
      select: {
        minWidth: rem("100px"),
        padding: spacing(1),
        fontSize: typography.body2.fontSize,
        fontWeight: typography.fontWeightRegular as number,
        lineHeight: rem("14px"),
        height: rem("30px"),
        color: palette.text.secondary,
        backgroundColor: palette.background.default,
        "&:focus": {
          backgroundColor: palette.background.default,
          borderRadius: "4px",
        },
      },
    },
    MuiInput: {
      root: {
        minHeight: rem("40px"),
        border: `1px solid ${palette.grey[600]}`,
        marginTop: `0px !important`,
        borderRadius: "4px",
        "&.Mui-focused": {
          borderColor: palette.primary.main,
          "&& svg": {
            fill: palette.primary.main,
          },
        },
        "&.Mui-error": {
          borderColor: palette.error.main,
          color: palette.error.main,
          "&& svg": {
            fill: palette.error.main,
          },
        },
        "&.Mui-disabled": {
          backgroundColor: palette.background.default,
          borderColor: palette.grey[300],
          "& svg": {
            fill: palette.grey[300],
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: `none !important`,
        },
        "&:after": {
          borderBottom: `none !important`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: `0px !important`,
        margin: spacing(0, 1, 0, 0),
      },
    },

    MuiPaper: {
      root: {
        backgroundColor: palette.background.default,
        borderColor: palette.grey[500],
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "5px",
        top: `67px !important`,
      },
    },
    MuiInputLabel: {
      shrink: {
        color: palette.grey[800],
        position: "static",
        transform: "none !important",
        marginBottom: spacing(1),
        fontWeight: typography.fontWeightRegular as number,
        fontSize: rem("12px"),
      },
    },
    MuiInputAdornment: {
      root: {
        "& button[class*='MuiIconButton-root']": {
          padding: spacing(1),
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: spacing(2),
      },
    },
  },
})

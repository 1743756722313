import { AccordionDetails, Box, styled } from "@material-ui/core"
import { palette, spacing } from "components/theme"
import { rem } from "polished"

export const ButtonContainer = styled(Box)(() => ({
  "& button": {},
}))

export const RuleContainer = styled(Box)(() => ({
  borderRadius: 10,
  border: `1px solid ${palette.grey[400]}`,
  padding: spacing(2),
  margin: spacing(2, 0),
}))

export const RuleInputContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "& button": {
    margin: spacing(1),
  },
}))

export const RuleContent = styled(Box)(() => ({
  margin: spacing(2, 0, 0),
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
}))

export const RuleSelectContainerLarge = styled(Box)(() => ({
  width: rem("160px"),
  padding: spacing(1),
}))

export const RuleSelectContainerSmall = styled(Box)(() => ({
  width: rem("95px"),
  padding: spacing(1),
}))

export const RuleDetailsContainer = styled(AccordionDetails)(() => ({
  justifyContent: "flex-start",
  display: "flex",
  flexDirection: "column",
  padding: `${spacing(2)}px !important`,
}))

export const CalculatorResultButtonHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  position: "relative",
  top: rem("-28px"),
  marginBottom: "-40px",
}))

export const CalculationErrorButton = styled(Box)(() => ({
  top: "5px",
  position: "relative",
  "& :hover": {
    backgroundColor: palette.info.light,
  },
  "& button": {
    fontSize: "13px",
    textTransform: "uppercase",

    "& svg": {
      marginRight: spacing(1),
    },
  },
}))

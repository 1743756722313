import * as React from "react"

import { IRuleArgument, IRuleAttributeArgument, IRuleState, RuleSelectProps } from "typings/modules"
import { Input } from "components"
import { CalculatorConditionValue, OperationTypes } from "constants/calculator"
import { getCalculatorStepRuleAttributes } from "utils/calculators"
import { RuleContent, RuleSelectContainerLarge, RuleSelectContainerSmall } from "../containers/styles"
import { CONDITIONAL_OPERATORS, CONDITIONAL_OPTIONS, NUMERICAL_OPERATORS } from "../constants/calculator"
import { RuleSelect } from "./RuleSelect"
import useCalculatorRules from "../hooks/use-calculator-rules"
import useStepGroupRules from "../hooks/use-step-group-rules"

const isInputField = (field: OperationTypes) => {
  const allowed = [OperationTypes.INPUT, OperationTypes.NUMBER, OperationTypes.STRING]

  const isValid = allowed.includes(field)

  return isValid
}

type RuleControlProps = {
  rule: IRuleState
  canEditCondition: boolean
  handleChange: (keyName: keyof IRuleState, value: string) => void
  disabled?: boolean
}

const RuleStatusField = (props: RuleControlProps) => {
  const { rule, handleChange: updater, canEditCondition, disabled = false } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    updater(name as keyof IRuleState, value)
  }

  const defaultValue = rule.conditionValue || ""

  const value = defaultValue === CalculatorConditionValue.SELECTIVE ? "If" : "-"

  return (
    <RuleSelectContainerSmall>
      {rule.id || canEditCondition ? (
        <Input label="Conditions" name="conditionValue" value={value} isDisabled />
      ) : (
        <RuleSelect
          label="Conditions"
          name="conditionValue"
          value={defaultValue}
          options={CONDITIONAL_OPTIONS}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </RuleSelectContainerSmall>
  )
}

type RuleArgumentProps = {
  argKey: keyof IRuleAttributeArgument
  handleChange: (argKey: keyof IRuleAttributeArgument, propKey: keyof IRuleArgument, value: string) => void
} & Pick<RuleControlProps, "rule" | "disabled">

const StepGroupRuleArgument = (props: RuleArgumentProps) => {
  const { rule, argKey, handleChange: updater, disabled = false } = props

  const { systemObjectOptions, step, steps } = useStepGroupRules()

  const argItem = React.useMemo(() => {
    let item = rule[argKey]

    if (item) {
      return item
    }

    item = ({ value: "" } as IRuleArgument) as Required<IRuleArgument>

    return item
  }, [rule, argKey])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target

    updater(argKey, name as keyof IRuleArgument, value)
  }

  const isField = isInputField(argItem.type)

  const attributeOptions = React.useMemo(() => {
    if (isField) {
      return []
    }

    const options = getCalculatorStepRuleAttributes({
      argument: argItem,
      ruleState: rule,
      step,
      steps,
      systemObjectOptions,
    })

    return options
  }, [isField, argItem, rule, step, steps, systemObjectOptions])

  return (
    <>
      <RuleSelectContainerLarge>
        <RuleSelect
          name="value"
          label="Attributes"
          value={argItem.value}
          options={systemObjectOptions}
          onChange={handleChange}
          disabled={disabled}
        />
      </RuleSelectContainerLarge>
      {typeof argItem.attribute === "string" && (
        <RuleSelectContainerLarge>
          {isField ? (
            <Input
              name="attribute"
              placeholder=""
              value={argItem.attribute}
              onChange={handleChange}
              isDisabled={disabled}
            />
          ) : (
            <RuleSelect
              name="attribute"
              label="Attributes"
              value={argItem.attribute}
              options={attributeOptions}
              onChange={handleChange}
              disabled={disabled}
            />
          )}
        </RuleSelectContainerLarge>
      )}
    </>
  )
}

const CalculatorRuleArgument = (props: RuleArgumentProps) => {
  const { rule, argKey, handleChange: updater, disabled = false } = props

  const { systemObjectOptions, step, steps } = useCalculatorRules()

  const argItem = React.useMemo(() => {
    let item = rule[argKey]

    if (item) {
      return item
    }

    item = ({ value: "" } as IRuleArgument) as Required<IRuleArgument>

    return item
  }, [rule, argKey])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target

    updater(argKey, name as keyof IRuleArgument, value)
  }

  const isField = isInputField(argItem.type)

  const attributeOptions = React.useMemo(() => {
    if (isField) {
      return []
    }

    const options = getCalculatorStepRuleAttributes({
      argument: argItem,
      ruleState: rule,
      step,
      steps,
      systemObjectOptions,
    })

    return options
  }, [isField, argItem, rule, step, steps, systemObjectOptions])

  return (
    <>
      <RuleSelectContainerLarge>
        <RuleSelect
          name="value"
          label="Attributes"
          value={argItem.value}
          options={systemObjectOptions}
          onChange={handleChange}
          disabled={disabled}
        />
      </RuleSelectContainerLarge>
      {typeof argItem.attribute === "string" && (
        <RuleSelectContainerLarge>
          {isField ? (
            <Input
              name="attribute"
              placeholder=""
              value={argItem.attribute}
              onChange={handleChange}
              isDisabled={disabled}
            />
          ) : (
            <RuleSelect
              name="attribute"
              label="Attributes"
              value={argItem.attribute}
              options={attributeOptions}
              onChange={handleChange}
              disabled={disabled}
            />
          )}
        </RuleSelectContainerLarge>
      )}
    </>
  )
}

const RuleOperator = (props: Omit<RuleControlProps, "canEditCondition">) => {
  const { rule, handleChange: updater, disabled = false } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    updater("operator", value)
  }

  let options: RuleSelectProps["options"] = NUMERICAL_OPERATORS

  if (rule.conditionValue === CalculatorConditionValue.SELECTIVE) {
    options = CONDITIONAL_OPERATORS
  }

  return (
    <RuleSelectContainerLarge>
      <RuleSelect
        name="operator"
        label="Operations"
        value={rule.operator}
        onChange={handleChange}
        options={options}
        disabled={disabled}
      />
    </RuleSelectContainerLarge>
  )
}

const CalculatorRuleConditional = (props: Omit<RuleArgumentProps, "argKey">) => {
  const { rule, handleChange: updater, disabled = false } = props

  return (
    <>
      <RuleContent>
        <RuleSelectContainerSmall>
          <Input label="Conditions" value="Then" isDisabled />
        </RuleSelectContainerSmall>
        <CalculatorRuleArgument
          key="if-conditional"
          rule={rule}
          handleChange={updater}
          argKey="thirdArgument"
          disabled={disabled}
        />
      </RuleContent>
      <RuleContent>
        <RuleSelectContainerSmall>
          <Input label="Conditions" value="Else" isDisabled />
        </RuleSelectContainerSmall>
        <CalculatorRuleArgument
          key="else-conditional"
          rule={rule}
          handleChange={updater}
          argKey="fourthArgument"
          disabled={disabled}
        />
      </RuleContent>
    </>
  )
}

const StepGroupRuleConditional = (props: Omit<RuleArgumentProps, "argKey">) => {
  const { rule, handleChange: updater, disabled = false } = props

  return (
    <>
      <RuleContent>
        <RuleSelectContainerSmall>
          <Input label="Conditions" value="Then" isDisabled />
        </RuleSelectContainerSmall>
        <StepGroupRuleArgument
          key="if-conditional"
          rule={rule}
          handleChange={updater}
          argKey="thirdArgument"
          disabled={disabled}
        />
      </RuleContent>
      <RuleContent>
        <RuleSelectContainerSmall>
          <Input label="Conditions" value="Else" isDisabled />
        </RuleSelectContainerSmall>
        <StepGroupRuleArgument
          key="else-conditional"
          rule={rule}
          handleChange={updater}
          argKey="fourthArgument"
          disabled={disabled}
        />
      </RuleContent>
    </>
  )
}

export {
  RuleStatusField,
  StepGroupRuleArgument,
  RuleOperator,
  CalculatorRuleArgument,
  CalculatorRuleConditional,
  StepGroupRuleConditional,
}

export const translations = {
  general: {
    fieldRequired: "Field is required",
    somethingWrong: "Something went wrong",
    changesSaved: "Your changes have been saved",
    valuesEditable: "Amounts in blue are editable",
    success: "Your changes have been saved",
    calculatorRunning: "The calculator is already running",
  },
  alert: {
    calculator: {
      success: "Your changes have been saved",
      stepGroup: {
        success: "You've successfully created a Rule Set",
      },
      step: {
        success: "You've successfully created a group of rules",
        error: "Error fetching list of rule groups",
      },
      rule: {
        error: "Error fetching list of rules",
      },
      systemObject: {
        error: "Error fetching system object options",
      },
      priceHistory: {
        info: "You don't have a price history, please run the calculator",
      },
      calculation: {
        error: "Calculation failed",
      },
    },
    signIn: {
      error: "Authentication failed",
    },
    bulk: {
      processing: "Importing Data",
      success: "Your data has been imported",
      error: "Data import failed",
      deleted: "Item has been successfully deleted",
    },
  },
} as const

import { Portal } from "@material-ui/core"
import * as React from "react"

import { Alert, IAlertProps } from "./Alert"

const UncontrolledAlert = (props: IAlertProps) => {
  const [isShown, setIsShown] = React.useState(true)

  React.useEffect(() => {
    let timer: NodeJS.Timeout = null

    if (isShown) {
      timer = setTimeout(() => {
        setIsShown(false)

        clearTimeout(timer)
      }, 10000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isShown])

  if (isShown) {
    const { action, severity, children } = props

    return (
      <Portal>
        <Alert action={action} severity={severity}>
          {children}
        </Alert>
      </Portal>
    )
  }

  return null
}

export { UncontrolledAlert }

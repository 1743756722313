import type { IStepGroupRequest } from "typings/modules"
import type { IStepGroupType } from "modules/Rules-Pricing/models/calculator"
import { api } from "./api"

export const getStepGroupTypes = async (calculatorId?: number): Promise<IStepGroupType[]> => {
  const { data } = await api.stepGroupTypes.get(calculatorId)

  return data
}

export const getMasterStepGroupTypes = async (calculatorId?: number) => {
  const { data } = await api.stepGroupTypes.getMasters(calculatorId)

  return data
}

export const getStepGroups = async () => {
  const response = await api.stepGroups.getAll()

  return response.data
}

export const getStepGroupById = async (stepGroupId: number) => {
  const response = await api.stepGroups.getById(stepGroupId)

  return response.data
}

export const upsertStepGroup = async (stepGroup: IStepGroupRequest, calculatorId?: number) => {
  const response = await api.stepGroups.upsert(stepGroup, calculatorId)

  return response
}

export const deleteStepGroup = async (stepGroupId: number) => {
  const response = await api.stepGroups.delete(stepGroupId)

  return response
}

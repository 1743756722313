import * as React from "react"
import { Box } from "@material-ui/core"
import { GridCellParams, GridCellValue, GridColDef } from "@mui/x-data-grid"
import { DeleteOutlined, EditOutlined } from "@material-ui/icons"

import { IconButton } from "components"
import { leftNonEditableColumn } from "modules/TableGlobalStyles"
import { productsAmountValueFormatter } from "utils/table"
import { ISupplier, ISupplierListState } from "../models/supplier"

const getColumns = (
  updater: (newState: Partial<ISupplierListState>) => void,
  userHasReadWriteRole: boolean
): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "Supplier ID",
      flex: 0.3,
      ...leftNonEditableColumn,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.7,
      ...leftNonEditableColumn,
    },
    {
      field: "productsCount",
      headerName: "Supplying",
      flex: 0.6,
      type: "string",
      ...leftNonEditableColumn,
      valueFormatter: productsAmountValueFormatter,
      valueParser: (value: GridCellValue) => Number(value),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.25,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      filterable: false,
      renderCell: ({ row }: GridCellParams): JSX.Element => {
        return (
          <Box>
            <IconButton
              color="inherit"
              size="small"
              icon={<EditOutlined />}
              onClick={() => updater({ modal: "update-supplier", data: row as ISupplier })}
              isDisabled={!userHasReadWriteRole}
            />
            <IconButton
              color="inherit"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => updater({ modal: "delete-supplier", data: row as ISupplier })}
              isDisabled={!userHasReadWriteRole}
            />
          </Box>
        )
      },
    },
  ]
}

export { getColumns }

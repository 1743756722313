import React from "react"
import { Dialog, DateInput } from "components"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { StyledPublishDate } from "../styles"

export interface IPublishResultsDialogProps {
  isOpen: boolean
  onClose: () => void
  onPublish: () => void
  publishDate?: MaterialUiPickersDate | null
  onPublishDateChange: (date: MaterialUiPickersDate | null, value?: string | null) => void
}

const PublishResultsDialog = (props: IPublishResultsDialogProps) => {
  const { isOpen, onClose, onPublish, publishDate, onPublishDateChange } = props

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title="Set Publish Date"
      dialogContentText={<>Please, set the date for publishing these new product pricing.</>}
      secondaryActionLabel="Cancel"
      primaryActionLabel="Confirm"
      primaryActionHandler={onPublish}
      secondaryActionHandler={onClose}
      maxWidth="xs"
      fullWidth
    >
      <StyledPublishDate>
        <DateInput
          label="Publish on"
          selectedDate={publishDate}
          handleDateChange={onPublishDateChange}
          minDate={new Date()}
        />
      </StyledPublishDate>
    </Dialog>
  )
}
export { PublishResultsDialog }

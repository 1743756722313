import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"

const theme = createTheme({
  palette: {
    primary: {
      main: "hsl(197,79%,49%)",
      contrastText: "hsl(0,0%,100%)",
      light: "hsla(197, 47%, 75%, 1)",
    },
    secondary: {
      main: "hsl(69, 61%, 52%)",
      contrastText: "hsl(0,0%,100%)",
      light: "hsla(220, 21%, 10%, 0.5)",
    },

    background: {
      default: "hsl(0,0%,100%)",
    },

    grey: {
      100: "hsl(0,0%,96%)", // G10
      200: "hsl(0,0%,93%)", // G20 disable default
      300: "hsl(0,0%,88%)", // G30
      400: "hsl(0,0%,74%)", // G40
      500: "hsl(0,0%,62%)", // G50
      600: "hsl(0,0%,46%)", // G60
      800: "hsl(0,0%,26%)", // G80
      900: "hsl(0,0%,13%)", // G90
    },
    text: {
      primary: "hsl(0,0%,26%)",
      secondary: "hsl(0,0%,46%)",
    },
    success: {
      main: "hsla(79, 61%, 52%, 1)",
      light: "hsla(79, 56%, 85%, 1)",
      dark: "hsla(69, 93%, 29%, 1)",
    },
    error: {
      main: "hsla(0, 100%, 40%, 1)",
      light: "hsla(0, 100%, 90%, 1)",
      dark: "hsla(4, 100%, 71%, 1)",
      contrastText: "hsla(4, 100%, 71%, 0.5)",
    },
    warning: {
      main: "hsla(54, 100%, 48%, 1)",
      light: "hsla(54, 100%, 88%, 1)",
      dark: "hsla(54, 92%, 44%, 1)",
    },
    info: {
      main: "hsla(196, 76%, 95%, 1)",
      light: "hsla(196, 50%, 98%, 1)",
      dark: "hsl(274, 24%, 39%)",
    },
  },
  typography: {
    fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: rem("36px"),
      fontWeight: 700,
    },
    h2: {
      fontSize: rem("24px"),
      fontWeight: 700,
    },
    h3: {
      fontSize: rem("18px"),
      fontWeight: 700,
      color: "hsla(227, 25%, 14%, 1)",
    },
    h4: {
      fontSize: rem("16px"),
      fontWeight: 700,
      lineHeight: rem("19px"),
      textTransform: "uppercase",
    },
    h5: {
      fontSize: rem("16px"),
      fontWeight: 400,
      lineHeight: rem("19px"),
    },
    body1: {
      fontSize: rem("14px"),
      fontWeight: 400,
    },
    body2: {
      fontSize: rem("14px"),
      fontWeight: 700,
    },
    caption: {
      fontSize: rem("12px"),
      lineHeight: rem("12px"),
      fontWeight: 400,
      color: "hsl(0,0%,46%)",
    },
  },
  props: {
    MuiTableCell: {
      padding: "none",
    },
  },
  spacing: [0, 8, 16, 24, 32, 48, 64, 96, 117, 0, 0],
  shadows: [
    "none",
    "0px 6px 13px rgba(21, 33, 56, 0.533345)",
    "8px 8px 16px 4px rgba(133, 139, 146, 0.06)",
    "0px 4px 19px hsla(199, 88%, 93%, 1)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
})

const { palette, typography, spacing } = theme

export { theme, palette, typography, spacing }

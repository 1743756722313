import type { FormikProps } from "formik"
import * as React from "react"
import { useHistory } from "react-router"
import { useDispatch } from "react-redux"
import { useMutation } from "react-query"
import _ from "lodash"
import { ThemeProvider } from "@material-ui/core"
import { GridRowId, GridRowModel, GridSelectionModel } from "@mui/x-data-grid"

import { uiActions } from "store/ui-slice"
import { WizardContainer, WizardTheme, WizardTitle } from "modules/Products/styles"
import { wizardSteps } from "modules/Products/constants/wizard"
import { ROUTES } from "constants/routes"
import { createProduct } from "services"
import { useCustomerGroups } from "services/hooks/useCustomerGroups"
import { columns } from "modules/Customers/constants/customerGroupsTable"
import { getStringIndexBySelectionModel } from "utils/table"
import { ICustomerGroup } from "modules/Customers/models/customerGroup"
import { getProductFromForm } from "utils/products"
import { IAction } from "models/store"
import {
  IProductStep,
  IProviderStep,
  productStepInitialValues,
  StepAction,
  providerStepInitialValues,
  ICustomerGroupStep,
} from "../models/newProductWizard"
import {
  AddCustomerGroupModal,
  CustomersAndPricingStep,
  DeleteCustomerGroupDialog,
  ProductStep,
  ProviderStep,
  WizardStepper,
} from "../components"
import { IProductData } from "../models/product"

const providerStepReducer = (prevState: IProviderStep, action: IAction): IProviderStep => {
  if (action.type === StepAction.FORM_CHANGED) {
    return {
      ...prevState,
      ...action.payload,
    }
  }
  return providerStepInitialValues
}

const productStepReducer = (prevState: IProductStep, action: IAction): IProductStep => {
  if (action.type === StepAction.FORM_CHANGED) {
    return {
      ...prevState,
      ...action.payload,
    }
  }
  return productStepInitialValues
}

const NewProductWizard = (): JSX.Element => {
  const dispatch = useDispatch()

  const history = useHistory()

  const [activeStep, setActiveStep] = React.useState(0)

  const [addCustomerModalOpen, setAddCustomerModalOpen] = React.useState(false)

  const [customerGroups, setCustomerGroups] = React.useState<ICustomerGroup[]>([])

  const [customerGroupRows, setCustomerGroupRows] = React.useState<GridRowModel[]>([])

  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowId[]>([])

  const [removeCustomerGroupModalOpen, setRemoveCustomerGroupModalOpen] = React.useState(false)

  const [customerGroupToDelete, setCustomerGroupToDelete] = React.useState<ICustomerGroup>()

  const [providerStepState, dispatchProviderStep] = React.useReducer(providerStepReducer, providerStepInitialValues)

  const [productStepState, dispatchProductStep] = React.useReducer(productStepReducer, productStepInitialValues)

  const { data, isLoading } = useCustomerGroups()

  const { mutate } = useMutation((productData: IProductData) => createProduct(productData), {
    onSuccess: () => {
      history.push(ROUTES.PRODUCTS.path)
    },
    onError: (error: string) => {
      dispatch(
        uiActions.showNotification({
          children: error.toString(),
          severity: "error",
        })
      )
    },
  })

  const handleNext = (): void => {
    if (activeStep === 2) {
      const payload: IProductData = {
        ...getProductFromForm(productStepState, providerStepState),
        customerGroups: _.map(customerGroups, item => ({ id: item.id })),
      }

      mutate(payload)

      return
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const removeItemFromTableSelection = (listItem: ICustomerGroup) => {
    const currentRowSelectionModel = [...rowSelectionModel]

    const selection = currentRowSelectionModel.filter(
      selectionModel => getStringIndexBySelectionModel(selectionModel) !== listItem.id
    )

    setRowSelectionModel([...selection])
  }

  const removeCustomerGroup = () => {
    if (customerGroupToDelete) {
      removeItemFromTableSelection(customerGroupToDelete)

      if (customerGroups?.length) {
        const currentList = [...customerGroups]
        const optionIndex = _.findIndex(currentList, option => option.id === customerGroupToDelete.id)
        currentList.splice(optionIndex, 1)
        setCustomerGroups([...currentList])
        setCustomerGroupToDelete(undefined)
      }
      setRemoveCustomerGroupModalOpen(false)
    }
  }

  const removeCustomerGroupHandler = (customerGroup: ICustomerGroup) => {
    setRemoveCustomerGroupModalOpen(true)
    setCustomerGroupToDelete({ ...customerGroup })
  }

  const addCustomerGroupHandler = (selectionModel: GridSelectionModel) => {
    setRowSelectionModel(selectionModel)
    const selection = selectionModel.map((index): GridRowModel | undefined => {
      const selectionIndex = getStringIndexBySelectionModel(index)
      return customerGroupRows.find(row => row.id === selectionIndex)
    })
    setCustomerGroups([...selection] as ICustomerGroup[])
  }

  React.useEffect(() => {
    if (data?.length) {
      setCustomerGroupRows([...data] as GridRowModel[])
    }
  }, [data])

  const formikRef = React.useRef({} as FormikProps<unknown>)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    formikRef.current?.handleSubmit(event)
  }

  const children = {
    1: (
      <ProviderStep
        ref={formikRef as React.MutableRefObject<FormikProps<IProviderStep>>}
        handleNext={handleNext}
        handleBack={handleBack}
        dispatchProviderStep={dispatchProviderStep}
        providerStepState={providerStepState}
      />
    ),
    2: (
      <ProductStep
        ref={formikRef as React.MutableRefObject<FormikProps<IProductStep>>}
        handleNext={handleNext}
        handleBack={handleBack}
        dispatchProductStep={dispatchProductStep}
        productStepState={productStepState}
      />
    ),
    3: (
      <CustomersAndPricingStep
        ref={formikRef as React.MutableRefObject<FormikProps<ICustomerGroupStep>>}
        handleNext={handleNext}
        handleBack={handleBack}
        customerStepState={customerGroups}
        removeItemHandler={removeCustomerGroupHandler}
        addGroupButtonHandler={() => {
          setAddCustomerModalOpen(true)
        }}
      />
    ),
  }

  return (
    <ThemeProvider theme={WizardTheme}>
      <WizardTitle variant="h2">Create New Product</WizardTitle>
      <AddCustomerGroupModal
        isOpen={addCustomerModalOpen}
        handleClose={() => setAddCustomerModalOpen(false)}
        onItemSelectionChange={addCustomerGroupHandler}
        columns={columns}
        rows={customerGroupRows}
        selectionModel={rowSelectionModel}
        isLoading={isLoading}
      />
      <DeleteCustomerGroupDialog
        onClose={() => setRemoveCustomerGroupModalOpen(false)}
        isOpen={removeCustomerGroupModalOpen}
        confirmHandler={removeCustomerGroup}
        customerGroup={customerGroupToDelete}
      />
      <form data-testid="wizard" onSubmit={handleSubmit}>
        <WizardContainer>
          <WizardStepper activeStep={activeStep} steps={wizardSteps} />
          {children[wizardSteps[activeStep].id as keyof typeof children]}
        </WizardContainer>
      </form>
    </ThemeProvider>
  )
}
export { NewProductWizard }

import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import Collapse from "@material-ui/core/Collapse"
import Table, { TableProps } from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import WidgetsIcon from "@material-ui/icons/Widgets"
import FolderOpenIcon from "@material-ui/icons/FolderOpen"
import classNames from "classnames"
import { Input } from "components"
import { TableCollapsibleTheme, TableCoStyles, anotherTableStyles } from "./styles"

export interface ITableProps extends TableProps {
  children?: React.ReactNode
}

export interface IRows {
  row: { Rule: string; collapsible: IColProps[] }
}
export interface IColRows {
  subRow: IColProps[]
}
export interface IColProps {
  Rule: string
  Unit: string
  Value: number
}

const SubTable = ({ subRow }: IColRows): JSX.Element => {
  const classes = anotherTableStyles()
  return (
    <Table key="tableCollapsible" size="small" aria-label="purchases">
      <colgroup>
        <col style={{ width: "65%" }} />
        <col style={{ width: "3%" }} />
        <col style={{ width: "32%" }} />
      </colgroup>
      <TableBody key="bodyCollapsible">
        {subRow.map(coRow => (
          <TableRow key={`rowCo-${coRow.Rule}`}>
            <TableCell className={classes.body}>
              <WidgetsIcon />
              {coRow.Rule}
            </TableCell>
            <TableCell className={classes.body}>{coRow.Unit}</TableCell>
            <TableCell className={classes.body}>
              <Input value={`${coRow.Value}`} name="EditRule" onChange={event => event.target.value} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const Row = ({ row }: IRows): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const classes = TableCoStyles()
  return (
    <>
      <TableRow>
        <TableCell className={classes.underline} component="th" scope="row" onClick={() => setOpen(!open)}>
          <IconButton
            aria-label="expand row"
            size="small"
            className={classNames({
              [classes.icon]: true,
              [classes.iconCollapsibleTableClose]: !open,
              [classes.iconCollapsibleTableOpen]: open,
            })}
          >
            <FolderOpenIcon />
          </IconButton>
          {row.Rule}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SubTable subRow={row.collapsible} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const CollapsibleTable = ({ children }: ITableProps): JSX.Element => {
  return (
    <ThemeProvider theme={TableCollapsibleTheme}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">{children}</Table>
      </TableContainer>
    </ThemeProvider>
  )
}

export { CollapsibleTable }

import * as React from "react"
import _ from "lodash"

import type { RuleManagementLocationState, IRuleArgument, IRuleAttributeArgument, IRuleState } from "typings/modules"
import { useLocationState } from "hooks"
import { ISelectOption } from "components"
import { isConditionalRuleValid, isSimpleRuleValid } from "utils/calculators"
import { CalculatorConditionValue, OperationTypes, Operators } from "constants/calculator"
import { LabelToOperationTypeMap } from "../models/calculator"
import { BLOCKED_CONDITIONAL_ARGUMENTS } from "../constants/calculator"

type RulesManagementStateProps = {
  isFakeRule: boolean
  canEditCondition: boolean
  rule: IRuleState
}

type RulesManagementOptions = {
  rulesRef: React.MutableRefObject<_.Dictionary<IRuleState>>
  systemObjectOptions: ISelectOption[]
}

const defaultRuleArgument = {
  value: "",
  attribute: undefined,
} as IRuleArgument

const useRuleManagement = (props: RulesManagementStateProps, options: RulesManagementOptions) => {
  const { rule: originalRule, isFakeRule } = props

  const { rulesRef, systemObjectOptions } = options

  const locationState = useLocationState<RuleManagementLocationState>()

  const { rule: locationStateRule, mode } = locationState

  const [state, setState] = React.useState(() => {
    if (isFakeRule) {
      return {
        rule: { ...originalRule },
        isOpen: true,
        isEditable: true,
      }
    }

    const isWatchMode = mode === "watch"

    if (mode === "new" || isWatchMode || !locationStateRule) {
      return {
        rule: { ...originalRule },
        isOpen: isWatchMode && locationStateRule?.id === originalRule.id,
        isEditable: false,
      }
    }

    const isOpen = locationStateRule.id === originalRule.id

    const isEditable = locationStateRule.id === originalRule.id

    return {
      rule: { ...originalRule },
      isOpen,
      isEditable,
    }
  })

  const { rule } = state

  const validate = (item: IRuleState) => {
    if (item.conditionValue === CalculatorConditionValue.SELECTIVE) {
      const isValid = isConditionalRuleValid(item)

      return isValid
    }

    const isValid = isSimpleRuleValid(item)

    return isValid
  }

  const updater = (newState: Partial<typeof state>) => {
    setState(prevState => {
      const joinedState = {
        ...prevState,
        ...newState,
        rule: { ...prevState.rule, ...newState.rule },
      }

      joinedState.rule.isValid = validate(joinedState.rule)

      return joinedState
    })
  }

  const handleAccordion = () => {
    updater({ isOpen: !state.isOpen })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    const newRule = ({
      [name]: value,
    } as unknown) as IRuleState

    updater({ rule: newRule })
  }

  const handleEdit = (event: React.MouseEvent) => {
    event.stopPropagation()

    updater({ isEditable: !state.isEditable })
  }

  const handleChangeArgument = (argKey: keyof IRuleAttributeArgument, propKey: keyof IRuleArgument, value: string) => {
    const ruleArgument = rule[argKey] as IRuleArgument

    const newArgument = {
      ...ruleArgument,
      [propKey]: value,
    }

    if (propKey === "value") {
      const selectedSystemObject = systemObjectOptions.find(item => item.value === value)

      newArgument.attribute = ""

      newArgument.name = selectedSystemObject?.label || ""

      newArgument.type =
        LabelToOperationTypeMap[value as keyof typeof LabelToOperationTypeMap] || OperationTypes.SYSTEM_OBJ
    }

    const newRule = {
      ...rule,
      [argKey]: newArgument,
    }

    updater({ rule: newRule })
  }

  const handleChangeField = (keyName: keyof IRuleState, value: string) => {
    const newRule = ({
      [keyName]: value,
    } as unknown) as IRuleState

    if (keyName === "conditionValue") {
      newRule.secondArgument = { ...defaultRuleArgument }

      newRule.thirdArgument = { ...defaultRuleArgument }

      newRule.fourthArgument = { ...defaultRuleArgument }

      newRule.operator = "" as Operators

      if (value === CalculatorConditionValue.SELECTIVE) {
        newRule.comparatorOrder = rule.order

        newRule.order = rule.order + 1
      } else {
        newRule.order = rule.order - 1
      }
    } else if (keyName === "operator") {
      if (!originalRule.id && rule.conditionValue !== CalculatorConditionValue.SELECTIVE) {
        newRule.thirdArgument = undefined

        newRule.fourthArgument = undefined
      }

      if (BLOCKED_CONDITIONAL_ARGUMENTS.includes(newRule.operator as typeof BLOCKED_CONDITIONAL_ARGUMENTS[number])) {
        newRule.secondArgument = { ...defaultRuleArgument }
      }
    }

    updater({ rule: newRule })
  }

  const handleRevert = (event: React.MouseEvent) => {
    event.stopPropagation()

    updater({ rule: originalRule, isEditable: false })
  }

  rulesRef.current[state.rule.internalId] = state.rule

  return {
    state,
    handleAccordion,
    handleInputChange,
    handleEdit,
    handleChangeArgument,
    handleChangeField,
    handleRevert,
  }
}

export default useRuleManagement

import { makeStyles } from "@material-ui/core"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const useStyles = makeStyles(() => ({
  tableHeaderContainer: {
    backgroundColor: palette.grey[600],
    display: "flex",
    alignItems: "center",
    padding: spacing(1),
    height: rem("24px"),
  },
  tableHeaderTitle: {
    textTransform: "none",
    color: palette.background.default,
    fontSize: typography.h4.fontSize,
    fontWeight: typography.fontWeightRegular as number,
    lineHeight: rem("24px"),
  },
}))

import { CancelledError } from "react-query"

interface IServerErrorResponsePayload {
  timestamp: string
  status: number
  error: string
  path: string
}

function formatRejectedServerError(error: string | IServerErrorResponsePayload) {
  try {
    if (typeof error === "string") {
      const json = JSON.parse(error) as IServerErrorResponsePayload

      const message = `Server(${json.status}): ${json.error} (${json.path})`

      return message
    }

    if (error.error) {
      if (error.status) {
        const message = `Server(${error.status}): ${error.error} (${error.path})`

        return message
      }

      return error.error
    }

    return JSON.stringify(error)
  } catch {
    return error as string
  }
}

function getErrorMessage(error: unknown) {
  if (!error || error instanceof CancelledError) {
    return null
  }

  if (typeof error === "string") {
    const message = formatRejectedServerError(error)

    return message
  }

  if (typeof error === "object") {
    const errorWithMessage = error as InstanceType<typeof Error>

    const message = formatRejectedServerError(errorWithMessage.message || (error as IServerErrorResponsePayload))

    if (message || (error as IServerErrorResponsePayload).error) {
      return message || (error as IServerErrorResponsePayload).error
    }
  }

  const json = JSON.stringify(error)

  if (json !== "{}") {
    return json
  }

  return error.toString()
}

export default getErrorMessage

import { styled, Box } from "@material-ui/core"

export const LeftTable = styled(Box)(() => ({
  marginRight: "10px",
  flex: 0.3,
}))

export const RightTable = styled(Box)(() => ({
  flex: 0.7,
}))

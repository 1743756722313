import * as React from "react"

import { CalculatorRulesContext } from "../context/CalculatorRulesContext"

const useCalculatorRules = () => {
  const context = React.useContext(CalculatorRulesContext)

  if (!context) {
    throw new Error("useCalculatorRules must be used within a CalculatorRulesProvider")
  }

  return context
}

export default useCalculatorRules

import * as React from "react"
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core"

import type { ICustomerGroup } from "modules/Customers/models/customerGroup"
import { ISelectOption, MultipleSelect, MultipleSelectType } from "components"

type CustomerGroupSelectProps = {
  customerGroupsOptions: ICustomerGroup[]
  calculatorCustomerGroups: string[]
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  disabled?: boolean
}

const CustomerGroupsSelect = ({
  customerGroupsOptions,
  calculatorCustomerGroups,
  onChange,
  disabled,
}: CustomerGroupSelectProps) => {
  const customerGroupOptions = React.useMemo(
    (): ISelectOption[] =>
      customerGroupsOptions
        .map((customerGroup: ICustomerGroup) => ({
          value: customerGroup.id.toString(),
          label: customerGroup.name,
        }))
        .sort((customerGroupA, customerGroupB) => customerGroupA.label.localeCompare(customerGroupB.label)) || [],
    [customerGroupsOptions]
  )

  return (
    <MultipleSelect
      label="Customer Group"
      handleChange={event => onChange(event)}
      type={MultipleSelectType.CHIP}
      value={calculatorCustomerGroups || []}
      displayEmpty
      placeholder="Choose customer group"
      disabled={disabled}
    >
      {customerGroupOptions?.map(customerGroupOption => (
        <MenuItem key={customerGroupOption.value} value={customerGroupOption.label}>
          <Checkbox color="primary" checked={calculatorCustomerGroups?.indexOf(customerGroupOption.label) > -1} />
          <ListItemText primary={customerGroupOption.label} />
        </MenuItem>
      ))}
    </MultipleSelect>
  )
}

export { CustomerGroupsSelect }

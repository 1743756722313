import { makeStyles } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import { rem, transparentize } from "polished"
import { palette, spacing, theme, typography } from "../theme"

export const AccordionTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiAccordion: {
      root: {
        backgroundColor: palette.background.default,
        boxShadow: theme.shadows[2],
        border: `0.5px solid ${palette.grey[200]}`,
        boxSizing: "border-box",
        margin: spacing(0, 1, 2),
        "&.Mui-expanded": {
          margin: spacing(0, 1, 2),
          "&.Mui-expanded:last-child": {
            marginBottom: `${spacing(2)}px !important`,
          },
        },
        "&::before": {
          display: "none",
        },
        "& div[class*='MuiDataGrid-root']": {
          minHeight: `${rem("200px")} !important`,
        },
      },
      rounded: {
        borderRadius: rem("10px"),
        "&:first-child, &:last-child": {
          borderRadius: rem("10px"),
        },
      },
    },
    MuiAccordionSummary: {
      expandIcon: {
        position: "absolute",
        top: rem("-8px"),
        left: rem("-20px"),
        color: palette.grey[500],
        minWidth: rem("45px"),
        "& svg": {
          fill: palette.grey[800],
        },
        "&.Mui-expanded": {
          transform: "rotate(180deg)",
        },
      },
      content: {
        margin: 0,
        justifyContent: "space-between",
        "&.Mui-expanded": {
          margin: 0,
          borderBottom: `1px solid ${transparentize(0.9, palette.common.black)}`,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: spacing(2, 0, 0),
        flexWrap: "wrap",
        justifyContent: "space-between",
        "& div[class*='MuiBox-root']": {
          fontSize: typography.caption.fontSize,
          fontWeight: typography.fontWeightBold,
          whiteSpace: "nowrap",
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: palette.info.main,
        },
        "& svg": {
          fill: palette.primary.light,
        },
        "&.Mui-disabled": {
          cursor: "default",
          "& svg": {
            fill: palette.grey[400],
          },
        },
      },
    },
    MuiContainer: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: spacing(1),
      },
    },
  },
})

export const accordionStyles = makeStyles(() => ({
  selected: {
    borderColor: palette.primary.main,
    "& svg": {
      fill: palette.primary.main,
    },
  },
  small: {
    minWidth: rem("427px"),
    minHeight: rem("75px"),
    padding: spacing(2),
    "& div[class*='MuiAccordionSummary-root']": {
      "&.Mui-expanded": {
        minHeight: rem("48px"),
      },
      "& h4[class*='MuiTypography-root']:nth-child(1)": {
        color: palette.grey[800],
        lineHeight: rem("24px"),
        fontSize: rem("16px"),
        fontWeight: typography.fontWeightBold,
      },
      "& span[class*='MuiTypography-root']:nth-child(2), span[class*='MuiTypography-root']:nth-child(4)": {
        fontSize: rem("12px"),
        color: palette.grey[500],
        lineHeight: rem("14px"),
        fontWeight: typography.fontWeightBold,
      },
      "& span[class*='MuiTypography-root']:nth-child(3)": {
        margin: spacing(0, 1),
        color: palette.grey[500],
        fontSize: rem("12px"),
        fontWeight: typography.fontWeightBold,
      },
    },
    "& button[class*='MuiAccordionSummary-expandIcon']": {
      position: "absolute",
      left: rem("-26px"),
      top: rem("-10px"),
    },
    "& button[class*='MuiIconButton-root']": {
      position: "absolute",
      top: "-10px",
      color: palette.grey[500],
      minWidth: rem("45px"),
    },
  },
  medium: {
    maxWidth: rem("430px"),
    padding: spacing(3, 3, 2, 3),
    margin: spacing(0, 1, 2),
  },
  large: {
    maxWidth: "100%",
    borderRadius: `3px !important`,
    padding: spacing(2, 0),
    margin: spacing(0, 0, 2, 0),
    "&.Mui-expanded": {
      margin: spacing(0, 0, 2, 0),
    },
    "& div[class*='MuiButtonBase-root']": {
      padding: 0,
    },
    "& div[class*='MuiAccordionSummary-root']": {
      height: "25px !important",
      minHeight: "25px !important",
      alignItems: "center",
      backgroundColor: palette.background.default,
      "&.Mui-expanded": {
        minHeight: rem("25px"),
      },
    },
    "& div[class*='MuiAccordionSummary-expandIcon']": {
      minWidth: 0,
      left: rem("12px"),
      top: 0,
    },
    "& div[class*='MuiAccordionSummary-content']": {
      "& div[class*='MuiBox-root']:nth-child(1)": {
        marginLeft: spacing(5),
      },
      backgroundColor: palette.info.light,
      padding: rem("4px"),
      "&.Mui-expanded": {
        height: rem("47px"),
      },
    },
    "& div[class*='MuiAccordionDetails-root']": {
      padding: spacing(2, 4),
      flexDirection: "column",
    },
    "& path": {
      height: rem("20px"),
      width: rem("20px"),
    },
    "& h3": {
      fontSize: `16px !important`,
      color: palette.grey[600],
    },
  },
}))

import { createStyles, makeStyles } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography, theme } from "../theme"

export const NavBarTheme = createTheme({
  typography,
  spacing,
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: palette.background.default,
        position: "fixed",
        top: 0,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.11)",
      },
    },
    MuiToolbar: {
      root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      gutters: {
        [theme.breakpoints.up("sm")]: {
          paddingLeft: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        "& svg": {
          fill: palette.grey[600],
          width: theme.spacing(3),
          height: theme.spacing(3),
        },
        "&.user-button svg": {
          width: theme.spacing(4),
          height: theme.spacing(4),
        },
      },
    },
  },
})

export const NavBarStyles = makeStyles(() =>
  createStyles({
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& svg": {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fill: theme.palette.grey[600],
      },
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 0,
        paddingLeft: theme.spacing(5),
        "&:focus": {
          paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
          width: rem("240px"),
        },
      },
    },
  })
)

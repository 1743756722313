import _ from "lodash"

import type { IBulkErrorMessage } from "models/bulk"
import {
  IAPICalculatorResponse,
  IAPIRuleResponse,
  IAPIStepRequest,
  IDebugResult,
  IDebugRule,
  IDebugStep,
  IDebugStepGroup,
  ITreeView,
  LabelToOperationTypeMap,
  OperationTypeToLabelMap,
} from "modules/Rules-Pricing/models/calculator"
import { ICustomerGroup, ISummarizedCustomerGroup } from "modules/Customers/models/customerGroup"
import type { IRuleArgument, IRuleState, IStep, IStepGroupResponse } from "typings/modules"
import { ICalculatorState, IStepGroupState, IStepState } from "store/state"
import { ISelectOption, ISelectOptionChild } from "components"
import { CalculatorConditionValue, OperationTypes } from "constants/calculator"
import { ISystemObject } from "modules/Rules-Pricing/models/systemObject"
import { getFormattedStepGroupRules } from "modules/Rules-Pricing/constants/utils"
import { v4 as uuidV4 } from "uuid"
import { BLOCKED_CONDITIONAL_ARGUMENTS } from "modules/Rules-Pricing/constants/calculator"
import { getUniqueID } from "./general"

export const getCalculatorStatus = (isActive?: boolean): string => (isActive ? "Active" : "Inactive")

export const getStepGroupName = (stepGroupName?: string): string => stepGroupName || "Untitled rule set"

export const getStepName = (stepName?: string): string => stepName || "Untitled rule group"

export const getStepGroupCardTitle = (stepGroupName?: string): string => stepGroupName || "New Rule Set"

export const joinCustomerGroups = (customerGroups: ISummarizedCustomerGroup[]): string =>
  customerGroups.map((customerGroup: ISummarizedCustomerGroup) => customerGroup.name).join(", ")

export const getRuleName = (ruleName?: string): string => ruleName || "Untitled Rule Name"

export const getNextStepOrder = (stepGroups: IStepGroupState[], stepGroupIndex: number): number => {
  const lastOrder = Math.max(...stepGroups[stepGroupIndex]?.steps.map((step: IStepState) => step.order))

  return lastOrder >= 0 ? lastOrder + 1 : 1
}

export const getSelectedStepGroupIndex = (calculatorState: ICalculatorState): number => {
  const tempIndex = calculatorState.stepGroups.findIndex(
    (stepGroup: IStepGroupState) => stepGroup.internalId === calculatorState.selectedStepGroup?.internalId
  )

  return tempIndex >= 0 ? tempIndex : 0
}

export const getStepGroupIndexByTabValue = (stepGroups: IStepGroupState[], tabValue?: number): number => {
  const tempIndex = stepGroups.findIndex((stepGroup: IStepGroupState) => stepGroup.tabValue === tabValue)

  return tempIndex >= 0 ? tempIndex : 0
}

export const getSelectedStepIndex = (calculatorState: ICalculatorState, stepGroupIndex: number): number => {
  const stepGroups = calculatorState.stepGroups[stepGroupIndex]?.steps || []

  const tempIndex = stepGroups.findIndex((step: IStepState) => {
    if (step.id) {
      return step.id === calculatorState.selectedStep?.id
    }

    return step.internalId === calculatorState.selectedStep?.internalId
  })

  return calculatorState.selectedStep?.internalId && tempIndex >= 0 ? tempIndex : 0
}

export const getCalculatorPath = (calculator: ICalculatorState): string => {
  const stepGroupIndex = getSelectedStepGroupIndex(calculator)

  const stepIndex = getSelectedStepIndex(calculator, stepGroupIndex)

  const stepGroupName = getStepGroupName(calculator.stepGroups[stepGroupIndex].name)

  const step = calculator.stepGroups[stepGroupIndex].steps[stepIndex]

  const stepName = getStepName(step.name)

  const title = `${calculator.name || "Untitled Calculator"} - ${stepGroupName} - ${stepName}`

  return title
}

export const getNextRuleOrder = (calculatorState: ICalculatorState): number => {
  const stepGroupIx = getSelectedStepGroupIndex(calculatorState)

  const stepIx = getSelectedStepIndex(calculatorState, stepGroupIx)

  const { rules } = calculatorState.stepGroups[stepGroupIx].steps[stepIx]

  const lastOrder = rules.length > 0 ? Math.max(...rules.map(rule => rule.order)) : 0

  return lastOrder + 1
}

export const getAPICustomerGroup = (
  calculatorState: ICalculatorState,
  customerGroups: ICustomerGroup[]
): ISummarizedCustomerGroup[] =>
  calculatorState.customerGroups.map((customerGroup: string) => ({
    id: _.find(customerGroups, { name: customerGroup })?.id,
    name: customerGroup,
  })) as ISummarizedCustomerGroup[]

const getBaseCalculator = (
  apiCalculator: IAPICalculatorResponse
): {
  customerGroups: string[]
  stepGroups: IStepGroupState[]
  name: string
  description?: string
  id: number
  isNew: false
  isActive: boolean
  tier: string
} => ({
  id: apiCalculator.id,
  name: apiCalculator.name,
  description: apiCalculator.description,
  isActive: apiCalculator.status === "ACTIVE",
  customerGroups: apiCalculator.customerGroups.map(customerGroup => customerGroup.name),
  isNew: false,
  stepGroups: [],
  tier: apiCalculator.tier,
})

const getBaseStepGroup = (
  stepGroup: IStepGroupResponse
): {
  name: string
  description?: string
  isOnEditMode: false
  id?: number
  type: string
  steps: IStepState[]
  main: boolean
} => ({
  id: stepGroup.id,
  name: stepGroup.name,
  description: stepGroup.description,
  type: stepGroup.type,
  isOnEditMode: false,
  steps: [],
  main: stepGroup.main,
})

const getBaseStep = (
  step: IStep
): {
  name: string
  isOnEditMode: false
  rules: IRuleState[]
  id: number
  order: number
  cycleReference: boolean
  imported: boolean
} => ({
  id: step.id,
  order: step.order,
  name: step.name,
  isOnEditMode: false,
  cycleReference: step.cycleReference,
  rules: [],
  imported: false,
})

export const mapCalculatorAPIToState = (apiCalculator: IAPICalculatorResponse): ICalculatorState => {
  const calculatorState: ICalculatorState = {
    ...getBaseCalculator(apiCalculator),
    internalId: getUniqueID(),
  } as ICalculatorState

  return calculatorState
}

export const mapCalculatorStepGroupStepsToState = (
  calculatorState: ICalculatorState,
  stepGroupResponse: IStepGroupResponse,
  currentStepGroupState: IStepGroupState,
  stepGroupIndex: number
) => {
  const stepGroupState: IStepGroupState = {
    ...getBaseStepGroup(stepGroupResponse),
    internalId: currentStepGroupState?.internalId || getUniqueID(),
    tabValue: currentStepGroupState?.tabValue || stepGroupIndex + 1,
  }

  const steps = stepGroupResponse.steps.map(step => {
    // find current step state for the merge
    const currentStepState = calculatorState.stepGroups[stepGroupIndex].steps.find(
      currentStep => currentStep.order === step.order
    )

    const stepState: IStepState = {
      ...getBaseStep(step),
      internalId: currentStepState?.internalId || getUniqueID(),
    }

    const rules = getFormattedStepGroupRules({
      rules: step.rules,
      systemObjects: calculatorState.systemObjectOptions,
      uuid: false,
    })

    stepState.rules = rules

    return stepState
  })

  stepGroupState.steps = steps

  return stepGroupState
}

export const mergeCalculatorAPIWithState = (
  currentCalculatorState: ICalculatorState,
  apiCalculator: IAPICalculatorResponse,
  systemObjectOptions: ISelectOption[]
): ICalculatorState => {
  const calculatorState: ICalculatorState = {
    ...currentCalculatorState,
    ...getBaseCalculator(apiCalculator),
    internalId: currentCalculatorState.internalId,
    systemObjectOptions,
  }

  apiCalculator.stepGroups.forEach((stepGroup, stepGroupIx) => {
    // find current step group state for the merge
    const currentStepGroupState = currentCalculatorState.stepGroups.find(
      currentStepGroup => currentStepGroup.type === stepGroup.type
    )

    const stepGroupState = mapCalculatorStepGroupStepsToState(
      currentCalculatorState,
      stepGroup,
      currentStepGroupState,
      stepGroupIx
    )

    calculatorState.stepGroups.push(stepGroupState)
  })

  const currentSelectedStepGroup = calculatorState.selectedStepGroup

  const selectedStepGroup = _.find(calculatorState.stepGroups, item => {
    if (item.id === currentSelectedStepGroup?.id) {
      return true
    }

    if (item.name === currentSelectedStepGroup?.name && item.type === currentSelectedStepGroup?.type) {
      return true
    }

    if (item.internalId === currentSelectedStepGroup?.internalId) {
      return true
    }

    return false
  })

  calculatorState.selectedStepGroup = { ...selectedStepGroup } || calculatorState.selectedStepGroup

  const selectedStep = _.find(
    calculatorState.selectedStepGroup?.steps,
    item => item.id === calculatorState.selectedStep?.id
  )

  calculatorState.selectedStep = selectedStep || calculatorState.selectedStep

  return calculatorState
}

export const mapSystemObjectsIntoOptions = (
  systemObjects: ISystemObject[],
  rules: IAPIRuleResponse[],
  steps: IAPIStepRequest[]
) => {
  const mappedSystemObjects = systemObjects.map(
    (sysObj: ISystemObject): ISelectOption => ({
      value: sysObj.id.toString(),
      label: sysObj.name,
      children: sysObj.attributes.map(attribute => ({
        value: attribute.id.toString(),
        label: attribute.attribute,
      })),
    })
  )

  const inputOption = {
    value: LabelToOperationTypeMap[OperationTypes.INPUT],
    label: LabelToOperationTypeMap[OperationTypes.INPUT],
  }

  /**
   * @description before this was a `map` but this included the comparator rule which is not needed
   */
  const childrenRules = rules.reduce((collection, ruleData) => {
    /**
     * @description if an error ocurred then remove this condition
     */
    if (!ruleData?.name.includes("comparator - ")) {
      collection.push({
        value: String(ruleData?.id),
        label: ruleData?.name,
      })
    }

    return collection
  }, [] as ISelectOption[])

  const listOfRuleOptions = {
    value: OperationTypeToLabelMap.RULE,
    label: OperationTypeToLabelMap.RULE,
    children: childrenRules,
  }

  const listOfStepOptions = {
    value: OperationTypeToLabelMap.STEP,
    label: OperationTypeToLabelMap.STEP,
    children: steps.map(stepData => ({
      value: String(stepData.id),
      label: stepData.name,
    })),
  }

  /**
   * @description listOfStepOptions is added to the end of the list and it must not be changed its position in any way
   */
  mappedSystemObjects.push(inputOption, listOfRuleOptions, listOfStepOptions)

  return mappedSystemObjects
}

export const mapSystemObjectAttributesIntoOptions = (
  systemObjects: ISystemObject[],
  rules: IAPIRuleResponse[],
  steps: IStep[]
) => {
  const options = systemObjects
    .map((sysObj: ISystemObject) =>
      sysObj.attributes.map(
        (attribute): ISelectOptionChild => ({
          value: attribute.id.toString(),
          label: attribute.attribute,
        })
      )
    )
    .reduce((acc, val) => [...acc, ...val])

  const listOfRules = rules.map((rule: IAPIRuleResponse) => ({
    value: rule.id.toString(),
    label: rule.name,
  }))

  const listOfSteps = steps.map((step: IStep) => ({
    value: step.id.toString(),
    label: step.name,
  }))

  options.push(...listOfRules, ...listOfSteps)

  return options
}

export const getImportingSteps = (systemObjectOptions: ISelectOption[], stepGroupState: IStepGroupState) => {
  const listOfSteps = systemObjectOptions[systemObjectOptions.length - 1].children

  const excludedStepIds = _.reduce(
    stepGroupState.steps,
    (collection, item) => {
      if (!item.imported) {
        collection.push(item.id)
      }

      return collection
    },
    [] as number[]
  )

  const importingSteps = listOfSteps.reduce((collection, current) => {
    if (!excludedStepIds.includes(Number(current.value))) {
      collection.push(current)
    }

    return collection
  }, [] as ISelectOptionChild[])

  return importingSteps
}

type GetCalculatorStepRuleAttributesParams = {
  step: IStep
  steps: IStep[]
  systemObjectOptions: ISelectOption[]
  argument: IRuleArgument
  ruleState: IRuleState
}

export const getCalculatorStepRuleAttributes = (params: GetCalculatorStepRuleAttributesParams): ISelectOption[] => {
  const { step, steps, systemObjectOptions, argument, ruleState } = params

  if (!argument) {
    return []
  }

  const argumentValue = argument.value

  const foundAttributes = _.find(systemObjectOptions, {
    value: argumentValue,
  })

  if (!foundAttributes) {
    return []
  }

  let attributes = foundAttributes.children

  /**
   * @description just includes the rules/steps which belong to the current step group
   */
  const isList = argumentValue === OperationTypeToLabelMap.RULE || argumentValue === OperationTypeToLabelMap.STEP

  if (isList) {
    let attributeIds: string[] = []

    if (argumentValue === OperationTypeToLabelMap.RULE) {
      attributeIds = _.reduce(
        step.rules,
        (collection, item) => {
          const stringifiedId = String(item.id)

          if (ruleState.id !== item.id) {
            collection.push(stringifiedId)
          }

          return collection
        },
        [] as string[]
      )
    } else if (argumentValue === OperationTypeToLabelMap.STEP) {
      attributeIds = _.reduce(
        steps,
        (collection, item) => {
          if (item.id !== step.id) {
            const stringifiedId = String(item.id)

            collection.push(stringifiedId)
          }

          return collection
        },
        [] as string[]
      )
    }

    attributes = attributes.filter(attribute => {
      const match = attributeIds.includes(attribute.value)

      return match
    })
  }

  const mappedAttributes = _.map(attributes, attribute => {
    const option = {
      value: String(attribute.value),
      label: attribute.label.toString(),
    } as ISelectOption

    return option
  })

  return mappedAttributes
}

export const isRuleArgumentValueValid = (argument?: IRuleArgument): boolean => !!argument?.value

export const isRuleArgumentValid = (argument?: IRuleArgument): boolean => !!(argument?.value && argument?.attribute)

export const isRuleArgumentEmpty = (argument?: IRuleArgument): boolean => !argument?.value && !argument?.attribute

export const isRuleConditional = (rule: IRuleState): boolean =>
  rule.conditionValue === CalculatorConditionValue.SELECTIVE

export const isSimpleRuleValid = (ruleState: IRuleState): boolean =>
  Boolean(
    ruleState.ruleName &&
      isRuleArgumentValid(ruleState.firstArgument) &&
      ruleState.operator &&
      isRuleArgumentValid(ruleState?.secondArgument)
  )

export const isSimpleRuleEmpty = (ruleState: IRuleState): boolean =>
  Boolean(
    isRuleArgumentEmpty(ruleState.firstArgument) &&
      !ruleState.operator &&
      isRuleArgumentEmpty(ruleState?.secondArgument)
  )

export const isConditionalRuleValid = (ruleState: IRuleState): boolean => {
  if (BLOCKED_CONDITIONAL_ARGUMENTS.includes(ruleState.operator as typeof BLOCKED_CONDITIONAL_ARGUMENTS[number])) {
    const isValid = Boolean(
      ruleState.ruleName &&
        isRuleArgumentValid(ruleState.firstArgument) &&
        isRuleArgumentValid(ruleState?.thirdArgument) &&
        isRuleArgumentValid(ruleState?.fourthArgument)
    )

    return isValid
  }

  const isValid = Boolean(
    ruleState.ruleName &&
      isRuleArgumentValid(ruleState.firstArgument) &&
      isRuleArgumentValid(ruleState.secondArgument) &&
      isRuleArgumentValid(ruleState?.thirdArgument) &&
      isRuleArgumentValid(ruleState?.fourthArgument) &&
      ruleState.operator
  )

  return isValid
}

export const isConditionalRuleEmpty = (ruleState: IRuleState) => {
  const isValid = Boolean(
    isRuleArgumentEmpty(ruleState.firstArgument) &&
      isRuleArgumentEmpty(ruleState.secondArgument) &&
      isRuleArgumentEmpty(ruleState?.thirdArgument) &&
      isRuleArgumentEmpty(ruleState?.fourthArgument) &&
      !ruleState.operator
  )

  return isValid
}

export const isRuleEmpty = (ruleState: IRuleState): boolean =>
  isRuleConditional(ruleState) ? isConditionalRuleEmpty(ruleState) : isSimpleRuleEmpty(ruleState)

export const isCalculatorValid = (
  calculatorState: ICalculatorState,
  stepGroupIndex: number,
  stepIndex: number
): boolean =>
  !!(
    calculatorState?.name &&
    calculatorState.customerGroups.length &&
    calculatorState.stepGroups[stepGroupIndex]?.name &&
    calculatorState.stepGroups[stepGroupIndex]?.type &&
    calculatorState.stepGroups[stepGroupIndex]?.steps[stepIndex]?.name
  )

export const removeAccordionIndex = (accordionElement: string, accordionList?: string[]): string[] => {
  if (accordionList?.length) {
    const currentList = [...accordionList]

    const optionIndex = currentList.indexOf(accordionElement)

    currentList.splice(optionIndex, 1)

    return currentList
  }
  return []
}

export const stepWithCycleReferenceExists = (calculatorState: ICalculatorState, stepGroupIndex: number): boolean =>
  calculatorState.stepGroups[stepGroupIndex].steps.some(stepGroup => stepGroup.cycleReference)

export const isInputSelected = (argument?: IRuleArgument): boolean => argument?.value === OperationTypes.INPUT

export const mapDebugResultIntoTree = (debugResult: IDebugResult): ITreeView[] =>
  debugResult.stepGroups.map((stepGroup: IDebugStepGroup) => ({
    nodeId: uuidV4(),
    label: `${stepGroup.name} $${stepGroup.price}`,
    sublistArray: stepGroup.steps.map((step: IDebugStep) => ({
      nodeId: uuidV4(),
      label: `${step.name} $${step.price}`,
      sublistArray: step.rules.map((rule: IDebugRule) => ({
        nodeId: uuidV4(),
        label: `${rule.name} $${rule.price}`,
      })),
    })),
  }))

export const mapCalculationResultIntoTree = (calculationResult?: IBulkErrorMessage[]): ITreeView[] =>
  calculationResult?.map((result: IBulkErrorMessage) => ({
    nodeId: result.ruleName,
    label: result.ruleName,
    sublistArray: [
      {
        nodeId: result.errorDescription,
        label: result.errorDescription,
      },
    ],
  })) || ([] as ITreeView[])

import { format } from "date-fns"
import { IInputCSV } from "modules/Competitors/models/competitor"
import { translations } from "utils/translations"
import { IAlertProps } from "components/Alert/Alert"
import { BULK_STATUS, TABLE } from "constants/bulk"
import { PRODUCT_TABLE_HEADERS, CUSTOMER_TABLE_HEADERS, CUSTOMER_GROUP_TABLE_HEADERS } from "constants/tables"
import { GridCellParams, GridFilterInputValue, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid"
import { IQueryParams } from "../models/api"

// eslint-disable-next-line prettier/prettier
const NOT_SELLING = "NOT_SELLING"

export const ALLOWED_FILTER_OPERATORS: string[] = ["startsWith", "equals", "inlist"] as string[]

export const formatDateFromString = (date?: string): string => (date ? format(new Date(date), "MM/dd/yyyy") : "")

export const formatDate = (date: Date): string => format(date, "yyyy-MM-dd")

export const formattedCSVValue = (key: string): string | boolean | null => {
  if (key) {
    const keyValue = key.replace(/"|'/g, "").trim()
    switch (keyValue.toLocaleLowerCase()) {
      case "-":
        return null
      case "yes":
        return true
      case "no":
        return false
      default:
        return keyValue
    }
  }
  return null
}

const isValidRow = (item: any) => {
  return Object.values(item).some(value => value !== null)
}

const isValidNumber = (value: string) => {
  // eslint-disable-next-line prettier/prettier
  return (value && value !== NOT_SELLING) || value === ""
}

export const mapCSVItems = (rows: string[], headers: string[]): IInputCSV[] => {
  const result: IInputCSV[] = []
  rows.forEach(r => {
    const splittedRow: string[] = r.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
    let itemObj = {}
    for (let i = 0; i < headers.length; i += 1) {
      itemObj = {
        ...itemObj,
        [headers[i]]: formattedCSVValue(splittedRow[i]),
      }
    }
    if (isValidRow(itemObj)) {
      result.push(itemObj)
    }
  })
  return result
}

const priceIsValid = (price: number | string) => {
  const priceValue = parseFloat(price.toString())
  return !Number.isNaN(priceValue)
}

export const mapProductsByCustomerGroupsItems = (rows: string[], headers: string[]): IInputCSV[] => {
  const result: IInputCSV[] = []
  rows.forEach(r => {
    const splittedRow: string[] = r.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
    let itemObj = {}
    if (splittedRow[0]) {
      itemObj = {
        ...itemObj,
        vsId: formattedCSVValue(splittedRow[0]),
      }
    }
    const customerGroups = []
    for (let i = 1; i < headers.length - 1; i += 2) {
      if (isValidNumber(splittedRow[i])) {
        let constumerGroupPrice: any = {
          name: headers[i] && headers[i].split("_")[0],
        }

        if (priceIsValid(splittedRow[i]) && priceIsValid(splittedRow[i + 1])) {
          constumerGroupPrice = {
            ...constumerGroupPrice,
            wholesalePrice: parseFloat(splittedRow[i]),
            retailPrice: parseFloat(splittedRow[i + 1]),
          }
        } else if (priceIsValid(splittedRow[i]) && (!splittedRow[i + 1] || splittedRow[i + 1] === "")) {
          constumerGroupPrice = {
            ...constumerGroupPrice,
            wholesalePrice: parseFloat(splittedRow[i]),
          }
        } else if (priceIsValid(splittedRow[i + 1]) && (!splittedRow[i] || splittedRow[i] === "")) {
          constumerGroupPrice = {
            ...constumerGroupPrice,
            retailPrice: parseFloat(splittedRow[i + 1]),
          }
        }
        customerGroups.push(constumerGroupPrice)
      }
    }
    if (customerGroups.length > 0) {
      itemObj = {
        ...itemObj,
        customerGroups,
      }
    }
    if (isValidRow(itemObj)) {
      result.push(itemObj)
    }
  })
  return result
}

export const csvToJson = (table: string, csv: string): IInputCSV[] => {
  let result: IInputCSV[] = []
  const lines = csv.split(/\r?\n/)
  const headers = lines.find(l => lines.indexOf(l) === 0)?.split(",") || []
  const rows = lines.filter(l => lines.indexOf(l) !== 0)

  switch (table) {
    case TABLE.PRODUCTS: {
      const PRODUCT_KEYS = PRODUCT_TABLE_HEADERS.map(column => column.accessor)
      result = mapCSVItems(rows, PRODUCT_KEYS)
      break
    }
    case TABLE.PRODUCTS_BY_CUSTOMERS_GROUP: {
      result = mapProductsByCustomerGroupsItems(rows, headers)
      break
    }
    case TABLE.CUSTOMERS: {
      const CUSTOMER_KEYS = CUSTOMER_TABLE_HEADERS.map(column => column.accessor)
      result = mapCSVItems(rows, CUSTOMER_KEYS)
      break
    }
    case TABLE.CUSTOMERS_GROUPS: {
      const CUSTOMER_GROUP_KEYS = CUSTOMER_GROUP_TABLE_HEADERS.map(column => column.accessor)
      result = mapCSVItems(rows, CUSTOMER_GROUP_KEYS)
      break
    }
    default: {
      result = mapCSVItems(rows, headers)
      break
    }
  }
  return result
}

export const buildUrlQueryParams = (queryParams: IQueryParams): string => {
  const params = new URLSearchParams({
    page: queryParams.page,
    size: queryParams.size,
  })

  if (queryParams.sortModel?.length) {
    const [gridSortItem] = queryParams.sortModel

    params.append("sort", gridSortItem.field)

    params.append("dir", gridSortItem?.sort || "asc")
  }

  if (queryParams.filterValue?.columnField && queryParams.filterValue?.value) {
    params.append(queryParams.filterValue.columnField, queryParams.filterValue.value)
    params.append("operator", queryParams.filterValue.operatorValue)
  }

  return params.toString()
}

export const getUniqueID = () => Math.floor(Math.random() * Date.now())

export const setAlertContent = (
  status: BULK_STATUS
): {
  children: string
  severity: IAlertProps["severity"]
} => {
  switch (status) {
    case BULK_STATUS.PROCESSING:
      return {
        children: translations.alert.bulk.processing,
        severity: "info",
      }
    case BULK_STATUS.PROCESSED:
    case BULK_STATUS.COMPLETED:
      return {
        children: translations.alert.bulk.success,
        severity: "success",
      }
    case BULK_STATUS.FAILED:
      return {
        children: translations.alert.bulk.error,
        severity: "error",
      }
    case BULK_STATUS.DELETED:
      return {
        children: translations.alert.bulk.deleted,
        severity: "info",
      }
    default:
      return {
        children: translations.alert.bulk.processing,
        severity: "info",
      }
  }
}

export const removeEmptyString = (value: string) => {
  const newValue = value.replaceAll(" ", "").replace("ㅤ", "")

  return newValue
}

/**
 * @copyright https://stackoverflow.com/questions/18379254/regex-to-split-camel-case#answers
 */
export const splitCamelCase = (value: string) => {
  const word = value.replace(/([a-z])([A-Z])/g, "$1 $2")

  const words = word.split(" ")

  return words
}

export const operatorIn: GridFilterOperator = {
  label: "In list",
  value: "inlist",
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null
    }
    return (params: GridCellParams): boolean => {
      return JSON.stringify(params.value) === JSON.stringify(filterItem.value)
    }
  },
  InputComponent: GridFilterInputValue,
  InputComponentProps: { type: "string", label: "Value" },
}

import { useQuery, UseQueryOptions } from "react-query"
import { IStepGroupType } from "modules/Rules-Pricing/models/calculator"
import { getMasterStepGroupTypes, getStepGroupTypes } from "services/stepGroups"

type StepGroupTypesProps = {
  masterType?: boolean
  calculatorId?: number
  options?: UseQueryOptions<IStepGroupType[]>
}

const fetchStepGroupTypes = async (calculatorId: number, masterType: boolean): Promise<IStepGroupType[]> => {
  const data = masterType ? getMasterStepGroupTypes(calculatorId) : getStepGroupTypes(calculatorId)

  return data
}

export const useStepGroupTypes = (props: StepGroupTypesProps) => {
  const { masterType = false, calculatorId, options } = props

  const queryKey = ["StepGroupTypes", `masterType:${masterType}}`]

  const query = useQuery(queryKey, () => fetchStepGroupTypes(calculatorId, masterType), options || {})

  return query
}

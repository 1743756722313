import React from "react"
import { Button, HeaderContainer, Actions } from "components"
import { ROUTES } from "constants/routes"
import { useHistory } from "react-router"
import { Box } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { useRunCalculator } from "services/hooks/useRunCalculator"
import Loader from "components/Loader"
import { useHasReadWriteRole } from "utils/user"

interface IHeaderProps {
  setDuplicateDialogIsOpen: (isOpen: boolean) => void
  disabled?: boolean
}

const HeaderActions = ({ setDuplicateDialogIsOpen, disabled }: IHeaderProps) => {
  const history = useHistory()

  const userHasReadWriteRole = useHasReadWriteRole()

  const { runCalc, isLoading } = useRunCalculator()

  const runCalculator = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    runCalc()
  }

  return (
    <>
      {isLoading && <Loader />}
      <HeaderContainer data-testid="header">
        <Box>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              /**
               * @todo - add a prompt for warning the user if goes back without saving
               */
              history.push(ROUTES.RULES_PRICING.path)
            }}
            icon={<ArrowBack />}
          >
            Back
          </Button>
        </Box>
        <Actions alignSelf="flex-start">
          <Button
            onClick={(): void => setDuplicateDialogIsOpen(true)}
            variant="outlined"
            isDisabled={disabled || !userHasReadWriteRole}
          >
            Duplicate
          </Button>
          <Button onClick={runCalculator} isDisabled={disabled || !userHasReadWriteRole}>
            Run
          </Button>
        </Actions>
      </HeaderContainer>
    </>
  )
}

export default HeaderActions

import { Box, makeStyles, styled } from "@material-ui/core"
import { palette, spacing } from "components/theme"
import { rem } from "polished"

export const summaryItemStyles = makeStyles(() => ({
  title: {
    color: palette.grey[500],
    fontSize: 12,
  },
  number: {
    lineHeight: rem("24px"),
    color: palette.grey[900],
  },
}))

export const StyledSummaryBox = styled(Box)(() => ({
  display: "flex",
}))

export const StyledSummaryItem = styled(Box)(() => ({
  padding: spacing(0, 3),
  "& p": {
    fontWeight: 500,
  },
}))

import { makeStyles } from "@material-ui/core"
import { rem } from "polished"
import { palette, spacing, typography } from "../theme"

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconContainer: {
    color: palette.primary.main,
    fill: palette.primary.main,
    paddingBottom: spacing(1),
  },
  titleContainer: {
    textTransform: "capitalize",
    color: palette.primary.main,
    fontSize: typography.caption.fontSize,
    fontWeight: typography.fontWeightBold as number,
    lineHeight: rem("14px"),
  },
}))

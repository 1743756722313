import { ITableColumn } from "models/tableColumn"
import { ACTION_AUDIT_MAP } from "constants/tables"
import { IAudit } from "../models/audit"

// Allows to handle commas into the string, and replace double quotes by simple quotes.
const handleValuesWithComma = (value: string | number) => {
  if (`${value}`.includes(",") && !`${value}`.includes('"')) {
    return `"${value}"`
  }

  if (`${value}`.includes('"')) {
    const newValue = value.toString().replaceAll('"', "'")

    return `"${newValue}"`
  }

  return value
}

const getHeaders = (columns: Array<ITableColumn>) =>
  columns.map((column: ITableColumn) => handleValuesWithComma(column.header)).join(",")

// Creates the csv file content using the columns accessors to print data
export const getCsvContent = <T>(columns: Array<ITableColumn>, data: Array<T>): string => {
  const headers = getHeaders(columns)

  const dataRow = data.map((row: T) => {
    const keys = Object.keys(row)

    const rows = columns.map((column: ITableColumn) => {
      const { accessor } = column

      if (!keys.includes(accessor)) {
        return null
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const realValue = row[column.accessor]

      const value = handleValuesWithComma(realValue)

      return column.formatter ? column.formatter(value as string) : value
    })

    const joinedRows = rows.join(",")

    return joinedRows
  })

  const joinedDataRow = dataRow.join("\r\n")

  const csvContent = `${headers}\r\n${joinedDataRow}`

  return csvContent
}

export const getAuditCsvContent = (columns?: Array<ITableColumn>, data?: Array<IAudit>[]): string => {
  const headers = getHeaders(columns)
  const dataRow = data
    ?.map((row: any) => {
      const keys = Object.keys(row[0])
      return columns
        ?.map((column: ITableColumn) => {
          if (!keys.includes(column.accessor) && column.accessor !== "fields" && column.accessor !== "action") {
            return ""
          }
          if (column.accessor === "action") {
            return ACTION_AUDIT_MAP[row[2]]
          }
          if (column.accessor === "fields") {
            return row[3].join(" - ")
          }
          return handleValuesWithComma(row[0][column.accessor])
        })
        .join(",")
    })
    .join("\r\n")
  return `${headers}\r\n${dataRow}`
}

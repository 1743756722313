import { GridColDef, GridRowModel, getGridStringOperators } from "@mui/x-data-grid"
import _ from "lodash"
import numeral from "numeral"

import type { IProduct, IProductData } from "modules/Products/models/product"
import type { IProductStep, IProviderStep } from "modules/Products/models/newProductWizard"
import { baseColumns } from "modules/Customers/constants/productCustomersTable"
import { ICustomerGroup, ProductPrices } from "modules/Customers/models/customerGroup"
import { leftEditableColumn, customerGroupPriceFormatterParser } from "../modules/TableGlobalStyles"
import { ALLOWED_FILTER_OPERATORS, operatorIn } from "./general"

export const getProductFromForm = (productStepState: IProductStep, providerStepState: IProviderStep): IProductData => {
  const marginFocused = numeral(productStepState.marginFocused).divide(100)

  const payload: IProductData = {
    supplierName: providerStepState.supplierName,
    supplierProdId: providerStepState.supplierProductId,
    manufacturer: providerStepState.manufacturer,
    upc: providerStepState.upc || null,
    name: productStepState.productName,
    ecomname: productStepState.ecomName,
    vsId: productStepState.vsId,
    majorCategoryName: productStepState.majorCategoryName,
    marsFeeStrategy: productStepState.marsFeeStrategy,
    categoryName: productStepState.categoryName || null,
    vipMarkerName: productStepState.vipMarkerName || null,
    srpRuleName: productStepState.srpRuleName || null,
    specieName: productStepState.specieName,
    feeStrategy: productStepState.feeStrategy,
    map: productStepState.map || 0,
    exception: productStepState.exception || null,
    banFeeStrategy: productStepState.banFeeStrategy || null,
    vcost: productStepState.vcost || 0,
    vetList: productStepState.vetList || 0,
    purchaseSize: productStepState.purchaseSize,
    size: productStepState.productSize,
    weight: productStepState.weight || 0,
    rxrequired: productStepState.rxrequired,
    instantrebate: productStepState.instantrebate || 0,
    packaging: productStepState.packaging,
    breakdown: productStepState.breakdown,
    customerGroups: [],
    marginFocused: marginFocused.value() as number,
    equineFee: productStepState.equineFee,
    equineRetailMarkUp: productStepState.equineRetailMarkUp,
  }

  return payload
}

export const getDynamicColumns = (response: IProduct[], cachedColumns: GridColDef[]): GridColDef[] => {
  const columnFields: string[] = []

  cachedColumns.push(...baseColumns)

  _.forEach(response, (product: IProduct) => {
    _.forEach(product.customerGroups, (customerGroup: ICustomerGroup) => {
      if (!columnFields.includes(customerGroup.name)) {
        columnFields.push(customerGroup.name)
      }
    })
  })

  _.forEach(columnFields, (columnField: string) => {
    cachedColumns.push({
      field: columnField,
      headerName: columnField,
      type: "number",
      filterable: false,
      sortable: false,
      ...leftEditableColumn,
      ...customerGroupPriceFormatterParser,
    })
  })

  const gridStringOperatorsArray = getGridStringOperators()
  const gridStringOperatorsProduct = [...gridStringOperatorsArray, operatorIn]
  return cachedColumns.map(column => ({
    ...column,
    filterOperators: gridStringOperatorsProduct.filter(({ value }) => {
      return ALLOWED_FILTER_OPERATORS.includes(value)
    }),
  }))
}

export const getProductDynamicRows = (price: ProductPrices, response?: IProduct[]): GridRowModel[] => {
  const rows: GridRowModel[] = _.map(response, product => {
    let productRow: GridRowModel = {}

    product.customerGroups?.forEach((customerGroup: ICustomerGroup) => {
      productRow = {
        ...productRow,
        [customerGroup.name]: customerGroup[price],
      }
    })

    return {
      id: product.id,
      vsId: product.vsId,
      name: product.name,
      ...productRow,
    }
  })

  return rows
}

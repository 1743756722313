import * as React from "react"
import { Box, Portal } from "@material-ui/core"
import { LinearIndeterminate } from "components"

const Loader = () => {
  return (
    <Portal>
      <Box className="calculator-rules-progress" position="absolute" top="65px" left="0" zIndex="100" width="100%">
        <LinearIndeterminate />
      </Box>
    </Portal>
  )
}

export default Loader

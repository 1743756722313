import * as React from "react"
import { FormikProps, useFormik } from "formik"

import { Input, Spacer } from "components"
import { ContainerInputIconButton, StepContent } from "../styles"
import {
  IProviderStepProps,
  StepAction,
  providerStepInitialValues,
  ProviderStepInitialValuesSchema,
  IProviderStep,
} from "../models/newProductWizard"
import { WizardHeader } from "./WizardHeader"
import { NewProductWizardFooter } from "./NewProductWizardFooter"

const ProviderStep = React.forwardRef<FormikProps<IProviderStep>, IProviderStepProps>(
  ({ dispatchProviderStep, providerStepState, handleNext }, formikRef) => {
    const formik = useFormik({
      initialValues: providerStepState || providerStepInitialValues,
      validationSchema: ProviderStepInitialValuesSchema,
      onSubmit: values => {
        dispatchProviderStep({
          type: StepAction.FORM_CHANGED,
          payload: {
            ...values,
            isStepValid: true,
          },
        })

        handleNext()
      },
    })

    React.useImperativeHandle(
      formikRef,
      () => ({
        ...formik,
      }),
      [formik]
    )

    return (
      <>
        <StepContent>
          <WizardHeader
            title="Who is providing this product?"
            subtitle="Please complete the information about the supplier of this product"
          />
          <ContainerInputIconButton>
            <Input
              label="Supplier Name"
              name="supplierName"
              placeholder="Choose a supplier"
              value={formik.values.supplierName}
              hasError={Boolean(formik.errors.supplierName && formik.touched.supplierName)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </ContainerInputIconButton>
          <Spacer size={3} />
          <Input
            label="Supplier Product ID"
            name="supplierProductId"
            placeholder="Choose a product ID"
            value={formik.values.supplierProductId}
            hasError={Boolean(formik.errors.supplierProductId && formik.touched.supplierProductId)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="Manufacturer"
            name="manufacturer"
            placeholder="Type a manufacturer"
            value={formik.values.manufacturer}
            hasError={Boolean(formik.errors.manufacturer && formik.touched.manufacturer)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="UPC"
            name="upc"
            placeholder="Type a UPC"
            value={formik.values.upc}
            hasError={Boolean(formik.errors.upc && formik.touched.upc)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </StepContent>
        <NewProductWizardFooter handleNext={handleNext} />
      </>
    )
  }
)

export { ProviderStep }

import { Box, makeStyles, styled } from "@material-ui/core"
import { rem, transparentize } from "polished"
import { createTheme } from "@material-ui/core/styles"
import { palette, spacing, typography, theme } from "../theme"

export const StepGroupSelectorTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiCard: {
      root: {
        backgroundColor: "transparent",
        border: "1px solid",
        borderRadius: "4px",
        padding: spacing(2),
        cursor: "pointer",
        minHeight: rem("315px"),
        borderColor: palette.grey[400],
      },
    },
    MuiCardHeader: {
      root: {
        padding: 0,
      },
      title: {
        fontSize: typography.h4.fontSize,
        fontWeight: typography.h4.fontWeight,
      },
    },
    MuiCardContent: {
      root: {
        padding: spacing(1, 0, 1, 0),
        "&:last-child": {
          paddingBottom: 8,
        },
        "& span": {
          fontSize: "14px",
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
        padding: 0,
      },
    },
    MuiIconButton: {
      root: {
        padding: spacing(1),
        color: palette.primary.light,
        "&.Mui-disabled": {
          cursor: "default",
          "& svg": {
            fill: palette.grey[400],
          },
        },
        "& svg": {
          fill: palette.primary.light,
        },
      },
    },
  },
})

export const cardStyles = makeStyles(() => ({
  selected: {
    borderColor: palette.primary.main,
    "& svg": {
      fill: palette.primary.main,
    },
  },
  small: {
    height: `${rem("110px")}!important`,
    minHeight: `${rem("110px")}!important`,
  },
}))

export interface IIDContainer {
  color: string
}
export const CollapsedStepGroupItem = styled(Box)(({ color }: IIDContainer) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.h3.fontWeight,
  fontFamily: theme.typography.fontFamily,
  width: spacing(5),
  height: spacing(5),
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color,
  backgroundColor: transparentize(0.9, color),
}))

import React, { useEffect, useState } from "react"
import { Box, Collapse, IconButton, Tab, ThemeProvider } from "@material-ui/core"
import {
  Content,
  MainContainer,
  NavBar,
  theme,
  Breadcrumbs,
  TabGroupIndicatorColor,
  TabGroup,
  IMenuTab,
  tabsMenuStyles,
  Alert,
  LinearIndeterminate,
  Dialog,
} from "components"
import { GlobalStyles } from "modules/GlobalStyles"
import { menuTabs } from "constants/menu"
import { useHistory, useLocation } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import CloseIcon from "@material-ui/icons/Close"
import { RootState } from "store"
import { uiActions } from "store/ui-slice"
import { Sidebar } from ".."

const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const uiState = useSelector((state: RootState) => state.ui)

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(uiActions.hideNotification())
    }, 30000)
  }, [dispatch, uiState.showAlert])

  const classes = tabsMenuStyles()

  const history = useHistory()

  const location = useLocation()

  const pathnameList = location.pathname
    .split("/")
    .filter(x => x)
    .map(path => path.replace("-", " "))

  const [tabGroupValue, setTabGroupValue] = useState(1)

  const onChangeHandler = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setTabGroupValue(newValue)

    history.push(menuTabs[newValue - 1].path)
  }

  useEffect(() => {
    const selectedTab = menuTabs.find(tab => location.pathname.includes(tab.path))

    if (selectedTab) {
      setTabGroupValue(menuTabs.indexOf(selectedTab) + 1)
    }
  }, [location.pathname])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {uiState.showDialog && (
        <Dialog
          title={uiState.dialog.title}
          onClose={() => dispatch(uiActions.hideDialog())}
          open={uiState.showDialog}
          primaryActionHandler={uiState.dialog.primaryActionHandler}
          secondaryActionLabel={uiState.dialog.secondaryActionLabel}
          primaryActionLabel={uiState.dialog.primaryActionLabel}
          dialogContentText={uiState.dialog.dialogContentText}
          dialogContentForm={uiState.dialog.dialogContentForm}
          maxWidth="md"
        >
          {uiState.dialog.children}
        </Dialog>
      )}
      {uiState.showAlert && (
        <Collapse in={uiState.showAlert}>
          <Alert
            severity={uiState.alert.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(uiActions.hideNotification())
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {uiState.alert.children}
          </Alert>
        </Collapse>
      )}
      <NavBar />

      {uiState.isLoading && (
        <Box position="relative" top="65px" zIndex="140">
          <LinearIndeterminate />
        </Box>
      )}

      <MainContainer>
        <Sidebar>
          <TabGroup value={tabGroupValue} onChange={onChangeHandler} indicatorColor={TabGroupIndicatorColor.SECONDARY}>
            {menuTabs.map((tab: IMenuTab) => (
              <Tab
                key={tab.id}
                value={tab.id}
                icon={tab.icon}
                className={classes.tab}
                label={tab.label}
                disableRipple
                onClick={tab.onClick}
              />
            ))}
          </TabGroup>
        </Sidebar>
        <Content className="main-content">
          <Breadcrumbs pathnameList={pathnameList} />
          {children}
        </Content>
      </MainContainer>
    </ThemeProvider>
  )
}

export { Layout }

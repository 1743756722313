import * as React from "react"
import { DeleteOutlined, EditOutlined, VisibilityOutlined } from "@material-ui/icons"
import { Box, Typography } from "@material-ui/core"
import { GridRowModel } from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import { useHistory, useParams, useRouteMatch } from "react-router"
import { useQueryClient, useMutation } from "react-query"
import { api } from "services/api"

import type { CalculatorRulesLocationState, IRule, IStep, IStepGroupResponse } from "typings/modules"
import { useCalculatorQueryKey } from "services/hooks/useCalculator"
import { uiActions } from "store/ui-slice"
import { useQueryCache } from "hooks"
import { IconButton, Dialog } from "components"
import { translations } from "utils/translations"
import type { IAPICalculatorResponse } from "../models/calculator"

interface ICalculatorListActions {
  row: GridRowModel
  step: IStep
  stepGroup: IStepGroupResponse
}

const RuleListActions = (props: ICalculatorListActions): JSX.Element => {
  const { row, step, stepGroup } = props

  const isMainStepGroupSelected = stepGroup.main

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const [calculatorQueryKey] = useCalculatorQueryKey()

  const cachedCalculator = useQueryCache<IAPICalculatorResponse>(calculatorQueryKey)

  const history = useHistory<CalculatorRulesLocationState>()

  const { calculatorId } = useParams<{ calculatorId: string }>()
  const { stepGroupId } = useParams<{ stepGroupId: string }>()

  const match = useRouteMatch()

  const { mutate: deleteRule } = useMutation(() => api.rules.delete(step.id, row.id), {
    onSuccess: () => {
      dispatch(
        uiActions.showNotification({
          children: translations.alert.calculator.success,
          severity: "success",
        })
      )
      queryClient.invalidateQueries(`Step-${step.id}`)
    },
    onError: (error: InstanceType<typeof Error>) => {
      dispatch(
        uiActions.showNotification({
          children: error.message || JSON.stringify(error),
          severity: "error",
        })
      )
    },
  })

  const formatCalculatorRuleLocationState = (
    mode: CalculatorRulesLocationState["mode"]
  ): CalculatorRulesLocationState => {
    const rule: CalculatorRulesLocationState["rule"] = {
      id: Number(row.id),
      name: row.ruleName,
    }

    if (rule.name.startsWith("comparator - ")) {
      const index = step.rules.findIndex(item => item.id === rule.id)

      const foundRule = step.rules[index + 1] || ({} as IRule)

      const isComparatorPartner = rule.name === `comparator - ${foundRule.name}`

      if (isComparatorPartner) {
        rule.id = foundRule.id

        rule.name = foundRule.name
      }
    }

    return {
      mode,
      calculator: {
        id: Number(calculatorId),
        name: cachedCalculator.state.data.name,
        tier: cachedCalculator.state.data.tier,
      },
      stepGroup: {
        id: stepGroup.id,
        name: stepGroup.name,
      },
      step: {
        id: step.id,
        name: step.name,
      },
      rule,
    }
  }

  if (isMainStepGroupSelected && !stepGroupId) {
    const handleWatch = () => {
      const options = formatCalculatorRuleLocationState("watch")

      history.push(`${match.url}/rules-management`, options)
    }

    return (
      <Box>
        <IconButton color="inherit" icon={<VisibilityOutlined />} onClick={handleWatch} />
      </Box>
    )
  }

  const handleEdit = () => {
    const options = formatCalculatorRuleLocationState("edit")

    history.push(`${match.url}/rules-management`, options)
  }

  return (
    <>
      <Dialog
        open={showDeleteDialog}
        title="Delete rule"
        onClose={() => setShowDeleteDialog(false)}
        primaryActionHandler={() => deleteRule()}
        secondaryActionLabel="Cancel"
        primaryActionLabel="Delete"
      >
        <Typography variant="h4">Are you sure you want to delete the rule {row.ruleName}?</Typography>
      </Dialog>
      <Box>
        <IconButton color="inherit" icon={<EditOutlined />} onClick={handleEdit} />
        <IconButton color="inherit" icon={<DeleteOutlined />} onClick={() => setShowDeleteDialog(true)} />
      </Box>
    </>
  )
}

export { RuleListActions }

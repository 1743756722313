import { useQuery } from "react-query"
import { ISummarizedCustomerGroup, ICustomerGroup } from "modules/Customers/models/customerGroup"
import { getSubGroups } from "services"

interface ISubGroupsParams {
  customerGroups: ISummarizedCustomerGroup[]
  customerGroupsOptions: ICustomerGroup[]
  options?: any
}

export const useSubGroups = ({ customerGroups, customerGroupsOptions, options }: ISubGroupsParams) => {
  const { data: subGroupsData, isLoading: isSubGroupsLoading } = useQuery(
    ["SubGroups", customerGroups?.map(({ name }) => name)] as const,
    ({ queryKey }) => {
      const [, customerGroupNames] = queryKey
      const foundCustomerGroups = customerGroupsOptions.filter(customerGroup =>
        customerGroupNames?.includes(customerGroup.name)
      )
      const ids = foundCustomerGroups.map(({ id }) => id)

      return getSubGroups(ids)
    },
    { ...options }
  )

  return { subGroupsData, isSubGroupsLoading }
}

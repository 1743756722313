import {
  ICustomerGroup,
  ICustomerGroupResponse,
  ICustomerGroupsAudit,
  IUpdateCustomerGroupRequest,
} from "modules/Customers/models/customerGroup"
import { GridSortItem } from "@mui/x-data-grid"
import { IProductPrice } from "modules/Customers/models/customer"
import { api } from "./api"

export const getCustomerGroups = async (
  page: number,
  size: number,
  sortModel?: GridSortItem[]
): Promise<ICustomerGroupResponse> => {
  const { data } = await api.customerGroups.get({
    page: page.toString(),
    size: size.toString(),
    sortModel,
  })
  return data
}

export const getAllCustomerGroups = async (): Promise<ICustomerGroup[]> => {
  const { data } = await api.customerGroups.getAll()
  return data
}

export const updateCustomerGroup = async (
  id: number,
  payload: IUpdateCustomerGroupRequest
): Promise<ICustomerGroup> => {
  const { data } = await api.customerGroups.put(id, payload)

  return data
}

export const deleteCustomerGroup = async (id: number): Promise<unknown> => {
  const { data } = await api.customerGroups.delete(id)

  return data
}

export const auditCustomerGroups = async (): Promise<Array<ICustomerGroupsAudit>[]> => {
  const { data } = await api.customerGroups.audit()
  return data.reverse()
}

export const getSubGroups = async (customerGroupIds?: number[]): Promise<string[]> => {
  if (Array.isArray(customerGroupIds) && customerGroupIds.length === 0) {
    return []
  }

  const { data } = await api.customerGroups.getSubgroups(customerGroupIds || [])

  return data
}

export const updateCustomerGroupProductPrice = async (price: IProductPrice): Promise<IProductPrice[]> => {
  const { data } = await api.customerGroups.updateProductPrice(price)
  return data
}

import { makeStyles } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import { palette, spacing, typography } from "../theme"

export const useStyles = makeStyles(() => ({
  small: {
    width: "70%",
    alignSelf: "flex-end",
  },
  spacingIcon: {
    paddingRight: spacing(2),
  },
  subOption: {
    backgroundColor: palette.grey[100],
    border: "none",
    display: "flex",
    flexDirection: "row",
    padding: spacing(1),
  },
  subOptionText: {
    "& span": {
      textTransform: "unset",
      fontWeight: typography.fontWeightRegular,
    },
  },
  subOptionButton: {
    top: "20px !important",
    right: "6px !important",
    "& svg": {
      fill: palette.text.primary,
    },
  },
  iconButton: {
    top: "27px !important",
    right: "22px !important",
    "& svg": {
      fill: palette.text.primary,
    },
  },
  ulList: {
    listStyle: "none",
    paddingInlineStart: 0,
  },
}))

export const ListTheme = createTheme({
  overrides: {
    MuiList: {
      root: {
        display: "flex",
        flexDirection: "column",
      },
    },
    MuiListItem: {
      root: {
        marginBottom: spacing(1),
        backgroundColor: palette.background.default,
        color: palette.text.primary,
        borderRadius: 4,
        border: `1px solid ${palette.grey[400]}`,
        alignItems: "unset",
        flexDirection: "column",
      },
      gutters: {
        padding: spacing(2),
      },
    },
    MuiButtonBase: {
      root: {
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        top: "28px",
        "& button": {
          color: palette.text.primary,
        },
      },
    },
    MuiListItemText: {
      root: {
        margin: "0 !important",
      },
      primary: {
        fontWeight: typography.fontWeightBold as number,
        textTransform: "uppercase",
      },
    },
  },
})

import React from "react"

export interface IMenuTab {
  id: number
  label: string
  path: string
  icon?: JSX.Element
  onClick?: () => void
}

export interface IMenu {
  children: React.ReactNode
}

export enum TabGroupOrientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum TabGroupVariant {
  FULL_WIDTH = "fullWidth",
  SCROLLABLE = "scrollable",
  STANDARD = "standard",
}

export enum TabGroupIndicatorColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export interface ITabGroup {
  orientation?: TabGroupOrientation
  variant?: TabGroupVariant
  value: number
  onChange: (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => void
  className?: string
  indicatorColor?: TabGroupIndicatorColor
  children: React.ReactNode
}

export interface ITabsItem {
  onClick: () => void
  icon?: JSX.Element
  label: string
}

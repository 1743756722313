import React from "react"
import { Switch as MuiSwitch, ThemeProvider } from "@material-ui/core"
import { SwitchTheme } from "./styles"

export interface ISwitch {
  id?: string
  name?: string
  checked?: boolean
  color: "primary" | "secondary"
  onChange: (e: React.ChangeEvent<any>) => void
  isDisabled?: boolean
}

export const Switch = (props: ISwitch) => {
  const { id, name, checked = false, color, onChange, isDisabled } = props

  return (
    <ThemeProvider theme={SwitchTheme}>
      <MuiSwitch id={id} name={id || name} checked={checked} color={color} onChange={onChange} disabled={isDisabled} />
    </ThemeProvider>
  )
}

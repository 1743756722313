import type { GridFilterItem, GridFilterModel, GridSortDirection, GridSortModel } from "@mui/x-data-grid"
import * as React from "react"
import { useQuery } from "react-query"

import type { GetCalculatorsParams } from "typings/services"
import { apiClient } from "services/ApiServiceAxios"
import { IAPICalculatorResponse } from "modules/Rules-Pricing/models/calculator"
import { buildUrlQueryParams } from "utils/general"
import { IApiPaginationResponse, IQueryParams } from "models/api"
import { ENV } from "constants/env"

const temporalInitialCalculatorsResponse: IApiPaginationResponse<IAPICalculatorResponse> = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0,
}

const fetchCalculators = async (params: GetCalculatorsParams) => {
  const queryParams: IQueryParams = {
    page: params.page.toString(),
    size: ENV.PAGINATION.DEFAULT_PAGE_SIZE.toString(),
    filterValue: {},
    sortModel: [
      {
        field: params.sort,
        sort: params.dir as GridSortDirection,
      },
    ],
  }

  if (params.searching) {
    queryParams.filterValue = {
      columnField: params.searching.key,
      value: params.searching.value,
    }
  }

  const query = buildUrlQueryParams(queryParams)

  const endpoint = `/calculators?${query}`

  const { data } = await apiClient.get<IApiPaginationResponse<IAPICalculatorResponse>>(endpoint)

  return data
}

const useCalculators = () => {
  const [filters, setFilters] = React.useState({
    page: 0,
    sortModel: [
      {
        field: "lastUpdate" as keyof IAPICalculatorResponse,
        sort: "desc",
      },
    ] as GridSortModel,
    searching: {
      id: 0,
      columnField: "name",
      operatorValue: "contains",
      value: "",
    } as GridFilterItem,
  })

  const updater = (newFilters: Partial<typeof filters>) => {
    setFilters(prev => ({ ...prev, ...newFilters }))
  }

  const [model] = filters.sortModel

  const queryKey = [
    "calculator-list",
    filters.page,
    model.field,
    model.sort,
    filters.searching.columnField,
    filters.searching.value,
  ]

  const query = useQuery(
    queryKey,
    () => {
      const params: GetCalculatorsParams = {
        page: filters.page,
        sort: model.field as GetCalculatorsParams["sort"],
        dir: model.sort.toUpperCase() as GetCalculatorsParams["dir"],
        searching: null,
      }

      if (filters.searching.columnField && filters.searching.value) {
        params.searching = {
          key: filters.searching.columnField as keyof IAPICalculatorResponse,
          value: filters.searching.value,
        }
      }

      return fetchCalculators(params)
    },
    {
      retry: 1,
    }
  )

  const handleFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    if (filterModel.items.length) {
      const [item] = filterModel.items

      if (item) {
        updater({ searching: item })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePage = (page: number) => {
    updater({ page })
  }

  const handleSort = (sortModel: GridSortModel) => {
    const [newModel] = sortModel

    const isTheSame = Boolean(newModel) && newModel.field === model.field && newModel.sort === model.sort

    if (query.isLoading || isTheSame) {
      return
    }

    if (!newModel) {
      const item = { ...model }

      item.sort = item.sort === "asc" ? "desc" : "asc"

      updater({ sortModel: [item] })

      return
    }

    updater({ sortModel })
  }

  return { query, filters, handleFilterChange, handlePage, handleSort }
}

export { temporalInitialCalculatorsResponse, useCalculators }

import React from "react"
import { Box, Typography } from "@material-ui/core"
import { Spacer } from "components"
import { HeaderContainer, Actions } from "./styles"

interface IHeaderProps {
  title: string
  subtitle?: string
  actions?: React.ReactNode
  align?: "left" | "center"
}

const Header = ({ title, actions, align, subtitle }: IHeaderProps): JSX.Element => {
  return (
    <HeaderContainer data-testid="header">
      <Box>
        <Typography variant="h1" color="textPrimary" align={align}>
          {title}
        </Typography>
        {subtitle && (
          <>
            <Spacer size={2} />
            <Typography variant="h2" color="textPrimary" align={align}>
              {subtitle}
            </Typography>
          </>
        )}
      </Box>
      {actions && <Actions>{actions}</Actions>}
    </HeaderContainer>
  )
}

Header.defaultProps = {
  actions: <></>,
  align: "left",
  subtitle: "",
}

export { Header }

import React from "react"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import { ThemeProvider } from "@material-ui/core"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { FormControlTheme } from "../styles"

export interface IDateInputProps {
  label?: string
  helperText?: string
  selectedDate?: MaterialUiPickersDate | null
  value?: string | null
  handleDateChange: (date: MaterialUiPickersDate | null, value?: string | null) => void
  error?: boolean
  minDate?: Date
}
const DateInput = ({
  label,
  selectedDate,
  handleDateChange,
  helperText,
  error,
  minDate,
}: IDateInputProps): JSX.Element => {
  return (
    <ThemeProvider theme={FormControlTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          data-testid="date-input"
          disableToolbar
          size="small"
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label={label}
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          helperText={helperText}
          InputAdornmentProps={{ position: "start" }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          error={error}
          minDate={minDate}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export { DateInput }

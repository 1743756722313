/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import { ThemeProvider, TextFieldProps, TextField } from "@material-ui/core"
import { IMaskMixin } from "react-imask"

import { FormControlTheme } from "components/Form/styles"
import { Field } from "./Field"

/**
 * @copyright https://github.com/uNmAnNeR/imaskjs/issues/321#issuecomment-598739251
 */
const MixinField = IMaskMixin<TextFieldProps, HTMLInputElement>(props => {
  return (
    <Field
      classes={props.classes}
      id={props.id}
      name={props.name}
      type={props.type}
      InputProps={props.InputProps}
      inputMode={props.inputMode}
      autoFocus={props.autoFocus}
      inputRef={props.inputRef}
      value={props.value}
    />
  )
})

const MixinInput = IMaskMixin<TextFieldProps, HTMLInputElement>(props => {
  const { type = "text", InputLabelProps, ...fieldProps } = props

  return (
    <ThemeProvider theme={FormControlTheme}>
      <TextField
        variant="outlined"
        size="small"
        type={type}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
        }}
        {...fieldProps}
      />
    </ThemeProvider>
  )
})

export { MixinField, MixinInput }

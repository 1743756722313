import React from "react"

const CircularRefIcon = (): JSX.Element => (
  <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8672 0.25H6.86719C3.55719 0.25 0.867188 2.94 0.867188 6.25C0.867188 9.56 3.55719 12.25 6.86719 12.25H16.8672C20.1772 12.25 22.8672 9.56 22.8672 6.25C22.8672 2.94 20.1772 0.25 16.8672 0.25ZM16.8672 10.25H6.86719C4.65719 10.25 2.86719 8.46 2.86719 6.25C2.86719 4.04 4.65719 2.25 6.86719 2.25H16.8672C19.0772 2.25 20.8672 4.04 20.8672 6.25C20.8672 8.46 19.0772 10.25 16.8672 10.25ZM16.8672 3.25C15.2072 3.25 13.8672 4.59 13.8672 6.25C13.8672 7.91 15.2072 9.25 16.8672 9.25C18.5272 9.25 19.8672 7.91 19.8672 6.25C19.8672 4.59 18.5272 3.25 16.8672 3.25Z"
      fill="#9FCF38"
    />
  </svg>
)

export { CircularRefIcon }

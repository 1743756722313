import pkg from "../../package.json"

const config = process.env

const ENV = {
  ENVIRONMENT: String(config.REACT_APP_ENV),
  IS_DEV: Boolean(config.REACT_APP_ENV === "development"),
  IS_STAGING: Boolean(config.REACT_APP_ENV === "staging"),
  IS_PROD: Boolean(config.REACT_APP_ENV === "production"),
  VERSION: pkg.version,
  API: {
    URL: String(config.REACT_APP_API_URL),
    MAX_RETRIES: 1,
    RETRY_TIMEOUT: 5000,
  },
  API_APPSERVER: {
    URL: String(config.REACT_APP_API_APPSERVER_URL),
    MAX_RETRIES: 1,
    RETRY_TIMEOUT: 5000,
  },
  PAGINATION: {
    DEFAULT_PAGE_SIZE: 20,
  },
  /**
   * Maximum file size by default to put files inside the drag-and-drop: `10MB`.
   */
  MAX_FILE_SIZE: 10485760,
}

export { ENV }

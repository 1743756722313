import { createTheme } from "@material-ui/core/styles"
import { rem, transparentize } from "polished"
import { palette, spacing, typography } from "../theme"

export const DialogTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: rem("524px"),
        backgroundColor: palette.background.default,
        boxShadow: "0px 8px 30px rgba(98, 105, 139, 0.25)",
        borderRadius: rem("16px"),
        minHeight: rem("181px"),
        overflow: "hidden !important",
      },
      paperFullWidth: {
        width: "calc(100% - 130px)",
      },
      paperScrollPaper: {
        maxHeight: "calc(100% - 130px)",
      },
    },
    MuiDialogTitle: {
      root: {
        display: "flex",
        alignItems: "center",
        lineHeight: rem("21px"),
        minWidth: rem("476px"),
        padding: spacing(3),
        "& h3": {
          flex: 1,
          fontSize: typography.h3.fontSize,
          fontWeight: typography.fontWeightBold,
        },
      },
    },
    MuiDialogContent: {
      root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        lineHeight: spacing(3),
        minWidth: rem("476px"),
        padding: spacing(0, 3),
        "& h4": {
          flex: 1,
          color: palette.grey[800],
          fontSize: typography.h4.fontSize,
          fontWeight: typography.fontWeightRegular,
          textTransform: "none",
        },
      },
    },
    MuiDialogActions: {
      root: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        minWidth: rem("476px"),
        padding: spacing(3),
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: transparentize(0.2, palette.text.primary),
      },
    },
  },
})

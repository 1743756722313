import { Box, makeStyles, styled } from "@material-ui/core"
import { palette, spacing } from "components/theme"
import { rem } from "polished"

export const DashboardContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: rem("6px"),
  overflowX: "hidden",
  height: "fit-content",
}))

export const DashboardHeader = styled(Box)(() => ({
  marginTop: rem("15px"),
  display: "flex",
  flexDirection: "row",
  marginBottom: spacing(4),
  paddingBottom: spacing(2),
  borderBottom: `1px solid ${palette.grey[600]}`,
  position: "relative",
  "& div[class*='MuiFormControl-root']": {
    width: rem("310px"),
    margin: spacing(1),
  },
  "& div[class*='MuiBox-root']": {
    "& div[class*='MuiFormControl-root']": {
      width: "auto !important",
      minWidth: 180,
    },
  },
}))

export const StatusSwitchContainer = styled(Box)(() => ({
  margin: spacing(1, 0, 0, 2),
  display: "flex",
  minWidth: "50px",
  position: "relative",
  top: rem("-5px"),
  "& p": {
    position: "relative",
    top: rem("-9.5px"),
  },
  "& span[class*='MuiSwitch-root']": {
    margin: spacing(0, 1),
    position: "relative",
    top: rem("26px"),
  },
}))

export const CircularSwitchContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  flex: 1,
  alignItems: "center",
  marginTop: spacing(2),
  "& span[class*='MuiSwitch-root']": {
    margin: "-8px 0px",
  },
}))

export const DashboardContentHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  margin: spacing(2, 0),
}))

export const RuleSetHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: rem(spacing(1)),
  "& .MuiIconButton-root": {
    padding: spacing(0),
    position: "relative",
    top: rem("-2.5px"),
  },
}))

export const DashboardAsideContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexBasis: "20%",
  overflow: "hidden scroll",
  height: "calc(100vh - 350px)",
  gap: rem("20px"),
  padding: spacing(0, 3, 0, 0),
  "&::-webkit-scrollbar": {
    backgroundColor: palette.grey[400],
    width: "5px",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: palette.grey[600],
    borderRadius: "5px",
  },
  "& + .MuiBox-root": {
    flexBasis: "80%",
  },
}))

export const DashboardMainContent = styled(Box)(() => ({
  display: "flex",
  flexBasis: "100%",
  flexDirection: "column",
  overflow: "hidden auto",
  padding: spacing(0, 3, 0, 0),
  height: "calc(100vh - 350px)",
  "&::-webkit-scrollbar": {
    backgroundColor: palette.grey[400],
    width: "5px",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: palette.grey[600],
    borderRadius: "5px",
  },
}))

export const IconButtonContainer = styled(Box)(() => ({
  display: "flex",
  gap: 8,
}))

export const dashboardLayoutStyles = makeStyles(theme => ({
  asideOpen: {
    flexBasis: "20%",
  },
  asideClose: {
    flexBasis: "5%",
  },
  MainContainerClose: {
    flexBasis: "80%",
  },
  MainContainerOpen: {
    flexBasis: "95%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: "250ms",
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  iconButton: {
    "& disabled": {
      color: "grey",
    },
  },
}))

export const StyledPublishDate = styled(Box)(() => ({
  display: "flex",
  justifyContent: "row",
  "& div": {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
}))

import React from "react"
import { IconButtonProps, IconButton as MuiIconButton, ThemeProvider } from "@material-ui/core"
import { IconButtonTheme } from "./styles"

export interface IIconButton extends IconButtonProps {
  icon: React.ReactNode
  ariaLabel?: string
  color?: "inherit" | "primary" | "secondary"
  size?: "small" | "medium"
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  className?: string
  isDisabled?: boolean
}

export const IconButton = ({
  color = "inherit",
  icon,
  size = "medium",
  ariaLabel,
  onClick,
  className,
  isDisabled,
}: IIconButton): JSX.Element => {
  return (
    <ThemeProvider theme={IconButtonTheme}>
      <MuiIconButton
        className={className}
        disableFocusRipple
        color={color}
        size={size}
        aria-label={ariaLabel}
        onClick={onClick}
        data-testid="icon-button"
        disabled={isDisabled}
      >
        {icon}
      </MuiIconButton>
    </ThemeProvider>
  )
}

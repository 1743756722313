import type { IBulkErrorMessage } from "models/bulk"
import { ICustomerGroup } from "modules/Customers/models/customerGroup"
import type { IRuleArgument, IRuleAttributeArgument, IRuleState } from "typings/modules"
import { ISelectOption } from "components"
import { CalculatorConditionValue, getCalculatorIdTrigger, OperationTypes, Operators } from "constants/calculator"
import { BULK_STATUS } from "constants/bulk"
import { getUniqueID } from "utils/general"
import { IStepGroupType } from "modules/Rules-Pricing/models/calculator"

export const getInitialStep = (order: number): IStepState => ({
  order,
  internalId: getUniqueID(),
  name: "",
  rules: [],
  isOnEditMode: true,
  cycleReference: false,
  imported: false,
})

const initialStep = getInitialStep(1)

export const getInitialStepGroup = (tabValue: number): IStepGroupState => ({
  tabValue,
  internalId: getUniqueID(),
  name: "",
  type: "",
  steps: [initialStep],
  isOnEditMode: true,
  main: false,
})

export interface IStepState {
  id?: number
  internalId: number
  order: number
  name: string
  rules: IRuleState[]
  isOnEditMode: boolean
  cycleReference: boolean
  imported: boolean
}

export interface IStepGroupState {
  id?: number
  internalId: number
  tabValue: number
  name: string
  type: string
  steps: IStepState[]
  isOnEditMode: boolean
  description?: string
  main: boolean
}

export interface ICalculatorState {
  id?: number
  internalId: number
  name: string
  description?: string
  stepGroups: IStepGroupState[]
  customerGroups: string[]
  isActive: boolean
  selectedStepGroup?: IStepGroupState // used for rendering its steps
  selectedStep?: IStepState // used for creating and showing rules of that step
  calculationUuid: string
  systemObjectOptions: ISelectOption[]
  systemObjectAttributeOptions?: ISelectOption[]
  stepGroupTypes?: IStepGroupType[]
  calculatorNotFound?: boolean
  isLoading?: boolean // something in the calculator is being loaded
  isValid?: boolean
  hasSavedRulesSuccessfully?: boolean // represent the successfully saved rules
  navigateToRuleCreator?: IStepState
  calculatorDuplicated?: number
  isNew: boolean
  calculatorCreated?: boolean
  navigateToCalculator?: boolean
  calculationErrors?: IBulkErrorMessage[]
  tier: string
}

export interface ISystemObjectActionCreatorParams {
  calculatorId?: number
  calculatorTier?: string
}

export interface ISaveCalculatorState {
  calculatorState: ICalculatorState
  stepGroupTypes?: IStepGroupType[]
  customerGroups?: ICustomerGroup[]
}

export interface IGetCalculatorByIdParams {
  calculatorId: number
  stepGroupTypes?: IStepGroupType[]
  systemObjectOptions?: ISelectOption[]
  trigger: getCalculatorIdTrigger
  currentCalculatorState?: ICalculatorState
}

export interface IRuleCreatorState {
  editableRule: IRuleState
}

export const calculatorInitialValues: ICalculatorState = {
  id: undefined,
  internalId: parseInt("", 10),
  name: "",
  description: "",
  stepGroups: [],
  systemObjectOptions: [],
  customerGroups: [],
  isActive: false,
  calculationUuid: "",
  selectedStepGroup: {} as IStepGroupState,
  selectedStep: {} as IStepState,
  isLoading: true,
  isNew: false,
  tier: "",
}

export const newCalculator: ICalculatorState = {
  id: undefined,
  internalId: getUniqueID(),
  name: "",
  description: "",
  stepGroups: [],
  systemObjectOptions: [],
  customerGroups: [],
  isActive: false,
  selectedStepGroup: undefined,
  calculationUuid: "",
  selectedStep: initialStep,
  isLoading: true,
  isNew: true,
  calculatorCreated: true,
  tier: "",
}

export const ruleCreatorInitialValues: IRuleState = {
  internalId: getUniqueID(),
  order: 1,
  comparatorOrder: 0,
  ruleName: "",
  isValid: false,
  operator: "" as Operators,
  type: "" as OperationTypes,
  conditionValue: CalculatorConditionValue.EMPTY,
  firstArgument: { value: "", attribute: undefined } as IRuleArgument,
  secondArgument: { value: "", attribute: undefined } as IRuleArgument,
  nameIsEditable: false,
}

export const initialArgument: IRuleArgument = {
  type: OperationTypes.SYSTEM_OBJ,
  name: "" as keyof IRuleAttributeArgument,
  value: "",
  attribute: "",
}

export const getInitialRule = (order: number): IRuleState =>
  ({
    internalId: getUniqueID(),
    ruleName: "",
    order,
    comparatorOrder: 0,
    isValid: false,
    operator: "" as Operators,
    conditionValue: CalculatorConditionValue.EMPTY,
    nameIsEditable: true,
    firstArgument: initialArgument,
    secondArgument: initialArgument,
    thirdArgument: initialArgument,
  } as IRuleState)

export interface IUserState {
  name: string
  password: string
  loggedIn: boolean
}

export const userInitialValues: IUserState = {
  name: "",
  password: "",
  loggedIn: false,
}

export interface IBulkUploadState {
  status: BULK_STATUS
  itemDeleted: boolean
}

export const bulkUploadInitialValues: IBulkUploadState = {
  status: "" as BULK_STATUS,
  itemDeleted: false,
}

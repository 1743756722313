import _ from "lodash"
import { v4 as uuidV4 } from "uuid"

import type { ISelectOption } from "components"
import { CalculatorConditionValue } from "constants/calculator"
import type { IRule, IRuleArgument, IRuleAttributeArgument, IRuleState, IStep } from "typings/modules"
import { IOperationInputRequest, OperationTypeToLabelMap } from "../models/calculator"
import { BLOCKED_CONDITIONAL_ARGUMENTS, CONDITIONAL_OPERATORS_LIST } from "./calculator"
import { getNewRuleState } from "./utils"

const getSystemObjectValue = (inputValue: string, systemObjects: ISelectOption[]) => {
  const foundItem = systemObjects.find((systemObjectOption: ISelectOption) => {
    const children = systemObjectOption.children || []

    const item = children.find(attribute => attribute.value === inputValue)

    return item
  })

  return foundItem?.value || ""
}

const formatFirstConditionValue = (rule: IRule) => {
  if (CONDITIONAL_OPERATORS_LIST.includes(rule.operation.name as typeof CONDITIONAL_OPERATORS_LIST[number])) {
    return CalculatorConditionValue.SELECTIVE
  }

  return CalculatorConditionValue.EMPTY
}

const formatStateArgument = (input: IOperationInputRequest, systemObjects: ISelectOption[]): IRuleArgument => {
  const value =
    OperationTypeToLabelMap[input.inputType as keyof typeof OperationTypeToLabelMap] ||
    getSystemObjectValue(input.value, systemObjects)

  const arg = {
    id: input.id,
    type: input.inputType,
    name: "" as keyof IRuleAttributeArgument,
    value,
    attribute: input.value.toString(),
  }

  return arg
}

const formatStepGroupRule = (rule: IRule, systemObjectOptions: ISelectOption[]) => {
  const ruleName = rule.name

  const [firstInput, secondInput, thirdInput] = rule.operation.inputs

  let ruleState = {} as IRuleState

  const firstConditionValue = formatFirstConditionValue(rule)

  const firstArgument = formatStateArgument(firstInput, systemObjectOptions)

  const secondArgument = BLOCKED_CONDITIONAL_ARGUMENTS.includes(
    (rule.operation.name as unknown) as typeof BLOCKED_CONDITIONAL_ARGUMENTS[number]
  )
    ? ({} as IRuleArgument)
    : formatStateArgument(secondInput, systemObjectOptions)

  const isConditionalOperatorValid = CONDITIONAL_OPERATORS_LIST.includes(
    rule.operation.name as typeof CONDITIONAL_OPERATORS_LIST[number]
  )

  if (isConditionalOperatorValid) {
    ruleState = {
      internalId: uuidV4(),
      comparatorId: rule.id,
      comparatorOperationId: rule.operation.id,
      comparatorOrder: rule.order,
      conditionValue: firstConditionValue,
      firstArgument,
      secondArgument,
      ruleName: "",
      operator: rule.operation.name,
      order: rule.order,
      created: rule.created,
    } as IRuleState
  }

  if (rule.operation.name === "CONDITIONAL") {
    ruleState = {
      ...ruleState,
      id: rule.id,
      ruleName,
      operationId: rule.operation.id,
      thirdArgument: secondInput ? secondArgument : ({} as IRuleArgument),
      fourthArgument: thirdInput ? formatStateArgument(thirdInput, systemObjectOptions) : ({} as IRuleArgument),
      conditionalInputId: firstInput.id,
      order: rule.order,
      created: rule.created,
    }
  }

  if (!isConditionalOperatorValid && rule.operation.name !== "CONDITIONAL") {
    ruleState = {
      id: rule.id,
      internalId: uuidV4(),
      order: rule.order,
      ruleName,
      conditionValue: firstConditionValue,
      firstArgument,
      secondArgument,
      operator: rule.operation.name,
      comparatorOrder: 0,
      operationId: rule.operation.id,
      created: rule.created,
    } as IRuleState
  }

  ruleState.isValid = true

  return ruleState
}

const mapEmptyRule = getNewRuleState

const formatStepRules = (step: IStep, systemObjectOptions: ISelectOption[]) => {
  const rules = _.reduce(
    step.rules,
    (collection, rule) => {
      const formattedRule = formatStepGroupRule(rule, systemObjectOptions)

      if (rule.operation.name === "CONDITIONAL") {
        const lastRule = collection[collection.length - 1]

        const newRule: IRuleState = {
          ...lastRule,
          ...formattedRule,
        }

        collection[collection.length - 1] = newRule
      } else {
        collection.push(formattedRule)
      }

      return collection
    },
    [] as IRuleState[]
  )

  return rules
}

export { formatStepRules, mapEmptyRule }

import React from "react"
import { Chip as MuiChip, ChipProps, ThemeProvider } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { ChipTheme } from "./styles"

export interface IChipProps extends ChipProps {
  handleDelete?(): any
}
const Chip = ({ color, size, label, handleDelete, variant, icon }: IChipProps): JSX.Element => {
  return (
    <ThemeProvider theme={ChipTheme}>
      <MuiChip
        size={size}
        color={color}
        label={label}
        variant={variant}
        onDelete={handleDelete}
        deleteIcon={<CloseIcon />}
        data-testid="chip"
        icon={icon}
      />
    </ThemeProvider>
  )
}

export { Chip }

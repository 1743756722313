import React, { ReactChild, ReactFragment, ReactPortal } from "react"
import { Accordion as MuiAccordion, ThemeProvider } from "@material-ui/core"
import classNames from "classnames"
import { AccordionTheme, accordionStyles } from "./styles"

export interface IAccordion {
  children: boolean | ReactChild | ReactFragment | ReactPortal
  size?: "small" | "medium" | "large"
  expanded?: boolean
  onChange?: (event: React.ChangeEvent<Record<string, unknown>>, expanded: boolean) => void
}

export const Accordion = ({ children, size, expanded, onChange }: IAccordion): JSX.Element => {
  const classes = accordionStyles()
  return (
    <ThemeProvider theme={AccordionTheme}>
      <MuiAccordion
        className={classNames({
          [classes.small]: size === "small",
          [classes.medium]: size === "medium",
          [classes.large]: size === "large",
          [classes.selected]: expanded === true,
        })}
        data-testid="accordion"
        expanded={expanded}
        onChange={onChange}
        TransitionProps={{ unmountOnExit: true }}
      >
        {children}
      </MuiAccordion>
    </ThemeProvider>
  )
}

Accordion.defaultProps = {
  size: "medium",
}

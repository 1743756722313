import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import _ from "lodash"

import type { IBulkErrorMessage } from "models/bulk"
import {
  getNextStepOrder,
  getSelectedStepGroupIndex,
  getSelectedStepIndex,
  getStepGroupIndexByTabValue,
  isCalculatorValid,
} from "utils/calculators"
import { ISelectOption } from "components"
import { IStepGroupType, LabelToOperationTypeMap } from "modules/Rules-Pricing/models/calculator"
import type { IRuleArgument, IRuleAttributeArgument, IRuleState } from "typings/modules"
import { CalculatorConditionValue, OperationTypes, Operators } from "constants/calculator"
import {
  calculatorInitialValues,
  getInitialStep,
  getInitialStepGroup,
  ICalculatorState,
  IStepGroupState,
  IStepState,
  newCalculator,
} from "./state"
import { ruleCreatorActions } from "./ruleCreator-slice"

export const calculatorSlice = createSlice({
  name: "calculator",
  initialState: calculatorInitialValues,
  reducers: {
    createCalculator: prevState => {
      Object.assign(prevState, { ...prevState, ...newCalculator })
    },
    calculatorUpdated: (prevState, action: PayloadAction<ICalculatorState>) => {
      Object.assign(prevState, action.payload)
    },
    calculatorDuplicated: (prevState, action: PayloadAction<number>) => {
      prevState.calculatorDuplicated = action.payload
    },
    cleanCalculatorDuplicated: prevState => {
      prevState.calculatorDuplicated = undefined
    },
    cleanCalculatorCreated: prevState => {
      prevState.calculatorCreated = false
    },
    calculatorIsLoading: prevState => {
      prevState.isLoading = true
    },
    calculatorIsNotLoading: prevState => {
      prevState.isLoading = false
    },
    navigateToRuleCreator: (prevState, action: PayloadAction<IStepState>) => {
      prevState.navigateToRuleCreator = action.payload
    },
    cleanNavigateToRuleCreator: prevState => {
      prevState.navigateToRuleCreator = undefined
    },
    navigateToCalculator: prevState => {
      prevState.navigateToCalculator = true
    },
    cleanNavigateToCalculator: prevState => {
      prevState.navigateToCalculator = false
    },
    calculatorNameChanged: (prevState, action: PayloadAction<string>) => {
      prevState.name = action.payload
    },
    customerGroupChanged: (prevState, action: PayloadAction<any>) => {
      prevState.customerGroups = [...action.payload]
    },
    calculatorDescriptionChanged: (prevState, action: PayloadAction<string>) => {
      prevState.description = action.payload
    },
    calculatorStatusChanged: (prevState, action: PayloadAction<boolean>) => {
      prevState.isActive = action.payload
    },
    stepGroupAdded: prevState => {
      const tabValues = prevState.stepGroups.map(stepGroup => stepGroup.tabValue)

      const tabValue = Math.max(...tabValues, 0)

      const initialStepGroup = getInitialStepGroup(tabValue + 1)

      prevState.stepGroups.unshift(initialStepGroup)

      prevState.selectedStepGroup = initialStepGroup
    },
    setStepGroupAsNotEditable: (prevState, action: PayloadAction<number>) => {
      const stepGroupIndex = _.findIndex(prevState.stepGroups, {
        tabValue: action.payload,
      })

      prevState.stepGroups[stepGroupIndex].isOnEditMode = false
    },
    setStepGroupAsEditable: (prevState, action: PayloadAction<number>) => {
      const stepGroupIndex = _.findIndex(prevState.stepGroups, {
        tabValue: action.payload,
      })

      prevState.stepGroups[stepGroupIndex].isOnEditMode = true
    },
    switchStepIsEditable: (
      prevState,
      action: PayloadAction<{
        stepId: number
        stepGroupIndex: number
        stepIndex: number
      }>
    ) => {
      const { stepId, stepGroupIndex, stepIndex } = action.payload

      if (stepId) {
        const orderedSteps = _.orderBy(prevState.stepGroups[stepGroupIndex].steps, ["order"], ["desc"])

        const currentStep = orderedSteps[stepIndex]

        const currentIndex = _.findIndex(prevState.stepGroups[stepGroupIndex].steps, { id: currentStep.id })

        const currentState = prevState.stepGroups[stepGroupIndex].steps[currentIndex].isOnEditMode

        prevState.stepGroups[stepGroupIndex].steps[currentIndex].isOnEditMode = !currentState
      } else {
        const currentState = prevState.stepGroups[stepGroupIndex].steps[stepIndex].isOnEditMode

        prevState.stepGroups[stepGroupIndex].steps[stepIndex].isOnEditMode = !currentState
      }
    },
    stepGroupNameChanged: (
      prevState,
      action: PayloadAction<{
        stepGroupName: string
        tabValue?: number
      }>
    ) => {
      const stepGroupIndex = getStepGroupIndexByTabValue(prevState.stepGroups, action.payload.tabValue)

      prevState.stepGroups[stepGroupIndex].name = action.payload.stepGroupName
    },
    stepGroupDescriptionChanged: (
      prevState,
      action: PayloadAction<{
        stepGroupDescription: string
        tabValue?: number
      }>
    ) => {
      const stepGroupIndex = getStepGroupIndexByTabValue(prevState.stepGroups, action.payload.tabValue)

      prevState.stepGroups[stepGroupIndex].description = action.payload.stepGroupDescription
    },
    stepGroupTypeChanged: (
      prevState,
      action: PayloadAction<{
        stepGroupType: string
        tabValue?: number
      }>
    ) => {
      const stepGroupIndex = getStepGroupIndexByTabValue(prevState.stepGroups, action.payload.tabValue)

      const stepGroup = prevState.stepGroups[stepGroupIndex]

      stepGroup.type = action.payload.stepGroupType
    },
    stepGroupTypeDeleted: (
      prevState,
      action: PayloadAction<{
        stepGroupType: string
        tabValue?: number
      }>
    ) => {
      const stepGroupIndex = getStepGroupIndexByTabValue(prevState.stepGroups, action.payload.tabValue)

      prevState.stepGroups[stepGroupIndex].type = action.payload.stepGroupType
    },
    setStepName: (
      prevState,
      action: PayloadAction<{
        stepIndex: number
        stepName: string
      }>
    ) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)

      prevState.stepGroups[stepGroupIndex].steps[action.payload.stepIndex].name = action.payload.stepName
    },
    updateStepName: (
      prevState,
      action: PayloadAction<{
        stepGroupIndex: number
        stepIndex: number
        stepName: string
      }>
    ) => {
      const { stepGroupIndex, stepIndex, stepName } = action.payload

      const orderedSteps = _.orderBy(prevState.stepGroups[stepGroupIndex].steps, ["order"], ["desc"])

      const currentStep = orderedSteps[stepIndex]

      const currentIndex = _.findIndex(prevState.stepGroups[stepGroupIndex].steps, { id: currentStep.id })

      prevState.stepGroups[stepGroupIndex].steps[currentIndex].name = stepName
    },
    stepAdded: prevState => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)

      const order = getNextStepOrder(prevState.stepGroups, stepGroupIndex)

      const initialStep = getInitialStep(order)

      prevState.stepGroups[stepGroupIndex].steps.unshift(initialStep)

      prevState.selectedStep = initialStep
    },
    addStepToStepGroup: (prevState, action: PayloadAction<number>) => {
      const order = getNextStepOrder(prevState.stepGroups, action.payload)
      const initialStep = getInitialStep(order)
      prevState.stepGroups[action.payload].steps.unshift(initialStep)
      prevState.selectedStep = initialStep
    },
    setSelectedStep: (prevState, action: PayloadAction<IStepState>) => {
      prevState.selectedStep = { ...action.payload }
    },
    setSelectedStepGroup: (prevState, action: PayloadAction<IStepGroupState>) => {
      prevState.selectedStepGroup = { ...action.payload }
    },
    setStepGroupState: (prevState, action: PayloadAction<IStepGroupState>) => {
      const stepGroupIndex = getStepGroupIndexByTabValue(prevState.stepGroups, action.payload.tabValue)

      prevState.stepGroups[stepGroupIndex] = { ...action.payload }
    },
    cleanSelectedStep: prevState => {
      prevState.selectedStep = {} as IStepState
    },
    ruleAdded: (prevState, action: PayloadAction<IRuleState>) => {
      if (action.payload.isValid) {
        const stepGroupIndex = getSelectedStepGroupIndex(prevState)

        const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)

        prevState.stepGroups[stepGroupIndex]?.steps[stepIndex].rules.unshift(action.payload)
      }
    },
    cleanState: prevState => {
      Object.assign(prevState, { ...prevState, ...calculatorInitialValues })
    },
    updateCalculationUuid: (prevState, action: PayloadAction<string>) => {
      prevState.calculationUuid = action.payload
    },
    systemObjectsUpdated: (prevState, action: PayloadAction<ISelectOption[]>) => {
      prevState.systemObjectOptions = action.payload
    },
    systemObjectAttributesUpdated: (prevState, action: PayloadAction<ISelectOption[]>) => {
      prevState.systemObjectAttributeOptions = action.payload
    },
    stepGroupTypesUpdated: (prevState, action: PayloadAction<IStepGroupType[]>) => {
      prevState.stepGroupTypes = action.payload
    },
    calculatorNotFound: prevState => {
      prevState.calculatorNotFound = true
    },
    editableRuleNameChanged: (
      prevState,
      action: PayloadAction<{
        ruleName: string
        ruleIndex: number
      }>
    ) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)

      const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)

      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload.ruleIndex].ruleName =
        action.payload.ruleName
    },
    switchRuleNameIsEditable: (prevState, action: PayloadAction<number>) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)
      const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)
      const currentState = prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload].nameIsEditable
      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload].nameIsEditable = !currentState
    },
    setArgumentOption: (
      prevState,
      action: PayloadAction<{
        value: string
        argument: keyof IRuleAttributeArgument
        ruleIndex: number
      }>
    ) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)
      const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)
      const clonedArgument = _.cloneDeep(
        prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload.ruleIndex][
          action.payload.argument
        ] as IRuleArgument
      )

      const keyLabelTopOperation = action.payload.value as keyof typeof LabelToOperationTypeMap

      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload.ruleIndex][action.payload.argument] = {
        ...clonedArgument,
        value: action.payload.value,
        type: LabelToOperationTypeMap[keyLabelTopOperation] || OperationTypes.SYSTEM_OBJ,
        attribute: "",
      }
    },
    setArgumentAttribute: (
      prevState,
      action: PayloadAction<{
        value: string
        argument: keyof IRuleAttributeArgument
        ruleIndex: number
      }>
    ) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)

      const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)

      const argument = _.cloneDeep(
        prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload.ruleIndex][action.payload.argument]
      ) as IRuleArgument

      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload.ruleIndex][action.payload.argument] = {
        ...argument,
        attribute: action.payload.value,
      }
    },
    operatorChanged: (
      prevState,
      action: PayloadAction<{
        operator: string
        ruleIndex: number
      }>
    ) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)
      const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)
      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload.ruleIndex].operator = action.payload
        .operator as Operators
    },
    setConditionValue: (
      prevState,
      action: PayloadAction<{
        value: CalculatorConditionValue
        ruleIndex: number
      }>
    ) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)
      const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)
      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[
        action.payload.ruleIndex
      ].firstArgument = {} as IRuleArgument
      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[
        action.payload.ruleIndex
      ].secondArgument = {} as IRuleArgument
      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[
        action.payload.ruleIndex
      ].thirdArgument = {} as IRuleArgument
      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[
        action.payload.ruleIndex
      ].fourthArgument = {} as IRuleArgument
      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload.ruleIndex].operator = "" as Operators

      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules[action.payload.ruleIndex].conditionValue =
        action.payload.value
    },
    checkCalculatorValidity: prevState => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)
      const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)
      prevState.isValid = isCalculatorValid(prevState, stepGroupIndex, stepIndex)
    },
    hasSavedRulesSuccessfully: prevState => {
      prevState.hasSavedRulesSuccessfully = true
    },
    cleanSavedRulesSuccessfully: prevState => {
      prevState.hasSavedRulesSuccessfully = false
    },
    deleteStepGroup: (prevState, action: PayloadAction<IStepGroupState>) => {
      let index
      if (action.payload.id) {
        index = prevState.stepGroups.findIndex((stepGroup: IStepGroupState) => stepGroup.id === action.payload.id)
      } else {
        index = prevState.stepGroups.findIndex(
          (stepGroup: IStepGroupState) => stepGroup.internalId === action.payload.internalId
        )
      }
      prevState.stepGroups.splice(index, 1)
      prevState.selectedStepGroup = prevState.stepGroups[prevState.stepGroups.length - 1]
    },
    deleteStep: (prevState, action: PayloadAction<IStepState>) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)

      let stepIndex

      if (action.payload.id) {
        stepIndex = prevState.stepGroups[stepGroupIndex].steps.findIndex(
          (step: IStepState) => step.id === action.payload.id
        )
      } else {
        stepIndex = prevState.stepGroups[stepGroupIndex].steps.findIndex(
          (step: IStepState) => step.internalId === action.payload.internalId
        )
      }

      prevState.stepGroups[stepGroupIndex].steps.splice(stepIndex, 1)

      /**
       * @description If the deleted step is the last one, we need to select the previous one
       */
      ;[prevState.selectedStep] = prevState.stepGroups[stepGroupIndex].steps
    },
    deleteRule: (prevState, action: PayloadAction<number | undefined>) => {
      const stepGroupIndex = getSelectedStepGroupIndex(prevState)
      const stepIndex = getSelectedStepIndex(prevState, stepGroupIndex)
      const index = prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules.findIndex(
        (rule: IRuleState) => rule.internalId === action.payload
      )
      prevState.stepGroups[stepGroupIndex].steps[stepIndex].rules.splice(index, 1)
    },
    cycleReferenceChanged: (
      prevState,
      action: PayloadAction<{
        cycleReference: boolean
        stepGroupIndex: number
        stepInternalId: number
      }>
    ) => {
      const { stepGroupIndex, stepInternalId, cycleReference } = action.payload

      const stepIndex = _.findIndex(prevState.stepGroups[stepGroupIndex].steps, { internalId: stepInternalId })

      prevState.stepGroups[stepGroupIndex].steps[stepIndex].cycleReference = cycleReference
    },
    setCalculationErrors: (prevState, action: PayloadAction<IBulkErrorMessage[]>) => {
      prevState.calculationErrors = [...action.payload]
    },
    cleanCalculationErrors: prevState => {
      prevState.calculationErrors = undefined
    },
    customerGroupTierChanged: (prevState, action: PayloadAction<string>) => {
      prevState.tier = action.payload
    },
  },
})

export const ruleAdded = createAsyncThunk("ruleAdded", async (rule: IRuleState, thunkAPI) => {
  await thunkAPI.dispatch(calculatorSlice.actions.ruleAdded(rule))

  const state = thunkAPI.getState() as { calculator: ICalculatorState }

  thunkAPI.dispatch(ruleCreatorActions.emptyRuleAdded(state.calculator))
})

export const calculatorActions = calculatorSlice.actions

export const calculatorReducer = calculatorSlice.reducer

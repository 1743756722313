export enum BULK_STATUS {
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
  ERROR = "ERROR",
  DELETED = "DELETED",
  COMPLETED = "COMPLETED",
}

export const TABLE = {
  COMPETITORS: "COMPETITORS",
  PRODUCTS: "PRODUCTS",
  CUSTOMERS: "CUSTOMERS",
  CUSTOMERS_GROUPS: "CUSTOMERS_GROUPS",
  PRODUCTS_BY_CUSTOMERS_GROUP: "PRODUCTS_BY_CUSTOMERS_GROUP",
}

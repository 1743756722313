import { createTheme } from "@material-ui/core/styles"
import { palette, spacing, typography } from "../theme"

export const BreadcrumbsTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: palette.grey[500],
        textTransform: "capitalize",
      },
    },
  },
})

import React from "react"
import { Typography } from "@material-ui/core"
import { StyledWizardHeader, TableWizardHeader } from "../styles"

interface IWizardHeader {
  title: string
  subtitle?: string
  table?: boolean
}

const WizardHeader = ({ title, subtitle, table }: IWizardHeader): JSX.Element => {
  return table ? (
    <TableWizardHeader>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body1">{subtitle}</Typography>
    </TableWizardHeader>
  ) : (
    <StyledWizardHeader>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body1">{subtitle}</Typography>
    </StyledWizardHeader>
  )
}

WizardHeader.defaultProps = {
  subtitle: null,
  table: false,
}

export { WizardHeader }

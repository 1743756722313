import { GridCellParams, GridColDef, GridRowId, GridRowModel } from "@mui/x-data-grid"
import React from "react"
import { IconButton, MenuOptionNames, OverrideIcon } from "components"
import { Box } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"

export interface IWizardStep {
  id: number
  label: WizardLabel
}

export enum WizardLabel {
  PROVIDER = "Provider",
  PRODUCT = "Product",
  CUSTOMERS_PRICING = "Customers & Pricing",
  SUMMARY = "Summary",
}

export const wizardSteps: IWizardStep[] = [
  {
    id: 1,
    label: WizardLabel.PROVIDER,
  },
  {
    id: 2,
    label: WizardLabel.PRODUCT,
  },
  {
    id: 3,
    label: WizardLabel.CUSTOMERS_PRICING,
  },
]

export const menuOptions = [
  { name: MenuOptionNames.EDIT, icon: <EditOutlinedIcon /> },
  {
    name: MenuOptionNames.OVERRIDE,
    icon: <OverrideIcon />,
    confirm: [<CloseIcon />, <CheckCircleOutlineIcon />],
  },
  { name: MenuOptionNames.DELETE, icon: <DeleteOutlineIcon /> },
]

export const getSummaryColumns = (
  onEditIconClick: (event: React.MouseEvent<HTMLElement>, id: GridRowId, row: GridRowModel) => void,
  onOverrideIconClick: (event: React.MouseEvent<HTMLElement>, id: GridRowId) => void
): GridColDef[] => [
  {
    field: "customerGroup",
    headerName: "Customer Group",
    flex: 1,
    editable: false,
  },

  { field: "vcost", headerName: "VS Cost", flex: 0.6, editable: true },
  {
    field: "fee",
    headerName: "Fee",
    flex: 0.4,
    editable: true,
  },
  {
    field: "feeStrategy",
    headerName: "Fee Strategy",
    flex: 0.8,
    editable: true,
  },
  { field: "adqCost", headerName: "ADQ Cost", flex: 0.6, editable: true },
  { field: "vsPrice", headerName: "VS Price", flex: 0.6, editable: true },
  {
    field: "retailPrice",
    headerName: "Retail Price",
    flex: 0.6,
    editable: true,
  },
  {
    field: "wholesalePrice",
    headerName: "Wholesale Price",
    flex: 0.6,
    editable: true,
  },
  {
    field: "actions",
    headerName: "Actions",
    headerAlign: "center",
    align: "center",
    sortable: false,
    flex: 0.4,
    renderCell: ({ id, row }: GridCellParams) => {
      return (
        <Box>
          <IconButton
            color="inherit"
            icon={<EditOutlinedIcon />}
            onClick={(event: React.MouseEvent<HTMLElement>) => onEditIconClick(event, id, row)}
          />
          <IconButton
            color="inherit"
            icon={<OverrideIcon />}
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => onOverrideIconClick(event, id)}
          />
        </Box>
      )
    },
  },
]

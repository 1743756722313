import * as React from "react"
import { DeleteOutlined, EditOutlined } from "@material-ui/icons"
import { Box, Typography } from "@material-ui/core"
import { GridRowModel } from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import type { IStep, StepGroupRulesLocationState } from "typings/modules"
import { uiActions } from "store/ui-slice"
import { IconButton, Dialog } from "components"
import { useQueryClient, useMutation } from "react-query"
import { api } from "services/api"
import { translations } from "utils/translations"
import { useStepGroup } from "./StepGroupContext"

interface IStepRuleListActionsProps {
  row: GridRowModel
  step: IStep
}
const StepRuleListActions = (props: IStepRuleListActionsProps) => {
  const { row, step } = props

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  const { stepGroupRef } = useStepGroup()

  const dispatch = useDispatch()

  const history = useHistory<StepGroupRulesLocationState>()

  const match = useRouteMatch()

  const queryClient = useQueryClient()

  const { mutate: deleteRule } = useMutation(() => api.rules.delete(step.id, row.id), {
    onSuccess: () => {
      dispatch(
        uiActions.showNotification({
          children: translations.alert.calculator.success,
          severity: "success",
        })
      )
      queryClient.invalidateQueries(`Step-${step.id}`)
    },
    onError: (error: InstanceType<typeof Error>) => {
      dispatch(
        uiActions.showNotification({
          children: error.message || JSON.stringify(error),
          severity: "error",
        })
      )
    },
  })

  const handleEdit = () => {
    const options: StepGroupRulesLocationState = {
      mode: "edit",
      stepGroup: {
        id: stepGroupRef.current.id,
        name: stepGroupRef.current.name,
      },
      step: {
        id: step.id,
        name: step.name,
      },
      rule: {
        id: row.id,
        name: row.name,
      },
    }

    history.push(`${match.url}/rules-management`, options)
  }

  return (
    <>
      <Dialog
        open={showDeleteDialog}
        title="Delete rule"
        onClose={() => setShowDeleteDialog(false)}
        primaryActionHandler={() => deleteRule()}
        secondaryActionLabel="Cancel"
        primaryActionLabel="Delete"
      >
        <Typography variant="h4">Are you sure you want to delete the rule {row.ruleName}?</Typography>
      </Dialog>
      <Box>
        <IconButton color="inherit" icon={<EditOutlined />} onClick={handleEdit} />
        <IconButton color="inherit" icon={<DeleteOutlined />} onClick={() => setShowDeleteDialog(true)} />
      </Box>
    </>
  )
}

export { StepRuleListActions }

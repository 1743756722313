import { createTheme } from "@material-ui/core/styles"
import { palette, spacing, theme } from "components/theme"

import { rem } from "polished"

export const ToolTipTheme = createTheme({
  palette,
  spacing,
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.background.default,
        color: palette.text.primary,
        boxShadow: theme.shadows[2],
        textTransform: "uppercase",
        fontSize: 13,
        marginTop: spacing(2),
        padding: spacing(1, 3),
      },
    },
    MuiBadge: {
      badge: {
        width: rem("31px"),
        height: rem("25px"),
        borderRadius: rem("10px"),
      },
    },
  },
})

import type { GridRowId, GridSelectionModel } from "@mui/x-data-grid"
import React from "react"
import map from "lodash/map"
import intersectionBy from "lodash/intersectionBy"

import { columns } from "modules/Customers/constants/customerGroupsTable"
import { useCustomerGroups } from "services/hooks/useCustomerGroups"
import { AddCustomerGroupModal } from "./AddCustomerGroupModal"
import { ICustomerGroups } from "../models/updateProductWizard"

const CustomerGroupsModal = ({
  isOpen,
  handleClose,
  initialCustomerGroups,
  handleAccept,
}: ICustomerGroups): JSX.Element => {
  const [selectedCustomerGroups, setSelectedCustomerGroups] = React.useState<GridRowId[]>(() =>
    map(initialCustomerGroups, "id")
  )

  const { data, isLoading } = useCustomerGroups()

  const handleChoose = (selectionModel: GridSelectionModel) => {
    setSelectedCustomerGroups(selectionModel)
  }

  const handleSubmit = () => {
    const ids = selectedCustomerGroups.map(id => ({ id }))

    const selected = intersectionBy(data, ids, "id")

    handleAccept(selected)
  }

  return (
    <AddCustomerGroupModal
      isOpen={isOpen}
      handleAccept={handleSubmit}
      handleClose={handleClose}
      isLoading={isLoading}
      columns={columns}
      rows={data || []}
      selectionModel={selectedCustomerGroups}
      onItemSelectionChange={handleChoose}
    />
  )
}

export { CustomerGroupsModal }

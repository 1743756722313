import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography, theme } from "../theme"

export const StepGroupSelectorTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: rem("10px"),
        minWidth: rem("123px"),
        height: rem("45px"),
        justifyContent: "space-around",
        textTransform: "capitalize",
        padding: "0 !important",
        boxShadow: "none",
        "& svg": {
          fill: palette.grey[500],
        },
        "&:active": {
          border: `1px solid ${palette.primary.main}`,
        },
      },
      outlined: {
        border: `1px solid ${palette.grey[300]} !important`,
        color: `${palette.grey[500]} !important`,
      },
      contained: {
        backgroundColor: palette.grey[300],
        color: palette.text.primary,
        boxShadow: "none",
        "&:hover": {
          color: palette.text.primary,
          boxShadow: "none",
          "& svg": {
            fill: "transparent",
            stroke: palette.text.primary,
          },
        },
        "& span": {
          padding: "0 0 0 10px",
        },
        "& svg": {
          padding: "0 10px",
          fill: "transparent",
          stroke: palette.text.primary,
        },
      },
      containedPrimary: {
        backgroundColor: palette.primary.main,
        "& svg": {
          stroke: palette.background.default,
        },
        "&:hover": {
          color: palette.background.default,
          "& svg": {
            stroke: palette.background.default,
          },
        },
      },
      containedSecondary: {
        backgroundColor: palette.secondary.main,
        "& svg": {
          stroke: palette.background.default,
        },
        "&:hover": {
          color: palette.background.default,
          "& svg": {
            stroke: palette.background.default,
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: palette.background.default,
        border: `1px solid ${palette.grey[200]}`,
        boxShadow: theme.shadows[3],
        borderRadius: "6px",
        minWidth: rem("200px"),
        padding: spacing(3, 1),
      },
      list: {
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        justifyContent: "center",
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        lineHeight: "1.3",
        margin: "2px 0",
        "&:hover": {
          backgroundColor: palette.grey[200],
          fontWeight: typography.fontWeightBold,
          borderRadius: "6px",
        },
      },
    },
  },
})

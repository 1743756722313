import { makeStyles } from "@material-ui/core"
import { palette, spacing } from "components/theme"
import { rem } from "polished"

export const tabsMenuStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
    "& .MuiTabs-flexContainer": {
      width: "100%",
    },
    "& .MuiTabs-indicator": {
      left: 6,
      width: rem("216px"),
      zIndex: 0,
      borderRadius: rem("8px"),
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      transition: "all 200ms ease",
      transitionDelay: "100ms",
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.body2.fontWeight,
    },
  },
  tab: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    textTransform: "none",
    minHeight: rem("45px"),
    zIndex: 1,
    padding: 0,
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      "& .MuiBox-root": {
        padding: spacing(2, "20px"),
      },
      "& > *:first-child": {
        marginBottom: 0,
      },
    },
  },
  separator: {
    marginBottom: spacing(4),
    position: "relative",
    overflow: "initial",
    "&::after": {
      backgroundColor: palette.grey[300],
      height: "1px",
      display: "block",
      content: "''",
      width: "100%",
      position: "absolute",
      bottom: `-${spacing(1)}px`,
    },
  },
}))

import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { App } from "modules/App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { ENV } from "constants/env"
import { reportWebVitals } from "./reportWebVitals"
import { store } from "./store"

// eslint-disable-next-line no-console
console.log(`API URL: ${ENV.API.URL}`)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import * as React from "react"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"

import { DashboardHeader, StatusSwitchContainer } from "modules/Rules-Pricing/styles"
import { CustomerGroupsSelect } from "modules/Rules-Pricing/components/MultipleOptions"
import { Box, Typography, IconButton } from "@material-ui/core"
import { Dialog, Input, Spacer, Switch, Select } from "components"
import { getCalculatorStatus } from "utils/calculators"
import { IAPICalculatorResponse } from "modules/Rules-Pricing/models/calculator"
import { useCustomerGroups } from "services/hooks/useCustomerGroups"
import { useCalculatorQueryKey, useUpdateCalculator } from "services/hooks/useCalculator"
import { useSubGroups } from "services/hooks/useSubGroups"
import { useQueryCache } from "hooks"
import CheckAndClearButtons from "components/CheckAndClearButtons"
import { palette } from "components/theme"
import { useDispatch } from "react-redux"
import { uiActions } from "store/ui-slice"
import { useHasReadWriteRole } from "utils/user"

const EMPTY_CALCULATOR: Partial<IAPICalculatorResponse> = {
  name: "",
  description: "",
  created: "",
  createdBy: "",
  status: "INACTIVE",
  stepGroups: [],
  customerGroups: [],
  tier: "",
}

const mockCacheData = {
  state: {
    data: EMPTY_CALCULATOR,
    status: "success",
  },
}

const HeaderContent = ({ isCreatingCalculator }: { isCreatingCalculator?: boolean }) => {
  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = React.useState(!!isCreatingCalculator)

  const [calculatorQueryKey] = useCalculatorQueryKey()

  const {
    state: { data: calculator, status: calculatorStatus },
  } = useQueryCache<IAPICalculatorResponse>(calculatorQueryKey) || mockCacheData

  const isCalculatorLoading = calculatorStatus === "loading"

  const { customerGroups, tier, description, status, name } = calculator as IAPICalculatorResponse

  const [stateValues, setStateValues] = React.useState({ customerGroups, tier, description, status, name })

  const isActive = stateValues.status === "ACTIVE"

  const { isCalculatorSaving, updateCalculator, isError } = useUpdateCalculator()
  const { data: customerGroupsOptions } = useCustomerGroups()

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)

  const [changeCustomerGroups, setChangeCustomerGroups] = React.useState(false)

  const { subGroupsData } = useSubGroups({
    customerGroups: stateValues.customerGroups,
    customerGroupsOptions,
    options: { enabled: customerGroupsOptions?.length > 0 },
  })

  React.useEffect(() => {
    if (isError) {
      setStateValues({ customerGroups, tier, description, status, name })
    }
  }, [customerGroups, tier, description, status, name, isError])

  const tierOptions = React.useMemo(
    () =>
      subGroupsData?.map(subGroup => ({
        value: subGroup,
        label: subGroup,
      })) || [],
    [subGroupsData]
  )

  const handleName = (nameAsParam: string) => {
    setStateValues({ ...stateValues, name: nameAsParam })
  }

  const handleCustomerGroupEdition = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const parseSelectedCustomerGroups = (event.target.value as string[]).map((customerGroup: any) => ({
      name: customerGroup,
      id: customerGroupsOptions.find(groupOption => groupOption.name === customerGroup)?.id,
    }))
    setStateValues({ ...stateValues, customerGroups: parseSelectedCustomerGroups })
    setChangeCustomerGroups(true)
    dispatch(
      uiActions.showNotification({
        children: "Changing customer groups will reset calculation history",
        severity: "info",
      })
    )
  }

  const customerGroupsNames = stateValues.customerGroups?.map(cg => cg.name)

  const handleTierEdition = (tierAsParam: string) => {
    setStateValues({ ...stateValues, tier: tierAsParam })
  }

  React.useEffect(() => {
    if (!tierOptions?.map(tierOption => tierOption.value).includes(tier)) {
      setStateValues(previousState => {
        return {
          ...previousState,
          tier: "",
        }
      })
    }
  }, [tierOptions, tier])

  const handleDescriptionEdition = (descriptionAsParam: string) => {
    setStateValues({ ...stateValues, description: descriptionAsParam })
  }

  const handleStatusEdition = (statusAsParam: boolean) => {
    const currentstatus = statusAsParam ? "ACTIVE" : "INACTIVE"

    setStateValues({ ...stateValues, status: currentstatus })
  }

  const handleCloseEdition = () => {
    setIsEditing(false)
    setStateValues({ customerGroups, tier, description, status, name })
    setShowConfirmDialog(false)
    setChangeCustomerGroups(false)
  }

  const handleSaveEdition = () => {
    const calculatorAsParam = {
      ...calculator,
      name: stateValues.name,
      customerGroups: stateValues.customerGroups,
      tier: stateValues.tier,
      description: stateValues.description,
      status: stateValues.status,
    }

    updateCalculator(calculatorAsParam)
    setIsEditing(false)
    setShowConfirmDialog(false)
    setChangeCustomerGroups(false)
  }

  const handleClickEdition = () => {
    setIsEditing(true)
  }

  const handleConfirmDialog = () => {
    dispatch(uiActions.hideNotification())
    if (calculator.id && changeCustomerGroups) {
      setShowConfirmDialog(true)
    } else {
      handleSaveEdition()
    }
  }

  const handleCloseConfirmDialog = () => {
    setShowConfirmDialog(false)
  }

  const userHasReadWriteRole = useHasReadWriteRole()

  return (
    <>
      {showConfirmDialog && (
        <Dialog
          open
          onClose={handleCloseConfirmDialog}
          title="Update calculator"
          primaryActionLabel="Update"
          primaryActionHandler={handleSaveEdition}
          secondaryActionLabel="Cancel"
          secondaryActionHandler={handleCloseConfirmDialog}
          dialogContentText={
            <Typography variant="h4">
              This will reset calculation history. Are you sure you want to update the calculator?
            </Typography>
          }
        />
      )}
      <Box display="flex" alignItems="center" width="fit-content" minWidth="400px" marginTop="15px">
        {isEditing ? (
          <Input
            label="Calculator Title"
            value={stateValues.name}
            placeholder="Untitled Calculator"
            onChange={event => handleName(event.target.value)}
          />
        ) : (
          <Typography variant="h2" color="textSecondary">
            {stateValues.name}
          </Typography>
        )}
      </Box>
      <DashboardHeader>
        <CustomerGroupsSelect
          customerGroupsOptions={customerGroupsOptions || []}
          calculatorCustomerGroups={customerGroupsNames}
          onChange={handleCustomerGroupEdition}
          disabled={!isEditing}
        />
        <Box width="198px">
          <Select
            options={tierOptions || []}
            handleChange={event => handleTierEdition(event.target.value)}
            handleClear={() => isEditing && handleTierEdition("")}
            value={stateValues.tier}
            label="Tier"
            placeholder="Choose a tier"
            disabled={!isEditing}
          />
        </Box>
        <Input
          multiline
          rowsMax={5}
          collapsableMultiline
          label="Calculator Description"
          value={stateValues.description}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            handleDescriptionEdition(event.target.value)
          }}
          placeholder="Add calculator description"
          maxLength="200"
          isDisabled={!isEditing}
        />
        <StatusSwitchContainer>
          <Box minWidth={50}>
            <Typography variant="caption" color="textPrimary">
              Status
            </Typography>
            <Spacer size={3} />
            <Typography variant="body2">
              <Box color={isActive ? palette.success.main : palette.warning.main}>{getCalculatorStatus(isActive)}</Box>
            </Typography>
          </Box>

          <Switch
            checked={isActive}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleStatusEdition(event.target.checked)
            }}
            color="primary"
            isDisabled={!isEditing}
          />
        </StatusSwitchContainer>
        {isEditing ? (
          <CheckAndClearButtons
            onCheckClick={handleConfirmDialog}
            onCloseClick={handleCloseEdition}
            disabled={!stateValues.customerGroups?.length || !stateValues.name}
          />
        ) : (
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={handleClickEdition}
              disabled={isCalculatorLoading || isCalculatorSaving || !userHasReadWriteRole}
            >
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </DashboardHeader>
    </>
  )
}

export default HeaderContent

import * as React from "react"
import { Box } from "@material-ui/core"

import type { IStepGroupResponse } from "typings/modules"
import { Dialog } from "components"
import { useCalculator, useUpdateCalculator } from "services/hooks/useCalculator"
import { useDuplicate } from "services/hooks/useDuplicate"
import { DashboardContent } from "../styles"
import HeaderActions from "../components/HeaderActions"
import HeaderContent from "../components/Dashboard/HeaderContent"
import AsideContent from "../components/Dashboard/AsideContent"
import MainContent from "../components/Dashboard/MainContent"

const Calculator = (): JSX.Element => {
  const { data: calculator, isLoading: isCalculatorLoading } = useCalculator()

  const { isCalculatorSaving, updateCalculator } = useUpdateCalculator()

  const { duplicateCalc } = useDuplicate()

  const [stepGroups, setStepGroups] = React.useState<IStepGroupResponse[]>(calculator?.stepGroups)

  const [selectedGroup, setSelectedGroup] = React.useState<IStepGroupResponse>()

  React.useEffect(() => {
    setStepGroups(calculator?.stepGroups)
  }, [calculator?.stepGroups])

  const [duplicatedCalculatorName, setDuplicatedCalculatorName] = React.useState("COPY -")

  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = React.useState(false)

  const handleUpdateCalculator = (stepGroupsParam: IStepGroupResponse[]) => {
    updateCalculator({ ...calculator, stepGroups: stepGroupsParam })
  }

  return (
    <>
      {calculator && (
        <>
          <Dialog
            title="Duplicate Calculator"
            onClose={() => setDuplicateDialogIsOpen(false)}
            open={duplicateDialogIsOpen}
            primaryActionHandler={() => {
              duplicateCalc(duplicatedCalculatorName)
              setDuplicateDialogIsOpen(false)
              setDuplicatedCalculatorName("")
            }}
            secondaryActionLabel="Cancel"
            primaryActionLabel="Save"
            dialogContentForm={{
              id: "calculatorName",
              label: "Calculator Name",
              type: "string",
              value: duplicatedCalculatorName,
              onChange: (event: React.ChangeEvent<{ value: string }>) =>
                setDuplicatedCalculatorName(event.target.value),
            }}
          />
          <HeaderActions
            setDuplicateDialogIsOpen={setDuplicateDialogIsOpen}
            disabled={isCalculatorLoading || isCalculatorSaving}
          />
          <DashboardContent>
            <HeaderContent />
            <Box display="flex" gridGap="24px">
              {stepGroups && (
                <AsideContent
                  stepGroups={stepGroups}
                  setStepGroups={setStepGroups}
                  selectedGroup={selectedGroup}
                  setSelectedGroup={setSelectedGroup}
                  updateCalculator={handleUpdateCalculator}
                />
              )}
              {selectedGroup?.id && <MainContent selectedGroup={selectedGroup} calculatorId={calculator.id} />}
            </Box>
          </DashboardContent>
        </>
      )}
    </>
  )
}

export { Calculator }

import { useQuery, useQueryClient, useMutation } from "react-query"
import { apiClient } from "services/ApiServiceAxios"
import { useDispatch } from "react-redux"
import { getStepGroupByImport } from "services/calculator"
import { uiActions } from "store/ui-slice"
import { translations } from "utils/translations"

interface IStepsImportParams {
  calculatorId: number
  stepGroupId: number
  hasSteps: boolean
  options?: any
}

export type StepImport = {
  id: number
  name: string
  created: string
  lastUpdate: string
}

type IStepImportToSaveParams = {
  stepGroupId: number
  stepId: number
}

const fetchStepsImport = async ({ calculatorId, stepGroupId, hasSteps }: IStepsImportParams): Promise<StepImport[]> => {
  const url = hasSteps
    ? `steps/?calculatorId=${calculatorId}&stepGroupMain=false&noStepGroupId=${stepGroupId}`
    : `steps/?calculatorId=${calculatorId}&stepGroupMain=false`

  const { data } = await apiClient.get(url)

  return data
}

export const useStepsImport = ({ calculatorId, stepGroupId, hasSteps, options }: IStepsImportParams) => {
  const query = useQuery(
    [`StepsImport-${stepGroupId}`, hasSteps],
    () => fetchStepsImport({ calculatorId, stepGroupId, hasSteps }),
    { ...options }
  )

  return query
}

export const useSaveImportStep = () => {
  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const { isLoading, mutate: saveImportStep } = useMutation(
    ({ stepGroupId, stepId }: IStepImportToSaveParams) => getStepGroupByImport(stepGroupId, stepId),
    {
      onSuccess: () => {
        dispatch(
          uiActions.showNotification({
            children: translations.alert.calculator.success,
            severity: "success",
          })
        )
      },
      onError: (error: InstanceType<typeof Error>) => {
        dispatch(
          uiActions.showNotification({
            children: error.message || JSON.stringify(error),
            severity: "error",
          })
        )
      },
      onSettled: response => {
        queryClient.setQueryData(`stepGroup-${response.id}`, response)
        queryClient.invalidateQueries(`StepsImport-${response.id}`)
      },
    }
  )

  return { saveImportStep, isLoading }
}

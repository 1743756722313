import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid"
import numeral from "numeral"

import {
  centerColumn,
  leftEditableColumn,
  percentFormatterParser,
  priceFormatterParser,
} from "modules/TableGlobalStyles"

const adjustmentColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    type: "string",
    sortable: false,
  },
  {
    field: "adjustment",
    headerName: "Value",
    flex: 0.8,
    type: "number",
    ...leftEditableColumn,
    ...percentFormatterParser,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams): string => {
      const numeric = numeral((params.value as string) || 0).multiply(100)
      return `${numeric.value()}%`
    },
  },
]

const commonTierLevelColumn: Omit<GridColDef, "field"> = {
  flex: 0.4,
  ...leftEditableColumn,
  ...percentFormatterParser,
  type: "number",
  sortable: false,
}

const levelColumns: GridColDef[] = [
  {
    field: "value",
    headerName: "Price",
    flex: 0.4,
    ...leftEditableColumn,
    ...priceFormatterParser,
    type: "number",
    sortable: false,
  },
  {
    field: "name",
    headerName: "Level",
    flex: 0.3,
    type: "string",
    ...centerColumn,
  },
  {
    field: "Tier1",
    headerName: "Tier 1",
    flex: 0.4,
    ...commonTierLevelColumn,
    sortable: false,
  },
  {
    field: "Tier2",
    headerName: "Tier 2",
    flex: 0.4,
    ...commonTierLevelColumn,
    sortable: false,
  },
  {
    field: "Tier3",
    headerName: "Tier 3",
    flex: 0.4,
    ...commonTierLevelColumn,
    sortable: false,
  },
]

const ASCMarkup: GridColDef[] = [
  {
    field: "categoryName",
    headerName: "Category",
    flex: 1,
    type: "string",
    sortable: false,
  },
  {
    field: "markup",
    headerName: "% Markup",
    flex: 0.8,
    type: "number",
    ...leftEditableColumn,
    ...percentFormatterParser,
    sortable: false,
  },
]

const ASCShippingEstimate: GridColDef[] = [
  { field: "weight", headerName: "Weight (lbs)", flex: 1, type: "string" },
  {
    field: "price",
    headerName: "ASC Zone 3",
    flex: 0.8,
    type: "number",
    ...leftEditableColumn,
    ...priceFormatterParser,
  },
]

export { adjustmentColumns, levelColumns, ASCMarkup, ASCShippingEstimate }

import * as React from "react"
import { useQueryClient } from "react-query"
import { Box, IconButton } from "@material-ui/core"
import { GetApp } from "@material-ui/icons"
import { useDispatch } from "react-redux"

import type { IStep } from "typings/modules"
import { exportStepCsv } from "services/calculator"
import { uiActions } from "store/ui-slice"
import { LinearIndeterminate } from "components"
import FileSaver from "file-saver"
import type { StepState } from "./Dashboard/MainContent"

type ExportStepProps = {
  calculatorId: string
  step: StepState
}

const ExportStep = (props: ExportStepProps) => {
  const { calculatorId, step } = props

  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const stepData = queryClient.getQueryData<IStep>(`Step-${step?.id}`, { exact: true })

  const [isDownloading, setIsDownloading] = React.useState(false)

  const handleDownload = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (calculatorId && step?.id) {
      setIsDownloading(true)

      try {
        const response = await exportStepCsv(calculatorId, step.id.toString())

        if (!response) {
          throw new Error(`Error exporting rule group`)
        }

        const blob = new Blob([response], { type: "text/csv;charset=utf-8" })

        FileSaver.saveAs(blob, `${step.name}.csv`)
      } catch (error) {
        dispatch(
          uiActions.showNotification({
            children: (error as InstanceType<typeof Error>).toString(),
            severity: "error",
          })
        )
      } finally {
        setIsDownloading(false)
      }
    }
  }

  return (
    <>
      {isDownloading && (
        <Box position="fixed" top="0" left="0" zIndex="141" width="100%">
          <LinearIndeterminate />
        </Box>
      )}
      <IconButton onClick={handleDownload} disabled={!stepData || isDownloading}>
        <GetApp />
      </IconButton>
    </>
  )
}

export default ExportStep

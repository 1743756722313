import { createTheme } from "@material-ui/core/styles"
import { rem } from "polished"
import { palette, spacing, typography, theme } from "../theme"

export const StepGroupSelectorTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiButton: {
      root: {
        border: `1px dashed ${palette.grey[300]}`,
        width: rem("75px"),
        height: rem("75px"),
        color: palette.primary.main,
        "& svg": {
          fontSize: "1.8rem",
        },
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: palette.background.default,
        border: `1px solid ${palette.grey[200]}`,
        boxShadow: theme.shadows[3],
        borderRadius: "6px",
        minWidth: rem("200px"),
        padding: spacing(3, 1),
      },
      list: {
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        justifyContent: "center",
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        lineHeight: "1.3",
        margin: "2px 0",
        "&:hover": {
          backgroundColor: palette.primary.light,
          fontWeight: typography.fontWeightBold,
          borderRadius: "6px",
        },
      },
    },
  },
})

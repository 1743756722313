import { makeStyles } from "@material-ui/core"
import { palette, spacing } from "components/theme"

export const buttonMenuStyles = makeStyles(() => ({
  root: {
    "& div": {
      width: "auto",
    },
  },
  icon: {
    color: palette.primary.main,
  },
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: palette.background.default,
      padding: 0,
    },
    "& ul": {
      padding: 0,
    },
    "& li": {
      padding: spacing(2, 3, 2, 2),
      color: palette.grey[900],
    },
  },
  iconMenu: {
    padding: spacing(0, 1),
    width: 13,
    "& svg": {
      width: 13,
      fill: palette.grey[500],
    },
  },
  confirmIcon: {
    zIndex: 10,
    "& svg": {
      fill: palette.primary.main,
    },
  },
  cancelIcon: {
    zIndex: 10,
    "& svg": {
      fill: palette.grey[500],
    },
  },
}))

import React from "react"
import { DebugIcon, IconButton } from "components"
import { GridRowModel } from "@mui/x-data-grid"
import { DebugDialog } from "../components"

interface ICalculatorListActions {
  row: GridRowModel
}
const CalculatorResultsActions = (props: ICalculatorListActions) => {
  const { row } = props

  const [isDebugOpen, setIsDebugOpen] = React.useState(false)

  const handleDebugOpen = () => {
    setIsDebugOpen(prevState => !prevState)
  }

  return (
    <>
      <IconButton size="small" color="inherit" icon={<DebugIcon />} onClick={handleDebugOpen} />
      {isDebugOpen && <DebugDialog handleClose={handleDebugOpen} row={row} />}
    </>
  )
}

export { CalculatorResultsActions }

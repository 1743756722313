import { createTheme } from "@material-ui/core/styles"
import { palette, spacing, typography } from "../theme"

export const EditInlineTheme = createTheme({
  palette,
  typography,
  spacing,
  overrides: {
    MuiIconButton: {
      root: {
        marginLeft: spacing(3),
        color: palette.primary.light,
        "&:hover": {
          backgroundColor: palette.info.main,
          color: palette.primary.main,
        },
      },
    },
  },
})

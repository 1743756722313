import * as React from "react"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { useQueryClient } from "react-query"
import { AccordionDetails, AccordionSummary, Box, IconButton, Typography } from "@material-ui/core"
import { DeleteOutline, KeyboardArrowDown } from "@material-ui/icons"
import size from "lodash/size"

import { useQueryCache } from "hooks"
import {
  Accordion,
  Button,
  Chip,
  CircularRefIcon,
  EditInline,
  NoRowsOverlay,
  Spacer,
  Switch,
  Table,
  Dialog,
} from "components"
import { useStep, useUpdateStep } from "services/hooks/useStep"
import { useCalculatorQueryKey } from "services/hooks/useCalculator"
import { formatDateFromString } from "utils/general"
import { CalculatorRulesLocationState, IStepGroupRequest, StepGroupRulesLocationState } from "typings/modules"
import Loader from "components/Loader"
import { CircularSwitchContainer } from "../styles"
import { getColumns } from "../constants/ruleGroupTable"
import type { StepState, UpdateStepOnStepGroupsParam } from "./Dashboard/MainContent"
import type { IAPICalculatorResponse } from "../models/calculator"
import ExportStep from "./ExportStep"

type StepComponentProps = {
  step: StepState
  stepGroup: any
  hasAnyCycleReferenceOnSteps: boolean
  expanded?: boolean
  updateStepOnStepGroups?: (param: UpdateStepOnStepGroupsParam) => void
  isMainEdition?: boolean
}

const StepComponent = (props: StepComponentProps) => {
  const {
    step,
    stepGroup,
    hasAnyCycleReferenceOnSteps,
    expanded = false,
    updateStepOnStepGroups,
    isMainEdition,
  } = props

  const [enabledFetch, setEnabledFetch] = React.useState(false)

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  const [calculatorQueryKey] = useCalculatorQueryKey()

  const cachedCalculator = useQueryCache<IAPICalculatorResponse>(calculatorQueryKey)

  const cachedStepGroup = useQueryCache<IStepGroupRequest>(`stepGroup-${stepGroup.id}`)

  const queryClient = useQueryClient()

  const { calculatorId } = useParams<{ calculatorId: string }>()

  const history = useHistory()

  const match = useRouteMatch()

  const { updateStep, isLoading: isStepUpdating } = useUpdateStep({
    calculatorId: +calculatorId || 0,
    stepGroupId: stepGroup.id,
  })

  const { data, isLoading } = useStep({ stepId: step.id, options: { enabled: enabledFetch } })

  const [isEditingName, setIsEditingName] = React.useState(expanded)

  const [stepName, setStepName] = React.useState(step.name)

  const onDeleteStep = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (!step.id) {
      updateStepOnStepGroups({
        stepToUpdate: { ...step, name: stepName },
        isDeleting: true,
        isEmpty: true,
      })
    } else {
      setShowDeleteDialog(true)
    }
  }

  const onClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    setIsEditingName(false)
    setStepName(step.name)
  }

  const handleOnExpanded = () => {
    if (!enabledFetch && step.id) {
      setEnabledFetch(true)
    }
  }

  const handleOnCheckClick = () => {
    if (step.id) {
      updateStep({ ...data, id: step.id, name: stepName })

      return
    }

    updateStepOnStepGroups({ stepToUpdate: { ...step, name: stepName } })
  }

  const handleOnSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateStepOnStepGroups({ stepToUpdate: { ...step, cycleReference: event.target.checked } })
  }

  const handleAddRule = () => {
    const pathname = `${match.url}/rules-management`

    if (isMainEdition) {
      const options: StepGroupRulesLocationState = {
        mode: "new",
        stepGroup: {
          id: stepGroup.id,
          name: cachedStepGroup.state.data.name,
        },
        step: {
          id: step.id,
          name: stepName,
        },
        rule: null,
      }

      history.push(pathname, options)
    } else {
      const options: CalculatorRulesLocationState = {
        mode: "new",
        calculator: {
          id: Number(calculatorId),
          name: cachedCalculator.state.data.name,
          tier: cachedCalculator.state.data.tier,
        },
        stepGroup: {
          id: stepGroup.id,
          name: cachedStepGroup.state.data.name,
        },
        step: {
          id: step.id,
          name: stepName,
        },
        rule: null,
      }

      history.push(pathname, options)
    }
  }

  const handleDeleteActionOnDialog = () => {
    updateStepOnStepGroups({
      stepToUpdate: { ...step, name: stepName },
      isDeleting: true,
    })

    const stepGroupQueryKey = `stepGroup-${stepGroup.id}`

    queryClient.invalidateQueries(stepGroupQueryKey)
  }

  const ruleRows = data?.rules?.reduce((acc, { id, name, created }) => {
    // If the rule start with the 'comparator' prefix, it should avoid to show it in the table
    if (name.split(" ")[0] === "comparator") {
      return acc
    }

    return [
      ...acc,
      {
        id,
        ruleName: name,
        created: formatDateFromString(created),
      },
    ]
  }, [])

  const avoidEdition = stepGroup.main && !isMainEdition

  return (
    <>
      {(isLoading || isStepUpdating) && <Loader />}
      <Dialog
        open={showDeleteDialog}
        title="Delete rule group"
        onClose={() => setShowDeleteDialog(false)}
        primaryActionHandler={handleDeleteActionOnDialog}
        secondaryActionLabel="Cancel"
        primaryActionLabel="Delete"
      >
        <Spacer size={3} />
        <Typography variant="h4">Are you sure you want to delete the rule group {step?.name}?</Typography>
        <Spacer size={3} />
      </Dialog>
      <Accordion key={step.id} size="large" onChange={handleOnExpanded}>
        <AccordionSummary expandIcon={<KeyboardArrowDown />} id={step.id?.toString()}>
          <EditInline
            isEditable={isEditingName}
            onInputChange={(event: React.ChangeEvent<{ value: string }>) => {
              setStepName(event.target.value)
            }}
            text={stepName}
            onEdit={
              avoidEdition
                ? undefined
                : event => {
                    event.stopPropagation()
                    setIsEditingName(prevState => !prevState)
                  }
            }
            onCheckClick={event => {
              event.stopPropagation()
              setIsEditingName(false)
              handleOnCheckClick()
            }}
            isCheckDisabled={!stepName}
            onClose={onClose}
            placeholder="Type group of rules"
            variant="h3"
          />
          <Box alignSelf="Flex-end">
            {step.cycleReference && (
              <Chip size="small" color="secondary" label="Circular Reference" icon={<CircularRefIcon />} />
            )}
            {!isMainEdition && <ExportStep calculatorId={calculatorId} step={step} />}
            {!avoidEdition && (
              <IconButton onClick={onDeleteStep} disabled={isLoading}>
                <DeleteOutline />
              </IconButton>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!avoidEdition && step.id && (
            <CircularSwitchContainer>
              <Typography variant="body1">Circular Reference</Typography>
              <Switch
                color="primary"
                checked={step.cycleReference}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnSwitchChange(event)
                }}
                isDisabled={hasAnyCycleReferenceOnSteps && !step.cycleReference}
              />
            </CircularSwitchContainer>
          )}
          {ruleRows ? (
            <Table
              autoHeight
              rows={ruleRows}
              columns={getColumns(data, cachedStepGroup.state.data, isMainEdition)}
              components={{
                NoRowsOverlay,
              }}
              hideFooter
            />
          ) : (
            <Typography variant="subtitle1">There are no rules</Typography>
          )}
          <Spacer size={2} />
          {!avoidEdition && (
            <Box display="flex" flexGrow="1" alignItems="center">
              <Button onClick={handleAddRule} variant="outlined" isDisabled={size(stepName) === 0 || !data}>
                + Add New Rule
              </Button>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export { StepComponent }

import React from "react"
import { AppBar, Box, Tabs as MuiTabs, ThemeProvider } from "@material-ui/core"
import { ITabs, ITabPanelProps } from "./props"
import { tabsTheme } from "./styles"

export const TabPanel = ({ children, value, index }: ITabPanelProps): JSX.Element => {
  return (
    <Box
      maxWidth="100%"
      height="100%"
      role="tabpanel"
      position="relative"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

export const Tabs = ({ orientation, variant, onChange, value, indicatorColor, children }: ITabs): JSX.Element => {
  return (
    <ThemeProvider theme={tabsTheme}>
      <AppBar position="static" color="transparent">
        <MuiTabs
          data-testid="tabs"
          orientation={orientation}
          variant={variant}
          value={value}
          onChange={onChange}
          indicatorColor={indicatorColor}
        >
          {children}
        </MuiTabs>
      </AppBar>
    </ThemeProvider>
  )
}
Tabs.defaultProps = {
  orientation: "horizontal",
  variant: "standard",
  indicatorColor: "primary",
}

import * as React from "react"
import { Redirect, useHistory, useLocation, useParams } from "react-router"
import { Tab } from "@material-ui/core"

import { Header, Tabs, TabPanel } from "components"
import Loader from "components/Loader"
import { ROUTES } from "constants/routes"
import type { CalculationEventsRoutingParams } from "./events-types"
import { TabEvent } from "./events-constants"

const Calculations = React.lazy(() => import("./components/calculations/calculations"))

const CalculationChildren = React.lazy(() => import("./components/calculation-children"))

const Publications = React.lazy(() => import("./components/publications/publications"))

const PublicationChildren = React.lazy(() => import("./components/publication-children"))

const ScheduledPublications = React.lazy(() => import("./components/scheduled-publications"))

const TABS = [
  {
    id: 0,
    pathname: ROUTES.CALCULATION_EVENTS.path,
    type: TabEvent.CALCULATOR,
    label: "Calculations",
    children: (
      <React.Suspense fallback={<Loader />}>
        <Calculations />
      </React.Suspense>
    ),
  },
  {
    id: 1,
    pathname: ROUTES.PUBLICATION_EVENTS.path,
    type: TabEvent.PUBLICATION,
    label: "Publications",
    children: (
      <React.Suspense fallback={<Loader />}>
        <Publications />
      </React.Suspense>
    ),
  },
  {
    id: 2,
    pathname: ROUTES.SCHEDULED_PUBLICATIONS_EVENTS.path,
    type: TabEvent.SCHEDULED_PUBLICATION,
    label: "Scheduled Publications",
    children: (
      <React.Suspense fallback={<Loader />}>
        <ScheduledPublications />
      </React.Suspense>
    ),
  },
] as const

const CHILDREN = [
  {
    type: TabEvent.CALCULATOR,
    Component: CalculationChildren,
  },
  {
    type: TabEvent.PUBLICATION,
    Component: PublicationChildren,
  },
  {
    type: TabEvent.SCHEDULED_PUBLICATION,
    Component: null as React.LazyExoticComponent<() => JSX.Element>,
  },
] as const

const EventsParents = () => {
  const location = useLocation()

  const history = useHistory()

  const handleTab = (event: React.ChangeEvent<unknown>, newValue: number) => {
    const newPathname = TABS[newValue].pathname

    history.push(newPathname)
  }

  const value = TABS.findIndex(tab => tab.pathname === location.pathname)

  return (
    <>
      <div>
        <Header title="List of Events" />
        <Tabs value={value} onChange={handleTab}>
          {TABS.map(currentTab => (
            <Tab key={currentTab.id} label={currentTab.label} disableFocusRipple disableRipple />
          ))}
        </Tabs>
      </div>
      {TABS.map(currentTab => (
        <TabPanel key={currentTab.id} value={value} index={currentTab.id}>
          {currentTab.children}
        </TabPanel>
      ))}
    </>
  )
}

const EventsChildren = () => {
  const location = useLocation()

  const value = TABS.find(tab => location.pathname.includes(tab.pathname))

  const ITEM = value ? CHILDREN.find(child => child.type === value.type) : null

  if (ITEM) {
    return (
      <React.Suspense fallback={<Loader />}>
        <ITEM.Component />
      </React.Suspense>
    )
  }

  return <Redirect to={ROUTES.CALCULATION_EVENTS.path} />
}

const Events = () => {
  const params = useParams<CalculationEventsRoutingParams>()

  if (params.uuid) {
    return <EventsChildren />
  }

  return <EventsParents />
}

export { Events }

import React from "react"
import { Typography } from "@material-ui/core"
import { Spacer, Dialog } from "components"

export interface IDeleteCompetitorpDialogProps {
  onClose: () => void
  confirmHandler: () => void
  isOpen: boolean
  competitorName: string
}

const DeleteCompetitorDialog = ({
  onClose,
  isOpen,
  competitorName,
  confirmHandler,
}: IDeleteCompetitorpDialogProps): JSX.Element => {
  return (
    <Dialog
      onClose={onClose}
      title="Delete Competitor"
      open={isOpen}
      secondaryActionLabel="Cancel"
      primaryActionLabel="Delete"
      primaryActionHandler={confirmHandler}
    >
      <>
        <Spacer size={3} />
        <Typography variant="h4">Are you sure you want to delete {competitorName}?</Typography>
        <Spacer size={3} />
      </>
    </Dialog>
  )
}

export { DeleteCompetitorDialog }

import * as React from "react"
import { Box, Portal } from "@material-ui/core"

import type { StepGroupRulesLocationState, IStep } from "typings/modules"
import { useCalculatorRulesSetup, useLocationState, useQueryCache } from "hooks"
import { ISelectOption, LinearIndeterminate } from "components"
import Loader from "components/Loader"
import type { ISystemObject } from "../models/systemObject"
import useManageStepGroupRules from "../hooks/use-manage-step-group-rules"
import { IStepGroupType } from "../models/calculator"
import useStepGroupRulesSetup from "../hooks/use-step-group-rules-setup"

type StepGroupRulesContextValue = ReturnType<typeof useCalculatorRulesSetup> &
  ReturnType<typeof useManageStepGroupRules> & {
    step: IStep
    steps: IStep[]
    systemObjects: ISystemObject[]
    stepGroupTypes: IStepGroupType[]
    systemObjectOptions: ISelectOption[]
  }

const StepGroupRulesContext = React.createContext<StepGroupRulesContextValue>(null)

const StepGroupRulesProviderContent = (props: React.PropsWithChildren<unknown>) => {
  const { children } = props

  const values = useManageStepGroupRules()

  return (
    <StepGroupRulesContext.Provider value={values}>
      {values.status === "loading" && (
        <Portal>
          <Box className="stepGroup-rules-progress" position="absolute" top="65px" left="0" zIndex="100" width="100%">
            <LinearIndeterminate />
          </Box>
        </Portal>
      )}
      {children}
    </StepGroupRulesContext.Provider>
  )
}

const StepGroupRulesProvider = (props: React.PropsWithChildren<unknown>) => {
  const { children } = props

  const values = useStepGroupRulesSetup()

  const locationState = useLocationState<StepGroupRulesLocationState>()

  const { stepGroup, step } = locationState

  const stepQueryKey = `stepGroup-${stepGroup.id}-step-${step.id}`

  const cachedStep = useQueryCache<IStep>(stepQueryKey)

  if (values.isLoading && cachedStep.state.dataUpdateCount <= 1) {
    return <Loader />
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StepGroupRulesProviderContent>{children}</StepGroupRulesProviderContent>
}

export { StepGroupRulesProvider as default, StepGroupRulesContext }

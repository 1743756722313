/* eslint-disable react/jsx-no-duplicate-props */
import React from "react"
import { TextField, ThemeProvider } from "@material-ui/core"
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input"
import { FormControlTheme, multilineStyles } from "../styles"

export interface IInput {
  isDisabled?: boolean
  hasError?: boolean
  className?: string
  label?: string
  helperText?: string
  placeholder?: string
  name?: string
  value?: string
  onBlur?: (event: React.FocusEvent<any>) => void
  onChange?: (event: React.ChangeEvent<any>) => void
  type?: string
  multiline?: boolean
  onClick?: React.MouseEventHandler
  rowsMax?: number | string
  minRows?: number | string
  collapsableMultiline?: boolean
  inputProps?: Partial<StandardInputProps>
  autoFocus?: any
  maxLength?: string
}

export const Input = (props: IInput): JSX.Element => {
  const {
    autoFocus,
    isDisabled,
    hasError,
    label,
    className,
    placeholder,
    name,
    helperText,
    value,
    onBlur,
    onChange,
    type,
    multiline,
    onClick,
    rowsMax,
    minRows,
    collapsableMultiline,
    inputProps,
    maxLength,
  } = props

  const classes = multilineStyles()

  return (
    <ThemeProvider theme={FormControlTheme}>
      <TextField
        multiline={multiline}
        maxRows={rowsMax}
        rows={minRows}
        data-testid="input"
        className={collapsableMultiline ? classes.collapsedMultiline : className}
        label={label}
        variant="outlined"
        size="small"
        disabled={isDisabled}
        placeholder={placeholder}
        error={hasError}
        helperText={helperText}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={inputProps}
        inputProps={{
          maxLength,
        }}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        type={type}
        onClick={onClick}
        autoFocus={autoFocus}
      />
    </ThemeProvider>
  )
}

Input.defaultProps = {
  disabled: false,
  error: false,
  className: "",
  icon: <></>,
  label: "",
  placeholder: "",
  name: "",
  value: "",
  collapsableMultiline: false,
}

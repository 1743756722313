import * as React from "react"

import type { IUseCustomerListState } from "hooks/use-customer-list"
import { CreateCustomerDialog } from "./CreateCustomerDialog"
import { UpdateCustomerDialog } from "./UpdateCustomerDialog"
import type { ICustomer } from "../models/customer"
import type { ICustomerGroup } from "../models/customerGroup"
import { DeleteCustomerDialog } from "./DeleteCustomerDialog"
import { UpdateCustomerGroupDialog } from "./UpdateCustomerGroupDialog"
import { DeleteCustomerGroupDialog } from "./DeleteCustomerGroupDialog"

interface IProps extends Pick<IUseCustomerListState, "data" | "modal"> {
  customerGroups: ICustomerGroup[]
  handleClose: () => void
  handleRefetch: () => void
}

const ManageCustomerDialog = ({ data, modal, customerGroups, handleClose, handleRefetch }: IProps): JSX.Element => {
  if (modal === "create-customer") {
    return (
      <CreateCustomerDialog customerGroups={customerGroups} handleClose={handleClose} handleRefetch={handleRefetch} />
    )
  }

  if (modal === "update-customer") {
    return (
      <UpdateCustomerDialog
        data={data as ICustomer}
        customerGroups={customerGroups}
        handleClose={handleClose}
        handleRefetch={handleRefetch}
      />
    )
  }

  if (modal === "delete-customer") {
    return <DeleteCustomerDialog data={data as ICustomer} handleClose={handleClose} handleRefetch={handleRefetch} />
  }

  if (modal === "update-customer-group") {
    return (
      <UpdateCustomerGroupDialog
        data={data as ICustomerGroup}
        handleClose={handleClose}
        handleRefetch={handleRefetch}
      />
    )
  }

  if (modal === "delete-customer-group") {
    return (
      <DeleteCustomerGroupDialog
        data={data as ICustomerGroup}
        handleClose={handleClose}
        handleRefetch={handleRefetch}
      />
    )
  }

  return (null as unknown) as JSX.Element
}

export { ManageCustomerDialog }

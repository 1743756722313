import {
  ICreateCustomerRequest,
  ICustomer,
  ICustomerResponse,
  IUpdateCustomerRequest,
} from "modules/Customers/models/customer"
import { IInputCSV, IBulkResponse } from "models/bulk"
import { GridFilterItem, GridSortItem } from "@mui/x-data-grid"
import { api } from "./api"

export const getCustomers = async (
  page: number,
  size: number,
  sortModel?: GridSortItem[],
  filterValue?: GridFilterItem
): Promise<ICustomerResponse> => {
  const { data } = await api.customers.get({
    page: page.toString(),
    size: size.toString(),
    sortModel,
    filterValue,
  })
  return data
}

export const getAllCustomers = async (): Promise<ICustomer[]> => {
  const { data } = await api.customers.getAll()
  return data
}

export const updateCustomers = async (inputCSV: IInputCSV[], isCustomerImport: boolean): Promise<IBulkResponse> => {
  const { data } = isCustomerImport
    ? await api.customers.updateCustomers(inputCSV)
    : await api.customerGroups.updateCustomerGroups(inputCSV)
  return data
}

export const createCustomer = async (payload: ICreateCustomerRequest): Promise<ICustomer> => {
  const { data } = await api.customers.create(payload)

  return data
}

export const updateCustomer = async (id: number, payload: IUpdateCustomerRequest): Promise<ICustomer> => {
  const { data } = await api.customers.put(id, payload)

  return data
}

export const deleteCustomer = async (id: number): Promise<unknown> => {
  const { data } = await api.customers.delete(id)

  return data
}

export const auditCustomers = async (): Promise<Array<any>[]> => {
  const { data } = await api.customers.audit()
  return data.reverse()
}

import * as React from "react"
import { useHistory, useParams } from "react-router"
import { ArrowBack } from "@material-ui/icons"

import { Button, HeaderContainer } from "components"
import { ROUTES } from "constants/routes"
import { DashboardContent } from "../styles"
import { StepGroupProvider, useStepGroup } from "../components/StepGroupContext"
import MainContent from "../components/Dashboard/MainContent"
import { StepGroupForm } from "../components/StepGroupForm"

const StepGroupChildren = () => {
  const history = useHistory()

  const { stepGroupId } = useParams<{ stepGroupId: string }>()

  const { stepGroupRef } = useStepGroup()

  const handleBack = () => {
    history.push(ROUTES.RULES_PRICING.path, { tab: 1 })
  }

  const isCreating = stepGroupId === "new"

  return (
    <>
      <HeaderContainer>
        <div>
          <Button variant="text" size="small" onClick={handleBack} icon={<ArrowBack />}>
            Back
          </Button>
        </div>
      </HeaderContainer>
      <DashboardContent>
        <StepGroupForm isCreating={isCreating} />
        {!isCreating && <MainContent selectedGroup={stepGroupRef.current as any} calculatorId={0} isMainEdition />}
      </DashboardContent>
    </>
  )
}

const StepGroup = () => {
  return (
    <StepGroupProvider>
      <StepGroupChildren />
    </StepGroupProvider>
  )
}

export { StepGroup }

import type { ICustomer } from "../models/customer"
import { ICustomerGroup } from "../models/customerGroup"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUpdateCustomerInitialState = (customer: ICustomer) => {
  const state = {
    fields: {
      name: customer.name,
      description: customer.description || "",
      resellerId: customer.resellerId,
      location: customer.location || "",
      customerGroupName: customer.customerGroupName,
      customerGroupSubgroup: customer.customerGroupSubgroup,
    },
    errors: {
      name: "",
      description: "",
      resellerId: "",
      location: "",
      customerGroupName: "",
      customerGroupSubgroup: "",
    },
  }

  return state
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUpdateCustomerGroupInitialState = (customerGroup: ICustomerGroup) => {
  const state = {
    fields: {
      name: customerGroup.name,
      fullName: customerGroup.fullName || "",
    },
    errors: {
      name: "",
      fullName: "",
    },
  }

  return state
}

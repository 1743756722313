import type { FormikProps } from "formik"
import * as React from "react"
import { useFormik } from "formik"
import { Box, InputAdornment, Typography } from "@material-ui/core"

import { Spacer, Input, Switch } from "components"
import { WizardHeader } from "./WizardHeader"
import { StepContent, ContainerInputs } from "../styles"
import {
  IProductStep,
  IProductStepProps,
  productStepInitialValues,
  ProductStepInitialValuesSchema,
  StepAction,
} from "../models/newProductWizard"
import { NewProductWizardFooter } from "./NewProductWizardFooter"
import { RxRequiredSwitchContainer } from "../containers/styles"
import { PercentInput } from "./PercentInput"

const ProductStep = React.forwardRef<FormikProps<IProductStep>, IProductStepProps>(
  ({ dispatchProductStep, productStepState, handleBack, handleNext }, formikRef) => {
    const formik = useFormik({
      initialValues: productStepState || productStepInitialValues,
      validationSchema: ProductStepInitialValuesSchema,
      onSubmit: values => {
        dispatchProductStep({
          type: StepAction.FORM_CHANGED,
          payload: {
            ...values,
            isStepValid: true,
          },
        })

        handleNext()
      },
    })

    React.useImperativeHandle(
      formikRef,
      () => ({
        ...formik,
      }),
      [formik]
    )

    return (
      <>
        <StepContent>
          <WizardHeader
            title="What's the product information"
            subtitle="Please complete the information about the product"
          />
          <Input
            label="Product Name"
            name="productName"
            placeholder="Type a product name"
            value={formik.values.productName}
            hasError={Boolean(formik.errors.productName && formik.touched.productName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="eCom Name"
            name="ecomName"
            placeholder="Type eCom Name"
            value={formik.values.ecomName}
            hasError={Boolean(formik.errors.ecomName && formik.touched.ecomName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="VS ID"
            name="vsId"
            placeholder="Type VS ID"
            value={formik.values.vsId}
            hasError={Boolean(formik.errors.vsId && formik.touched.vsId)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="Major Category"
            name="majorCategoryName"
            placeholder="Type Major Category"
            value={formik.values.majorCategoryName}
            hasError={Boolean(formik.errors.majorCategoryName && formik.touched.majorCategoryName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="ASC Category"
            name="categoryName"
            placeholder="Type ASC Category"
            value={formik.values.categoryName}
            hasError={Boolean(formik.errors.categoryName && formik.touched.categoryName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="VIP Marker"
            name="vipMarkerName"
            placeholder="Type VIP Marker"
            value={formik.values.vipMarkerName}
            hasError={Boolean(formik.errors.vipMarkerName && formik.touched.vipMarkerName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="SRP Rule"
            name="srpRuleName"
            placeholder="Type SRP Rule"
            value={formik.values.srpRuleName}
            hasError={Boolean(formik.errors.srpRuleName && formik.touched.srpRuleName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="Species"
            name="specieName"
            placeholder="Type Species"
            value={formik.values.specieName}
            hasError={Boolean(formik.errors.specieName && formik.touched.specieName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="Exception"
            name="exception"
            placeholder="Type exception"
            value={formik.values.exception}
            hasError={Boolean(formik.errors.exception && formik.touched.exception)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <Input
            label="Instant Rebate"
            name="instantrebate"
            placeholder="Type instant rebate"
            type="number"
            value={(formik.values.instantrebate || "").toString()}
            hasError={Boolean(formik.errors.instantrebate && formik.touched.instantrebate)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <WizardHeader
            title="What's the pricing information"
            subtitle="Please complete the information related to expected price"
          />
          <ContainerInputs>
            <Input
              label="Banfield Fee Strategy"
              name="banFeeStrategy"
              placeholder="Type Banfield Fee Strategy"
              value={formik.values.banFeeStrategy}
              hasError={Boolean(formik.errors.banFeeStrategy && formik.touched.banFeeStrategy)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Input
              label="Fee Strategy"
              name="feeStrategy"
              placeholder="Type Fee Strategy"
              value={formik.values.feeStrategy}
              hasError={Boolean(formik.errors.feeStrategy && formik.touched.feeStrategy)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </ContainerInputs>
          <Spacer size={3} />
          <ContainerInputs>
            <Input
              label="MarsFee Strategy"
              name="marsFeeStrategy"
              placeholder="Type MarsFee Strategy"
              value={formik.values.marsFeeStrategy}
              hasError={Boolean(formik.errors.marsFeeStrategy && formik.touched.marsFeeStrategy)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Input
              label="VS Cost"
              name="vcost"
              placeholder="Type VS Cost"
              type="number"
              value={(formik.values.vcost ?? "").toString()}
              hasError={Boolean(formik.errors.vcost && formik.touched.vcost)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </ContainerInputs>
          <Spacer size={3} />
          <ContainerInputs>
            <Input
              label="Vetlist"
              name="vetList"
              placeholder="Type Vetlist"
              type="number"
              value={(formik.values.vetList ?? "").toString()}
              hasError={Boolean(formik.errors.vetList && formik.touched.vetList)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Input
              label="MAP"
              name="map"
              placeholder="Type MAP"
              type="number"
              value={(formik.values.map ?? "").toString()}
              hasError={Boolean(formik.errors.map && formik.touched.map)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </ContainerInputs>
          <Spacer size={3} />
          <ContainerInputs>
            <PercentInput
              field={{
                label: "Margin Focused Margin",
                name: "marginFocused",
                placeholder: "Type MFM",
                value: (formik.values.marginFocused ?? "").toString(),
                error: Boolean(formik.errors.marginFocused && formik.touched.marginFocused),
                InputProps: {
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                },
              }}
              handleChange={formik.handleChange}
            />
            <Input
              label="Equine Fee"
              name="equineFee"
              placeholder="Type Equine Fee"
              type="number"
              value={(formik.values.equineFee ?? "").toString()}
              hasError={Boolean(formik.errors.equineFee && formik.touched.equineFee)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </ContainerInputs>
          <Spacer size={3} />
          <Input
            label="Equine Retail Mark Up"
            name="equineRetailMarkUp"
            placeholder="Type Equine Retail Mark Up"
            type="number"
            value={(formik.values.equineRetailMarkUp ?? "").toString()}
            hasError={Boolean(formik.errors.equineRetailMarkUp && formik.touched.equineRetailMarkUp)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Spacer size={3} />
          <RxRequiredSwitchContainer>
            <Box justifyContent="center" marginLeft="15px">
              <Typography variant="body1" color="textPrimary">
                Rx Required
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Switch
                id="rxrequired"
                name="rxrequired"
                checked={formik.values.rxrequired}
                onChange={formik.handleChange}
                color="primary"
              />
              <Typography variant="body1" color="textSecondary">
                {formik.values.rxrequired ? "Active" : "Inactive"}
              </Typography>
            </Box>
          </RxRequiredSwitchContainer>
          <WizardHeader
            title="Packaging information?"
            subtitle="Please complete the information about how this product is packed"
          />
          <ContainerInputs>
            <Input
              label="Purchase Size"
              name="purchaseSize"
              placeholder="Type purchase size"
              value={formik.values.purchaseSize}
              hasError={Boolean(formik.errors.purchaseSize && formik.touched.purchaseSize)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Input
              label="Strength/Size"
              name="productSize"
              placeholder="Type strength/size"
              value={formik.values.productSize}
              hasError={Boolean(formik.errors.productSize && formik.touched.productSize)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </ContainerInputs>
          <Spacer size={3} />
          <ContainerInputs>
            <Input
              label="Weight"
              name="weight"
              placeholder="Type weight"
              type="number"
              value={(formik.values.weight || "").toString()}
              hasError={Boolean(formik.errors.weight && formik.touched.weight)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </ContainerInputs>
          <Spacer size={3} />
          <ContainerInputs>
            <Input
              label="Packaging"
              name="packaging"
              placeholder="Type packaging"
              value={formik.values.packaging}
              hasError={Boolean(formik.errors.packaging && formik.touched.packaging)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Input
              label="Breakdown"
              name="breakdown"
              placeholder="Type breakdown"
              type="number"
              value={(formik.values.breakdown || "").toString()}
              hasError={Boolean(formik.errors.breakdown && formik.touched.breakdown)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </ContainerInputs>
        </StepContent>
        <NewProductWizardFooter handleBack={handleBack} handleNext={handleNext} />
      </>
    )
  }
)

export { ProductStep }

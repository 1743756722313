import React from "react"
import { ReactComponent as SvgLogo } from "assets/images/logo.svg"
import { LogoContainer } from "./styles"

interface ILogo {
  className?: string
}

const Logo = ({ className }: ILogo): JSX.Element => {
  return (
    <LogoContainer data-testid="logo" className={className}>
      <SvgLogo />
    </LogoContainer>
  )
}

Logo.defaultProps = {
  className: "",
}

export { Logo }
